import styled from 'styled-components';
import { RadioContainer } from 'components/atoms/radio-button/styled';
import { InputContainer } from 'components/atoms/input-currency/styled';

export const RadiolistContainer = styled.div`
  position: relative;

  ${RadioContainer} {
    position: relative;
    margin-top: 33px;

    &:first-child {
      margin-bottom: 0;
    }
  }

  ${InputContainer} {
    margin-top: 15px;
  }

  .button-interrogation {
    position: absolute;
    top: 0;
    left: 146px;
  }
`;

export const RadioItem = styled.div`
  position: relative;
`;
