/* eslint-disable no-param-reassign */
import axios from 'axios';
import { IClient } from 'models/user';
import { firebaseService } from './firebase-service';

class ClientsService {
  async getInstance() {
    const userToken = await firebaseService.getUserToken();

    return axios.create({
      baseURL: process.env.REACT_APP_API_URL,

      headers: {
        Authorization: userToken
          ? `Bearer ${userToken}`
          : process.env.REACT_APP_API_TOKEN,
      },
    });
  }

  async getFavoritesClients() {
    const clients = await (await this.getInstance())
      .get<IClient[]>(`clients/favorites`)
      .then((res) =>
        res.data.map((client) => {
          return {
            ...client,
            assessment: client.score
              ? `Nota ${client.score}`
              : 'Sem avaliações',
          };
        }),
      );
    return clients;
  }

  async getClientByUid(uid: string) {
    return (await this.getInstance())
      .get<IClient>(`/clients/${uid}`)
      .then((res) => res.data);
  }

  async getClientEvaluationsByUid(uid: string) {
    return (await this.getInstance())
      .get<any[]>(`/clients/${uid}/evaluations`)
      .then((res) => res.data);
  }
}

export const clientsService = new ClientsService();
