import { useAlert } from 'context/alert-context';
import { useAsync } from 'context/async-context';
import { ProfileEnum } from 'enums/profile';
import { SectionLink, SectionTitle } from 'pages/profile/styled';
import React from 'react';
import { meService } from 'services/me-service';
import { LinkTypography, Typography } from 'styles/typography';

interface Props {
  tab: string;
  onProfileCreated: () => Promise<unknown>;
}
const NoProfileTab: React.FC<Props> = ({ tab, onProfileCreated }) => {
  const { handledAsync } = useAsync();
  const { showToast } = useAlert();

  const createClientProfile = () => {
    handledAsync(async () => {
      await meService.createClientProfile();
      await onProfileCreated();
      showToast({ text: 'Perfil criado com sucesso!' });
    });
  };

  return (
    <>
      <SectionTitle fontSize="body1" fontWeight="normal">
        Seja um{' '}
        {tab === ProfileEnum.WORKER.toString() ? 'prestador' : 'cliente'} no Tô
        Parado!
      </SectionTitle>
      {tab === ProfileEnum.WORKER.toString() && (
        <SectionLink to="/criar-perfil-prestador">
          <Typography
            textDecoration="underline"
            color="primary"
            fontSize="body1"
            align="left"
            lineHeight="120%"
          >
            Criar conta de prestador
          </Typography>
        </SectionLink>
      )}
      {tab === ProfileEnum.CLIENT.toString() && (
        <SectionLink as="button" onClick={createClientProfile} to="">
          <LinkTypography
            color="primary"
            fontSize="body1"
            align="left"
            lineHeight="120%"
          >
            Criar conta de cliente
          </LinkTypography>
        </SectionLink>
      )}
    </>
  );
};

export default NoProfileTab;
