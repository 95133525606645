import styled, { css } from 'styled-components';
import { Typography } from 'styles/typography';

interface AddressProps {
  show?: boolean;
}
export const AddressText = styled(Typography)<AddressProps>`
  opacity: 0;
  transition: all 0.3s;
  transform: translateX(-80px);

  ${({ show }) =>
    show &&
    css`
      opacity: 1;
      transform: translateX(0);
    `}
`;
