/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from 'components/atoms/button';
import Header from 'components/molecules/header';
import CategorySelector from 'components/organisms/category-selector';
import { IModalDefaultProps } from 'modals/types';
import { ICategory, ISkill } from 'models/skill';
import { useState } from 'react';
import { Modal, ModalTransition } from 'react-simple-hook-modal';
import { FlexContainer } from 'styles/flex-container';
import { Spacing } from 'styles/spacing';

interface ISearchProps extends IModalDefaultProps {
  onSelect: (items: ISkill[]) => void;
  initialSubcategories?: ISkill[];
  defaultTitle?: string;
}

const SearchModal: React.FC<ISearchProps> = ({
  isModalOpen,
  onSelect,
  onClose,
  initialSubcategories = [],
  defaultTitle = '',
}) => {
  const [category, setCategory] = useState<ICategory>();
  const [subcategories, setSubcategories] = useState<ISkill[]>([]);

  const title = category?.name || defaultTitle;

  return (
    <Modal
      id="search-modal"
      isOpen={isModalOpen}
      modalClassName="no-padding"
      transition={ModalTransition.BOTTOM_UP}
    >
      <FlexContainer fullPageHeight>
        <Header title={title} onBack={onClose} />
        <Spacing size={3} />
        <CategorySelector
          multiple
          onCategorySelected={setCategory}
          onSubcategorySelected={setSubcategories}
          initialSubcategories={initialSubcategories.map(
            (item) => item.skill_id,
          )}
          resetOnBackToCategories
        />
        <Button
          disabled={!subcategories?.length}
          disableChevron
          onClick={() => onSelect(subcategories)}
        >
          Buscar
        </Button>
      </FlexContainer>
    </Modal>
  );
};

export default SearchModal;
