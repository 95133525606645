import defaultProfilePhoto from 'assets/img/default-profile-photo.png';
import NavTabs from 'components/molecules/nav-tabs';
import NoProfileTab from 'components/molecules/no-profile-tab';
import TitleHeader from 'components/molecules/title-header';
import { useAsync } from 'context/async-context';
import { useUserContext } from 'context/user-context';
import { ProfileEnum } from 'enums/profile';
import { useEffect, useState } from 'react';
import { FlexContainer } from 'styles/flex-container';
import { Typography } from 'styles/typography';
import { IconChevronRight, IconChecked } from 'styles/icons';
import { firebaseService } from 'services/firebase-service';
import { profileMenuItems } from './config';
import MyServices from './my-services';
import {
  SectionLink,
  SectionTitle,
  NotificationBadge,
  LabelContainer,
  SectionDefault,
} from './styled';

const Profile = () => {
  const [activeTab, setActiveTab] = useState<string>('');
  const [userProviders, setUserProviders] = useState<string[]>([]);
  const {
    user,
    loadUserData,
    loadProfileData,
    worker,
    client,
    logout,
    unregisterDeviceToken,
    missingProfileData,
    isWorker,
    isClient,
  } = useUserContext();
  const { handledAsync } = useAsync();

  useEffect(() => {
    if (user) {
      const providers = firebaseService.getUserProvider() || [];
      setUserProviders(providers);
      handledAsync(async () => {
        await onTabChanged(user.profiles[0]);
      });
    }
  }, []);

  useEffect(() => {
    if (isWorker || missingProfileData?.length) {
      onTabChanged(ProfileEnum.WORKER);
    }
  }, [isWorker]);

  const onTabChanged = async (profile: ProfileEnum) => {
    setActiveTab(`${profile}`);
    await loadProfileData(profile);
  };

  const menuItemClicked = (action?: string) => {
    if (action === 'logout') {
      handledAsync(async () => {
        await unregisterDeviceToken();
        await logout();
      });
    }
  };

  const userHasTabProfile = user?.profiles
    ?.map((item) => item.toString())
    .includes(activeTab);

  const showNotificationBadge = (route: string): boolean =>
    missingProfileData.findIndex((eachRoute) => eachRoute === route) >= 0;

  return (
    <FlexContainer>
      <TitleHeader
        avatarImg={user?.picture_path || defaultProfilePhoto}
        title={user?.name || ''}
        allowPhotoUpload
        hasPreviousPicture={!!user?.picture_path}
      />
      <NavTabs
        tabs={[
          { value: ProfileEnum.CLIENT, label: 'Cliente' },
          { value: ProfileEnum.WORKER, label: 'Prestador' },
        ]}
        activeTab={activeTab}
        onTabChange={onTabChanged}
        noMarginTop
      />

      {!userHasTabProfile && (
        <NoProfileTab tab={activeTab} onProfileCreated={loadUserData} />
      )}

      {profileMenuItems
        .filter(
          (section) =>
            userHasTabProfile &&
            (!section.profile || section.profile === activeTab),
        )
        .map((section) => (
          <div key={section.title}>
            <SectionTitle
              fontSize="body1"
              fontWeight="normal"
              lineHeight="120%"
            >
              {section.title}
            </SectionTitle>

            {section.links.map((link) => {
              let { route } = link;
              if (route) {
                if (route.indexOf('/perfil-prestador') >= 0) {
                  route = `${route}/${worker?.worker_uid}`;
                } else if (route.indexOf('/perfil-cliente') >= 0) {
                  route = `${route}/${client?.client_uid}`;
                }
                return (
                  <SectionLink to={route} key={route}>
                    <LabelContainer>
                      <Typography color="primary">{link.label}</Typography>
                      {showNotificationBadge(route) && <NotificationBadge />}
                    </LabelContainer>

                    <IconChevronRight />
                  </SectionLink>
                );
              }

              if (['Facebook', 'Google'].includes(link.label)) {
                const isGoogleProvider =
                  link.label === 'Google' &&
                  userProviders.findIndex(
                    (eachProvider) => eachProvider === 'google.com',
                  ) >= 0;

                const isFacebookProvider =
                  link.label === 'Facebook' &&
                  userProviders.findIndex(
                    (eachProvider) => eachProvider === 'facebook.com',
                  ) >= 0;
                return (
                  <SectionDefault key={link.action || link.label}>
                    <Typography>{link.label}</Typography>
                    {(isGoogleProvider || isFacebookProvider) && (
                      <IconChecked />
                    )}
                  </SectionDefault>
                );
              }

              return (
                <SectionLink
                  key={link.action || link.label}
                  as="button"
                  onClick={() => menuItemClicked(link.action)}
                  to=""
                >
                  <Typography>{link.label}</Typography>
                </SectionLink>
              );
            })}
          </div>
        ))}

      {activeTab === ProfileEnum.CLIENT && userHasTabProfile && !isClient && (
        <MyServices />
      )}
    </FlexContainer>
  );
};

export default Profile;
