import { Button } from 'components/atoms/button/index';
import { useModalStepsContext } from 'context/modal-steps-context';
import { colors } from 'styles/colors';
import { IconClose } from 'styles/icons';
import { Spacing } from 'styles/spacing';
import { Typography } from 'styles/typography';

export const ContactIntro = () => {
  const { nextStep, closeModal } = useModalStepsContext();

  return (
    <>
      <div style={{ textAlign: 'right' }}>
        <Spacing size={5} />
        <IconClose width={24} fill={colors.white} onClick={closeModal} />
        <Spacing size={5} />
      </div>
      <Typography
        as="h3"
        color="white"
        fontSize="heading3"
        fontWeight="bold"
        letterSpacing="-0.01em"
      >
        Contato de <br />
        emergência
      </Typography>
      <Spacing size={7} />
      <Typography as="p" fontWeight="normal">
        O App Tô Parado se preocupa com a sua segurança e por isso criou a
        função de Emergência.
        <br />
        <br />
        Por ligação, a gente vai entrar em contato com seus contatos de
        emergências e compartilhar dados do seu serviço, como: Sua localização e
        informações de contato.
      </Typography>
      <Button onClick={nextStep}>Adicionar contato</Button>
    </>
  );
};
