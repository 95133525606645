import CheckList from 'components/organisms/check-list';
import RadioList from 'components/organisms/radio-list';
import { ISkill } from 'models/skill';
import React from 'react';

interface Props {
  skills?: ISkill[];
  multiple?: boolean;
  onSelected: (items: ISkill[]) => void;
  checked?: ISkill[];
}

const SubcategoryList: React.FC<Props> = ({
  skills = [],
  onSelected,
  multiple,
  checked = [],
}) => {
  const handleItemSelected = (items: string[]) => {
    const tmpSelected = skills.filter(
      (skill) => items.indexOf(`${skill.skill_id}`) >= 0,
    );
    onSelected(tmpSelected);
  };

  const mappedItems = skills.map((skill) => ({
    id: `${skill.skill_id}`,
    label: skill.skill,
  }));

  if (multiple) {
    return (
      <CheckList
        checkboxItems={mappedItems}
        onChange={handleItemSelected}
        checked={checked.map((item) => `${item.skill_id}`)}
      />
    );
  }

  return (
    <RadioList
      radioItems={mappedItems}
      onChange={(id) => handleItemSelected([id])}
      value={checked?.length ? `${checked[0].skill_id}` : ''}
    />
  );
};

export default SubcategoryList;
