import React from 'react';
import { IconSearch } from 'styles/icons';
import { InputContainer, InputStyled } from './styled';

interface IInputPasswordProps {
  onChange: (text: string) => void;
  placeholder?: string;
  value?: string;
}

const InputSearch: React.FC<IInputPasswordProps> = ({
  placeholder,
  onChange,
  value = '',
}) => {
  return (
    <InputContainer>
      <InputStyled
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
        value={value}
      />
      <IconSearch />
    </InputContainer>
  );
};

export default InputSearch;
