import HistoriaImg from 'assets/img/historia.png';
import TrabalhoImg from 'assets/img/seu-trabalho.png';
import Benefit from 'components/atoms/benefit';
import { Button } from 'components/atoms/button';
import Image from 'components/atoms/image';
import { IModalDefaultProps } from 'modals/types';
import { Modal, ModalTransition } from 'react-simple-hook-modal';
import { colors } from 'styles/colors';
import { FlexContainer, ScrollView } from 'styles/flex-container';
import { IconClose } from 'styles/icons';
import { Spacing } from 'styles/spacing';
import { Typography } from 'styles/typography';
import MosaicoImg from 'assets/img/mosaico.png';
import { responsiveSize } from 'styles/sizes';
import { IconCloseContainer } from './styled';

const benefits = [
  {
    image: HistoriaImg,
    title: 'História pessoal',
    text:
      'Onde você nasceu? Quais as suas principais dificuldades e vitórias na vida?',
  },
  {
    image: TrabalhoImg,
    title: 'Seu trabalho',
    text: 'Como você aprendeu a fazer seu trabalho? Tem planos para o futuro?',
  },
];

const StoryModal: React.FC<IModalDefaultProps> = ({ isModalOpen, onClose }) => {
  return (
    <Modal
      id="story-modal"
      modalClassName="no-padding"
      isOpen={isModalOpen}
      transition={ModalTransition.BOTTOM_UP}
    >
      <FlexContainer padding="0" darkMode fullPageHeight overflow="hidden">
        <ScrollView>
          <FlexContainer>
            <IconCloseContainer>
              <IconClose width={24} fill={colors.white} onClick={onClose} />
            </IconCloseContainer>
            <Typography
              as="h3"
              color="white"
              fontSize="heading3"
              fontWeight="bold"
              letterSpacing="-0.01em"
              display="block"
              margin={`0 0 ${responsiveSize(3)} 0`}
            >
              Sua história
            </Typography>

            <Image src={MosaicoImg} height={240} />

            <Typography
              as="p"
              margin={`${responsiveSize(4)} 0 ${responsiveSize(5)} 0`}
            >
              O App Tô Parado quer aproximar clientes e prestadores de forma
              humana e honesta.
              <br />
              <br />
              Conte um pouco da sua história de vida e crie relacionamento com
              seus clientes que vai além do serviço prestado.
            </Typography>
            <Benefit benefitList={benefits} />
          </FlexContainer>
        </ScrollView>
        <Button disableChevron bottomFixed={false} margin="0" onClick={onClose}>
          Contar minha história
        </Button>
      </FlexContainer>
    </Modal>
  );
};

export default StoryModal;
