import axios from 'axios';
import { firebaseService } from './firebase-service';

class LocationService {
  async getInstance() {
    const userToken = await firebaseService.getUserToken();

    return axios.create({
      baseURL: process.env.REACT_APP_API_URL,

      headers: {
        Authorization: userToken
          ? `Bearer ${userToken}`
          : process.env.REACT_APP_API_TOKEN,
      },
    });
  }

  async getFullAddress(lat: number, lng: number) {
    if (!(lat && lng)) return null;
    return (await this.getInstance())
      .get(`/domains/full-address?lat=${lat}&lng=${lng}`)
      .then((res) => res.data);
  }

  async getServiceDirections(
    origin: string,
    serviceUid: string,
    modes?: string,
  ) {
    const modesQuery = modes ? `&modes=${modes}` : '';
    return (await this.getInstance())
      .get(`/services/${serviceUid}/directions?origin=${origin}${modesQuery}`)
      .then((resp) => resp.data);
  }
}
export const locationService = new LocationService();
