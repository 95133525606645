import styled from 'styled-components';
import { colors } from 'styles/colors';

export const CodeWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  width: 100%;
  justify-content: space-between;
`;

export const CodeSquareWrapper = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  justify-content: center;
  gap: 10px;
`;

export const CodeSquare = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border: 2px solid ${colors.text};
`;

export const CodeInput = styled.input`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 230px;
  height: 50px;
  opacity: 0;
`;
