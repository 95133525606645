import styled from 'styled-components';
import { responsiveSize } from 'styles/sizes';

export const QuestionList = styled.ul`
  margin-top ${responsiveSize(7)};

  li:last-child{
    padding-bottom: 0;
  }
`;

export const QuestionWrapper = styled.div`
  flex: 1;
`;
