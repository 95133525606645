import { Button } from 'components/atoms/button/index';
import SimpleSlider from 'components/molecules/simple-slider';
import { useAlert } from 'context/alert-context';
import { useAsync } from 'context/async-context';
import { useStepsContext } from 'context/steps-context';
import { IServiceStatusEnum } from 'enums/service-status';
import { capitalizeFirstChar } from 'helpers';
import { IServicePatch } from 'models/service';
import { ISkillsResponse } from 'models/skill';
import { useEffect, useState } from 'react';
import { skillsService } from 'services/skills-service';
import { Spacing } from 'styles/spacing';
import { Typography } from 'styles/typography';

export const ReviewCreatedService = () => {
  const [skillsResponse, setSkillsResponse] = useState<ISkillsResponse>();
  const { nextStep, getData, patchData } = useStepsContext();
  const { handledAsync } = useAsync();
  const { showToast } = useAlert();

  const previousData = getData<IServicePatch>();

  useEffect(() => {
    handledAsync(async () => {
      setSkillsResponse(await skillsService.getAll());
    });
  }, []);

  const btnClicked = async () => {
    handledAsync(async () => {
      await patchData<IServicePatch>(
        { status: IServiceStatusEnum.PUBLISHED },
        true,
      );
      nextStep();
      showToast({ text: 'Serviço publicado com sucesso!' });
    });
  };

  const category = skillsResponse?.categories.find(
    (item) => `${item.id}` === `${previousData.category}`,
  )?.name;
  const subCategory = skillsResponse?.skills.find(
    (item) => `${item.skill_id}` === `${previousData.sub_category}`,
  )?.skill;

  return (
    <>
      <Typography
        as="h3"
        color="title"
        fontSize="heading3"
        fontWeight="bold"
        lineHeight="48px"
        display="block"
        letterSpacing="-0.01em"
        margin="0 0 45px"
      >
        Revise as informações antes de publicar
      </Typography>
      <SimpleSlider
        infinite={false}
        items={
          previousData.pictures?.map((item) => ({
            image: item.path,
          })) || []
        }
        height={240}
      />
      <Typography
        as="span"
        color="text"
        fontSize="body1"
        fontWeight="normal"
        lineHeight="120%"
        display="block"
        margin="10px 0 32px"
      >
        {capitalizeFirstChar(subCategory)} · {previousData.location?.district}
      </Typography>
      <Typography
        as="p"
        color="text"
        fontSize="body2"
        fontWeight="normal"
        display="block"
      >
        {previousData.description}
        <br />
        <br />
        {previousData.location?.fullAddress}
      </Typography>
      <Spacing size={7} />
      <Button onClick={btnClicked} disableChevron>
        Publicar
      </Button>
    </>
  );
};
