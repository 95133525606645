/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Button } from 'components/atoms/button';
import InputPassword from 'components/atoms/input-password';
import { useAlert } from 'context/alert-context';
import { useAsync } from 'context/async-context';
import { useModalStepsContext } from 'context/modal-steps-context';
import { useState } from 'react';
import { firebaseService } from 'services/firebase-service';
import { Typography } from 'styles/typography';
import { IChangePasswordModel } from './types';

const NewPassword = () => {
  const [inputValue, setInputValue] = useState('');
  const { nextStep, getData } = useModalStepsContext();
  const { showToast, showAlert } = useAlert();
  const { handledAsync } = useAsync();

  const { currentPassword } = getData<IChangePasswordModel>();

  const handleBtnClicked = () => {
    if (currentPassword === inputValue) {
      showAlert({
        title: 'Senha inválida',
        text: 'A nova senha deve ser diferente da senha atual.',
      });
      return;
    }
    handledAsync(async () => {
      await firebaseService.changePassword(currentPassword, inputValue);
      showToast({
        text: 'Senha alterada',
      });
      nextStep();
    });
  };

  return (
    <>
      <Typography
        as="h3"
        color="title"
        fontSize="heading3"
        fontWeight="bold"
        display="block"
        letterSpacing="-0.01em"
        margin="0 0 45px"
      >
        Digite sua nova senha. Ela precisa ter pelo menos 6 caracteres.
      </Typography>
      <InputPassword onChange={setInputValue} />
      <Button disabled={!inputValue} onClick={handleBtnClicked} disableChevron>
        Finalizar
      </Button>
    </>
  );
};

export default NewPassword;
