import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { responsiveSize } from 'styles/sizes';

export const ListItem = styled(Link)`
  padding-bottom: ${responsiveSize(6)};
  text-decoration: none;

  &:last-child {
    padding-bottom: 0;
  }
`;
