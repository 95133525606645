import styled, { css } from 'styled-components';
import { responsiveSize } from './sizes';

interface SpacingProps {
  size: number;
  direction?: 'horizontal' | 'vertical';
  inline?: boolean;
}
export const Spacing = styled.span<SpacingProps>`
  display: ${({ inline }) => (inline ? 'inline-' : '')}block;
  ${({ direction = 'horizontal', size = 1 }) =>
    direction === 'horizontal'
      ? css`
          width: 100%;
          height: ${responsiveSize(size)};
        `
      : css`
          width: ${responsiveSize(size)};
          height: 100%;
        `}
`;
