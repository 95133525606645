import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useState,
  useContext,
} from 'react';

interface ILoadingContext {
  isLoading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

export const Context = createContext<ILoadingContext>({} as ILoadingContext);
export const useLoader = () => useContext(Context);

const LoaderContext: React.FC<unknown> = ({ children }) => {
  const [isLoading, setLoading] = useState(false);

  return (
    <Context.Provider value={{ isLoading, setLoading }}>
      {children}
    </Context.Provider>
  );
};

export default LoaderContext;
