import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from 'styles/colors';
import { responsiveSize } from 'styles/sizes';
import { Typography } from 'styles/typography';

interface SectionLinkProps {
  social?: boolean;
}

export const SectionTitle = styled(Typography)`
  padding: ${responsiveSize(6)} 0 ${responsiveSize(2)} 0;
`;

export const SectionDefault = styled.div`
  padding: ${responsiveSize(2)} 0;
  text-decoration: none;
  display: flex;
  text-align: left;
  color: ${colors.primary};
  justify-content: space-between;
  align-items: center;

  svg {
    width: 14px;
  }
`;

export const SectionLink = styled(Link)<SectionLinkProps>`
  padding: ${responsiveSize(2)} 0;
  text-decoration: none;
  display: flex;
  text-align: left;
  color: ${colors.primary};
  justify-content: space-between;
  align-items: center;

  svg {
    width: 6px;
  }

  &:hover {
    text-decoration: underline;
  }

  &:last-child {
    padding-bottom: 0;
  }
`;

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const NotificationBadge = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${colors.primary};
  border-radius: 4px;
  margin-left: 8px;
`;
