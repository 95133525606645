/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from 'components/atoms/button';
import NavTabs from 'components/molecules/nav-tabs';
import CheckList from 'components/organisms/check-list';
import RadioList from 'components/organisms/radio-list';
import { FilterTypeEnum } from 'enums/filter-type';
import { IModalDefaultProps } from 'modals/types';
import { IIdLabelOption } from 'models/id-label-option';
import React, { useState } from 'react';
import { Modal, ModalTransition } from 'react-simple-hook-modal';
import { IconClose } from 'styles/icons';
import { TabContent, TabStyled } from './styled';

interface IFilterModalProps extends IModalDefaultProps {
  filtersOptions: IIdLabelOption[];
  sortOptions: IIdLabelOption[];
  sortTabName?: string;
  filtersTabName?: string;
  filters: any;
  sort: any;
  onChange: (data: { filters: any; sort: any }) => void;
}

const FiltersModal: React.FC<IFilterModalProps> = ({
  isModalOpen,
  onClose,
  filtersOptions,
  sortOptions,
  filtersTabName = '',
  sortTabName = 'Ordenar',
  filters,
  sort,
  onChange,
}) => {
  const [activeTab, setActiveTab] = useState(
    `${filtersTabName ? FilterTypeEnum.FILTER : FilterTypeEnum.SORT}`,
  );
  const [tmpFilters, setTmpFilters] = useState<string[]>([]);
  const [tmpSort, setTmpSort] = useState<string>(sort);

  const handleApplyClicked = () => {
    onChange({ filters: tmpFilters, sort: tmpSort });
    onClose();
  };

  const tabs: any = [
    filtersTabName
      ? { value: FilterTypeEnum.FILTER, label: filtersTabName }
      : undefined,
    sortTabName
      ? { value: FilterTypeEnum.SORT, label: sortTabName }
      : undefined,
  ].filter(Boolean);

  return (
    <Modal
      id="filters-modal"
      isOpen={isModalOpen}
      transition={ModalTransition.TOP_DOWN}
    >
      <div style={{ textAlign: 'right' }}>
        <IconClose width={24} onClick={onClose} />
      </div>
      <NavTabs tabs={tabs} activeTab={activeTab} onTabChange={setActiveTab} />
      <TabStyled>
        {activeTab === FilterTypeEnum.SORT ? (
          <TabContent>
            <RadioList
              value={sort}
              onChange={setTmpSort}
              radioItems={sortOptions}
            />
          </TabContent>
        ) : (
          <TabContent>
            <CheckList
              checked={filters}
              checkboxItems={filtersOptions}
              onChange={setTmpFilters}
            />
          </TabContent>
        )}
        <Button disableChevron onClick={handleApplyClicked}>
          Aplicar
        </Button>
      </TabStyled>
    </Modal>
  );
};

export default FiltersModal;
