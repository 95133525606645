import NotificationButton from 'components/atoms/notification-button';
import React, { useState } from 'react';
import { FlexContainer } from 'styles/flex-container';
import { responsiveSize } from 'styles/sizes';
import { ITypographProps, Typography } from 'styles/typography';
import Avatar from '../avatar';
import AvatarUpload from '../avatar-upload';

interface Props {
  title: string;
  hideNotification?: boolean;
  textProps?: Partial<ITypographProps>;
  avatarImg?: string;
  allowPhotoUpload?: boolean;
  hasPreviousPicture?: boolean;
}
const TitleHeader: React.FC<Props> = ({
  title,
  hideNotification,
  textProps,
  avatarImg,
  allowPhotoUpload,
  hasPreviousPicture,
}) => {
  const [uploadMenu, setUploadMenu] = useState(false);

  const toggleUploadMenu = () => {
    setUploadMenu(!uploadMenu);
  };

  return (
    <FlexContainer
      padding={`${responsiveSize(4)} 0`}
      as="header"
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      overflow="hidden"
    >
      {avatarImg && (
        <>
          <Avatar
            image={avatarImg}
            size="lg"
            hideStatus
            onClick={allowPhotoUpload ? toggleUploadMenu : undefined}
          />
          {allowPhotoUpload && (
            <AvatarUpload
              isOpen={uploadMenu}
              handleClose={toggleUploadMenu}
              hasPhoto={hasPreviousPicture}
            />
          )}
        </>
      )}
      <Typography fontSize="body1" lineHeight="120%" as="h1" {...textProps}>
        {title}
      </Typography>
      {!hideNotification && <NotificationButton />}
    </FlexContainer>
  );
};

export default TitleHeader;
