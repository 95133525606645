/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Button } from 'components/atoms/button';
import InputPassword from 'components/atoms/input-password';
import { useModalStepsContext } from 'context/modal-steps-context';
import { useState } from 'react';
import { Typography } from 'styles/typography';
import { IChangePasswordModel } from './types';

const CurrentPassword = () => {
  const [inputValue, setInputValue] = useState('');
  const { nextStep, replaceData } = useModalStepsContext();

  const handleBtnClicked = () => {
    replaceData<IChangePasswordModel>({ currentPassword: inputValue });
    nextStep();
  };

  return (
    <>
      <Typography
        as="h3"
        color="title"
        fontSize="heading3"
        fontWeight="bold"
        display="block"
        letterSpacing="-0.01em"
        margin="0 0 45px"
      >
        Digite sua senha atual
      </Typography>
      <InputPassword onChange={setInputValue} />
      <Button disabled={!inputValue} onClick={handleBtnClicked}>
        Continuar
      </Button>
    </>
  );
};

export default CurrentPassword;
