import { useState } from 'react';
import Slider, { Settings } from 'react-slick';
import ServiceListSkeleton from 'components/skeletons/service-list';
import { IService } from 'models/service';
import { Typography } from 'styles/typography';
import Image from 'components/atoms/image';
import { capitalizeFirstChar, currencyFormatted } from 'helpers';
import { getPaymentTypeDesc } from 'enums/payment-type';
import PlaceholderImg from 'assets/img/servico-placeholder.png';
import CustomActionSheet from 'components/molecules/custom-action-sheet';
import { IconDottedMenu } from 'styles/icons';
import {
  NewServiceButton,
  MyServicesContainer,
  SliderItem,
  LinkStyled,
  StrongStyled,
  ItemFooter,
} from './styled';

interface MyServiceProps {
  data: IService[];
  handleNewService: () => void;
  loading: boolean;
  onRemove: (uid: string) => void;
  onEdit: (uid: string) => void;
}

const settings: Settings = {
  infinite: false,
  arrows: false,
  dots: true,
  autoplay: false,
  autoplaySpeed: 100,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  draggable: true,
};

const MyServices = ({
  data,
  handleNewService,
  loading,
  onEdit,
  onRemove,
}: MyServiceProps) => {
  const [isActionSheetOpen, setIsActionSheetOpen] = useState(false);
  const [confirmActionSheet, setConfirmActionSheet] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleToggleActionSheet = () => {
    setIsActionSheetOpen((currState) => !currState);
  };

  const toggleConfirmActionSheet = () => {
    setConfirmActionSheet((currState) => !currState);
  };

  return (
    <MyServicesContainer>
      {loading ? (
        <ServiceListSkeleton />
      ) : data.length ? (
        <>
          <Typography
            as="h3"
            fontSize="heading3"
            fontWeight="bold"
            letterSpacing="-0.01em"
            margin="0 0 20px 0"
          >
            Seus serviços
          </Typography>
          <Slider
            {...settings}
            afterChange={(slideIndex) => setCurrentSlide(slideIndex)}
          >
            {data.map((eachService) => (
              <SliderItem key={eachService.uid}>
                <Image
                  src={
                    eachService?.pictures?.length
                      ? eachService?.pictures[0].path
                      : PlaceholderImg
                  }
                  height={240}
                />

                <LinkStyled to={`/servico/${eachService.uid}`}>
                  <Typography fontSize="body1" display="block">
                    {capitalizeFirstChar(eachService.sub_category.name)} ·{' '}
                    {eachService.location?.district}
                  </Typography>

                  <Typography
                    fontSize="body2"
                    display="block"
                    margin="7px 0 8px 0"
                  >
                    {eachService.description}
                  </Typography>
                </LinkStyled>

                <ItemFooter>
                  <LinkStyled to={`/servico/${eachService.uid}`}>
                    <Typography fontSize="body1" display="block">
                      {!eachService.payment_value ? (
                        <StrongStyled>
                          {getPaymentTypeDesc(eachService.payment_type)}
                        </StrongStyled>
                      ) : (
                        <>
                          <StrongStyled>
                            {currencyFormatted.format(
                              eachService.payment_value,
                            )}
                          </StrongStyled>
                          {' / '}
                          {getPaymentTypeDesc(eachService.payment_type)}
                        </>
                      )}
                    </Typography>
                  </LinkStyled>

                  <IconDottedMenu onClick={handleToggleActionSheet} />
                </ItemFooter>
              </SliderItem>
            ))}
          </Slider>
        </>
      ) : (
        <Typography
          as="h3"
          fontSize="heading3"
          fontWeight="bold"
          letterSpacing="-0.01em"
        >
          Você tem um serviço pra ser feito? Cadastre aqui!
        </Typography>
      )}

      <NewServiceButton onClick={handleNewService} hasServices={!!data.length}>
        <Typography as="span" fontSize="body2" fontWeight="bold" color="white">
          Cadastrar serviço
        </Typography>
      </NewServiceButton>

      <CustomActionSheet
        items={[
          {
            label: 'Editar serviço',
            onClick: () => onEdit(data[currentSlide].uid),
          },
          {
            label: `Excluir serviço`,
            color: 'primary',
            onClick: toggleConfirmActionSheet,
          },
        ]}
        open={isActionSheetOpen}
        onClose={handleToggleActionSheet}
      />

      <CustomActionSheet
        items={[
          {
            label: 'Deseja excluir esse serviço?',
            isTitle: true,
          },
          {
            label: 'Não, voltar',
          },
          {
            label: `Sim, excluir`,
            color: 'primary',
            onClick: () => onRemove(data[currentSlide].uid),
          },
        ]}
        open={confirmActionSheet}
        onClose={toggleConfirmActionSheet}
      />
    </MyServicesContainer>
  );
};

export default MyServices;
