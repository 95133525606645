import { capitalizeFirstChar } from 'helpers';
import { FlexContainer } from 'styles/flex-container';
import { Spacing } from 'styles/spacing';
import { Typography } from 'styles/typography';
import { Link } from 'react-router-dom';
import Avatar from '../avatar';
import { LeftCol, RightCol } from './styled';

interface IProfileProps {
  name: string;
  image?: string;
  assessment?: string;
  online?: boolean;
  skills?: string[];
  showChatLink?: boolean;
  uid?: string;
  city?: string;
}
const PersonCard: React.FC<IProfileProps> = ({
  name,
  image,
  assessment,
  online,
  skills,
  showChatLink,
  uid,
  city,
}) => {
  const isThreeLines = !!skills?.length;

  const getSkillsText = () => {
    if (!skills) {
      return '';
    }
    if ((skills.length || 0) > 2) {
      return `${skills
        ?.slice(0, 2)
        .map((key) => capitalizeFirstChar(key))
        .join(', ')} e +${skills.length - 2} habilidades`;
    }
    return skills.map((key) => capitalizeFirstChar(key)).join(' e ');
  };

  return (
    <FlexContainer
      padding="0"
      direction="row"
      alignItems={isThreeLines ? undefined : 'center'}
      overflow="hidden"
    >
      <LeftCol>
        {isThreeLines && <Spacing size={1} />}
        <Avatar image={image} online={online} />
      </LeftCol>
      <RightCol>
        <FlexContainer
          padding="0"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography display="block" fontSize="body1" truncate>
            {name}
          </Typography>

          {showChatLink && (
            <Link to={`/conversa/${uid}`} onClick={(e) => e.stopPropagation()}>
              <Typography>Conversar</Typography>
            </Link>
          )}
        </FlexContainer>
        <Typography display="block" fontSize="body2">
          {assessment}
        </Typography>
        {skills?.length && (
          <Typography display="block" fontSize="body2">
            {getSkillsText()}
          </Typography>
        )}
        {city && (
          <Typography display="block" fontSize="body2">
            <Spacing size={1} />
            {city}
          </Typography>
        )}
      </RightCol>
    </FlexContainer>
  );
};

export default PersonCard;
