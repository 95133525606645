/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useState, useContext, ReactNode } from 'react';
import SimpleModal, { ISimpleModalOptions } from 'modals/simple-modal';
import Toast, { IToastOptions } from 'components/molecules/toast';
import AlertModal from 'components/molecules/AlertModal';

interface AlertModalOptions {
  content: ReactNode;
  buttonTitle: string;
  onButtonClicked: () => void;
}

interface IAlertContext {
  showAlert: (options: ISimpleModalOptions) => void;
  showErrorAlert: (err: any, customMessage?: string) => void;
  showToast: (options: IToastOptions) => void;
  isAlertOpen: boolean;
  showAlertModal: (options: AlertModalOptions) => void;
}

export const Context = createContext<IAlertContext>({} as IAlertContext);
export const useAlert = () => useContext(Context);

const AlertContext: React.FC<unknown> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalOptions, setModalOptions] = useState<ISimpleModalOptions>();
  const [toastOptions, setToastOptions] = useState<IToastOptions>(
    {} as IToastOptions,
  );
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
  const [
    alertModalOptions,
    setAlertModalOptions,
  ] = useState<null | AlertModalOptions>(null);

  const showAlert = (options: ISimpleModalOptions) => {
    setModalOptions(options);
    setIsOpen(true);
  };

  const showToast = (options: IToastOptions) => {
    setToastOptions(options);
    setIsToastOpen(true);
  };

  const showAlertModal = (options: AlertModalOptions) => {
    setIsAlertModalOpen(true);
    setAlertModalOptions(options);
  };

  const closeModal = () => {
    setModalOptions(undefined);
    setIsOpen(false);
  };

  const showErrorAlert = (err: any, customMessage?: string) => {
    const responseMessage = err.response?.data?.message;
    // eslint-disable-next-line no-console
    console.error('ALERT', responseMessage || err);
    let message =
      customMessage ||
      responseMessage ||
      err?.message ||
      'Ocorreu um erro ao efetuar a operação. Por favor, tente novamente.';

    if (message === 'Network Error') {
      message = 'Ocorreu um erro ao conectar com o servidor. Tente novamente.';
    }
    showAlert({
      title: err?.title || 'Erro',
      text: message,
      htmlId: 'error-modal',
    });
  };

  const handleToastDismiss = () => {
    setIsToastOpen(false);
    if (toastOptions.onDismiss) toastOptions.onDismiss();
  };

  return (
    <Context.Provider
      value={{
        isAlertOpen: isOpen,
        showAlert,
        showErrorAlert,
        showToast,
        showAlertModal,
      }}
    >
      {children}
      <SimpleModal
        {...modalOptions}
        onClose={closeModal}
        isModalOpen={isOpen}
      />
      <Toast
        {...toastOptions}
        isOpen={isToastOpen}
        onDismiss={handleToastDismiss}
      />
      <AlertModal
        isOpen={isAlertModalOpen}
        content={alertModalOptions?.content}
        buttonTitle={alertModalOptions?.buttonTitle}
        onButtonClicked={() => {
          alertModalOptions?.onButtonClicked();
          setIsAlertModalOpen(false);
        }}
      />
    </Context.Provider>
  );
};

export default AlertContext;
