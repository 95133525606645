import { Button } from 'components/atoms/button';
import InputEmail from 'components/atoms/input-email';
import { useAsync } from 'context/async-context';
import { useStepsContext } from 'context/steps-context';
import { ProfileEnum } from 'enums/profile';
import { ILead } from 'models/lead';
import { useState } from 'react';
import { Typography } from 'styles/typography';

const RegisterEmail = () => {
  const [inputValue, setInputValue] = useState('');
  const [isValid, setIsValid] = useState(true);

  const { getData, patchData, nextStep, profile } = useStepsContext();
  const { handledAsync } = useAsync();

  const savedData = getData<ILead>();

  const onEmailChanged = (value: string, valid?: boolean) => {
    setInputValue(value);
    setIsValid(Boolean(valid));
  };

  const btnClicked = async () => {
    handledAsync(async () => {
      await patchData<ILead>({ email: inputValue }, true);
      nextStep();
    });
  };

  return (
    <>
      <Typography
        as="h3"
        color="title"
        fontSize="heading3"
        fontWeight="bold"
        lineHeight="48px"
        display="block"
        letterSpacing="-0.01em"
        margin="0 0 51px"
      >
        Qual o seu email?
      </Typography>
      <InputEmail
        placeholder="Digite seu email"
        onChange={onEmailChanged}
        initialData={savedData?.email}
        helperText={profile === ProfileEnum.WORKER ? 'Opcional' : ''}
        errorText={isValid ? '' : 'O formato do e-mail é inválido'}
      />
      <Button disabled={!isValid} onClick={btnClicked}>
        Continuar
      </Button>
    </>
  );
};

export default RegisterEmail;
