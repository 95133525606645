import Toolbar, { IToolbarItem } from 'components/molecules/toolbar';
import Chats from 'pages/chats';
import InProgress from 'pages/in-progress';
import Profile from 'pages/profile';
import Workers from 'pages/workers';
import ViewServices from 'pages/view-services';
import GenericNotFound from 'pages/generic-not-found';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from 'routes/private-route';
import { ScrollView } from 'styles/flex-container';
import { HomeContainer } from './styled';

const Home = () => {
  const pages: IToolbarItem[] = [
    {
      route: '/t/servicos',
      label: 'Serviços',
      component: ViewServices,
    },
    {
      route: '/t/prestadores',
      label: 'Prestadores',
      component: Workers,
    },
    {
      route: '/t/trabalhos',
      label: 'Trabalhos',
      component: InProgress,
      authenticated: true,
    },
    {
      route: '/t/conversas',
      label: 'Conversas',
      component: Chats,
      authenticated: true,
    },
    {
      route: '/t/perfil',
      label: 'Perfil',
      component: Profile,
      authenticated: true,
    },
  ];
  return (
    <HomeContainer>
      <ScrollView>
        <Switch>
          {pages.map((page) => {
            if (!page.authenticated) {
              return (
                <Route
                  key={page.label}
                  path={page.route}
                  component={page.component}
                />
              );
            }
            return (
              <PrivateRoute
                key={page.label}
                path={page.route}
                component={page.component}
              />
            );
          })}
          <Route path="">
            <GenericNotFound hideToolbar />
          </Route>
        </Switch>
      </ScrollView>
      <Toolbar />
    </HomeContainer>
  );
};

export default Home;
