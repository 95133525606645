/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from 'components/atoms/button';
import InputSecurityValidate from 'components/atoms/input-security-validate';
import { useAsync } from 'context/async-context';
import { useEffect, useRef, useState } from 'react';
import { Typography } from 'styles/typography';

const RESEND_INTERVAL_SECONDS = 30;

interface ITokenPageProps {
  onConfirmClicked: (code: string) => void;
  onResendClicked: () => Promise<void>;
  buttonText: string;
  disableButtonChevron?: boolean;
}
const TokenPage: React.FC<ITokenPageProps> = ({
  buttonText,
  onConfirmClicked,
  onResendClicked,
  disableButtonChevron,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [count, setCount] = useState(RESEND_INTERVAL_SECONDS);
  const timer = useRef<any>(null);

  const { handledAsync } = useAsync();

  useEffect(() => {
    timer.current = createInterval();
    return () => cancelInterval();
  }, []);

  const createInterval = () => {
    return setInterval(() => {
      setCount((state) => {
        if (state - 1 === 0) {
          cancelInterval();
        }
        return state - 1;
      });
    }, 1000);
  };

  const cancelInterval = () => {
    clearInterval(timer.current);
    timer.current = null;
  };

  const handleResendButton = () => {
    handledAsync(async () => {
      await onResendClicked();
      setCount(RESEND_INTERVAL_SECONDS);
      timer.current = createInterval();
    });
  };

  const canResendCode = count <= 0;

  return (
    <>
      <Typography
        as="h3"
        color="title"
        fontSize="heading3"
        fontWeight="bold"
        lineHeight="48px"
        display="block"
        letterSpacing="-0.01em"
        margin="0 0 21px"
      >
        Validação de segurança
      </Typography>
      <Typography as="p" fontSize="body1" margin="0 0 38px">
        Digite o código de 4 digitos que te enviei por SMS
      </Typography>

      <div>
        <InputSecurityValidate onChange={setInputValue} />

        <Typography
          as={!canResendCode ? 'span' : 'button'}
          textDecoration="underline"
          display="block"
          align="center"
          margin="26px auto 0 auto"
          onClick={canResendCode ? handleResendButton : undefined}
        >
          {!canResendCode ? `Reenviar código em ${count}` : 'Reenviar código'}
        </Typography>
      </div>

      <Button
        disabled={!inputValue}
        onClick={() => onConfirmClicked(inputValue)}
        disableChevron={disableButtonChevron}
      >
        {buttonText}
      </Button>
    </>
  );
};

export default TokenPage;
