import { ProfileEnum } from 'enums/profile';
import ClientDetail from 'pages/client-detail';
import CreateService from 'pages/create-service';
import EmergencyContact from 'pages/emergency-contact';
import Estimate from 'pages/estimate';
import Home from 'pages/home';
import Login from 'pages/login';
import Notifications from 'pages/notifications';
import Onboarding from 'pages/onboarding';
import PersonalInfo from 'pages/personal-info';
import EditSkills from 'pages/profile-edit/edit-skills';
import Register from 'pages/register';
import { ServiceDetail } from 'pages/service-detail';
import Support from 'pages/support';
import SupportDetail from 'pages/support/support-detail';
import ChatBox from 'pages/chat-box';
import WorkerDetail from 'pages/worker-detail';
import { Route, Switch, useHistory } from 'react-router-dom';
import Favorites from 'pages/favorites';
import EmergencyActivated from 'pages/emergency-activated';
import YourStory from 'pages/your-story';
import GenericNotFound from 'pages/generic-not-found';
import EditService from 'pages/create-service/edit-service';
import CreateWorkerProfile from 'pages/profile/create-worker-profile';
import { useUserContext } from 'context/user-context';
import { useNotification } from 'components/molecules/in-app-notification';
import { useEffect } from 'react';
import { StorageKeyEnum } from 'enums/storage-key';
import UpdateProfileRole from 'components/organisms/update-profile-role';
import ScrollToTop from './scroll-to-top';
import PrivateRoute from './private-route';

const Routes = () => {
  const { missingProfileData, user } = useUserContext();
  const { showNotification } = useNotification();
  const history = useHistory();

  const showUpdateProfileModal = !!(user && !user.preferred_role);

  useEffect(() => {
    if (missingProfileData.length) {
      const isMessageAlreadyShown = localStorage.getItem(
        StorageKeyEnum.IN_APP_SHOWN,
      );

      if (!isMessageAlreadyShown && user) {
        showNotification({
          title: 'Complete seu perfil',
          message: 'Algumas informações estão faltando!',
          type: 'internal',
          onClick: () => history.push('/t/perfil'),
        });
        localStorage.setItem(StorageKeyEnum.IN_APP_SHOWN, '1');
      }
    }
  }, [missingProfileData, user]);

  return (
    <>
      <ScrollToTop />
      <UpdateProfileRole shown={showUpdateProfileModal} />

      <Switch>
        <Route exact path="/" component={Onboarding} />
        <Route path="/login" component={Login} />

        <Route path="/cadastro-cliente">
          <Register profile={ProfileEnum.CLIENT} />
        </Route>
        <Route path="/cadastro-prestador">
          <Register profile={ProfileEnum.WORKER} />
        </Route>
        <Route path="/t" component={Home} />

        <PrivateRoute path="/criar-servico" component={CreateService} />
        <Route path="/editar-servico" component={EditService} />

        <Route path="/servico/:serviceUid" component={ServiceDetail} />
        <PrivateRoute
          path="/orcamento/:serviceUid"
          component={Estimate}
          profile={ProfileEnum.WORKER}
        />
        <Route path="/perfil-cliente/:uid?" component={ClientDetail} />
        <Route path="/perfil-prestador/:uid?" component={WorkerDetail} />
        <Route path="/criar-perfil-prestador" component={CreateWorkerProfile} />
        <Route path="/informacoes-pessoais/:profile" component={PersonalInfo} />
        <Route path="/editar-habilidades" component={EditSkills} />
        <Route path="/suporte" exact component={Support} />
        <Route path="/suporte/:id" component={SupportDetail} />
        <Route path="/contato-emergencia" component={EmergencyContact} />
        <Route path="/favoritos" component={Favorites} />
        <Route path="/emergencia" component={EmergencyActivated} />
        <Route path="/sua-historia" component={YourStory} />
        <PrivateRoute path="/notificacoes" component={Notifications} />
        <PrivateRoute path="/conversa/:uid" component={ChatBox} />
        <Route path="">
          <GenericNotFound hideToolbar={false} />
        </Route>
      </Switch>
    </>
  );
};

export default Routes;
