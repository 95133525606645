import { Button } from 'components/atoms/button/index';
import Header from 'components/molecules/header';
import VideoPlayer from 'components/molecules/video-player';
import { useAlert } from 'context/alert-context';
import { useAsync } from 'context/async-context';
import { useUserContext } from 'context/user-context';
import { ProfileEnum } from 'enums/profile';
import { IModalDefaultProps } from 'modals/types';
import { IWorker } from 'models/user';
import { useRef, useState } from 'react';
import { Modal, ModalTransition } from 'react-simple-hook-modal';
import { firebaseService } from 'services/firebase-service';
import { meService } from 'services/me-service';
import { FlexContainer } from 'styles/flex-container';
import { IconVideoUpload } from 'styles/icons';
import { Spacing } from 'styles/spacing';
import { LinkTypography, Typography } from 'styles/typography';
import { FullWidthContainer, PlaceholderUpload } from './styled';

type Props = IModalDefaultProps;
export const StoryVideoModal: React.FC<Props> = ({ isModalOpen, onClose }) => {
  const [videoFile, setVideoFile] = useState<File>();
  const inputRef = useRef<HTMLInputElement>(null);

  const { handledAsync } = useAsync();
  const { showToast, showErrorAlert } = useAlert();
  const { worker, setWorker } = useUserContext();

  const handleBtnClicked = () => {
    handledAsync(async () => {
      if (videoFile) {
        const videoUrl = await firebaseService.uploadFile(videoFile);
        const payload = {
          video_bio_path: videoUrl,
        } as IWorker;
        await meService.patchByProfile(ProfileEnum.WORKER, payload);
        setWorker({ ...worker, ...payload });
      }
      showToast({
        text: 'História atualizada',
      });
      onClose();
    });
  };

  const fileChangedHandler = (e) => {
    const file: File = e.target.files[0];
    if (file.type.indexOf('video') < 0) {
      showErrorAlert({
        message: 'O formato do arquivo escolhido não é suportado.',
      });
    } else {
      setVideoFile(file);
    }
  };

  const triggetInputFile = () => {
    inputRef.current?.click();
  };

  const videoSrc = videoFile || worker?.video_bio_path;

  return (
    <Modal
      id="story-photos-modal"
      modalClassName="no-padding"
      isOpen={isModalOpen}
      transition={ModalTransition.SCALE}
    >
      <FlexContainer fullPageHeight>
        <Header title="" isModal onCloseModal={onClose} />
        <Typography
          as="h3"
          color="title"
          fontSize="heading3"
          fontWeight="bold"
          display="block"
          letterSpacing="-0.01em"
        >
          Vídeo da sua história
        </Typography>
        <Spacing size={5} />
        <FullWidthContainer>
          {!videoSrc ? (
            <PlaceholderUpload onClick={triggetInputFile}>
              <IconVideoUpload />
            </PlaceholderUpload>
          ) : (
            <VideoPlayer src={videoSrc} />
          )}
        </FullWidthContainer>
        <LinkTypography
          as="button"
          display="block"
          margin="auto auto 0"
          fontSize="body1"
          onClick={triggetInputFile}
        >
          {videoSrc ? 'Trocar' : 'Adicionar'} vídeo
        </LinkTypography>
        <Spacing size={10} />
        <input
          type="file"
          accept="video/*"
          ref={inputRef}
          onChange={fileChangedHandler}
        />
        <Button disabled={!videoSrc} onClick={handleBtnClicked} disableChevron>
          Salvar
        </Button>
      </FlexContainer>
    </Modal>
  );
};
