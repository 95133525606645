import styled from 'styled-components';
import { colors } from 'styles/colors';
import { responsiveSize } from 'styles/sizes';
import IconMenu from 'assets/img/icon-menu.svg';

export const ThumbsContainer = styled.div`
  display: flex;
  gap: ${responsiveSize(2)};
  padding: ${responsiveSize(1)} 0 ${responsiveSize(4)} 0;
`;

export const ThumbsSquare = styled.button`
  border: 1px solid ${colors.text};
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.rotated {
    transform: rotateZ(180deg);
  }
  &.active {
    color: ${colors.white};
    background-color: ${colors.text};
  }
`;

export const TextContainer = styled.div`
  .subtitle {
    font-weight: bold;
  }
  .pad-left {
    padding-left: 16px;
  }
  .italic {
    font-style: italic;
  }
  .icon-menu {
    background-image: url(${IconMenu});
    width: 14px;
    height: 20px;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    transform: translateY(25%);
  }
`;
