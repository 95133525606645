/* eslint-disable react/require-default-props */
import { Container, BoxContainer, BoxButton, BoxContent } from './styles';

interface AlertModalProps {
  isOpen: boolean;
  content: React.ReactNode | null;
  buttonTitle?: string;
  onButtonClicked?: () => void;
}

const AlertModal = ({
  isOpen,
  content,
  buttonTitle = '',
  onButtonClicked = () => null,
}: AlertModalProps) => {
  if (!isOpen) return <></>;

  return (
    <Container>
      <BoxContainer>
        <BoxContent>{content}</BoxContent>
        <BoxButton onClick={onButtonClicked}>{buttonTitle}</BoxButton>
      </BoxContainer>
    </Container>
  );
};

export default AlertModal;
