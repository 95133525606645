export const termsData = {
  term: {
    title: 'TERMOS E CONDIÇÕES GERAIS DE USO',
    text: `<strong>A TOPARADO S.A. (TôParado), pessoa jurídica de direito privado constituída sob a forma de sociedade anônima de capital fechado, inscrita no </strong>Cadastro de Pessoas Jurídicas (CNPJ) da Receita Federal do Brasil (RFB) sob o nº 44.256.174/0001-95, <strong>é uma sociedade empresária destinada à exploração comercial da hospedagem de anúncios de prestação e tomada de serviços, através de software proprietário da TôParado, disponibilizado única e exclusivamente pelo aplicativo “TôParado” e pelos endereços eletrônicos “toparado.com.br” e “toparado.app.br” (Plataformas).</strong>
    <br><br>
    <strong>
      O software destina-se a prestadores (Prestadores) e tomadores (Tomadores) de serviço interessados em contratar, direta e pessoalmente, a prestação e tomada de serviços, de qualquer natureza lícita. Ao utilizar os serviços de anúncio oferecidos pela TôParado, mediante acesso de suas Plataformas e emprego de seu software, o Usuário (Usuário) declara que leu atentamente os presentes Termos e Condições Gerais de Uso (Termos) e concorda com tudo neles disposto, assim como com a Política de Privacidade (Política de Privacidade) da TôParado, que é parte integrante destes Termos.
    </strong>
    <br><br>
   <strong> 1. DO OBJETO </strong>
    <br>
    1.1. Os serviços objeto dos presentes Termos consistem em:
    <br>
    1.1.1. Permitir aos Tomadores que utilizem as Plataformas para livremente e sem direcionamento ou interferência anunciar a contratação de serviços, para recebimento de orçamentos por Prestadores, e submeter propostas de serviço, em resposta aos anúncios disponibilizados pelos Prestadores.
    <br>
    1.1.2. Permitir aos Prestadores que utilizem as Plataformas para livremente e sem direcionamento ou interferência anunciar seus serviços e submeter orçamentos em resposta aos anúncios disponibilizados pelos Tomadores.
    <br>
    1.1.3. Viabilizar o contato direto entre Prestadores e Tomadores interessados em adquirir os serviços, por meio da divulgação das informações de contato de uma parte à outra.
    <br>
    1.2. A TôParado, portanto, possibilita que os Usuários contatem-se e negociem entre si diretamente, sem intervir no contato, na negociação ou na efetivação dos negócios, não sendo, nesta qualidade, fornecedor de quaisquer serviços anunciados por seus Usuários nas Plataformas.
    <br>
    1.3. Na qualidade de classificado de serviços, a TôParado não impõe ou interfere em qualquer negociação sobre condição, valor, qualidade, forma ou prazo na contratação entre os Tomadores e Prestadores, tampouco garante a qualidade, ou entrega dos serviços contratados entre os Usuários.
    <br>
    1.4. Ao se cadastrar, o Usuário poderá utilizar todos os serviços disponibilizados nas Plataformas disponíveis, declarando, para tanto, ter lido, compreendido e aceitado os presentes Termos.
    <br><br>
    <strong>2. DA CAPACIDADE PARA CADASTRAR-SE:</strong>
    <br>
    2.1. Os serviços da TôParado estão disponíveis para pessoas físicas e pessoas jurídicas regularmente inscritas nos cadastros de contribuintes federal e estaduais que tenham capacidade legal para contratá-los. Não podem utilizá-los, assim, pessoas que não gozem dessa capacidade, inclusive menores de idade ou pessoas que tenham sido inabilitadas das Plataformas da TôParado, temporária ou definitivamente. Ficam, desde já, os Usuários advertidos das sanções legais cominadas no Código Civil.
    <br>
    2.2. É vedada a criação de mais de um cadastro por Usuário. Em caso de multiplicidade de cadastros elaborados por um só Usuário, a TôParado reserva-se o direito de, a seu exclusivo critério e sem necessidade de prévia anuência dos ou comunicação aos Usuários, inabilitar todos os cadastros existentes e impedir eventuais cadastros futuros vinculados a estes.
    <br>
    2.3. Somente será permitida a vinculação de um cadastro a um CPF/CNPJ, um telefone e um endereço de correio eletrônico, não podendo haver duplicidade de dados em nenhum caso.
    <br>
    2.4. A TôParado pode unilateralmente excluir o cadastro dos Usuários quando verificado que a conduta do Usuário é ou será prejudicial ou ofensiva a outros Usuários, a TôParado e seus funcionários ou a terceiros.
    <br><br>
    <strong>3. DO CADASTRO</strong>
    <br>
    <br>
    3.1. É necessário o preenchimento completo de todos os dados pessoais exigidos pela TôParado no momento do cadastramento, para que o Usuário esteja habilitado a utilizar as Plataformas.
    <br>
    3.2. É de exclusiva responsabilidade dos Usuários fornecer, atualizar e garantir a veracidade dos dados cadastrais, não cabendo à TôParado qualquer tipo de responsabilidade civil e criminal resultante de dados inverídicos, incorretos ou incompletos fornecidos pelos Usuários.
    <br>
    3.3. A TôParado se reserva o direito de utilizar todos os meios válidos e possíveis para identificar seus Usuários, bem como de solicitar dados adicionais e documentos que estime serem pertinentes a fim de conferir os dados pessoais informados.
    <br>
    3.4. Caso a TôParado considere um cadastro, ou as informações nele contidas, suspeito de conter dados errôneos ou inverídicos, a TôParado se reserva o direito de suspender, temporária ou definitivamente, o Usuário responsável pelo cadastramento, assim como impedir e bloquear qualquer publicidade ou cadastro de serviços e cancelar anúncios publicados por este, sem prejuízo de outras medidas que entenda necessárias e oportunas. No caso de aplicação de quaisquer destas sanções, não assistirá aos Usuários direito a qualquer tipo de indenização ou ressarcimento por perdas e danos, lucros cessantes ou danos morais.
    <br>
    3.5. O Usuário acessará sua conta por meio de apelido e senha, comprometendo-se a não informar a terceiros esses dados, responsabilizando-se integralmente pelo uso que deles seja feito.
    <br>
    3.6. O Usuário compromete-se a notificar a TôParado imediatamente, por meio dos canais de contato mantidos pela TôParado nas Plataformas, a respeito de qualquer uso não autorizado de sua conta. O Usuário será o único responsável pelas operações efetuadas em sua conta, uma vez que o acesso só será possível mediante a utilização de senha de seu exclusivo conhecimento.
    <br>
    3.7. Em nenhuma hipótese será permitida a cessão, a venda, o aluguel ou outra forma de transferência da conta. Não se permitirá, ainda, a criação de novos cadastros por pessoas cujos cadastros originais tenham sido cancelados por infrações às políticas da TôParado.
    <br>
    3.8. O apelido que o Usuário utiliza na TôParado não poderá guardar semelhança com o nome TôParado, tampouco poderá ser utilizado qualquer apelido que insinue ou sugira que os serviços serão prestados pela TôParado ou que fazem parte de promoções suas. Também serão eliminados apelidos considerados ofensivos ou que infrinjam a legislação em vigor.
    <br>
    3.9. Não é permitido aos Prestadores se apresentarem aos Tomadores como funcionários, prestadores de serviço ou contratados da TôParado. Os Tomadores entendem que os Prestadores não são subordinados ou vinculados à TôParado de qualquer forma.
    <br>
    3.10. A TôParado se reserva o direito de, unilateralmente e sem prévio aviso, recusar qualquer solicitação de cadastro e de cancelar um cadastro previamente aceito.
    <br><br>
    <strong>
    4. DAS MODIFICAÇÕES DOS TERMOS E CONDIÇÕES GERAIS DE USO
    <br>
    4.1. A TôParado poderá alterar, a qualquer tempo e a seu único e exclusivo critério, estes Termos. Os novos Termos entrarão em vigor 10 (dez) dias depois de publicados na respectiva Plataforma. No prazo de 5 (cinco) dias contados a partir da publicação das modificações, o Usuário deverá informar, por correio eletrônico, caso não concorde com os termos alterados. Nesse caso, o vínculo contratual deixará de existir, desde que não haja contas ou dívidas em aberto. Não havendo manifestação no prazo estipulado, entender-se-á que o Usuário aceitou tacitamente os novos Termos, e o contrato continuará vinculando as partes.
    </strong>
    <br>
    4.2. As alterações não vigorarão em relação a negociações entre Prestador e Tomadores já iniciadas ao tempo em que tais alterações sejam publicadas. Apenas para estes, os Termos valerão com a redação anterior.
    <br>
    4.3. Os serviços oferecidos por TôParado poderão ser diferentes para cada região do país. Estes Termos deverão ser interpretados de acordo com a região em que foi efetuado o cadastro do Prestador.
    <br><br>
    <strong>5. DOS ANÚNCIOS E DO ACEITE DE ORÇAMENTOS</strong>
    <br>
    5.1. Ao publicar um anúncio de contratação para um serviço, os Tomadores prestarão informações básicas sobre os serviços que pretendem contratar e o valor estimado da contratação, que serão disponibilizados aos Prestadores que poderão, de livre vontade, entrar em contato com o Tomador, para fechar a contratação.
    <br>
    5.2. Ao publicar um anúncio de prestação de serviços, os Prestadores prestarão informações básicas sobre suas qualificações, condições de prestação do serviço e orçamento estimado em valor fixo ou variável, que serão disponibilizados aos Tomadores que poderão, de livre vontade, entrar em contato com o Prestador para fechar a contratação.
    <br><br>
    <strong>6. DOS SERVIÇOS E ANÚNCIOS E ORÇAMENTOS PROIBIDOS</strong>
    <br>
    6.1. A TôParado é uma plataforma de classificados online de serviços, estando proibida a veiculação de qualquer anúncio ou pedido de orçamento de venda, aluguel, troca ou qualquer forma de transferência de posse ou propriedade de qualquer bem móvel ou imóvel.
    <br>
    6.2. Estão proibidas também a veiculação de anúncios de serviços ilegais de acordo com a legislação vigente ou que possam ser considerados ofensivos a terceiros.
    <br>
    6.3. A TôParado excluirá, unilateralmente e sem qualquer comunicação prévia, aquele Usuário que desrespeitar as regras contidas nesta seção.
    <br>
    6.4. A TôParado não realiza uma curadoria prévia dos anúncios veiculados na Plataforma.
    <br>
    6.5. Qualquer Usuário ou pessoa física ou jurídica que se sentir ofendido por qualquer anúncio veiculado nas Plataformas poderá requisitar à TôParado que exclua aquele anúncio de suas Plataformas, pelos seus canais de atendimento.
    <br><br>
    <strong> 7. DO SISTEMA DE AVALIAÇÃO DOS USUÁRIOS</strong>
    <br>
    7.1. Os Usuários aceitam e se submetem ao sistema de avaliação adotado pela TôParado.
    <br>
    7.2. Os Usuários concordam que a TôParado poderá cancelar, excluir ou suspender por tempo indeterminado cadastros que apresentem avaliações negativas de forma reiterada.
    <br>
    7.3. Não assistirá aos Usuários qualquer tipo de indenização ou ressarcimento por perdas e danos, lucros cessantes e danos morais, em razão da avaliação atribuída aos serviços ou contratação anunciados, ou cancelamento ou exclusão de cadastro dela decorrentes.
    <br>
    7.4. Tendo em vista que os comentários postados são opiniões pessoais dos Usuários, estes serão responsáveis pelas opiniões publicadas nas Plataformas perante a TôParado, terceiros, órgãos governamentais ou demais Usuários do site, isentando a TôParado de qualquer responsabilidade relativa à veiculação dos comentários, que em nenhum caso refletem ponto de vista ou opinião da TôParado ou seus prepostos.
    <br>
    7.5. A TôParado não excluíra comentários ou qualificações sobre os Usuários. Somente o Usuário responsável pelo comentário ou qualificação poderá remover ou alterar os comentários ou qualificações.
    <br>
    7.6. A TôParado se reserva o direito de excluir unilateralmente e a seu exclusivo critério, comentários que contenham e cadastros de Usuários que pratiquem:
    <br>
    7.6.1. Ofensa à honra, imagem, reputação e dignidade de terceiros.
    <br>
    7.6.2. Pornografia, pedofilia, e outras modalidades de satisfação sexual.
    <br>
    7.6.3. Racismo ou discriminação de qualquer natureza.
    <br>
    7.6.4. Bullying, stalking ou qualquer outra espécie de constrangimento ilegal ou assédio.
    <br>
    7.6.5. Manifesta violação a direito autoral ou direito de imagem.
    <br>
    7.6.6. Utilização de marcas, símbolos, logotipos ou emblemas de terceiros.
    <br>
    7.6.7. Instigação ou apologia à prática de crimes, como tráfico ou uso de entorpecentes, estupro, homicídio, estelionato, dentre outros.
    <br>
    7.6.8.  Erros ou suspeita de equívocos.
    <br>
    7.6.9. Qualquer outra prática de natureza ilícita ou que infrinja direitos de terceiros.
    <br><br>
    <strong>8. DAS OBRIGAÇÕES DOS USUÁRIOS</strong>
    <br>8.1. O Prestador deve ter capacidade legal para prestar o serviço, e o Tomador para contratá-lo.
    <strong><br>8.2. Em virtude da TôParado não figurar como parte nas transações de contratação dos serviços que se realizam entre os Usuários, a responsabilidade por todas as obrigações delas decorrentes, sejam fiscais, trabalhistas, consumeristas ou de qualquer outra natureza, será exclusivamente do Tomador, do Prestador ou de ambos, conforme o caso. Na hipótese de interpelação judicial que tenha como Ré a TôParado, cujos fatos fundem-se em ações de um Usuário, este será chamado ao processo, devendo arcar com todos os ônus que daí decorram, incluindo despesas com taxas, emolumentos, acordos, honorários advocatícios entre outros. Por não figurar como parte nas transações que se realizam entre os Usuários, a TôParado também não pode obrigar os Usuários a honrarem suas obrigações ou a efetivarem a negociação.
    </strong>
    <br>8.3. O Prestador deverá ter em mente que, na medida em que atue como um fornecedor de serviços, sua oferta o vincula, nos termos do artigo 30 do Código de Defesa do Consumidor e do artigo 429 do Código Civil, cujo cumprimento poderá ser exigido judicialmente pelo Tomador.
    <br>
    8.4. TôParado não se responsabiliza pelas obrigações tributárias que recaiam sobre as atividades dos Usuários. Assim como estabelece a legislação pertinente em vigor, o Tomador deverá exigir nota fiscal do Prestador em suas transações. O Prestador, nos moldes da lei vigente, responsabilizar-se-á pelo cumprimento da integralidade das obrigações oriundas de suas atividades, notadamente aquelas referentes a tributos incidentes.
    <br><br>
   <strong> 9. DAS PRÁTICAS VEDADAS</strong>
   <br>
    9.1 É terminantemente vedado aos Usuários, entre outras atitudes previstas nestes Termos, manipular, direta ou indiretamente, os preços dos serviços anunciados.
    <br>
    9.2. É proibido aos Usuários divulgar o mesmo anúncio, conteúdo, item ou serviço em mais de uma categoria e/ou de forma repetida. A TôParado se reserva o direito de excluir anúncios ou serviços repetidos, assim como suspender ou excluir o cadastro do Usuário responsável pela duplicidade.
    <strong><br>9.3. Os Usuários não poderão:</strong>
    <br>
    9.3.1. Obter, guardar, divulgar, comercializar e/ou utilizar dados pessoais sobre outros Usuários para fins comerciais ou ilícitos.
    <br>
    9.3.2. Usar meios automáticos, incluindo spiders, robôs, crawlers, ferramentas de captação de dados ou similares para baixar dados do site (exceto ferramentas de busca na internet e arquivos públicos não comerciais).
    <strong>
    <br>
    9.3.3. Burlar, ou tentar burlar, de qualquer forma que seja, o sistema, mecanismo e/ou a plataforma do site.
    <br>
    9.3.4. Incluir meios de contato como telefone, e-mail, endereço e outras formas de comunicação nas ofertas.
    </strong>
    <br><br>
    <strong>10. DAS VIOLAÇÕES NO SISTEMA OU DA BASE DE DADOS</strong>
    <br>
    10.1. É vedada a utilização de dispositivo, software ou outro recurso que possa interferir nas atividades e nas operações de Plataformas, bem como nos anúncios, nas descrições, nas contas ou em seus bancos de dados. Qualquer intromissão, tentativa de, ou atividade que viole ou contrarie as leis de direito de propriedade intelectual e as proibições estipuladas nestes Termos tornará o responsável passível de sofrer os efeitos das ações legais pertinentes, bem como das sanções aqui previstas, sendo ainda responsável por indenizar a TôParado ou seus Usuários por eventuais danos causados.
    <br><br>
    <strong>11. DAS SANÇÕES</strong>
    <br>
    <br>
    11.1 Sem prejuízo de outras medidas, a TôParado poderá, a seu exclusivo critério e sem necessidade de prévia anuência dos ou comunicação aos Usuários, advertir, suspender ou cancelar, temporária ou permanentemente, o cadastro ou os anúncios do Usuário, podendo aplicar sanção que impacte negativamente em sua reputação, a qualquer tempo, iniciando as ações legais cabíveis e suspendendo a prestação de seus serviços, se:
    <br>
    11.1.1. O Usuário não cumprir qualquer dispositivo destes Termos e as demais políticas da TôParado.
    <br>
    11.1.2. Descumprir com seus deveres de Usuário.
    <br>
    11.1.3. Praticar atos delituosos ou criminais.
    <br>
    11.1.4. Não puder ser verificada a identidade do Usuário, qualquer informação fornecida por ele esteja incorreta ou se as informações prestadas levarem a crer que o cadastro seja falso ou de pessoa diversa.
    <br>
    11.1.5. A TôParado entender que os anúncios ou qualquer outra atitude do Usuário tenham causado algum dano a terceiros ou à TôParado, ou tenham a potencialidade de assim o fazer.
    <br>
    11.2. Nos casos de suspensão, temporária ou permanente, do cadastro do Usuário, todos os anúncios ativos e as ofertas realizadas serão automaticamente canceladas.
    <br>
    11.3. A TôParado se reserva o direito de, a qualquer momento e a seu exclusivo critério, solicitar o envio de documentação pessoal ou de qualquer documento que comprove a veracidade das informações cadastrais.
    <br>
    11.4. Em caso de requisição de documentos, quaisquer prazos determinados nestes Termos só serão aplicáveis a partir da data de recebimento dos documentos solicitados ao Usuário pela TôParado.
    <br><br>
    <strong>12. DAS RESPONSABILIDADES</strong>
    <br>
    12.1. A TôParado não se responsabiliza por vícios ou defeitos técnicos e/ou operacionais oriundos do sistema do Usuário ou de terceiros.
    <strong><br>12.2. A TôParado não é responsável pela entrega dos serviços anunciados pelos Prestadores nas Plataformas, ou pelo pagamento da remuneração oferecida pelos Tomadores.
    <br>
    12.3. A TôParado tampouco se responsabiliza pela existência, quantidade, qualidade, estado, integridade ou legitimidade dos serviços oferecidos ou contratados pelos Usuários, assim como pela capacidade para contratar dos Usuários ou pela veracidade dos dados pessoais por eles fornecidos.
    <br>
    12.4. A TôParado, por não ser proprietária, depositante ou detentora dos produtos oferecidos, não outorga garantia por vícios ocultos ou aparentes nas negociações entre os Usuários. Cada Usuário conhece e aceita ser o único responsável pelos serviços que anuncia ou pelas ofertas que realiza.
    </strong>
    <br>
    12.5. A TôParado não será responsável por ressarcir seus Usuários por quaisquer gastos com ligações telefônicas, pacotes de dados, SMS, mensagens, correspondência eletrônica, correspondência física, ou qualquer outro valor despendido pelo Usuário em razão de contato com a TôParado ou quaisquer outros Usuários, por qualquer motivo que seja.
    <br>
    <strong>
    12.6. A TôParado não poderá ser responsabilizada pelo efetivo cumprimento das obrigações assumidas pelos Usuários. Os Usuários reconhecem e aceitam que, ao realizar negociações com outros Usuários, fazem-no por sua conta e risco, reconhecendo a TôParado como mera fornecedora de serviços de disponibilização de espaço virtual para anúncio dos serviços ofertados por terceiros.
    </strong>
    <br>
    12.7. Em nenhum caso a TôParado será responsável pelo lucro cessante ou por qualquer outro dano e/ou prejuízo que o Usuário possa sofrer devido às negociações realizadas ou não realizadas por meio de suas Plataformas, decorrentes da conduta de outros Usuários.
    <strong>
    <br>
    12.8. Por se tratar de negociações realizadas por meio eletrônico entre dois Usuários que não se conheciam previamente à negociação, a TôParado recomenda que toda transação seja realizada com cautela e prudência.
    <br>
    12.9. Caso um ou mais Usuários ou algum terceiro iniciem qualquer tipo de reclamação ou ação legal contra outro ou outros Usuários, todos e cada um dos Usuários envolvidos nas reclamações ou ações eximem de toda responsabilidade a TôParado e seus diretores, gerentes, empregados, agentes, operários, representantes e procuradores.
    </strong>12.10. A TôParado se reserva o direito de auxiliar e cooperar com qualquer autoridade judicial ou órgão governamental, podendo enviar informações cadastrais ou negociais de seus Usuários, quando considerar que seu auxílio ou cooperação sejam necessários para proteger seus Usuários, funcionários, colaboradores, administradores, sócios ou qualquer pessoa que possa ser prejudicada pela ação ou omissão combatida.
    <br><br>
    <strong>13. DO ALCANCE DOS SERVIÇOS
    <br>
    13.1. Estes Termos não geram nenhum contrato de sociedade, de mandato, de franquia ou relação de trabalho entre a TôParado e o Usuário. O Usuário manifesta ciência de que a TôParado não é parte de nenhuma transação realizada entre Usuários, nem possui controle algum sobre a qualidade, a segurança ou a legalidade dos serviços anunciados pelos Usuários, sobre a veracidade ou a exatidão dos anúncios elaborados pelos Usuários, e sobre a capacidade dos Usuários para negociar.
    <br>
    13.2. A TôParado não pode assegurar o êxito de qualquer transação realizada entre Usuários, tampouco verificar a identidade ou os dados pessoais dos Usuários. A TôParado não garante a veracidade da publicação de terceiros que apareça em suas Plataformas e não será responsável pela correspondência ou por contratos que o Usuário realize com terceiros.
    </strong>
    <br>
    13.3. A TôParado poderá oferecer aos Usuários um serviço de conferência, para garantir a sua identidade e dados cadastrais. A TôParado poderá informar aos Usuários quando esse serviço foi utilizado pelo Prestador ou Tomador.
    <br>
    13.4. O Usuário que burlar o sistema de conferência a fim de receber vantagens ilícitas será excluído das Plataformas e poderão ser tomadas as medidas judiciais cabíveis, especialmente de cunho criminal.
    <br><br>
    <strong>14. DOS PROBLEMAS DECORRENTES DO USO DO SISTEMA</strong>
    <br>
    14.1. A TôParado não se responsabiliza por qualquer dano, prejuízo ou perda sofridos pelo Usuário em razão de falhas em sua conexão com a internet, com o seu provedor, no sistema, com o sistema de SMS, com a linha telefônica ou no servidor utilizados pelo Usuário, decorrentes de condutas de terceiros, caso fortuito ou força maior.
    <br>
    14.2. A TôParado não é responsável pela compatibilidade entre a suas Plataformas e hardwares de propriedade do Usuário. O Usuário deverá manter o seu equipamento atualizado e não poderá responsabilizar a TôParado caso as Plataformas não sejam acessíveis em equipamentos antiquados.
    <br>
    14.3. A TôParado também não será responsável por qualquer vírus, trojan, malware, spyware ou qualquer software que possa danificar, alterar as configurações ou infiltrar o equipamento do Usuário em decorrência do acesso, da utilização ou da navegação na internet, ou como consequência da transferência de dados, informações, arquivos, imagens, textos ou áudio.
    <br><br>
    <strong>15. DA PROPRIEDADE INTELECTUAL E LINKS</strong>
    <br>
    15.1. O uso comercial da expressão "TôParado" como marca, nome empresarial ou nome de domínio, bem como os logos, marcas, insígnias, conteúdo das telas relativas aos serviços das Plataformas e o conjunto de programas, bancos de dados, redes e arquivos que permitem que o Usuário acesse e use sua conta, são propriedade de TôParado e estão protegidos pelas leis e pelos tratados internacionais de direito autoral, de marcas, de patentes, de modelos e de desenhos industriais. O uso indevido e a reprodução total ou parcial dos referidos conteúdos são proibidos, salvo com autorização expressa da TôParado.
    <br><br>
    <strong>16. DA INDENIZAÇÃO
    <br>
    16.1. O Usuário indenizará a TôParado, suas filiais, empresas controladas, controladores diretos ou indiretos, diretores, administradores, colaboradores, representantes e empregados, inclusive quanto a honorários advocatícios, por qualquer demanda promovida por outros Usuários ou terceiros, decorrentes das atividades do primeiro nas Plataformas, de quaisquer descumprimentos, por aquele, dos Termos e das demais políticas da TôParado ou, ainda, de qualquer violação, pelo Usuário, de lei ou de direitos de terceiros.
    </strong>
    <br><br>
    <strong>17. DA LEGISLAÇÃO APLICÁVEL E DO FORO DE ELEIÇÃO</strong>
    <br>
    17.1. Todos os itens destes Termos são regidos pelas leis vigentes na República Federativa do Brasil. Para todos os assuntos referentes à interpretação, ao cumprimento ou a qualquer outro questionamento relacionado a estes Termos, as partes concordam em se submeter ao Foro da Comarca de Campinas, em prejuízo de qualquer outra, por mais especial que seja, excetuada expressa previsão legal em contrário.
    
  `,
  },
  policy: {
    title: 'POLÍTICA DE PRIVACIDADE',
    text: `<strong>A TOPARADO S.A. (TôParado), pessoa jurídica de direito privado constituída sob a forma de sociedade anônima de capital fechado, inscrita no </strong>Cadastro de Pessoas Jurídicas (CNPJ) da Receita Federal do Brasil (RFB) sob o nº 44.256.174/0001-95, <strong>é uma sociedade empresária destinada à exploração comercial da hospedagem de anúncios de prestação e tomada de serviços, através de software proprietário da TôParado, disponibilizado única e exclusivamente pelo aplicativo “TôParado” e pelos endereços eletrônicos “toparado.com.br” e “toparado.app.br” (Plataformas). </strong>
    <br><br>
    <strong>Esta Política de Privacidade é parte integrante dos Termos de Uso e Condições Gerais, e, portanto, requisito essencial para utilização das Plataformas da TôParado, cujo funcionamento depende desta autorização de uso. A Política de Privacidade foi redigida segundo a Lei Geral de Proteção de Dados, e esclarece a todos os Usuários das Plataformas da empresa as práticas vigentes na coleta, tratamento e armazenamento de dados dos Usuários pela TôParado, nos seguintes termos. </strong>
     <br><br>
     1. Todas as informações ou os dados pessoais prestados pelo Usuário à TôParado são armazenados em servidores ou meios magnéticos de alta segurança.
     <br><br>
     2. A TôParado toma todas as medidas razoáveis e possíveis para manter a confidencialidade e a segurança das informações por ela armazenadas, mas não é responsável por prejuízo que possa ser derivado da violação dessas medidas por parte de terceiros que utilizem de meios indevidos, fraudulentos ou ilegais para acessar as informações armazenadas nos servidores ou nos bancos de dados utilizados pela TôParado S.A.
     <br><br>
     3. A TôParado coleta ou pode coletar os seguintes dados e informações pessoais de seus Usuários:
     <br>
     3.1. Diretamente dos Usuários:
     <br>
     3.1.1. Nome completo.
     <br>
     3.1.2. Data de nascimento.
     <br>
     3.1.3. Gênero.
     <br>
     3.1.4. Naturalidade.
     <br>
     3.1.5. Profissão.
     <br>
     3.1.6. Cópia digitalizada de documento de identidade com foto.
     <br>
     3.1.7. Número de telefone ou celular.
     <br>
     3.1.8. Endereço de correio eletrônico.
     <br>
     3.1.9 Eventuais dados biométricos (ex: selfies).
     <br>
     3.1.10. Informações biográficas espontaneamente disponibilizadas pelos Usuáros.
     <br>
     3.2. Durante o acesso e navegação pelas Plataformas:
     <br>
     3.2.1. Endereço IP.
     <br>
     3.2.2. Geolocalização com base no endereço IP, mediante autorização da coleta.
     <br>
     3.2.3. Interações realizados e perfil de uso de sites.
     <br>
     3.2.4. Cookies.
     <br>
     3.2.5. Dados do sistema operacional (sistema operacional e informações do navegador).
     <br>
     3.2.6. Conversas privadas realizadas dentro da ferramenta.
     <br>
     3.2.7. Informações de contratação ou solicitação e serviços (ex: conta bancária).
     <br>
     3.2.8. Histórico de transações realizadas nas Plataformas.
     <br>
     3.2.9. Histórico e registros dos canais de atendimento ao cliente e de interações com prepostos da TôParado.
     <br>
     3.2.10. Feedback dos serviços oferecidos ou prestados dentro das Plataformas.
     <br>
     3.3. Por intermédio de terceiros (informações públicas):
     <br>
     3.3.1. Levantamento de antecedentes criminais e informações de processos judiciais e administrativos. 
     <br>
     3.3.2. Consulta a sites oficiais de órgãos governamentais, ou cadastros de devedores inadimplentes mantidos por organizações privadas.
     <br>
     3.3.3. Outras informações públicas, publicadas pelo Usuário ou por terceiros, licitamente, inclusive registros de órgãos públicos.
     <br>
     3.3.4. Depoimentos e declarações referentes à TôParado postados em redes sociais ou espaços públicos da internet, incluindo nome e imagem do depoente ou declarante.
     <br><br>
     4. Essas informações são coletadas quando o Usuário as insere uma das Plataformas, no caso dos dados pessoais, quando acesso uma das Plataformas, no caso das informações de acesso e navegação, ou por iniciativa da TôParado, no caso das informações coletadas por intermédio de terceiros, e são armazenadas e tratadas pela TôParado para as seguintes finalidades:
     <br>
     4.1. Manutenção cadastral necessária para o fornecimento dos serviços de anúncios online.
     <br>
     4.2. Identificação do Usuário nas Plataformas.
     <br>
     4.3. Viabilizar a utilização pelo Usuário da Plataformas.
     <br>
     4.4. Localizar Prestadores e Tomadores de serviço próximos ao Usuário.
     <br>
     4.5. Prevenir fraudes e implementar de medidas de segurança da plataforma e do aplicativo.
     <br>
     4.6. Comunicar a respeito de pontos que possam ser de interesse do Usuário e que sejam relacionados aos serviços de anúncios da TôParado.
     <br>
     4.7. Prestar serviços de suporte e atendimento mediante solicitação pelo Usuário.
     <br>
     4.8. Realizar pesquisas de uso e interação das Plataformas para aprimoramento dos serviços de anúncio oferecidos pela TôParado.
     <br>
     4.9. Gerar relatórios estatísticos, anonimizados, a respeito do uso e interação dos Usuários.
     <br>
     4.10. Informar o Usuário sobre novidades, funcionalidades, conteúdos, notícias e demais eventos relevantes para a manutenção do relacionamento deste relacionamento. Tais comunicações são realizadas por meio de ferramentas automáticas de envio de mensagens criadas a partir de processos customizados baseados no histórico do Usuário.
     <br>
     4.11. Cumprir as obrigações legais e regulatórias aos quais a TôParado está sujeita.
     <br>
     4.12. Resguardar a TôParado dos seus direitos e obrigações relacionados às Plataformas, conforme disposições da legislação brasileira.
     <br>
     4.13. Colaborar e/ou cumprir ordens judiciais ou requisições por autoridades administrativas competentes.
     <br><br>
     5. Os dados e informações pessoais dos Usuários coletados poderão ser compartilhados com parceiros da TôParado, quando necessário para as mesmas finalidades descritas acima, em forma anonimizada, sempre que possível sem ônus à finalidade.
     <br><br>
     6. A TôParado, por motivos legais, manterá em seu banco de dados todas as informações coletadas dos Usuários que excluírem seus cadastros que forem exigidas por obrigação legal ou regulatória, até a prescrição da obrigação pertinente.
     <br><br>
     7. O apelido e a senha de cada Usuário servem para garantir a privacidade e a sua segurança. A TôParado recomenda a seus Usuários que não compartilhem essas informações com ninguém, e não se responsabiliza por danos ou prejuízos causados ao Usuário pelo compartilhamento dessas informações.
     <br><br>
     8. A TôParado prestará todas as informações requisitadas por órgãos públicos, desde que devidamente justificadas e compatíveis com a legislação vigente, sem que lhe possa ser atribuída qualquer responsabilidade pelo compartilhamento.
     <br><br>
     9. A TôParado utiliza cookies e softwares de monitoramento de seus Usuários para prover a seus Usuários a melhor navegação possível, baseado em suas necessidades, assim como para pesquisas internas. Esses cookies não coletam informações pessoais, apenas informam preferências de uso e de navegação de cada Usuário, além de prover à TôParado estatísticas e dados para aprimorar seus serviços.
     <br><br>
     10. No caso de suspeita ou de efetiva violação de dados pessoais dos Usuários, a TôParado se compromete a notificar os Usuários afetados.
     <br><br>
     11. No caso de mudanças ou alterações na presente Política Privacidade, a TôParado se compromete a notificar os Usuários por meio de suas Plataformas, com antecedência mínima de 15 (quinze) dias à efetivação das alterações; a concordância com a política de privacidade dar-se-á tacitamente na ausência de revogação do consentimento pelo Usuário.
     <br><br>
     12. De acordo com a Lei Geral de Proteção de Dados (Lei nº 13.709/18), o Usuário poderá requerer:
     <br>
     12.1. Acesso aos seus dados pessoais armazenados pela TôParado.
     <br>
     12.2. Confirmação de se a TôParado está exercendo o tratamento de seus dados pessoais.
     <br>
     12.3. Revogação de seu consentimento anterior para coleta, tratamento e armazenamento de seus dados pessoais.
     <br>
     12.4. Fornecimento ao Usuário, ou terceiros que forem indicados pelo Usuário, dos dados pessoais do Usuário, em formato estruturado, desde que respeitado o segredo comercial dos produtos da TôParado.
     <br>
     12.5. A solicitação, a qualquer momento, da retificação de quaisquer dados pessoais, caso encontrem-se incompletos, inexatos ou desatualizados, que estejam aos cuidados da TôParado.
     <br>
     12.6. A exclusão de seus dados pessoais armazenados pela TôParado. Após a requisição, todos os dados que não forem essenciais para os serviços prestados pela TôParado, tais quais o que TôParado seja legalmente obrigada a armazenar, serão excluídos.
     <br><br>
     13. A solicitação do exercício dos direitos dos titulares de dados deverá ser requerida por meio da seção “Fale Conosco” no endereço eletrônico “toparado.com.br” nas condições estabelecidas pela Lei Geral de Proteção de Dado (LGPD).
    
    `,
  },
};
