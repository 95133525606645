import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Flex1Wrapper, FlexContainer } from 'styles/flex-container';
import { responsiveSize } from 'styles/sizes';
import { Spacing } from 'styles/spacing';

interface Props {
  listSize?: number;
  textLines?: number;
}
const PersonListSkeleton: React.FC<Props> = ({
  listSize = 3,
  textLines = 2,
}) => {
  const widths = ['60%', '50%', '70%'];
  return (
    <>
      {Array(listSize)
        .fill(1)
        .map((item, index) => (
          <div key={index}>
            <FlexContainer
              padding="0"
              direction="row"
              gap={responsiveSize(1.5)}
            >
              <Skeleton width={35} height={35} circle />
              <Flex1Wrapper>
                {Array(textLines)
                  .fill(1)
                  .map((_, j) => (
                    <Skeleton key={j} width={widths[j]} />
                  ))}
              </Flex1Wrapper>
            </FlexContainer>
            {index < listSize - 1 ? <Spacing size={6} /> : ''}
          </div>
        ))}
    </>
  );
};

export default PersonListSkeleton;
