import { useHistory } from 'react-router-dom';

import { Button } from 'components/atoms/button';
import { ProfileEnum } from 'enums/profile';
import { IModalDefaultProps } from 'modals/types';
import { useState } from 'react';

import { Modal, ModalTransition } from 'react-simple-hook-modal';
import { colors } from 'styles/colors';
import { FlexContainer } from 'styles/flex-container';
import { IconClose } from 'styles/icons';
import { responsiveSize } from 'styles/sizes';
import { Typography, LinkTypography } from 'styles/typography';
import CheckboxCard from 'components/molecules/checkbox-card';
import AccountBenefits from './account-benefits';
import {
  CheckboxWrapper,
  CreateAccountContainer,
  IconCloseContainer,
} from './styled';

interface Props extends IModalDefaultProps {
  initialTab?: ProfileEnum;
  isLogged?: boolean;
}
const CreateAccount: React.FC<Props> = ({
  isModalOpen,
  onClose,
  initialTab,
}) => {
  const history = useHistory();
  const [userRole, serUserRole] = useState<null | ProfileEnum>(
    initialTab || null,
  );
  const [showBenefits, setShowBenefits] = useState(false);

  const handleCreateButton = () => {
    onClose();
    history.push(
      `/cadastro-${userRole === ProfileEnum.CLIENT ? 'cliente' : 'prestador'}`,
    );
  };

  const toggleShowBenefits = () => {
    setShowBenefits((currState) => !currState);
  };

  const handleLoginButton = () => {
    onClose();
    history.push('/login');
  };

  const handleChangeCheckbox = (type: 'customer' | 'worker') => {
    serUserRole(type === 'customer' ? ProfileEnum.CLIENT : ProfileEnum.WORKER);
  };

  return (
    <Modal
      id="create-account-modal"
      modalClassName="no-padding"
      isOpen={isModalOpen}
      transition={ModalTransition.BOTTOM_UP}
    >
      {showBenefits && userRole ? (
        <AccountBenefits
          type={userRole}
          onPrev={toggleShowBenefits}
          onNext={handleCreateButton}
        />
      ) : (
        <FlexContainer darkMode fullPageHeight>
          <CreateAccountContainer>
            <IconCloseContainer>
              <IconClose width={24} fill={colors.white} onClick={onClose} />
            </IconCloseContainer>
            <Typography
              as="h3"
              color="white"
              fontSize="heading3"
              fontWeight="bold"
              letterSpacing="-0.01em"
              margin={`${responsiveSize(4)} 0`}
              display="block"
            >
              Bem vindo!
            </Typography>
            <Typography
              fontSize="body1"
              fontWeight="bold"
              color="white"
              display="block"
            >
              O que você é?
            </Typography>

            <CheckboxWrapper>
              <CheckboxCard
                isChecked={!!(userRole && userRole === ProfileEnum.CLIENT)}
                description="Quero contratar um prestador"
                title="Cliente"
                onChange={() => handleChangeCheckbox('customer')}
              />

              <CheckboxCard
                isChecked={!!(userRole && userRole === ProfileEnum.WORKER)}
                description="Quero trabalhar"
                title="Prestador"
                onChange={() => handleChangeCheckbox('worker')}
              />
            </CheckboxWrapper>
          </CreateAccountContainer>

          <LinkTypography
            color="white"
            textDecoration="underline"
            margin="auto 0 60px;"
            onClick={handleLoginButton}
          >
            Já tenho conta
          </LinkTypography>

          <Button onClick={toggleShowBenefits} disabled={!userRole}>
            Criar conta
          </Button>
        </FlexContainer>
      )}
    </Modal>
  );
};

export default CreateAccount;
