import { Button } from 'components/atoms/button/index';
import Textarea from 'components/atoms/textarea/index';
import { useStepsContext } from 'context/steps-context';
import { IServiceOffer } from 'models/service-offer';
import { useState } from 'react';
import { Typography } from 'styles/typography';

export const Activities = () => {
  const [description, setDescription] = useState('');
  const { nextStep, patchData } = useStepsContext();

  const btnClicked = () => {
    patchData<IServiceOffer>({
      description,
    });
    nextStep();
  };

  return (
    <>
      <Typography
        as="h3"
        color="title"
        fontSize="heading3"
        fontWeight="bold"
        lineHeight="48px"
        display="block"
        letterSpacing="-0.01em"
        margin="0 0 29px"
      >
        Como você vai fazer esse serviço?
      </Typography>
      <Textarea
        placeholder="Escreva mais detalhes sobre o que vai ser feito."
        onChange={setDescription}
      />
      <Button disabled={!description} onClick={btnClicked}>
        Avançar
      </Button>
    </>
  );
};
