import styled, { css } from 'styled-components';
import { responsiveSize } from './sizes';

interface ISimpleCarouselProps {
  firstItemMargin?: boolean;
}
export const SimpleCarousel = styled.div<ISimpleCarouselProps>`
  margin: 0 -30px;
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;

  > * {
    scroll-snap-align: start;
    min-width: max-content;
    padding-right: ${responsiveSize(3)};
    padding-bottom: ${responsiveSize(2.5)};

    ${({ firstItemMargin }) =>
      firstItemMargin &&
      css`
        &:first-child {
          margin-left: 30px;
        }
      `}
  }
`;
