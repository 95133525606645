import { useAlert } from 'context/alert-context';
import React from 'react';
import { IconInterrogation } from 'styles/icons';
import { responsiveSize } from 'styles/sizes';
import { Typography } from 'styles/typography';
import { ActionLink, HelperIconWrapper, InfoContainer, Row } from './styled';

export interface IInfoItemProps {
  title: string;
  action?: 'edit' | 'add' | 'change-password';
  value?: string;
  helperText?: string;
  onActionClicked?: () => void;
}
const InfoItem: React.FC<IInfoItemProps> = ({
  title,
  action = 'edit',
  value,
  helperText,
  onActionClicked = () => null,
}) => {
  const { showAlert } = useAlert();

  return (
    <InfoContainer>
      <Row>
        <Typography as="h4" fontSize="body1" lineHeight="120%">
          {title}
        </Typography>
        {helperText && (
          <HelperIconWrapper
            onClick={() => {
              showAlert({
                title,
                text: helperText,
              });
            }}
          >
            <IconInterrogation />
          </HelperIconWrapper>
        )}
        <ActionLink as="button" onClick={onActionClicked}>
          {action === 'edit'
            ? 'Editar'
            : action === 'change-password'
            ? 'Alterar'
            : 'Adicionar'}
        </ActionLink>
      </Row>
      {value && (
        <Row>
          <Typography
            fontWeight={action === 'add' ? 'bold' : 'normal'}
            margin={`0 ${responsiveSize(title === 'Endereço' ? 7 : 12)} 0 0`}
            dangerouslySetInnerHTML={{ __html: value }}
          />
        </Row>
      )}
    </InfoContainer>
  );
};

export default InfoItem;
