import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';

interface Props {
  active?: boolean;
}
export const SkillItemContainer = styled.button<Props>`
  transition: all 0.2s;
  font-family: 'Acid Grotesk';
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  border-radius: 25px;
  padding: 8px 19.5px 8px 19.5px;
  border: 1px solid ${colors.text};

  ${({ active }) =>
    active &&
    css`
      color: ${colors.white};
      background-color: ${colors.text};
    `}
`;
