import styled from 'styled-components';
import { colors } from 'styles/colors';
import { responsiveSize } from 'styles/sizes';

export const TagStyled = styled.div`
  background-color: #f0f0f0;
  min-height: ${responsiveSize(4)};
  padding: ${responsiveSize(1)};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const RoundIcon = styled.div`
  min-width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: ${colors.text};
  color: ${colors.gray};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${responsiveSize(0.5)};
  cursor: pointer;
`;
