import styled from 'styled-components';
import { colors } from 'styles/colors';
import { fontSizes } from 'styles/typography';

export const CenterContent = styled.div`
  margin: auto 0;
`;

export const AlertContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;

  h3 {
    font-size: 20px;
    line-height: 24px;
    margin: 0 0 20px;
    text-align: center;
    font-weight: bold;
  }

  p {
    font-size: ${fontSizes.body2};
    text-align: center;
  }

  a {
    margin-top: 5px;
    text-decoration: none;
    color: ${colors.primary};
  }
`;
