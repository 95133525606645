import { Button } from 'components/atoms/button/index';
import InputZipCode from 'components/atoms/input-zip-code/index';
import { useAsync } from 'context/async-context';
import { useStepsContext } from 'context/steps-context';
import { ILocation } from 'models/location';
import { IServicePatch } from 'models/service';
import { useState } from 'react';
import { Typography } from 'styles/typography';

export const ZipCode = () => {
  const [location, setLocation] = useState<ILocation>();
  const { nextStep, getData, patchData } = useStepsContext();
  const { handledAsync } = useAsync();

  const previousData = getData<IServicePatch>();

  const btnClicked = async () => {
    handledAsync(async () => {
      await patchData<IServicePatch>({ location }, true);
      nextStep();
    });
  };

  return (
    <>
      <Typography
        as="h3"
        color="title"
        fontSize="heading3"
        fontWeight="bold"
        lineHeight="48px"
        display="block"
        letterSpacing="-0.01em"
        margin="0 0 54px"
      >
        Qual o CEP onde o serviço será realizado?
      </Typography>
      <InputZipCode
        onChange={setLocation}
        initialData={previousData?.location?.zipcode}
      />
      <Button disabled={!location} onClick={btnClicked}>
        Continuar
      </Button>
    </>
  );
};
