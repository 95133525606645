import React, { FC } from 'react';

import { FlexContainer } from 'styles/flex-container';
import { Spacing } from 'styles/spacing';
import { LinkTypography, Typography } from 'styles/typography';

interface Props {
  loginAction?: () => void;
}
const ErrorAuthRequired: FC<Props> = ({ loginAction }) => {
  return (
    <FlexContainer width="90%">
      <Spacing size={12} />
      <Typography fontWeight="bold" fontSize="heading3" letterSpacing="-0.01em">
        Você precisa estar logado para acessar essa página
      </Typography>
      <Spacing size={3} />
      <Typography fontSize="body1" lineHeight="120%">
        Crie um cadastro ou faça login abaixo.
      </Typography>
      <Spacing size={15} />
      <LinkTypography
        as="button"
        fontSize="body1"
        color="primary"
        onClick={loginAction}
      >
        Fazer login ou cadastro
      </LinkTypography>
    </FlexContainer>
  );
};

export default ErrorAuthRequired;
