import PersonCard from 'components/molecules/person-card';
import { useAsync } from 'context/async-context';
import { useState, useEffect } from 'react';
import { workersService } from 'services/workers-service';
import { useUserContext } from 'context/user-context';
import { IWorkerListItem } from 'models/user';
import NoProfileTab from 'components/molecules/no-profile-tab';
import { ProfileEnum } from 'enums/profile';
import { Typography } from 'styles/typography';
import { useHistory } from 'react-router-dom';
import { PersonListItem } from './styled';

const FavoriteWorkers = () => {
  const [workersList, setWorkersList] = useState<IWorkerListItem[]>();

  const history = useHistory();
  const { handledAsync } = useAsync();
  const { client } = useUserContext();

  const getFavoritesWorkers = async () => {
    handledAsync(async () => {
      if (client) {
        if (client.favorite_workers) {
          const workersInfo = await workersService.getFavoriteWorkers();

          setWorkersList(workersInfo);
        } else {
          setWorkersList([]);
        }
      }
    });
  };

  useEffect(() => {
    getFavoritesWorkers();
  }, []);

  if (!client) {
    return (
      <NoProfileTab
        tab={ProfileEnum.CLIENT}
        onProfileCreated={getFavoritesWorkers}
      />
    );
  }

  return (
    <>
      {workersList?.map((person) => (
        <PersonListItem
          key={person.uid}
          onClick={() => history.push(`/perfil-prestador/${person.uid}`)}
        >
          <PersonCard
            image={person.picture_path}
            online={person.online}
            name={person.name}
            assessment={person.assessment}
            skills={person.skills}
            uid={person.user_uid}
            showChatLink
          />
        </PersonListItem>
      ))}

      {workersList?.length === 0 && (
        <Typography align="center">Nenhum prestador favorito.</Typography>
      )}
    </>
  );
};

export default FavoriteWorkers;
