import styled from 'styled-components';
import { responsiveSize } from 'styles/sizes';

export const ChecklistContainer = styled.div`
  position: relative;
`;

export const ItemContainer = styled.div`
  position: relative;
  margin-top: ${responsiveSize(4)};

  &:last-child {
    margin-bottom: ${responsiveSize(4)};
  }
`;
