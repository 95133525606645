import styled from 'styled-components';
import { colors } from 'styles/colors';

export const Container = styled.div`
  background-color: #f2f2f2;
`;

export const HeaderWrapper = styled.div`
  display: flex;
`;

export const Avatar = styled.img`
  min-width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #babaca;
  margin-right: 16px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 30px;

  span {
    color: ${colors.primary};
    font-size: 20px;
    font-weight: 700;
  }
`;
