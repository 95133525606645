import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from 'styles/colors';

export const LinkStyled = styled(Link)`
  position: relative;
`;

export const Indicator = styled.span`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${colors.primary};
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
`;
