import React from 'react';
import { ModalProvider } from 'react-simple-hook-modal';
import Loader from '../components/molecules/loader';
import LoaderContext from './loader-context';
import AlertContext from './alert-context';
import AsyncContext from './async-context';
import ModalPageContext from './modal-page-context';
import UserContext from './user-context';

const RootContext: React.FC<unknown> = ({ children }) => {
  return (
    <>
      <LoaderContext>
        <ModalProvider>
          <AlertContext>
            <AsyncContext>
              <UserContext>
                <ModalPageContext>{children}</ModalPageContext>
              </UserContext>
            </AsyncContext>
          </AlertContext>
        </ModalProvider>
        <Loader />
      </LoaderContext>
    </>
  );
};

export default RootContext;
