import NavTabs from 'components/molecules/nav-tabs';
import TitleHeader from 'components/molecules/title-header';
import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { FlexContainer } from 'styles/flex-container';
import { chatService } from 'services/chat-service';
import { useAsync } from 'context/async-context';
import { useUserContext } from 'context/user-context';
import Talk from 'talkjs';
import { Inbox } from './styled';

const Chats = () => {
  const { handledAsync } = useAsync();
  const [activeTab, setActiveTab] = useState('all');
  const { user, talkJSSession } = useUserContext();
  const history = useHistory();

  const inbox = useRef<Talk.Inbox>();
  const inboxContainer = useRef<HTMLDivElement>(null);

  const createInbox = () => {
    if (user) {
      handledAsync(async () => {
        chatService.setTalkSession(talkJSSession);
        await chatService.createInbox().then((current) => {
          inbox.current = current;

          if (inbox.current) {
            inbox.current.on('selectConversation', (e) => {
              if (history.location.pathname !== `/conversa/${e.others[0].id}`) {
                history.push(`/conversa/${e.others[0].id}`);
              }
            });

            inbox.current.mount(inboxContainer.current);
          }
        });
      });
    }
  };

  useEffect(() => {
    if (inbox.current) {
      let filter: any = null;
      if (user && activeTab === 'favorites') {
        filter = { custom: { [user.uid]: ['==', 'true'] } };
      } else if (activeTab === 'in-progress') {
        filter = { custom: { in_progress: 'exists' } };
      }
      inbox.current.setFeedFilter(filter);
    }
  }, [activeTab]);

  useEffect(() => {
    createInbox();
  }, []);

  return (
    <FlexContainer padding="0" style={{ height: '100%' }}>
      <FlexContainer flex1={false} padding="0 30px">
        <TitleHeader
          title="Chat"
          textProps={{
            fontWeight: 'bold',
            fontSize: 'heading3',
          }}
        />
      </FlexContainer>
      <NavTabs
        tabs={[
          { value: 'all', label: 'Todos' },
          { value: 'favorites', label: 'Favoritos' },
          { value: 'in-progress', label: 'Em andamento' },
        ]}
        activeTab={activeTab}
        onTabChange={setActiveTab}
      />
      <Inbox ref={inboxContainer} />
    </FlexContainer>
  );
};

export default Chats;
