import { ModalStepsContextProvider } from 'context/modal-steps-context';
import { IModalSteps } from 'context/modal-steps-context/types';
import { IModalDefaultProps } from 'modals/types';
import React, { useEffect } from 'react';
import { Modal, ModalTransition } from 'react-simple-hook-modal';
import { ProfileEnum } from 'enums/profile';
import Complement from './complement';
import Feedback from './feedback';
import RatingStars from './rating-stars';
import StarsSuccess from './stars-success';
import Success from './success';

interface IServiceRatingProps extends IModalDefaultProps {
  serviceUid?: string;
  otherUid: string;
  role: ProfileEnum;
}
const ServiceRating: React.FC<IServiceRatingProps> = ({
  isModalOpen,
  onClose,
  serviceUid,
  otherUid,
  role,
}) => {
  const steps: IModalSteps[] = [
    { component: <RatingStars /> },
    { component: <StarsSuccess /> },
    { component: <Feedback currentUserProfile={role} /> },
    {
      component: (
        <Complement
          serviceUid={serviceUid}
          otherUid={otherUid}
          currentUserProfile={role}
        />
      ),
    },
    { component: <Success />, contrastHeader: true },
  ];
  return (
    <Modal
      id="service-rating-modal"
      modalClassName="no-padding"
      isOpen={isModalOpen}
      transition={ModalTransition.SCALE}
    >
      <ModalStepsContextProvider
        headerTitle="Avaliação do serviço"
        closeModal={onClose}
        steps={steps}
      />
    </Modal>
  );
};

export default ServiceRating;
