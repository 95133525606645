import React from 'react';
import InputText, { IInputTextProps } from '../input-text';

const InputEmail: React.FC<IInputTextProps> = ({
  required,
  onChange,
  ...rest
}) => {
  const handleChange = (value) => {
    const pattern = /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/;
    const isValid = (!value && !required) || pattern.test(value);
    onChange(isValid ? value : '', isValid);
  };

  return <InputText onChange={handleChange} {...rest} type="email" />;
};

export default InputEmail;
