import SliderImagesWithInput from 'components/molecules/slider-images-with-input';
import { IImageFile } from 'models/file';
import React from 'react';
import ReactImageUploading from 'react-images-uploading';
import { colors } from 'styles/colors';
import { FlexContainer } from 'styles/flex-container';
import { IconImageUpload } from 'styles/icons';
import { Spacing } from 'styles/spacing';
import { LinkTypography } from 'styles/typography';
import { PlaceholderUpload } from './styled';

interface Props {
  images: IImageFile[];
  onChange: (imageList: IImageFile[]) => void;
  maxFiles?: number;
}
const MultipleImageUpload: React.FC<Props> = ({
  images,
  onChange,
  maxFiles = 20,
}) => {
  const handleTextChange = (value: string, index: number) => {
    const tmpImages = [...images];
    tmpImages[index].description = value;
    onChange(tmpImages);
  };

  return (
    <>
      <ReactImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxFiles}
        dataURLKey="data_url"
      >
        {({ onImageUpload, onImageRemove }) => (
          <FlexContainer padding="0">
            {!images.length && (
              <PlaceholderUpload onClick={onImageUpload}>
                <IconImageUpload fill={colors.white} />
              </PlaceholderUpload>
            )}
            <SliderImagesWithInput
              images={images}
              onImageRemove={onImageRemove}
              onTextChange={handleTextChange}
            />
            <LinkTypography
              as="button"
              display="block"
              margin="auto auto 0"
              fontSize="body1"
              onClick={onImageUpload}
            >
              Adicionar foto
            </LinkTypography>
            <Spacing size={10} />
          </FlexContainer>
        )}
      </ReactImageUploading>
    </>
  );
};

export default MultipleImageUpload;
