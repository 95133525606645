import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from 'styles/colors';
import { responsiveSize } from 'styles/sizes';

interface Props {
  $smallMargin?: boolean;
}
export const HeaderContainer = styled.header<Props>`
  padding: 25px 0 15px 0;
  position: relative;
  margin-bottom: ${({ $smallMargin }) => responsiveSize($smallMargin ? 2 : 5)};
  p {
    position: relative;
  }
`;

export const BackButton = styled.a`
  position: absolute;
  left: -10px;
  transform: rotate(180deg);
`;

export const TopRight = styled.button`
  position: absolute;
  top: 2px;
  right: 0;

  svg {
    margin-right: 0 !important;
  }

  &.favorited {
    svg {
      animation: beat 2s ease-out;
      animation-duration: 0.8s;
      path {
        fill: ${colors.primary};
        stroke: ${colors.primary};
      }
    }
  }
`;

export const ProgressBarContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: -30px;
  right: 0;
  width: 100vw;
  overflow: hidden;
  height: 4px;
  background: #f2f2f2;
`;

export const ProgressBarItem = styled.div`
  width: 0;
  height: 4px;
  background: #677483;
  transition: width 1s;
`;

export const LinkStyled = styled(Link)`
  text-decoration: none;
  color: ${colors.text};
`;
