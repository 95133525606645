import InputText from 'components/atoms/input-text';
import { IImageFile } from 'models/file';
import React from 'react';
import Slider from 'react-slick';
import { IconClose } from 'styles/icons';
import {
  SliderContainer,
  SlideImage,
  CloseIconContainer,
  ItemContainer,
} from './styled';

const settings = {
  centerMode: true,
  infinite: false,
  centerPadding: '60px',
  slidesToShow: 1,
  slidesToScroll: -1,
  speed: 500,
  rtl: false,
};

interface Props {
  images: IImageFile[];
  onImageRemove: (index: number) => void;
  onTextChange: (text: string, index: number) => void;
}
const SliderImagesWithInput: React.FC<Props> = ({
  images,
  onImageRemove,
  onTextChange,
}) => {
  return (
    <SliderContainer>
      <Slider {...settings}>
        {images.map((image, index) => {
          const key = image.data_url || image.path;

          return (
            <ItemContainer key={key}>
              <SlideImage src={`${key}`} />
              <InputText
                value={image.description}
                onChange={(value: string) => onTextChange(value, index)}
                placeholder="O que essa foto mostra?"
              />
              <CloseIconContainer onClick={() => onImageRemove(index)}>
                <IconClose />
              </CloseIconContainer>
            </ItemContainer>
          );
        })}
      </Slider>
    </SliderContainer>
  );
};

export default SliderImagesWithInput;
