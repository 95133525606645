import React from 'react';
import { useLoader } from 'context/loader-context';
import { colors } from 'styles/colors';
import { LoaderStyled } from './styled';
import SVGAnimation from './svg-animation';

const Loader = () => {
  const { isLoading } = useLoader();

  return (
    <>
      {isLoading && (
        <LoaderStyled overlay>
          <SVGAnimation size="100px" color={colors.white} />
        </LoaderStyled>
      )}
    </>
  );
};

export default Loader;
