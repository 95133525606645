import {
  Beer,
  Body,
  Book,
  Build,
  Car,
  GlobeOutline,
  HammerOutline,
  Heart,
  HomeSharp,
  LaptopOutline,
  PawSharp,
  Shirt,
} from 'react-ionicons';

export const iconsMap = {
  build: Build,
  cut: HammerOutline,
  home: HomeSharp,
  heart: Heart,
  book: Book,
  beer: Beer,
  car: Car,
  laptop: LaptopOutline,
  pets: PawSharp,
  shirt: Shirt,
  body: Body,
  globe: GlobeOutline,
};
