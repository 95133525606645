import axios, { AxiosInstance } from 'axios';

class ForgotPasswordService {
  private instance: AxiosInstance;

  constructor() {
    this.instance = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}/registrations/forgot-password`,
      headers: {
        Authorization: process.env.REACT_APP_API_TOKEN,
      },
    });
  }

  async sendCode(cellphone: string) {
    return this.instance.post('/', { cellphone }).then((resp) => resp.data);
  }

  async validateCodeAndChangePassword(
    cellphone: string,
    code: string,
    newPassword: string,
  ) {
    return this.instance
      .put('/', {
        cellphone,
        code,
        new_password: newPassword,
        fake_email: `${cellphone}@${process.env.REACT_APP_FAKE_EMAIL_DOMAIN}`,
      })
      .then((resp) => resp.data);
  }
}

export const forgotPasswordService = new ForgotPasswordService();
