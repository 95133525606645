/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { ProfileEnum } from 'enums/profile';
import { IUser } from 'models/user';
import { firebaseService } from './firebase-service';

class MeService {
  private async getInstance() {
    const userToken = await firebaseService.getUserToken();
    return axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        Authorization: userToken
          ? `Bearer ${userToken}`
          : process.env.REACT_APP_API_TOKEN,
      },
    });
  }

  async getUserInfo() {
    return (await this.getInstance())
      .get<IUser>(`/me`)
      .then((resp) => resp.data);
  }

  async patchBasicInfo(data: Partial<IUser>) {
    return (await this.getInstance())
      .patch(`/me`, data)
      .then((resp) => resp.data);
  }

  async getProfileInfo(profile: ProfileEnum) {
    return (await this.getInstance())
      .get(`/me/${profile}`)
      .then((resp) => resp.data);
  }

  async patchByProfile(profile: ProfileEnum, data: Partial<any>) {
    return (await this.getInstance())
      .patch(`/me/${profile}`, data)
      .then((resp) => resp.data);
  }

  async createClientProfile() {
    return (await this.getInstance())
      .post(`/me/client`)
      .then((resp) => resp.data);
  }

  async createWorkerProfile(data: Partial<any>) {
    return (await this.getInstance())
      .post(`/me/worker`, data)
      .then((resp) => resp.data);
  }

  async requestPhoneChange(cellphone: string) {
    return (await this.getInstance())
      .post('/me/change-phone', { cellphone })
      .then((resp) => resp.data);
  }

  async validateCodeAndChangePhone(cellphone: string, code: string) {
    return (await this.getInstance())
      .put('/me/change-phone', {
        cellphone,
        code,
        fake_email: `${cellphone}@${process.env.REACT_APP_FAKE_EMAIL_DOMAIN}`,
      })
      .then((resp) => resp.data);
  }

  async registerToken(token: string) {
    return (await this.getInstance())
      .post('/me/register-token', { device_token: token })
      .then((resp) => resp.data);
  }

  async unregisterToken(token: string) {
    return (await this.getInstance())
      .patch('/me/unregister-token', { device_token: token })
      .then((resp) => resp.data);
  }

  async updatePreferredRole(role: ProfileEnum) {
    return (await this.getInstance())
      .patch('/me/role', { preferred_role: role })
      .then((resp) => resp.data);
  }
}

export const meService = new MeService();
