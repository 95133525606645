import Header from 'components/molecules/header';
import { IModalDefaultProps } from 'modals/types';
import React from 'react';
import { Modal, ModalTransition } from 'react-simple-hook-modal';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { FlexContainer } from 'styles/flex-container';
import { Spacing } from 'styles/spacing';
import { Typography } from 'styles/typography';
import { MainContainer, PaddingContainer } from './styled';

interface Props extends IModalDefaultProps {
  image: string;
  caption?: string;
}
const ImagePreview: React.FC<Props> = ({
  image,
  caption,
  isModalOpen,
  onClose,
}) => {
  return (
    <Modal
      id="add-contact-modal"
      modalClassName="no-padding"
      isOpen={isModalOpen}
      transition={ModalTransition.SCALE}
    >
      <FlexContainer fullPageHeight padding="0">
        <PaddingContainer>
          <Header
            title="Detalhe da foto"
            isModal
            onCloseModal={onClose}
            smallMargin
          />
        </PaddingContainer>
        <MainContainer>
          <TransformWrapper centerOnInit centerZoomedOut>
            <TransformComponent>
              <img src={image} alt="" />
            </TransformComponent>
          </TransformWrapper>
          <Spacing size={2} />
          <PaddingContainer>
            <Typography fontSize="body1">{caption}</Typography>
          </PaddingContainer>
        </MainContainer>
      </FlexContainer>
    </Modal>
  );
};

export default ImagePreview;
