import React, { useState } from 'react';
import { mask as masker, unMask } from 'remask';
import InputText, { IInputTextProps } from '../input-text';

const InputCellNumber: React.FC<IInputTextProps> = ({ onChange, ...rest }) => {
  const [inputValue, setInputValue] = useState('');

  const validateCellNumber = (value = '') => {
    return value.length === 15;
  };

  const inputChange = (value = '') => {
    const orginalValue = unMask(value);
    const maskedValue = masker(orginalValue, '(99) 99999-9999');
    const isValid = validateCellNumber(maskedValue);
    setInputValue(maskedValue);
    onChange(isValid ? maskedValue : '');
  };

  return (
    <InputText type="tel" {...rest} onChange={inputChange} value={inputValue} />
  );
};

export default InputCellNumber;
