/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { Button } from 'components/atoms/button/index';
import MultipleImageUpload from 'components/organisms/multiple-image-upload';
import { useAsync } from 'context/async-context';
import { useStepsContext } from 'context/steps-context';
import { IImageFile } from 'models/file';
import { IServicePatch } from 'models/service';
import { useState } from 'react';
import { firebaseService } from 'services/firebase-service';
import { FlexContainer } from 'styles/flex-container';
import { Spacing } from 'styles/spacing';
import { Typography } from 'styles/typography';

export const ServicePhotos = () => {
  const { nextStep, getData, patchData } = useStepsContext();
  const previousData = getData<IServicePatch>();

  const [images, setImages] = useState<IImageFile[]>(
    previousData.pictures || [],
  );
  const { handledAsync } = useAsync();

  const isInvalid = false;

  const btnClicked = async () => {
    handledAsync(async () => {
      const tmpImages = [...images];
      for (const img of tmpImages) {
        if (img.file) {
          img.path = await firebaseService.uploadFile(img.file);
          delete img.file;
        }
      }
      setImages(tmpImages);
      await patchData<IServicePatch>(
        {
          pictures: tmpImages.map((item) => ({
            description: item.description,
            path: item.path!,
          })),
        },
        true,
      );
      nextStep();
    });
  };

  return (
    <FlexContainer padding="0">
      <Typography
        as="h3"
        color="title"
        fontSize="heading3"
        fontWeight="bold"
        display="block"
        letterSpacing="-0.01em"
      >
        Fotos do serviço
      </Typography>
      <Spacing size={5} />
      <MultipleImageUpload images={images} onChange={setImages} />
      <Button disabled={isInvalid} onClick={btnClicked}>
        Continuar e Revisar
      </Button>
    </FlexContainer>
  );
};
