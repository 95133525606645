import { ButtonStyled } from 'components/atoms/button/style';
import { RadiolistContainer } from 'components/organisms/radio-list/styled';
import styled from 'styled-components';

export const TabStyled = styled.div``;

export const TabContent = styled.div`
  ${RadiolistContainer} {
    margin-bottom: 40px;
  }
  ${ButtonStyled} {
    justify-content: center;
  }
`;
