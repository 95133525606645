import styled from 'styled-components';
import { responsiveSize } from 'styles/sizes';

export const PersonListItem = styled.div`
  padding-bottom: ${responsiveSize(3)};
  text-decoration: none;

  &:last-child {
    padding-bottom: 0;
  }
`;
