/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { Typography } from 'styles/typography';
import { Button } from 'components/atoms/button';
import ImageUploading from 'react-images-uploading';
import IconAddPhoto from 'assets/img/add-photo.svg';
import { useStepsContext } from 'context/steps-context';
import { firebaseService } from 'services/firebase-service';
import { useAsync } from 'context/async-context';
import { meService } from 'services/me-service';
import { useUserContext } from 'context/user-context';
import {
  ButtonAddPhoto,
  IconAddPhotoStyled,
  UserUploadedPhoto,
  Container,
} from './styled';

const RegisterPicture = () => {
  const [images, setImages] = useState<any[]>([]);
  const { nextStep } = useStepsContext();
  const { handledAsync } = useAsync();
  const { patchUser } = useUserContext();

  const onBtnClicked = () => {
    if (images[0]?.file) {
      handledAsync(async () => {
        const imageUrl = await firebaseService.uploadFile(images[0].file);
        const user = await meService.patchBasicInfo({
          picture_path: imageUrl,
        });
        patchUser(user);
        nextStep();
      });
    }
  };

  return (
    <>
      <Typography
        as="h3"
        color="title"
        fontSize="heading3"
        fontWeight="bold"
        lineHeight="48px"
        display="block"
        letterSpacing="-0.01em"
        margin="0 0 22px"
      >
        {images.length > 0
          ? 'A foto ficou boa?'
          : 'Parabéns, você já faz parte do Tô Parado!'}
      </Typography>

      <Typography as="p" fontSize="body1" margin="0 0 26px">
        {images.length === 0 ? 'Complete seu perfil adicionando uma foto.' : ''}
      </Typography>
      <ImageUploading value={images} onChange={setImages} dataURLKey="data_url">
        {({ imageList, onImageUpload }) => (
          <>
            {images.length < 1 && (
              <>
                <ButtonAddPhoto onClick={onImageUpload}>
                  <IconAddPhotoStyled
                    src={IconAddPhoto}
                    alt="Ícone de adicionar foto"
                  />
                </ButtonAddPhoto>
                <Typography
                  fontSize="body1"
                  color="title"
                  as="button"
                  textDecoration="underline"
                  display="block"
                  margin="68px auto 0 auto"
                  onClick={nextStep}
                >
                  Finalizar
                </Typography>
              </>
            )}

            {imageList.map((image) => (
              <Container key={Math.random()}>
                <UserUploadedPhoto
                  style={{
                    backgroundImage: `url(${image.data_url})`,
                  }}
                />
                <Typography
                  fontSize="body1"
                  color="title"
                  as="button"
                  textDecoration="underline"
                  display="block"
                  margin="32px auto"
                  onClick={onImageUpload}
                >
                  Não, quero ajustar
                </Typography>
                <Button disableChevron onClick={onBtnClicked}>
                  Sim, ficou ótima!
                </Button>
              </Container>
            ))}
          </>
        )}
      </ImageUploading>
    </>
  );
};

export default RegisterPicture;
