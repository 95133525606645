import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { colors } from 'styles/colors';
import { IconChevronRight, IconHeart, IconClose } from 'styles/icons';
import { Typography } from 'styles/typography';
import ActionSheetMenu from 'components/molecules/action-sheet-menu';
import { IActionSheetItem } from '../custom-action-sheet';
import {
  TopRight,
  HeaderContainer,
  BackButton,
  ProgressBarContainer,
  ProgressBarItem,
  LinkStyled,
} from './styled';

interface IHeaderProps {
  title?: string;
  progress?: number;
  contrast?: boolean;
  favoriteIcon?: boolean;
  isModal?: boolean;
  onCloseModal?: () => void;
  onBack?: () => void;
  smallMargin?: boolean;
  onFavoriteClicked?: (favorite) => void;
  isFavorite?: boolean;
  actionSheetItems?: IActionSheetItem[];
  to?: string;
}

const Header: React.FC<IHeaderProps> = ({
  title,
  progress,
  contrast,
  favoriteIcon,
  isModal,
  onCloseModal,
  onBack,
  smallMargin,
  onFavoriteClicked,
  isFavorite,
  actionSheetItems,
  to,
}) => {
  const history = useHistory();

  const favoriteClicked = (e) => {
    if (onFavoriteClicked) {
      onFavoriteClicked(!isFavorite);
    }
  };

  return (
    <HeaderContainer $smallMargin={smallMargin}>
      <Typography
        as="p"
        fontSize="body2"
        fontWeight="normal"
        align="center"
        display="block"
      >
        {(!isModal || onBack) && (
          <BackButton
            onClick={(e) => {
              e.preventDefault();
              if (onBack) onBack();
              else history.goBack();
            }}
          >
            <IconChevronRight stroke={contrast ? colors.white : undefined} />
          </BackButton>
        )}
        {to ? <LinkStyled to={to}>{title}</LinkStyled> : title}
        {isModal && (
          <TopRight onClick={onCloseModal}>
            <IconClose fill={contrast ? colors.white : undefined} />
          </TopRight>
        )}
        {favoriteIcon && (
          <TopRight
            onClick={favoriteClicked}
            className={isFavorite ? 'favorited' : ''}
          >
            <IconHeart />
          </TopRight>
        )}
        {actionSheetItems && (
          <TopRight>
            <ActionSheetMenu items={actionSheetItems} contrast={contrast} />
          </TopRight>
        )}
      </Typography>
      {progress && (
        <ProgressBarContainer>
          <ProgressBarItem style={{ width: `${progress}%` }} />
        </ProgressBarContainer>
      )}
    </HeaderContainer>
  );
};

export default Header;
