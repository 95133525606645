import React from 'react';
import { IconClose } from 'styles/icons';
import { Typography } from 'styles/typography';
import { TagStyled, RoundIcon } from './style';

interface Props {
  label: string;
  onRemove: (label: string) => void;
}
const AppliedFilter: React.FC<Props> = ({ label, onRemove }) => {
  return (
    <TagStyled>
      <RoundIcon onClick={() => onRemove(label)}>
        <IconClose width={10} />
      </RoundIcon>
      <Typography textTransform="lowercase">{label}</Typography>
    </TagStyled>
  );
};

export default AppliedFilter;
