import styled from 'styled-components';

export const PictureStyled = styled.picture`
  position: relative;
`;

export const ImgStyled = styled.img`
  width: 100%;
  object-fit: cover;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;

  &.loaded {
    opacity: 1;
    visibility: visible;
  }

  &:not(.loaded) {
    position: absolute;
  }
`;
