import { useModalPage } from 'context/modal-page-context';
import ImagePreview from 'modals/image-preview';
import { IWorkerListItem } from 'models/user';
import Skeleton from 'react-loading-skeleton';
import { Typography } from 'styles/typography';
import { WorkerProfileContainer, ProfileIcon, RightCol } from './styled';

interface IWorkerProps {
  worker?: IWorkerListItem;
}

const WorkerProfile: React.FC<IWorkerProps> = ({ worker }) => {
  const { openModal } = useModalPage();

  const onPhotoClicked = () => {
    openModal({
      component: ImagePreview,
      props: {
        image: worker?.picture_path,
        caption: worker?.name,
      },
    });
  };

  return (
    <WorkerProfileContainer>
      <ProfileIcon src={worker?.picture_path} onClick={onPhotoClicked} />
      <RightCol>
        <Typography
          as="strong"
          display="block"
          fontSize="heading3"
          fontWeight="bold"
          lineHeight="39px"
          letterSpacing="-0.01em"
          margin="0 0 11px"
        >
          {worker?.name || <Skeleton />}
        </Typography>
        <Typography fontSize="body1" display="block">
          {worker ? (
            `Prestador desde ${worker.created_at.split('-')[0]}`
          ) : (
            <Skeleton />
          )}
        </Typography>
      </RightCol>
    </WorkerProfileContainer>
  );
};

export default WorkerProfile;
