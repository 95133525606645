import { StepsContextProvider } from 'context/steps-context';
import React from 'react';
import { StorageKeyEnum } from 'enums/storage-key';
import { ILead } from 'models/lead';
import { leadService } from 'services/lead-service';
import { ProfileEnum } from 'enums/profile';
import { useUserContext } from 'context/user-context';
import { IRouteSteps } from 'context/steps-context/types';
import RegisterChooseProvider from './register-choose-provider';
import RegisterPicture from './register-picture';
import DocumentNumber from './register-document';
import Gender from './register-gender';
import RegisterName from './register-name';
import Password from './register-password';
import RegisterPhone from './register-phone';
import RegisterSecurityValidation from './register-security-validation';
import RegisterSkills from './register-skills';
import ZipCode from './register-zip-code';
import RegisterEmail from './register-email';

interface Props {
  profile: ProfileEnum;
}
const Register: React.FC<Props> = ({ profile }) => {
  const { redirectRoute, setRedirectRoute } = useUserContext();
  const isWorker = profile === ProfileEnum.WORKER;
  const baseRoute = `/cadastro-${
    profile === ProfileEnum.WORKER ? 'prestador' : 'cliente'
  }`;

  const allPages: IRouteSteps[] = [
    { url: `${baseRoute}`, component: RegisterChooseProvider, exact: true },
    {
      url: `${baseRoute}/nome`,
      component: RegisterName,
      allowDirectAccess: true,
    },
    { url: `${baseRoute}/numero-celular`, component: RegisterPhone },
    {
      url: `${baseRoute}/validacao-de-seguranca`,
      component: RegisterSecurityValidation,
    },
    { url: `${baseRoute}/email`, component: RegisterEmail },
    { url: `${baseRoute}/cep`, component: ZipCode, onlyWorker: true },
    // {
    //   url: `${baseRoute}/data-nascimento`,
    //   component: BirthDate,
    //   onlyWorker: true,
    // },
    { url: `${baseRoute}/genero`, component: Gender, onlyWorker: true },
    {
      url: `${baseRoute}/documento`,
      component: DocumentNumber,
    },
    {
      url: `${baseRoute}/habilidades`,
      component: RegisterSkills,
      onlyWorker: true,
    },
    { url: `${baseRoute}/senha`, component: Password },
    { url: `${baseRoute}/finalizar`, component: RegisterPicture },
  ];

  const patchLead = async (data: Partial<ILead>, previousData?: ILead) => {
    if (previousData?.lead_uid) {
      await leadService.patchLead(previousData?.lead_uid, data);
    }
  };

  return (
    <StepsContextProvider
      routes={allPages.filter((page) => isWorker || !page.onlyWorker)}
      headerTitle={`Cadastro ${isWorker ? 'prestador' : 'cliente'}`}
      successRoute={
        redirectRoute || `/t/${isWorker ? 'servicos' : 'prestadores'}`
      }
      onFinish={() => setRedirectRoute('')}
      storageKey={StorageKeyEnum.LEAD}
      profile={profile}
      handlePatchData={patchLead}
    />
  );
};

export default Register;
