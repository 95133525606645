import Header from 'components/molecules/header';
import NavTabs from 'components/molecules/nav-tabs';
import { useState } from 'react';
import { FlexContainer } from 'styles/flex-container';
import { Spacing } from 'styles/spacing';
import FavoriteClients from './favorite-clients';
import FavoriteWorkers from './favorite-workers';
import FavoriteServices from './favorite-services';

const Favorites = () => {
  const [activeTab, setActiveTab] = useState('clients');

  return (
    <FlexContainer>
      <Header title="Favoritos" />
      <NavTabs
        tabs={[
          { value: 'clients', label: 'Clientes' },
          { value: 'workers', label: 'Prestadores' },
          { value: 'services', label: 'Serviços' },
        ]}
        activeTab={activeTab}
        onTabChange={setActiveTab}
        noMarginTop
      />
      <Spacing size={7} />

      {activeTab === 'clients' && <FavoriteClients />}

      {activeTab === 'workers' && <FavoriteWorkers />}

      {activeTab === 'services' && <FavoriteServices />}
    </FlexContainer>
  );
};

export default Favorites;
