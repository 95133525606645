import InputSearch from 'components/atoms/input-search';
import CategoryItem from 'components/molecules/category-item';
import SubcategoryList from 'components/molecules/subcategory-list';
import { useAsync } from 'context/async-context';
import { normalizeText } from 'helpers';
import { ICategory, ISkill, ISkillsResponse } from 'models/skill';
import React, { useEffect, useState } from 'react';
import { skillsService } from 'services/skills-service';
import { FlexContainer } from 'styles/flex-container';
import { Spacing } from 'styles/spacing';
import { LinkTypography, Typography } from 'styles/typography';
import { ListContainer } from './styled';

interface Props {
  showSearch?: boolean;
  multiple?: boolean;
  onCategorySelected: (skill?: ICategory) => void;
  onSubcategorySelected: (items: ISkill[]) => void;
  resetOnBackToCategories?: boolean;
  initialSubcategories?: number[];
}

const CategorySelector: React.FC<Props> = ({
  onCategorySelected,
  onSubcategorySelected,
  showSearch = true,
  multiple = false,
  resetOnBackToCategories = false,
  initialSubcategories = [],
}) => {
  const [skillsResponse, setSkillsResponse] = useState<ISkillsResponse>();
  const [inputValue, setInputValue] = useState('');
  const [categorySelected, setCategorySelected] = useState<ICategory>();
  const [subsSelecteds, setSubsSelecteds] = useState<ISkill[]>([]);

  const { handledAsync } = useAsync();

  useEffect(() => {
    handledAsync(async () => {
      const tmpSkills = await skillsService.getAll();
      setSkillsResponse(tmpSkills);

      if (initialSubcategories.length) {
        const tmpSelected = tmpSkills.skills.filter((item) =>
          initialSubcategories.some((id) => `${id}` === `${item.skill_id}`),
        );

        setSubsSelecteds(tmpSelected);
        onSubcategorySelected(tmpSelected);
      }
    });
  }, []);

  const handleCategoryClicked = (item?: ICategory) => {
    setCategorySelected(item);
    onCategorySelected(item);
  };

  const handleSubcategoryClicked = (items: ISkill[]) => {
    let newSelecteds = items;

    if (!resetOnBackToCategories && categorySelected) {
      newSelecteds = subsSelecteds
        .filter((sub) => !sub.categories.includes(categorySelected.id))
        .concat(items);
    }

    setSubsSelecteds(newSelecteds);
    onSubcategorySelected(newSelecteds);
  };

  const categories = skillsResponse?.categories;
  const allSubs = skillsResponse?.skills;

  const normalizedText = normalizeText(inputValue);
  const filteredList = allSubs
    ?.filter(
      (item) =>
        !categorySelected || item.categories.includes(categorySelected.id),
    )
    .filter((item) => normalizeText(item.skill).indexOf(normalizedText) > -1);

  const noResults = !!(normalizedText && !filteredList?.length);
  const showCategories = !normalizedText && !categorySelected;
  const showSubs = normalizedText || categorySelected;

  return (
    <>
      {showSearch && (
        <>
          <InputSearch
            onChange={setInputValue}
            value={inputValue}
            placeholder="O que você busca?"
          />
          {noResults && (
            <Typography
              display="block"
              color="primary"
              fontSize="body2"
              margin="8px 0 0 0"
            >
              A gente não encontrou resultado.
            </Typography>
          )}
          <div>
            <Spacing size={4} />
          </div>
        </>
      )}
      <FlexContainer padding="0" overflow="visible">
        {showCategories && (
          <ListContainer>
            {categories?.map((item) => {
              const qtyActive = subsSelecteds.filter((sub) =>
                sub.categories.includes(item.id),
              ).length;
              return (
                <CategoryItem
                  key={item.id}
                  category={item}
                  isActive={!multiple && qtyActive > 0}
                  indicator={qtyActive}
                  onClick={handleCategoryClicked}
                />
              );
            })}
          </ListContainer>
        )}

        {showSubs && (
          <>
            <LinkTypography
              fontSize="body1"
              onClick={() => {
                if (resetOnBackToCategories) {
                  handleSubcategoryClicked([]);
                }
                setInputValue('');
                handleCategoryClicked(undefined);
              }}
            >
              Voltar para categorias
            </LinkTypography>

            <SubcategoryList
              skills={filteredList?.length ? filteredList : allSubs}
              multiple={multiple}
              onSelected={handleSubcategoryClicked}
              checked={subsSelecteds}
            />
          </>
        )}
        <div>
          <Spacing size={4} />
        </div>
      </FlexContainer>
    </>
  );
};

export default CategorySelector;
