import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { responsiveSize } from 'styles/sizes';

export const InspiratingStoryWrapper = styled(Link)`
  text-decoration: none;
`;

export const InspiratingStory = styled.div`
  border: 1px solid #000000;
  width: 252px;
  height: 160px;
  padding: ${responsiveSize(1)} ${responsiveSize(3)};
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;
