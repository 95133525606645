import styled from 'styled-components';
import { responsiveSize } from 'styles/sizes';
import { Link } from 'react-router-dom';

export const StyledLink = styled(Link)`
  text-decoration: none;
`;

export const RightCol = styled.div`
  padding-left: ${responsiveSize(2)};
  overflow: hidden;
`;
