import EmergencyImg from 'assets/img/contato-emergencia.png';
import { Button } from 'components/atoms/button';
import Image from 'components/atoms/image';
import { useAlert } from 'context/alert-context';
import { useAsync } from 'context/async-context';
import { useUserContext } from 'context/user-context';
import { IModalDefaultProps } from 'modals/types';
import { useHistory } from 'react-router-dom';
import { Modal, ModalTransition } from 'react-simple-hook-modal';
import { emergencyService } from 'services/emergency-service';
import { colors } from 'styles/colors';
import { FlexContainer } from 'styles/flex-container';
import { IconClose } from 'styles/icons';
import { Spacing } from 'styles/spacing';
import { LinkTypography, Typography } from 'styles/typography';
import { IconCloseContainer, MainContent } from './styled';

const ActivateEmergencyModal: React.FC<IModalDefaultProps> = ({
  isModalOpen,
  onClose,
}) => {
  const history = useHistory();
  const { showAlert } = useAlert();
  const { handledAsync } = useAsync();
  const { user } = useUserContext();

  const handleBtnClicked = () => {
    handledAsync(async () => {
      await emergencyService.actionEmergency('activated');
      onClose();
      history.push('/emergencia');
    });
  };

  return (
    <Modal
      id="activate-emergency-modal"
      modalClassName="no-padding"
      isOpen={isModalOpen}
      transition={ModalTransition.BOTTOM_UP}
    >
      <FlexContainer darkMode fullPageHeight>
        <IconCloseContainer>
          <IconClose width={24} fill={colors.white} onClick={onClose} />
        </IconCloseContainer>

        <MainContent>
          <Typography
            as="h3"
            fontSize="heading3"
            fontWeight="bold"
            letterSpacing="-0.01em"
            display="block"
          >
            Emergência
          </Typography>
          <Spacing size={5} />
          <Typography display="block">
            A gente vai ligar para seu contato de emergência e alertar ele que
            você pode estar numa situação difícil.
          </Typography>
          <Spacing size={5} />
          <LinkTypography
            as="button"
            onClick={() => {
              showAlert({
                title: 'Como funciona a Emergência',
                text:
                  'Por ligação, a gente vai acionar o seu contato de emergência e informar que você pode estar precisando de ajuda.',
              });
            }}
          >
            Como funciona?
          </LinkTypography>
        </MainContent>

        <FlexContainer direction="row" padding="0" gap="15px" flex1={false}>
          <Image src={EmergencyImg} circle width={50} height={50} />
          <div>
            <Typography
              fontSize="body1"
              lineHeight="120%"
              display="block"
              margin="0 0 8px 0"
            >
              {user?.emergency_contact}
            </Typography>
            <Typography display="block">{user?.emergency_cellphone}</Typography>
          </div>
        </FlexContainer>
        <Spacing size={10} />

        <Button disableChevron onClick={handleBtnClicked}>
          Ativar emergência
        </Button>
      </FlexContainer>
    </Modal>
  );
};

export default ActivateEmergencyModal;
