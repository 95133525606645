import { IServiceOffer } from 'models/service-offer';
import React from 'react';
import { serviceOfferService } from 'services/service-offer-service';
import { FlexContainer } from 'styles/flex-container';
import { Spacing } from 'styles/spacing';
import { LinkTypography, Typography } from 'styles/typography';
import {
  capitalizeFirstChar,
  currencyFormatted,
  formatDateToView,
} from 'helpers';
import { Link } from 'react-router-dom';
import { IProgressStatusEnum } from 'enums/progress-status';
import { ProfileEnum } from 'enums/profile';
import { responsiveSize } from 'styles/sizes';
import { useAlert } from 'context/alert-context';
import { getPaymentTypeDesc } from 'enums/payment-type';
import { useAsync } from 'context/async-context';
import Image from 'components/atoms/image';
import PlaceholderImg from 'assets/img/servico-placeholder-small.png';
import { RightCol, StyledLink } from './styled';

interface Props {
  service_offer: IServiceOffer;
  userProfile: ProfileEnum;
  reloadAction: () => void;
}
const CardInProgress: React.FC<Props> = ({
  service_offer,
  userProfile,
  reloadAction,
}) => {
  const { handledAsync } = useAsync();
  const { showToast } = useAlert();

  const acceptProposal = (uid) => {
    handledAsync(async () => {
      await serviceOfferService.accept(uid);
      reloadAction();
      showToast({
        text: 'Negociação aceita',
      });
    });
  };

  const declineProposal = (uid) => {
    handledAsync(async () => {
      await serviceOfferService.decline(uid);
      reloadAction();
      showToast({
        text: 'Negociação rejeitada',
        rightAction: 'undo',
        onUndoClicked: () =>
          handledAsync(async () => {
            await serviceOfferService.undoDecline(uid);
            reloadAction();
          }),
      });
    });
  };

  const removeProposal = (uid) => {
    handledAsync(async () => {
      await serviceOfferService.cancelProposal(uid);
      reloadAction();
      showToast({
        text: 'Negociação excluída',
        rightAction: 'undo',
        onUndoClicked: () =>
          handledAsync(async () => {
            await serviceOfferService.undoCancel(uid);
            reloadAction();
          }),
      });
    });
  };

  return (
    <FlexContainer padding={responsiveSize(2)} direction="row">
      {service_offer && (
        <Image
          src={
            service_offer.pictures_paths
              ? service_offer.pictures_paths[0]
              : PlaceholderImg
          }
          width={35}
          height={35}
        />
      )}
      <RightCol>
        <StyledLink to={`/servico/${service_offer.service_uid}`}>
          <Typography display="block" truncate>
            {capitalizeFirstChar(service_offer.skill)} -{' '}
            {service_offer.location?.district}
          </Typography>
          <Spacing size={0.5} />
          <Typography
            color="primary"
            fontWeight="bold"
            fontSize="body1"
            lineHeight="120%"
          >
            {currencyFormatted.format(service_offer.payment_value || 0)}
          </Typography>
          <Typography
            fontSize="body1"
            lineHeight="120%"
            margin={`0 0 0 ${responsiveSize(0.5)}`}
          >
            / {getPaymentTypeDesc(service_offer.payment_type)}
          </Typography>
          <Spacing size={0.5} />
        </StyledLink>
        {service_offer.status === IProgressStatusEnum.ESTIMATE_SENT && (
          <>
            <Typography display="block">
              Proposto em {formatDateToView(service_offer.proposed_at)}
            </Typography>
            {userProfile === ProfileEnum.WORKER ? (
              <LinkTypography
                margin={`${responsiveSize(2)} 0 0 0`}
                onClick={() => removeProposal(service_offer.uid)}
              >
                Excluir negociação
              </LinkTypography>
            ) : (
              <>
                <Link to={`/perfil-prestador/${service_offer.worker_uid}`}>
                  <Typography
                    margin={`${responsiveSize(1)} 0 0 0`}
                    display="block"
                  >
                    {service_offer.name}
                  </Typography>
                </Link>
                {service_offer.score ? (
                  <Typography fontSize="menu" lineHeight="120%">
                    Nota {service_offer.score} em{' '}
                    {service_offer.services_finished} serviços
                  </Typography>
                ) : null}

                <Typography
                  display="block"
                  padding={`${responsiveSize(2)} 0 0 0`}
                >
                  {service_offer.description}
                </Typography>

                <FlexContainer
                  padding={`${responsiveSize(3)} 0 0 0`}
                  direction="row"
                  justifyContent="space-between"
                >
                  <LinkTypography
                    as="button"
                    onClick={() => acceptProposal(service_offer.uid)}
                  >
                    Aceitar
                  </LinkTypography>
                  <LinkTypography
                    as="button"
                    onClick={() => declineProposal(service_offer.uid)}
                  >
                    Recusar
                  </LinkTypography>
                </FlexContainer>
              </>
            )}
          </>
        )}
        {service_offer.accepted_at && (
          <>
            <Typography display="block">
              Contratado em {formatDateToView(service_offer.accepted_at)}
            </Typography>
            <Link to={`/conversa/${service_offer.user_uid}`}>
              <Typography margin={`${responsiveSize(2)} 0 0 0`}>
                Conversar com o{' '}
                {userProfile === ProfileEnum.CLIENT ? 'Prestador' : 'Cliente'}
              </Typography>
            </Link>
          </>
        )}
      </RightCol>
    </FlexContainer>
  );
};

export default CardInProgress;
