import Header from 'components/molecules/header';
import { useAsync } from 'context/async-context';
import { useUserContext } from 'context/user-context';
import { getPaymentTypeDesc } from 'enums/payment-type';
import { capitalizeText, currencyFormatted } from 'helpers';
import { IUser } from 'models/user';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { chatService } from 'services/chat-service';
import { FlexContainer } from 'styles/flex-container';
import { Typography } from 'styles/typography';
import ServiceRating from 'modals/service-rating';
import { useModalPage } from 'context/modal-page-context';
import { ProfileEnum } from 'enums/profile';
import Talk from 'talkjs';
import { useLoader } from 'context/loader-context';
import { ServiceCard, ServiceImg } from './styled';

const ChatboxModal = () => {
  const { handledAsync } = useAsync();
  const { user, talkJSSession } = useUserContext();
  const chatboxContainer = useRef<HTMLDivElement>(null);
  const [other, setOther] = useState<IUser>();
  const [serviceInProgress, setServiceInProgress] = useState<any>();
  const { uid } = useParams<any>();
  const { openModal } = useModalPage();
  const { setLoading } = useLoader();

  const chatbox = useRef<Talk.Chatbox>();

  const handleEvaluate = () => {
    if (!other) return;
    openModal({
      component: ServiceRating,
      props: {
        otherUid: other?.worker_uid || other?.client_uid,
        role: other.worker_uid ? ProfileEnum.CLIENT : ProfileEnum.WORKER,
      },
    });
  };

  const actionSheetItems = [
    {
      label: `Avaliar esse ${other?.worker_uid ? 'prestador' : 'cliente'}`,
      onClick: handleEvaluate,
    },
    {
      label: `Voltar`,
      onClick: () => null,
    },
  ];

  const createChatbox = () => {
    if (user && other) {
      handledAsync(async () => {
        await chatService.setTalkSession(talkJSSession);

        const otherUser = await chatService.createUser({
          id: other.uid,
          name: other.name,
          photoUrl: other.picture_path || undefined,
        });

        const conversation = await chatService.getOrCreateConversation(
          otherUser,
        );
        if (conversation) {
          conversation.setAttributes({
            custom: {
              in_progress: serviceInProgress?.service_uid || null,
            },
            subject: serviceInProgress?.category,
          });
        }

        chatbox.current = await chatService.createChatbox(conversation);
        chatbox.current
          ?.mount(chatboxContainer.current)
          .then(() => setLoading(false));
      });
    }
  };

  useEffect(() => {
    setLoading(true);
    handledAsync(async () => {
      const { service_in_progress, ...res } = await chatService.getChatData(
        uid,
      );
      setServiceInProgress(service_in_progress);
      setOther(res);
    });
  }, []);

  useEffect(() => {
    if (user && other) {
      createChatbox();
    }
  }, [user, other]);

  return (
    <FlexContainer padding="0">
      <FlexContainer flex1={false} padding="0 30px">
        <Header
          to={
            other?.worker_uid
              ? `/perfil-prestador/${other.worker_uid}`
              : `/perfil-cliente/${other?.client_uid}`
          }
          actionSheetItems={actionSheetItems}
          title={other?.name || ''}
          contrast={false}
          smallMargin
        />
      </FlexContainer>
      {serviceInProgress && (
        <ServiceCard>
          <ServiceImg src={serviceInProgress?.picture_path} alt="Service" />
          <Link to={`/servico/${serviceInProgress.service_uid}`}>
            <Typography>
              {capitalizeText(serviceInProgress.category)} •{' '}
              {getPaymentTypeDesc(serviceInProgress.payment_type)}{' '}
              {currencyFormatted.format(serviceInProgress.payment_value)}
            </Typography>
          </Link>
        </ServiceCard>
      )}
      <FlexContainer padding="0" ref={chatboxContainer} />
    </FlexContainer>
  );
};

export default ChatboxModal;
