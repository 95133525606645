/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useState } from 'react';
import { IconPlay } from 'styles/icons';
import { OverlayVideo, VideoContainer } from './styled';

interface Props {
  src: string | File;
}
const VideoPlayer: React.FC<Props> = ({ src }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const playVideo = () => {
    videoRef.current?.play();
    setIsPlaying(true);
  };

  const parsedSrc = typeof src === 'string' ? src : URL.createObjectURL(src);

  return (
    <VideoContainer>
      <video
        ref={videoRef}
        width="100%"
        controls
        onPause={() => setIsPlaying(false)}
      >
        <source src={parsedSrc} />
      </video>
      {!isPlaying && (
        <OverlayVideo onClick={playVideo}>
          <IconPlay />
        </OverlayVideo>
      )}
    </VideoContainer>
  );
};

export default VideoPlayer;
