import React, { useState } from 'react';
import { colors } from 'styles/colors';
import { IconDottedMenu } from 'styles/icons';
import CustomActionSheet, { IActionSheetItem } from '../custom-action-sheet';

interface Props {
  items: IActionSheetItem[];
  contrast?: boolean;
}
const ActionSheetMenu: React.FC<Props> = ({ items, contrast }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <IconDottedMenu
        onClick={handleOpen}
        fill={contrast ? colors.white : undefined}
      />
      <CustomActionSheet
        items={items}
        contrast={contrast}
        open={isOpen}
        onClose={handleClose}
      />
    </>
  );
};

export default ActionSheetMenu;
