import { useAsync } from 'context/async-context';
import { useState, useEffect } from 'react';
import { serviceService } from 'services/service-service';
import ServiceList from 'components/organisms/service-list';
import { ProfileEnum } from 'enums/profile';
import NoProfileTab from 'components/molecules/no-profile-tab';
import { useUserContext } from 'context/user-context';
import { Typography } from 'styles/typography';

const FavoriteServices = () => {
  const [serviceList, setServiceList] = useState<any[]>();

  const { handledAsync } = useAsync();
  const { worker } = useUserContext();

  const getFavoriteServices = async () => {
    handledAsync(async () => {
      const servicesInfo = await serviceService.getFavoriteServices();
      setServiceList(servicesInfo);
    });
  };

  useEffect(() => {
    if (worker) {
      getFavoriteServices();
    }
  }, []);

  if (!worker) {
    return (
      <NoProfileTab
        tab={ProfileEnum.WORKER}
        onProfileCreated={getFavoriteServices}
      />
    );
  }

  return (
    <>
      {serviceList && (serviceList?.length || 0) > 0 && (
        <ServiceList
          listItems={serviceList}
          loadMore={() => {
            return null;
          }}
          loading={false}
          hasNextPage={false}
        />
      )}

      {serviceList?.length === 0 && (
        <Typography align="center">Nenhum serviço favorito.</Typography>
      )}
    </>
  );
};

export default FavoriteServices;
