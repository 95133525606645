import { ModalStepsContextProvider } from 'context/modal-steps-context';
import { IModalSteps } from 'context/modal-steps-context/types';
import { useUserContext } from 'context/user-context';
import { IModalDefaultProps } from 'modals/types';
import React from 'react';
import { Modal, ModalTransition } from 'react-simple-hook-modal';
import { ContactIntro } from './contact-intro';
import ContactName from './contact-name';
import ContactPhone from './contact-phone';

const AddContactModal: React.FC<IModalDefaultProps> = ({
  isModalOpen,
  onClose,
}) => {
  const { user } = useUserContext();

  const steps: IModalSteps[] = [
    { component: <ContactIntro />, hideHeader: true, contrastHeader: true },
    { component: <ContactName /> },
    { component: <ContactPhone /> },
  ];

  if (user?.emergency_contact) {
    steps.shift();
  }

  return (
    <Modal
      id="add-contact-modal"
      modalClassName="no-padding"
      isOpen={isModalOpen}
      transition={ModalTransition.SCALE}
    >
      <ModalStepsContextProvider
        headerTitle="Cadastro de emergência"
        closeModal={onClose}
        steps={steps}
        initialData={user}
      />
    </Modal>
  );
};

export default AddContactModal;
