import styled from 'styled-components';
import { responsiveSize } from 'styles/sizes';

export const Container = styled.div`
  padding-top: ${responsiveSize(5)};
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  gap: 20px;
  margin-top: 28px;
`;

export const IconCloseContainer = styled.div`
  text-align: right;
`;

export const IconReturnContainer = styled.div`
  text-align: left;
  cursor: pointer;
`;
