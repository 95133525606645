import { responsiveSize } from 'styles/sizes';
import { Typography } from 'styles/typography';
import Image from 'components/atoms/image';
import { BenefitContainer, ColLeft, ColRight } from './styled';

interface IbenefitListProps {
  image?: string;
  title?: string;
  text?: string;
}

interface IBenefitProps {
  benefitList: IbenefitListProps[];
}

const Benefit: React.FC<IBenefitProps> = ({ benefitList }) => {
  return (
    <>
      {benefitList.map((item) => (
        <BenefitContainer key={item.title}>
          <ColLeft>
            <Image src={item.image} circle width={50} height={50} />
          </ColLeft>
          <ColRight>
            <Typography
              fontSize="body1"
              display="block"
              margin={`0 0 ${responsiveSize(1)} 0`}
            >
              {item.title}
            </Typography>
            <Typography>{item.text}</Typography>
          </ColRight>
        </BenefitContainer>
      ))}
    </>
  );
};

export default Benefit;
