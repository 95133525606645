import styled from 'styled-components';
import { responsiveSize } from 'styles/sizes';

export const IconCloseContainer = styled.div`
  padding: ${responsiveSize(5)} 0;
  text-align: right;
`;

export const MainContent = styled.div`
  margin-bottom: auto;
`;
