import { Button } from 'components/atoms/button';
import Select from 'components/atoms/select';
import { useAlert } from 'context/alert-context';
import { useAsync } from 'context/async-context';
import { useStepsContext } from 'context/steps-context';
import { GenderEnum, genderOptionList } from 'enums/gender';
import { ILead } from 'models/lead';
import { useState } from 'react';
import { Typography } from 'styles/typography';

const Gender = () => {
  const [gender, setGender] = useState<GenderEnum>();
  const { nextStep, patchData, getData } = useStepsContext();
  const { handledAsync } = useAsync();
  const { showAlert } = useAlert();

  const savedData = getData<ILead>();

  const tooltipClicked = () => {
    showAlert({
      title: 'Por que pedimos essa informação?',
      text:
        'Esta informação nos ajuda a conhecer melhor nossos usuários e poder proporcionar uma experiência mais completa.',
    });
  };

  const mainBtnClicked = () => {
    handledAsync(async () => {
      await patchData<ILead>({ gender }, true);
      nextStep();
    });
  };

  return (
    <>
      <Typography
        as="h3"
        color="title"
        fontSize="heading3"
        fontWeight="bold"
        lineHeight="48px"
        display="block"
        letterSpacing="-0.01em"
        margin="0 0 41px"
      >
        Informe seu gênero
      </Typography>
      <Typography
        as="button"
        textDecoration="underline"
        color="text"
        fontSize="body1"
        margin="0 0 36px"
        onClick={tooltipClicked}
      >
        Por que pedimos essa informação?
      </Typography>
      <Select
        label="Selecione seu gênero"
        emptyText="Selecione seu gênero"
        initialData={savedData.gender || ''}
        onChange={setGender}
        optionList={genderOptionList}
      />
      <Button disabled={!gender} onClick={mainBtnClicked}>
        Continuar
      </Button>
    </>
  );
};

export default Gender;
