import { FC } from 'react';
import { ButtonStyled, ChevronContainer } from 'components/atoms/button/style';
import { colors } from 'styles/colors';

interface IButtonProps {
  disableChevron?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  backgroundColor?: keyof typeof colors;
  justifyContent?: string;
  bottomFixed?: boolean;
  margin?: string;
}

export const Button: FC<IButtonProps> = ({
  children,
  disableChevron,
  backgroundColor = 'primary',
  justifyContent = 'space-between',
  bottomFixed = true,
  margin,
  onClick,
  ...rest
}) => {
  return (
    <ButtonStyled
      onClick={onClick}
      backgroundColor={backgroundColor}
      justifyContent={justifyContent}
      bottomFixed={bottomFixed ? 1 : 0}
      margin={margin}
      {...rest}
    >
      {children}
      {!disableChevron && <ChevronContainer />}
    </ButtonStyled>
  );
};
