/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-return-assign */
import React, { useState } from 'react';
import { Typography } from 'styles/typography';
import * as S from './styled';

const TOKEN_LENGTH = 4;

interface IInputSecurityValidateProps {
  onChange: (value: string) => void;
}

const InputSecurityValidate: React.FC<IInputSecurityValidateProps> = ({
  onChange,
}) => {
  const [inputValue, setInputValue] = useState('');

  const parseTextInput = (text: string) => {
    const parsed = (text || '').replace(/\D/g, '').substr(0, TOKEN_LENGTH);
    setInputValue(parsed);
    onChange(parsed.length === TOKEN_LENGTH ? parsed : '');
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    parseTextInput(value);
  };

  const onPaste = (e) => {
    e.preventDefault();
    const text: string = (
      e.clipboardData ||
      (window as any).clipboardData ||
      ''
    )?.getData('text');

    parseTextInput(text);
    return false;
  };

  return (
    <>
      <S.CodeWrapper>
        <S.CodeSquareWrapper>
          {[...Array(TOKEN_LENGTH)].map((_, index) => (
            <S.CodeSquare key={index}>
              <Typography fontSize="body1">
                {inputValue.split('')[index]}
              </Typography>
            </S.CodeSquare>
          ))}
          <S.CodeInput
            onPaste={onPaste}
            onChange={handleInputChange}
            value={inputValue}
            type="tel"
          />
        </S.CodeSquareWrapper>
      </S.CodeWrapper>
    </>
  );
};

export default InputSecurityValidate;
