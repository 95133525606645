import NoProfileTab from 'components/molecules/no-profile-tab';
import PersonCard from 'components/molecules/person-card';
import { useAsync } from 'context/async-context';
import { useUserContext } from 'context/user-context';
import { ProfileEnum } from 'enums/profile';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { clientsService } from 'services/clients-service';
import { Typography } from 'styles/typography';
import { PersonListItem } from './styled';

const FavoriteClients = () => {
  const [clientsList, setClientsList] = useState<any[]>();

  const history = useHistory();
  const { handledAsync } = useAsync();
  const { worker } = useUserContext();

  const getFavoritesClients = async () => {
    handledAsync(async () => {
      const clientsInfo = await clientsService.getFavoritesClients();
      setClientsList(clientsInfo);
    });
  };

  useEffect(() => {
    if (worker) {
      getFavoritesClients();
    }
  }, []);

  if (!worker) {
    return (
      <NoProfileTab
        tab={ProfileEnum.WORKER}
        onProfileCreated={getFavoritesClients}
      />
    );
  }

  return (
    <>
      {clientsList?.map((person) => (
        <PersonListItem
          key={person.uid}
          onClick={() => history.push(`/perfil-cliente/${person.uid}`)}
        >
          <PersonCard
            image={person.picture_path}
            online={person.online}
            name={person.name}
            assessment={person.assessment}
            uid={person.user_uid}
            showChatLink
          />
        </PersonListItem>
      ))}

      {clientsList?.length === 0 && (
        <Typography align="center">Nenhum cliente favorito.</Typography>
      )}
    </>
  );
};

export default FavoriteClients;
