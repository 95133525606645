import styled from 'styled-components';
import { responsiveSize } from 'styles/sizes';

export const BenefitContainer = styled.div`
  display: flex;
  padding-bottom: ${responsiveSize(4)};
`;

export const ColLeft = styled.div`
  margin-right: ${responsiveSize(2)};
  margin-top: ${responsiveSize(1)};
`;

export const ColRight = styled.div``;
