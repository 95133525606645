import styled from 'styled-components';

export const ButtonAddPhoto = styled.button`
  margin: 0 auto;
  display: block;
  width: 100%;
  height: 121px;
`;

export const IconAddPhotoStyled = styled.img``;

export const Container = styled.div``;

export const UserUploadedPhoto = styled.div`
  margin: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% center;
  width: 120px;
  height: 120px;
  border-radius: 50%;
`;
