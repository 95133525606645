/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useContext } from 'react';
import { useAlert } from './alert-context';
import { useLoader } from './loader-context';

interface IAsyncContextOptions {
  showLoading: boolean;
}
interface IAsyncContext {
  handledAsync: (
    body: () => Promise<unknown>,
    errorHandler?: (err: any) => string | boolean,
    options?: IAsyncContextOptions,
  ) => Promise<void>;
}

export const Context = createContext<IAsyncContext>({} as IAsyncContext);
export const useAsync = () => useContext(Context);

const AsyncContext: React.FC<unknown> = ({ children }) => {
  const { setLoading } = useLoader();
  const { showErrorAlert } = useAlert();

  /**
   * Helper to make it easy to build error handled async functions
   * @param body should execute HTTP request and other operations without worrying with error handling
   * @param errorHandler  Handler expecting either a message to show in alert or "false", which prevents the alert appearence
   * @param options options to configure the handler
   */
  const handledAsync = async (
    body: () => Promise<unknown>,
    errorHandler?: (err: any) => string | boolean,
    options: IAsyncContextOptions = { showLoading: true },
  ) => {
    if (options.showLoading) {
      setLoading(true);
    }
    try {
      await body();
    } catch (err) {
      if (!errorHandler) {
        showErrorAlert(err);
      } else {
        const customMessage = errorHandler(err);
        if (customMessage) {
          showErrorAlert(err, `${customMessage}`);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Context.Provider value={{ handledAsync }}>{children}</Context.Provider>
  );
};

export default AsyncContext;
