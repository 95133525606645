import { Button } from 'components/atoms/button';
import Header from 'components/molecules/header';
import { useAlert } from 'context/alert-context';
import { useModalPage } from 'context/modal-page-context';
import { useUserContext } from 'context/user-context';
import { getFirstName } from 'helpers';
import React, { useEffect } from 'react';

import { FlexContainer } from 'styles/flex-container';
import { responsiveSize } from 'styles/sizes';
import { Spacing } from 'styles/spacing';
import { LinkTypography, Typography } from 'styles/typography';
import AddContactModal from './add-contact-modal';

const EmergencyContact = () => {
  const { openModal } = useModalPage();
  const { showAlert } = useAlert();
  const { user } = useUserContext();

  const handleAddContact = () => {
    openModal({ component: AddContactModal });
  };

  useEffect(() => {
    if (!user?.emergency_contact) {
      handleAddContact();
    }
  }, []);

  return (
    <FlexContainer>
      <Header title="Contato de emergência" />

      {!user?.emergency_contact && (
        <Typography>
          Você ainda não cadastrou o seu contato de emergência.
        </Typography>
      )}
      {user?.emergency_contact && (
        <>
          <Typography>
            Contato de {getFirstName(user?.emergency_contact)}
          </Typography>
          <Typography
            fontSize="body1"
            lineHeight="120%"
            margin={`${responsiveSize(1)} 0 ${responsiveSize(3)} 0`}
          >
            {user?.emergency_contact}
          </Typography>
          <FlexContainer
            padding="0"
            direction="row"
            justifyContent="space-between"
            flex1={false}
          >
            <Typography fontSize="body1">
              {user?.emergency_cellphone}
            </Typography>
            <LinkTypography
              color="primary"
              as="button"
              onClick={handleAddContact}
            >
              Editar
            </LinkTypography>
          </FlexContainer>
        </>
      )}
      <Spacing size={7} />
      <LinkTypography
        as="button"
        onClick={() => {
          showAlert({
            title: 'Como funciona a Emergência?',
            text:
              'Por ligação, a gente vai acionar o seu contato de emergência e informar que você pode estar precisando de ajuda.',
          });
        }}
      >
        Como funciona?
      </LinkTypography>

      {!user?.emergency_contact && (
        <Button onClick={handleAddContact} disableChevron>
          Adicionar contato
        </Button>
      )}
    </FlexContainer>
  );
};

export default EmergencyContact;
