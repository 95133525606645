export const masks = {
  text: '',
  cep: '99999-999',
  date: '99/99/9999',
  document: ['999.999.999-999', '99.999.999/9999-99'],
  currencyBRL: [
    ',9',
    ',99',
    '9,99',
    '99,99',
    '999,99',
    '9.999,99',
    '99.999,99',
    '999.999,99',
    '9.999.999,99',
  ],
};
