import styled from 'styled-components';

export const PlaceholderUpload = styled.div`
  background-color: #677483;
  width: 183px;
  height: 183px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  cursor: pointer;
`;
