import React, { useState } from 'react';
import { mask as masker } from 'remask';
import { masks } from 'styles/masks';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import InputText, { IInputTextProps } from '../input-text';

const InputDocumentNumber: React.FC<IInputTextProps> = ({
  onChange,
  ...rest
}) => {
  const [inputValue, setInputValue] = useState('');

  const handleChange = (value = '') => {
    const unmasked = value.replace(/\D/g, '');
    const maskedValue = masker(unmasked, masks.document);
    setInputValue(maskedValue);

    let isValid = false;
    if (unmasked.length > 11) {
      isValid = cnpj.isValid(unmasked);
    } else {
      isValid = cpf.isValid(unmasked);
    }
    onChange(isValid ? unmasked : '');
  };

  return (
    <InputText
      type="tel"
      placeholder="Digite um CPF ou CNPJ"
      {...rest}
      onChange={handleChange}
      value={inputValue}
    />
  );
};

export default InputDocumentNumber;
