/* eslint-disable @typescript-eslint/no-non-null-assertion */
import TagManager from 'react-gtm-module';
import { useState } from 'react';
import { Button } from 'components/atoms/button';
import InputPassword from 'components/atoms/input-password';
import { useAsync } from 'context/async-context';
import { useStepsContext } from 'context/steps-context';
import { useUserContext } from 'context/user-context';
import { ILead } from 'models/lead';

import { firebaseService } from 'services/firebase-service';
import { leadService } from 'services/lead-service';
import { Typography } from 'styles/typography';
import { ProfileEnum } from 'enums/profile';

const Password = () => {
  const [inputValue, setInputValue] = useState('');
  const { nextStep, getData, clearData, profile } = useStepsContext();
  const { handledAsync } = useAsync();
  const { loadUserData, registerDeviceToken } = useUserContext();

  const handleBtnClicked = () => {
    handledAsync(
      async () => {
        const data = getData<ILead>();
        await firebaseService.createUser(
          `${data.cellphone}@${process.env.REACT_APP_FAKE_EMAIL_DOMAIN}`,
          inputValue,
        );

        await leadService.registrateUser(data.lead_uid!);
        const tagManagerArgs = {
          dataLayer: {
            event:
              profile === ProfileEnum.CLIENT
                ? 'cadastro-novo-cliente'
                : 'cadastro-novo-prestador',
          },
        };

        TagManager.dataLayer(tagManagerArgs);

        await loadUserData();
        registerDeviceToken();
        clearData();
        nextStep();
      },
      (err) => {
        firebaseService.deleteCurrentUser();
        return err.message;
      },
    );
  };

  return (
    <>
      <Typography
        as="h3"
        color="title"
        fontSize="heading3"
        fontWeight="bold"
        lineHeight="48px"
        display="block"
        letterSpacing="-0.01em"
        margin="0 0 12px"
      >
        Cadastre uma senha
      </Typography>
      <Typography as="p" fontSize="body1" margin="0 0 36px">
        Essa é a senha que você vai usar pra acessar o App. Ela precisa ter pelo
        menos 6 caracteres.
      </Typography>
      <InputPassword
        placeholder="Cadastre sua senha"
        onChange={setInputValue}
      />
      <Button disabled={!inputValue} onClick={handleBtnClicked}>
        Concluir cadastro
      </Button>
    </>
  );
};

export default Password;
