import { ProfileEnum } from 'enums/profile';

export interface IProfileLink {
  route?: string;
  action?: 'logout';
  label: string;
}

export interface IProfileSection {
  title: string;
  profile?: ProfileEnum;
  links: IProfileLink[];
}

export const profileMenuItems: IProfileSection[] = [
  {
    title: 'Suas configurações como prestador',
    profile: ProfileEnum.WORKER,
    links: [
      {
        label: 'Informações pessoais',
        route: '/informacoes-pessoais/worker',
      },
      {
        label: 'Habilidades',
        route: '/editar-habilidades',
      },
      {
        label: 'Sua história',
        route: '/sua-historia',
      },
      {
        label: 'Ver perfil',
        route: '/perfil-prestador',
      },
    ],
  },
  {
    title: 'Suas configurações como cliente',
    profile: ProfileEnum.CLIENT,
    links: [
      {
        label: 'Informações pessoais',
        route: '/informacoes-pessoais/client',
      },
      {
        label: 'Ver perfil',
        route: '/perfil-cliente',
      },
    ],
  },
  {
    title: 'Suas configurações do App',
    links: [
      {
        label: 'Contato de emergência',
        route: '/contato-emergencia',
      },
      {
        label: 'Favoritos',
        route: '/favoritos',
      },
      {
        label: 'Notificações',
        route: '/notificacoes',
      },
      {
        label: 'Suporte',
        route: '/suporte',
      },
    ],
  },
  {
    title: 'Redes sociais vinculadas',
    links: [
      {
        label: 'Facebook',
      },
      {
        label: 'Google',
      },
    ],
  },
  {
    title: 'Encerrar sessão',
    links: [
      {
        label: 'Sair',
        action: 'logout',
      },
    ],
  },
];
