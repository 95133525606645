import ServiceItem from 'components/molecules/service-item';
import ServiceListSkeleton from 'components/skeletons/service-list';
import { IService } from 'models/service';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { ServiceListContainer } from './styled';

interface IServiceListProps {
  listItems: IService[];
  canEdit?: boolean;
  loadMore?: () => void;
  loading?: boolean;
  hasNextPage?: boolean;
}

const ServiceList: React.FC<IServiceListProps> = ({
  listItems,
  canEdit,
  loadMore = () => null,
  loading = false,
  hasNextPage = false,
}) => {
  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: loadMore,
    disabled: false,
    rootMargin: '0px 0px 0px 0px',
  });

  return (
    <ServiceListContainer>
      {listItems.map((item) => (
        <ServiceItem
          item={item}
          canEdit={canEdit}
          key={item.uid}
          onRemove={loadMore}
        />
      ))}
      {(hasNextPage || loading) && (
        <div ref={sentryRef}>
          <ServiceListSkeleton />
        </div>
      )}
    </ServiceListContainer>
  );
};

export default ServiceList;
