import React, { useState } from 'react';
import { mask as masker } from 'remask';
import { masks } from 'styles/masks';
import { differenceInYears, parse } from 'date-fns';
import InputText, { IInputTextProps } from '../input-text';

interface Props extends IInputTextProps {
  minAge?: number;
}
const InputDate: React.FC<Props> = ({ minAge, onChange, ...rest }) => {
  const [value, setValue] = useState('');
  const [errorText, setErrorText] = useState('');

  const handleChange = (inputValue = '') => {
    const maskedValue = masker(inputValue, `${masks.date}`);
    setValue(maskedValue);

    const date = parse(maskedValue, 'dd/MM/yyyy', new Date());
    let isValid = inputValue.length === 10 && !Number.isNaN(date.getTime());

    if (isValid && minAge && differenceInYears(new Date(), date) < minAge) {
      setErrorText(`A idade mínima permitida é de ${minAge} anos.`);
      isValid = false;
    } else {
      setErrorText('');
    }
    onChange(isValid ? maskedValue : '');
  };

  return (
    <InputText
      type="tel"
      placeholder="DD/MM/AAAA"
      {...rest}
      onChange={handleChange}
      value={value}
      errorText={errorText}
    />
  );
};

export default InputDate;
