import { ProfileEnum } from 'enums/profile';
import CreateAccount from 'modals/create-account';
import { ICoordinates } from 'models/location';
import { IClient, IUser, IWorker } from 'models/user';
import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useModal } from 'react-simple-hook-modal';
import { firebaseService } from 'services/firebase-service';
import { meService } from 'services/me-service';
import { chatService } from 'services/chat-service';
import Talk from 'talkjs';
import { StorageKeyEnum } from 'enums/storage-key';
import { useLoader } from './loader-context';

interface IUserContext {
  user?: IUser;
  patchUser: (data: Partial<IUser>) => void;
  client?: IClient;
  setClient: (client?: IClient) => void;
  loadUserData: () => Promise<IUser>;
  worker?: IWorker;
  redirectRoute?: string;
  setRedirectRoute: (route?: string) => void;
  setWorker: (worker?: IWorker) => void;
  loadProfileData: (profile: ProfileEnum) => Promise<IWorker>;
  getGeolocation: () => Promise<ICoordinates | null>;
  openAuthModal: (successRoute?: string, profile?: ProfileEnum) => void;
  login: (cellphone: string, password: string) => Promise<void>;
  registerDeviceToken: () => Promise<void>;
  unregisterDeviceToken: () => Promise<void>;
  logout: () => Promise<void>;
  talkJSSession: Talk.Session | undefined;
  missingProfileData: string[];
  isClient?: boolean;
  isWorker?: boolean;
}

export const Context = createContext<IUserContext>({} as IUserContext);
export const useUserContext = () => useContext(Context);

const UserContext: React.FC<unknown> = ({ children }) => {
  const [initialProfile, setInitialProfile] = useState<ProfileEnum>();
  const [user, setUser] = useState<IUser>();
  const [client, setClient] = useState<IClient>();
  const [worker, setWorker] = useState<IWorker>();
  const [redirectRoute, setRedirectRoute] = useState<string>();
  const [geoLocation, setGeoLocation] = useState<ICoordinates | null>();
  const talkJSSession = useRef<Talk.Session | undefined>();
  const [missingProfileData, setMissingProfileData] = useState<string[]>([]);

  const isClient = user?.preferred_role === ProfileEnum.CLIENT;
  const isWorker = user?.preferred_role === ProfileEnum.WORKER;

  const checkMissingInformations = () => {
    const missingInfo: string[] = [];

    if (worker && user?.profiles.includes(ProfileEnum.WORKER)) {
      if (!worker.document_number || !worker.email) {
        missingInfo.push('/informacoes-pessoais/worker');
      }

      if (!worker.bio || !worker.story_pictures?.length) {
        missingInfo.push('/sua-historia');
      }

      setMissingProfileData(missingInfo);
    }
  };

  const { setLoading } = useLoader();
  const { openModal, isModalOpen, closeModal } = useModal();
  const history = useHistory();

  const loadUserData = async () => {
    const resp = await meService.getUserInfo();

    const me = await chatService.createUser({
      id: resp.uid,
      name: resp.name,
      photoUrl: resp.picture_path || undefined,
    });
    if (!talkJSSession.current) {
      const session = await chatService.getTalkSession(me);
      talkJSSession.current = session;
    }
    setUser(resp);
    if (resp.emergency_active) {
      history.push('/emergencia');
    }
    return resp;
  };

  const patchUser = (data: Partial<IUser>) => {
    setUser({ ...user, ...data } as IUser);
  };

  const loadProfileData = async (profile: ProfileEnum) => {
    const resp = await meService.getProfileInfo(profile);
    if (profile === ProfileEnum.WORKER) {
      setWorker(resp);
    } else {
      setClient(resp);
    }
    return resp;
  };

  const registerDeviceToken = async () => {
    const deviceToken = localStorage.getItem('@to_parado/push_token');

    if (deviceToken) {
      await meService.registerToken(deviceToken);
    }
  };

  const unregisterDeviceToken = async () => {
    const deviceToken = localStorage.getItem('@to_parado/push_token');

    if (deviceToken) {
      await meService.unregisterToken(deviceToken);
    }
  };

  const initUser = async () => {
    return loadUserData().then((resp) =>
      Promise.all(resp.profiles.map((profile) => loadProfileData(profile))),
    );
  };

  const openAuthModal = async (
    successRoute?: string,
    profile?: ProfileEnum,
  ) => {
    setRedirectRoute(successRoute);
    setInitialProfile(profile);
    openModal();
  };

  const login = async (cellphone: string, password: string) => {
    await firebaseService.login(cellphone, password);
    await initUser();

    localStorage.removeItem(StorageKeyEnum.LEAD);
    localStorage.removeItem(StorageKeyEnum.SERVICE_DRAFT);
  };

  const logout = async () => {
    await firebaseService.logout();
    localStorage.removeItem(StorageKeyEnum.SERVICE_DRAFT);
    localStorage.removeItem(StorageKeyEnum.IN_APP_SHOWN);

    if (talkJSSession.current) {
      talkJSSession.current.destroy();
      talkJSSession.current = undefined;
    }
    history.push('/');
    window.location.reload(true);
  };

  const getGeolocation = async () => {
    if (geoLocation) {
      return geoLocation;
    }

    return new Promise<ICoordinates | null>((resolve) => {
      navigator.geolocation.getCurrentPosition(
        (resp) => {
          const coords = {
            lat: resp.coords.latitude,
            lng: resp.coords.longitude,
          };
          resolve(coords);
          setGeoLocation(coords);
        },
        (err) => {
          console.log(err);
          resolve(null);
        },
      );
    });
  };

  useEffect(() => {
    setLoading(true);
    initUser().finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    checkMissingInformations();
  }, [worker]);

  return (
    <Context.Provider
      value={{
        login,
        user,
        patchUser,
        client,
        setClient,
        loadUserData,
        worker,
        redirectRoute,
        setRedirectRoute,
        setWorker,
        loadProfileData,
        getGeolocation,
        openAuthModal,
        logout,
        registerDeviceToken,
        unregisterDeviceToken,
        talkJSSession: talkJSSession.current,
        missingProfileData,
        isClient,
        isWorker,
      }}
    >
      {children}
      <CreateAccount
        isModalOpen={isModalOpen}
        onClose={() => closeModal()}
        initialTab={initialProfile}
        isLogged={!!user}
      />
    </Context.Provider>
  );
};

export default UserContext;
