import { Button } from 'components/atoms/button/index';
import Textarea from 'components/atoms/textarea/index';
import { useAsync } from 'context/async-context';
import { useStepsContext } from 'context/steps-context';
import { IServicePatch } from 'models/service';
import { useState } from 'react';
import { Typography } from 'styles/typography';

export const ServiceDescription = () => {
  const [textareaValue, setTextareaValue] = useState('');
  const { nextStep, getData, patchData } = useStepsContext();
  const { handledAsync } = useAsync();

  const previousData = getData<IServicePatch>();

  const btnClicked = async () => {
    handledAsync(async () => {
      await patchData<IServicePatch>({ description: textareaValue }, true);
      nextStep();
    });
  };

  return (
    <>
      <Typography
        as="h3"
        color="title"
        fontSize="heading3"
        fontWeight="bold"
        lineHeight="48px"
        display="block"
        letterSpacing="-0.01em"
        margin="0 0 29px"
      >
        Descrição
      </Typography>
      <Textarea
        placeholder="Escreva mais detalhes sobre o que deve ser feito"
        onChange={setTextareaValue}
        initialData={previousData.description}
      />
      <Button disabled={!textareaValue} onClick={btnClicked}>
        Continuar
      </Button>
    </>
  );
};
