/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { Button } from 'components/atoms/button/index';
import Header from 'components/molecules/header';
import MultipleImageUpload from 'components/organisms/multiple-image-upload';
import { useAlert } from 'context/alert-context';
import { useAsync } from 'context/async-context';
import { useUserContext } from 'context/user-context';
import { ProfileEnum } from 'enums/profile';
import { IModalDefaultProps } from 'modals/types';
import { IImageFile } from 'models/file';
import { IWorker } from 'models/user';
import { useState } from 'react';
import { Modal, ModalTransition } from 'react-simple-hook-modal';
import { firebaseService } from 'services/firebase-service';
import { meService } from 'services/me-service';
import { FlexContainer } from 'styles/flex-container';
import { Spacing } from 'styles/spacing';
import { Typography } from 'styles/typography';

export const StoryPhotosModal: React.FC<IModalDefaultProps> = ({
  isModalOpen,
  onClose,
}) => {
  const { worker, setWorker } = useUserContext();
  const [images, setImages] = useState<IImageFile[]>(
    worker?.story_pictures || [],
  );

  const { handledAsync } = useAsync();
  const { showToast } = useAlert();

  const handleBtnClicked = () => {
    handledAsync(async () => {
      const tmpImages = [...images];
      for (const img of tmpImages) {
        if (img.file) {
          img.path = await firebaseService.uploadFile(img.file);
          delete img.file;
        }
        delete img.data_url;
      }
      const payload = {
        story_pictures: tmpImages,
      } as IWorker;
      await meService.patchByProfile(ProfileEnum.WORKER, payload);
      setWorker({ ...worker, ...payload });

      showToast({
        text: 'História atualizada',
      });
      onClose();
    });
  };

  return (
    <Modal
      id="story-photos-modal"
      modalClassName="no-padding"
      isOpen={isModalOpen}
      transition={ModalTransition.SCALE}
    >
      <FlexContainer fullPageHeight>
        <Header title="" isModal onCloseModal={onClose} />
        <Typography
          as="h3"
          color="title"
          fontSize="heading3"
          fontWeight="bold"
          display="block"
          letterSpacing="-0.01em"
        >
          Fotos da sua história
        </Typography>
        <Spacing size={5} />
        <MultipleImageUpload images={images} onChange={setImages} />
        <Button
          disabled={!images.length}
          onClick={handleBtnClicked}
          disableChevron
        >
          Salvar
        </Button>
      </FlexContainer>
    </Modal>
  );
};
