import styled from 'styled-components';
import { colors } from 'styles/colors';

export const HowToGetContainer = styled.div`
  span {
    span {
      position: absolute;
      right: 0;
    }
  }
`;

export const LocalizationContainer = styled.div`
  display: flex;
  align-items: stretch;
`;

export const IconContainer = styled.div`
  position: relative;
  padding-left: 43px;
`;

export const IconStyled = styled.img`
  position: absolute;
  left: 0;
  background: ${colors.white};
  z-index: 5;

  &:nth-child(1) {
    top: 0;
    padding-bottom: 3px;
  }

  &:nth-child(2) {
    bottom: 0px;
    padding-top: 6px;
    padding-bottom: 16px;
  }
`;

export const LineStyled = styled.div`
  position: absolute;
  left: 14px;
  width: 1px;
  height: 100%;
  background: ${colors.title};
`;

export const RouteContainer = styled.div`
  margin-top: 48px;
`;

export const InfoContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

export const IconTransportStyled = styled.img`
  margin-right: 16px;
`;

export const IconPersonStyled = styled.img`
  margin-right: 10px;
`;

export const SmallIconBus = styled.img`
  width: 14px;
  height: 16px;
  margin-left: 13px;
  margin-right: 12px;
`;
