import React, { InputHTMLAttributes, useEffect } from 'react';
import { Typography } from 'styles/typography';
import { InputContainer, InputStyled } from './styled';

export interface IInputTextProps {
  initialData?: string;
  onChange: (value: string, valid?: boolean) => void;
  placeholder?: string;
  type?: string;
  value?: string;
  errorText?: string;
  helperText?: string;
  required?: boolean;
  inputExtraProps?: InputHTMLAttributes<HTMLInputElement>;
}

const InputText: React.FC<IInputTextProps> = ({
  onChange,
  initialData,
  type = 'text',
  placeholder,
  value,
  errorText,
  helperText,
  required = true,
  inputExtraProps,
}) => {
  useEffect(() => {
    if (initialData || !value) {
      onChange(initialData || '');
    }
  }, [initialData]);

  return (
    <InputContainer>
      <InputStyled
        type={type}
        placeholder={placeholder}
        {...inputExtraProps}
        defaultValue={value !== undefined ? undefined : initialData}
        onChange={(e) => onChange(e.target.value)}
        value={value ?? undefined}
      />

      {(errorText || helperText) && (
        <Typography margin="16px 0 0 0" color={errorText ? 'primary' : 'text'}>
          {errorText || helperText}
        </Typography>
      )}
    </InputContainer>
  );
};

export default InputText;
