import { Button } from 'components/atoms/button';
import InputDocumentNumber from 'components/atoms/input-document-number';
import { useAlert } from 'context/alert-context';
import { useAsync } from 'context/async-context';
import { useStepsContext } from 'context/steps-context';
import { ILead } from 'models/lead';
import { useState } from 'react';
import { Typography } from 'styles/typography';

const DocumentNumber = () => {
  const [inputValue, setInputValue] = useState('');
  const { nextStep, patchData, getData, profile } = useStepsContext();
  const { showAlert } = useAlert();
  const { handledAsync } = useAsync();

  const savedData = getData<ILead>();

  const helpClicked = () => {
    showAlert({
      title: 'Validação de CPF ou CNPJ',
      text:
        'Para sua segurança, nós do Tô parado validamos o CPF ou CNPJ de todos os usuários cadastrados.<br/><br/>Fique tranquilo, esta informação não será exibida para outros usuários.',
    });
  };

  const mainBtnClicked = () => {
    handledAsync(async () => {
      await patchData<ILead>(
        {
          document_type: inputValue.length === 11 ? 'cpf' : 'cnpj',
          document_number: inputValue,
        },
        true,
      );
      nextStep();
    });
  };

  return (
    <>
      <Typography
        as="h3"
        color="title"
        fontSize="heading3"
        fontWeight="bold"
        lineHeight="48px"
        display="block"
        letterSpacing="-0.01em"
        margin="0 0 51px"
      >
        Digite seu CPF ou CNPJ
      </Typography>
      <Typography
        as="button"
        textDecoration="underline"
        color="text"
        fontSize="body1"
        margin="0 0 36px"
        onClick={helpClicked}
      >
        Por que pedimos essa informação?
      </Typography>
      <InputDocumentNumber
        initialData={savedData.document_number}
        onChange={setInputValue}
      />
      <Button disabled={!inputValue} onClick={mainBtnClicked}>
        Continuar
      </Button>
    </>
  );
};

export default DocumentNumber;
