/* eslint-disable react/require-default-props */
import { toast, ToastContainerProps } from 'react-toastify';

import { useCallback, useRef } from 'react';

import { Typography } from 'styles/typography';
import * as S from './styles';

interface NotificationProps extends ToastContainerProps {
  title: string;
  message: string;
  avatar?: string;
  onClose: () => void;
  onClick?: () => void;
  type: 'push' | 'internal';
}

export interface ShowToastData {
  title: string;
  message: string;
  avatar?: string;
  type: 'push' | 'internal';
  onClick?: () => void;
}

const NotificationContainer = ({
  message,
  title,
  avatar,
  onClose,
  onClick,
  type,
}: NotificationProps) => {
  return (
    <S.Container>
      <S.HeaderWrapper>
        {avatar && <S.Avatar src={avatar} />}
        <S.ContentWrapper>
          <Typography fontSize="body1" display="block">
            {title}
          </Typography>
          <Typography fontSize="body2" display="block">
            {message}
          </Typography>
        </S.ContentWrapper>
      </S.HeaderWrapper>

      <S.Footer>
        <Typography
          fontSize="body2"
          display="block"
          fontWeight="bold"
          onClick={onClose}
        >
          Fechar
        </Typography>
        <Typography
          fontSize="body2"
          display="block"
          fontWeight="bold"
          onClick={onClick}
        >
          {type === 'push' ? 'Ver Mensagem' : 'Saiba mais'}
        </Typography>
      </S.Footer>
    </S.Container>
  );
};

export const useNotification = () => {
  const toastId = useRef<null | React.ReactText>(null);

  const handleClose = () => {
    toast.dismiss();
  };

  const showNotification = useCallback(
    ({ message, title, avatar, type, onClick }: ShowToastData) => {
      const handleClick = () => {
        if (onClick) onClick();
        handleClose();
      };

      toastId.current = toast(
        ({ closeToast, toastProps }) => (
          <NotificationContainer
            delay={2000}
            {...closeToast}
            {...toastProps}
            title={title}
            message={message}
            avatar={avatar}
            onClose={handleClose}
            onClick={handleClick}
            type={type}
          />
        ),
        {
          type: 'success',
        },
      );
    },
    [],
  );

  return { showNotification };
};
