import styled from 'styled-components';
import { colors } from 'styles/colors';

export const ContactAvatar = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${colors.white};
`;

export const RightCol = styled.div`
  flex: 1;
`;
