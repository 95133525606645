import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';
import ChevronWhite from 'assets/img/chevron-white.svg';
import ChevronBlack from 'assets/img/chevron-black.svg';
import { fontSizes } from 'styles/typography';
import { responsiveSize } from 'styles/sizes';

export const ChevronContainer = styled.div``;

interface IButtonProps {
  backgroundColor?: string;
  justifyContent?: string;
  bottomFixed?: 0 | 1;
  margin?: string;
}
export const ButtonStyled = styled.button<IButtonProps>`
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
  border: none;
  background-color: ${({ backgroundColor }) =>
    colors[backgroundColor || 'primary']};
  padding: 0 ${responsiveSize(4)};
  height: ${responsiveSize(8)};
  color: ${colors.white};
  text-align: left;
  font-family: 'Acid Grotesk';
  font-weight: bold;
  font-size: ${fontSizes.body1};
  line-height: 120%;
  color: ${colors.white};
  width: 100vw;
  z-index: 30;

  ${({ bottomFixed, margin = 'auto 0 -30px -30px' }) =>
    bottomFixed
      ? css`
          position: fixed;
          bottom: 0;
          left: 0;
        `
      : css`
          margin: ${margin};
        `}

  ${ChevronContainer} {
    background: url(${ChevronWhite}) no-repeat;
    width: 10px;
    height: 16px;
  }

  &:disabled {
    background-color: ${colors.gray};
    color: ${colors.text};

    ${ChevronContainer} {
      background: url(${ChevronBlack}) no-repeat;
      width: 16px;
      height: 9px;
      transform: rotate(-90deg);
    }
  }
`;
