import {
  CheckboxContainer,
  Checkbox,
  CheckboxLabel,
  CustomCheckbox,
  Bullet,
} from './styled';

interface ICheckboxProps {
  label?: string;
  id?: string;
  checked?: boolean;
  labelComponent?: any;
  onClick?: () => void;
  onChange?: (e) => void;
}

const CheckboxButton: React.FC<ICheckboxProps> = ({
  label,
  id,
  checked,
  labelComponent,
  onClick,
  onChange,
}) => {
  return (
    <CheckboxContainer>
      <Checkbox
        id={id}
        name="checkbox-list"
        checked={checked}
        onChange={onChange}
      />
      <CheckboxLabel htmlFor={id} onClick={onClick}>
        <CustomCheckbox>
          <Bullet />
        </CustomCheckbox>
        {labelComponent || label}
      </CheckboxLabel>
    </CheckboxContainer>
  );
};

export default CheckboxButton;
