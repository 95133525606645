/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { ProfileEnum } from 'enums/profile';
import { IService, IServicePatch } from 'models/service';
import { IRating } from 'models/rating';
import { ICoordinates } from 'models/location';
import { firebaseService } from './firebase-service';

class ServiceService {
  async getInstance() {
    const userToken = await firebaseService.getUserToken();
    return axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        Authorization: userToken
          ? `Bearer ${userToken}`
          : process.env.REACT_APP_API_TOKEN,
      },
    });
  }

  async deleteService(uid: string) {
    return (await this.getInstance())
      .patch(`services/${uid}/delete`)
      .then((resp) => resp.data);
  }

  async undoDeleteService(uid: string) {
    return (await this.getInstance())
      .patch(`services/${uid}/delete/undo`)
      .then((resp) => resp.data);
  }

  async getFavoriteServices() {
    const services = await (await this.getInstance())
      .get<IService[]>(`services/favorites`)
      .then((res) => res.data);
    return services;
  }

  async getServices(
    offset: number,
    limit: number,
    paymentTypes?: string[],
    subcategories?: number[],
    order = 'distance:ASC',
    geoLocation?: ICoordinates | null,
  ) {
    const paymentTypeQuery = paymentTypes?.length
      ? `&payment_type=${paymentTypes}`
      : '';
    const subcategoryQuery = subcategories?.length
      ? `&sub_category=${subcategories}`
      : '';
    const orderQuery = order ? `&order=${order}` : '';
    const limitQuery = limit ? `&limit=${limit}` : '';
    const offsetQuery = offset ? `&offset=${offset}` : '';

    if (!geoLocation) {
      geoLocation = { lat: -22.907104, lng: -47.06324 };
    }
    const geoLocationQuery = geoLocation
      ? `&lat=${geoLocation.lat}&lng=${geoLocation.lng}`
      : '';
    const services = await (await this.getInstance())
      .get<IService[]>(
        `services/publisheds?${limitQuery}${offsetQuery}${subcategoryQuery}${paymentTypeQuery}${orderQuery}${geoLocationQuery}`,
      )
      .then((res) => res.data);
    return services;
  }

  async getUserServices(clientUid: string) {
    const services = await (await this.getInstance())
      .get<IService[]>(`services/publisheds?client_uid=${clientUid}`)
      .then((res) => res.data);
    return services;
  }

  async getMyServices(status: string[]) {
    const services = await (await this.getInstance())
      .get<IService[]>(`services/me?status=${status}`)
      .then((res) => res.data);
    return services;
  }

  async createService(data: Partial<IServicePatch>) {
    return (await this.getInstance())
      .post('/services', data)
      .then((resp) => resp.data);
  }

  async patchService(uid: string, data: Partial<IServicePatch>) {
    return (await this.getInstance())
      .patch(`/services/${uid}`, data)
      .then((resp) => resp.data);
  }

  async getService(uid: string) {
    return (await this.getInstance())
      .get<IService>(`/services/${uid}`)
      .then((resp) => resp.data);
  }

  async terminateService(uid: string, done: boolean) {
    return (await this.getInstance()).put(`services/${uid}/terminate/${done}`);
  }

  async evaluate(
    rating: IRating,
    otherUid: string,
    role: ProfileEnum,
    serviceUid?: string,
  ) {
    return (await this.getInstance()).post(
      `services/${
        role === ProfileEnum.CLIENT ? 'evaluate-worker' : 'evaluate-client'
      }/${otherUid}`,
      {
        score: rating.stars,
        evaluation_text: rating.feedback?.join(','),
        description: rating.complement,
        service_uid: serviceUid,
      },
    );
  }
}

export const serviceService = new ServiceService();
