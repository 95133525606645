import styled from 'styled-components';

export const ServiceListContainer = styled.div`
  > * {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
