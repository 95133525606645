import { useState } from 'react';
import { Typography } from 'styles/typography';
import Checkbox from 'components/atoms/checkbox';
import InputCellNumber from 'components/atoms/input-cell-number';
import { Button } from 'components/atoms/button';
import { useStepsContext } from 'context/steps-context';
import { ILead } from 'models/lead';
import { leadService } from 'services/lead-service';
import { useAsync } from 'context/async-context';
import { Spacing } from 'styles/spacing';
import { useAlert } from 'context/alert-context';
import { termsService } from 'services/terms-service';
import { ModalTransition } from 'react-simple-hook-modal';
import { responsiveSize } from 'styles/sizes';

const RegisterPhone = () => {
  const [inputValue, setInputValue] = useState('');

  const [termCheckbox, setTermCheckbox] = useState<boolean>(false);
  const [policyCheckbox, setPolicyCheckbox] = useState<boolean>(false);
  const { getData, patchData, nextStep, profile } = useStepsContext();
  const { handledAsync } = useAsync();

  const savedData = getData<ILead>();
  const { showAlert } = useAlert();

  const btnClicked = async () => {
    handledAsync(
      async () => {
        const cellphone = inputValue.replace(/\D/g, '');
        await leadService.createLead(
          savedData.lead_name,
          cellphone,
          profile!,
          termCheckbox,
          policyCheckbox,
        );
        patchData<ILead>({ cellphone });
        nextStep();
      },
      () =>
        'Ocorreu um erro ao validar o celular. Verifique o número digitado e tente novamente.',
    );
  };

  const onTermClicked = (e, type) => {
    e.stopPropagation();
    e.preventDefault();

    const data = termsService.getTerm(type);

    showAlert({
      ...data,
      textMaxHeight: '50vh',
      titleProps: {
        fontSize: 'body1',
      },
      transition: ModalTransition.SCALE,
      buttonText: 'Concordo',
      onButtonClicked: () => {
        if (type === 'term') {
          setTermCheckbox(true);
        } else {
          setPolicyCheckbox(true);
        }
      },
    });
  };

  return (
    <>
      <Typography
        as="h3"
        color="title"
        fontSize="heading3"
        fontWeight="bold"
        display="block"
        letterSpacing="-0.01em"
        margin={`0 0 ${responsiveSize(5)}`}
      >
        Digite o número de seu celular
      </Typography>
      <InputCellNumber
        placeholder="Digite seu celular"
        onChange={setInputValue}
        initialData={savedData?.cellphone}
      />

      <Spacing size={4} />

      <Checkbox
        id="term"
        checked={termCheckbox}
        onChange={(e) => setTermCheckbox(e.target.checked)}
        labelComponent={
          <Typography fontSize="body1" display="inline">
            Li e concordo com os{' '}
            <Typography
              fontSize="body1"
              onClick={(e) => onTermClicked(e, 'term')}
              color="blueLink"
              align="none"
              display="inline"
            >
              Termos e Condições.
            </Typography>
          </Typography>
        }
      />

      <Spacing size={3} />

      <Checkbox
        id="policy"
        checked={policyCheckbox}
        onChange={(e) => setPolicyCheckbox(e.target.checked)}
        labelComponent={
          <Typography fontSize="body1" display="inline">
            Li e concordo com a{' '}
            <Typography
              fontSize="body1"
              onClick={(e) => onTermClicked(e, 'policy')}
              color="blueLink"
              align="none"
              display="inline"
            >
              Política de Privacidade.
            </Typography>
          </Typography>
        }
      />

      <Spacing size={3} />

      <Button
        disabled={!inputValue || !termCheckbox || !policyCheckbox}
        onClick={btnClicked}
        bottomFixed={false}
      >
        Continuar
      </Button>
    </>
  );
};

export default RegisterPhone;
