import BigLogoImg from 'assets/img/big-logo.png';
import CameraMicImg from 'assets/img/camera-mic.svg';
import LogoImg from 'assets/img/logo.png';
import React from 'react';
import { Spacing } from 'styles/spacing';
import { Typography } from 'styles/typography';
import {
  BigLogo,
  DesktopStyled,
  IframeContainer,
  LogoImage,
  TextArea,
} from './style';

const DesktopContainer: React.FC<unknown> = ({ children }) => {
  const isIframeMode = () => {
    const isDesktop = !/iPhone|iPad|iPod|Android|IEMobile/i.test(
      navigator.userAgent,
    );
    const isInIframe = !(window === window.parent);

    if (isDesktop && !isInIframe) {
      return true;
    }
    return false;
  };

  if (isIframeMode()) {
    return (
      <DesktopStyled>
        <TextArea>
          <LogoImage src={LogoImg} />
          <Typography
            fontSize="heading2"
            color="white"
            fontWeight="bold"
            display="block"
          >
            O App pra quem faz
          </Typography>
          <Spacing size={2} />
          <Typography color="white" display="block" fontWeight="bold">
            Você tem um serviço para ser realizado e precisa
            <br />
            de uma mãozinha?
            <br />
            <br />
            Crie sua conta e aproveite ao máximo!
          </Typography>
          {/* <BannerGoogleLink href="https://google.com">
            <img src={GooglePlayImg} alt="Google Play" />
          </BannerGoogleLink> */}
        </TextArea>

        <BigLogo src={BigLogoImg} />

        <IframeContainer>
          <img src={CameraMicImg} alt="" />
          <iframe
            src={window.location.href}
            width="100%"
            height="100%"
            frameBorder="0"
            title="Tô Parado"
            data-hj-allow-iframe
          />
        </IframeContainer>
      </DesktopStyled>
    );
  }

  return <>{children}</>;
};

export default DesktopContainer;
