import styled, { css } from 'styled-components';
import { colors } from './colors';
import { Typography } from './typography';

interface FlexContainerProps {
  width?: string;
  padding?: string;
  direction?: string;
  alignItems?: string;
  justifyContent?: string;
  darkMode?: boolean;
  fullPageHeight?: boolean;
  margin?: string;
  flex1?: boolean;
  gap?: string;
  overflow?: string;
}
export const FlexContainer = styled.div<FlexContainerProps>`
  display: flex;

  ${({
    width = '100%',
    direction = 'column',
    alignItems = 'initial',
    justifyContent = 'initial',
    padding = '0 30px 30px',
    darkMode = false,
    margin = '0',
    flex1 = true,
    gap = undefined,
    overflow = 'hidden auto',
  }) => css`
    flex: ${flex1 ? 1 : undefined};
    gap: ${gap};
    overflow: ${overflow};
    width: ${width};
    flex-direction: ${direction};
    align-items: ${alignItems};
    justify-content: ${justifyContent};
    padding: ${padding};
    margin: ${margin};
    background-color: ${darkMode ? colors.text : 'inherit'};
  `}

  ${({ fullPageHeight }) =>
    fullPageHeight &&
    css`
      height: 100vh;
      height: var(--pageHeight);
    `}

  ${({ darkMode }) =>
    darkMode &&
    css`
      ${Typography} {
        color: ${colors.white};
      }
    `}
`;

export const ScrollView = styled.div`
  overflow: hidden auto;
  flex: 1 1 0%;
  height: -webkit-fill-available;
`;

export const Flex1Wrapper = styled.div`
  flex: 1;
  overflow: hidden;
`;
