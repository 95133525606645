import Image from 'components/atoms/image';
import styled from 'styled-components';

export const ServiceCard = styled.div`
  display: flex;
  padding: 0 30px;
  padding-bottom: 8px;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
`;
export const ServiceImg = styled(Image).attrs((props) => ({
  ...props,
  skeletonProps: {
    width: 50,
    height: 50,
  },
}))`
  margin-right: 15px;
  width: 50px;
  height: 50px;
`;
