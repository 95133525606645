/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useContext, useState } from 'react';

interface ICustomModalOptions {
  component: any;
  onClose?: (data?: any) => void;
  props?: any;
}

interface IModalContext {
  openModal: (options: ICustomModalOptions) => void;
}

export const Context = createContext<IModalContext>({} as IModalContext);
export const useModalPage = () => useContext(Context);

const ModalPageContext: React.FC<unknown> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalOptions, setModalOptions] = useState<ICustomModalOptions>();

  const openModal = (options: ICustomModalOptions) => {
    setModalOptions(options);
    setIsOpen(true);
  };

  const closeCustomModal = () => {
    if (modalOptions?.onClose) {
      modalOptions?.onClose();
    }
    setIsOpen(false);
  };

  return (
    <Context.Provider
      value={{
        openModal,
      }}
    >
      {children}
      {modalOptions?.component && (
        <modalOptions.component
          isModalOpen={isOpen}
          {...modalOptions.props}
          onClose={closeCustomModal}
        />
      )}
    </Context.Provider>
  );
};

export default ModalPageContext;
