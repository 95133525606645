import Header from 'components/molecules/header';
import { useModalPage } from 'context/modal-page-context';
import { useUserContext } from 'context/user-context';
import { format } from 'date-fns';
import { getGenderDesc } from 'enums/gender';
import { formatDateToView } from 'helpers';
import ProfileEdit from 'pages/profile-edit';
import ChangePassword from 'pages/profile-edit/change-password';
import ChangePhone from 'pages/profile-edit/change-phone';
import { EditProfileRouteEnum } from 'pages/profile-edit/types';
import React from 'react';
import { useParams } from 'react-router-dom';
import { FlexContainer } from 'styles/flex-container';
import InfoItem from './info-item';

const PersonalInfo = () => {
  const { openModal } = useModalPage();
  const { user } = useUserContext();

  const handleItemClick = (component, props = {}) => {
    openModal({ component, props });
  };

  const handleSimpleEditClicked = (
    field: EditProfileRouteEnum,
    initialData?: any,
  ) => {
    openModal({
      component: ProfileEdit,
      props: {
        field,
        initialData,
      },
    });
  };

  return (
    <FlexContainer>
      <Header title="Informações pessoais" />

      <InfoItem
        title="Nome"
        value={user?.name}
        onActionClicked={() =>
          handleSimpleEditClicked(EditProfileRouteEnum.NAME, user?.name)
        }
      />
      <InfoItem
        title="Telefone"
        value={user?.cellphone}
        onActionClicked={() => handleItemClick(ChangePhone)}
        helperText="Através do seu número de telefone, enviaremos o código de ativação do seu cadastro e poderemos entrar em contato caso o botão de emergência seja ativado. Fique tranquilo, o seu número não ficará visível para outros usuários da plataforma."
      />
      <InfoItem
        title="Email"
        action={user?.email ? 'edit' : 'add'}
        value={user?.email || 'Adicione pra aumentar a segurança de sua conta'}
        onActionClicked={() =>
          handleSimpleEditClicked(EditProfileRouteEnum.EMAIL, user?.email)
        }
      />
      {/* <InfoItem
        title="Data de nascimento"
        action={user?.birthday ? 'edit' : 'add'}
        value={formatDateToView(user?.birthday)}
        onActionClicked={() =>
          handleSimpleEditClicked(
            EditProfileRouteEnum.BIRTH_DATE,
            formatDateToView(user?.birthday),
          )
        }
      /> */}
      <InfoItem
        title="Gênero"
        action={user?.gender ? 'edit' : 'add'}
        value={getGenderDesc(user?.gender)}
        onActionClicked={() =>
          handleSimpleEditClicked(EditProfileRouteEnum.GENDER, user?.gender)
        }
      />
      <InfoItem
        title="CPF ou CNPJ"
        action={user?.document_number ? 'edit' : 'add'}
        value={user?.document_number}
        onActionClicked={() =>
          handleSimpleEditClicked(
            EditProfileRouteEnum.DOCUMENT_NUMBER,
            user?.document_number,
          )
        }
      />
      <InfoItem
        title="Endereço"
        action={user?.location ? 'edit' : 'add'}
        value={
          user?.location
            ? `${user.location.zipcode}<br/>${user.location.fullAddress}`
            : undefined
        }
        onActionClicked={() =>
          handleSimpleEditClicked(
            EditProfileRouteEnum.ADDRESS,
            user?.location?.zipcode,
          )
        }
      />
      <InfoItem
        title="Senha"
        action="change-password"
        value="Altere sua senha de acesso e aumente sua segurança"
        onActionClicked={() => handleItemClick(ChangePassword)}
      />
    </FlexContainer>
  );
};

export default PersonalInfo;
