import { Typography } from 'styles/typography';
import { Icon, Container, EachRow } from './styled';

interface BenefitsListProps {
  data: {
    text: string;
    image: string;
  }[];
}

const BenefitsList = ({ data }: BenefitsListProps) => {
  return (
    <Container>
      {data.map((eachItem, index) => (
        <EachRow key={String(index)}>
          <Icon src={eachItem.image} />
          <Typography as="p" color="white" fontSize="body2">
            {eachItem.text}
          </Typography>
        </EachRow>
      ))}
    </Container>
  );
};

export default BenefitsList;
