/* eslint-disable @typescript-eslint/no-explicit-any */
import Header from 'components/molecules/header';
import { useAsync } from 'context/async-context';
import { IFaq } from 'models/faq';
import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';
import { faqService } from 'services/faq-service';
import { FlexContainer } from 'styles/flex-container';
import { IconThumbsUp } from 'styles/icons';
import { Spacing } from 'styles/spacing';
import { LinkTypography, Typography } from 'styles/typography';
import { ThumbsContainer, ThumbsSquare, TextContainer } from './styled';

const SupportDetail = () => {
  const [data, setData] = useState<IFaq>();
  const [likeOrDislike, setLikeOrDislike] = useState<string>();
  const { id } = useParams<any>();
  const { handledAsync } = useAsync();

  useEffect(() => {
    const interactions = getPreviousInteractions();
    setLikeOrDislike(interactions[id]);

    handledAsync(
      async () => {
        if (id) {
          setData(await faqService.getById(id));
        }
      },
      undefined,
      { showLoading: false },
    );
  }, []);

  const getPreviousInteractions = () => {
    try {
      return JSON.parse(localStorage.getItem('faqInteractions') || '{}');
    } catch (err) {
      return {};
    }
  };

  const likeOrDislikeQuestion = async (key: 'like' | 'dislike') => {
    const interactions = getPreviousInteractions();
    const isSame = interactions[id] === key;
    localStorage.setItem(
      'faqInteractions',
      JSON.stringify({
        ...interactions,
        [id]: isSame ? undefined : key,
      }),
    );
    setLikeOrDislike(isSame ? undefined : key);

    if (!isSame) {
      faqService.saveLikeOrDislike(id, key);
    }
  };

  return (
    <FlexContainer>
      <Header title="Suporte" />
      <Typography
        display="block"
        fontSize="heading3"
        fontWeight="bold"
        letterSpacing="-0.01em"
      >
        {!data ? <Skeleton count={2} /> : data.question}
      </Typography>
      <Spacing size={3} />
      {!data ? (
        <Skeleton count={10} />
      ) : (
        <TextContainer>
          <Typography
            as="div"
            display="block"
            dangerouslySetInnerHTML={{
              __html: data.answer,
            }}
          />
        </TextContainer>
      )}

      {data && (
        <>
          <Spacing size={5} />
          <Typography>Essa dica foi útil?</Typography>

          <ThumbsContainer>
            <ThumbsSquare
              onClick={() => likeOrDislikeQuestion('like')}
              className={likeOrDislike === 'like' ? 'active' : ''}
            >
              <IconThumbsUp />
            </ThumbsSquare>
            <ThumbsSquare
              onClick={() => likeOrDislikeQuestion('dislike')}
              className={`rotated ${
                likeOrDislike === 'dislike' ? 'active' : ''
              }`}
            >
              <IconThumbsUp />
            </ThumbsSquare>
          </ThumbsContainer>

          <Typography color="title">
            Se você ainda tiver dúvidas, fale com a gente pelo e-mail:
          </Typography>
          <LinkTypography
            as="a"
            fontWeight="bold"
            href="mailto:suporte@toparado.com.br"
            target="_blank"
          >
            suporte@toparado.com.br
          </LinkTypography>
        </>
      )}
    </FlexContainer>
  );
};

export default SupportDetail;
