import axios from 'axios';
import { useAsync } from 'context/async-context';
import { ILocation } from 'models/location';
import React, { useState, useEffect } from 'react';

import { mask as masker } from 'remask';
import { masks } from 'styles/masks';
import InputText from '../input-text';
import { AddressText } from './styled';

interface Props {
  initialData?: string;
  onChange: (value?: ILocation) => void;
  placeholder?: string;
}

const InputZipCode: React.FC<Props> = ({ onChange, ...rest }) => {
  const [value, setValue] = useState('');
  const [location, setLocation] = useState<ILocation>();
  const { handledAsync } = useAsync();

  useEffect(() => {
    onChange(location);
  }, [location]);

  const handleChange = async (inputValue = '') => {
    const maskedValue = masker(inputValue, `${masks.cep}`);
    const isValid = maskedValue.length === 9;
    setValue(maskedValue);

    if (isValid) {
      handledAsync(
        async () => {
          const resp = await axios.get(
            `${process.env.REACT_APP_ZIPCODE_SEARCH}/${inputValue.replace(
              /\D/g,
              '',
            )}/json`,
          );
          if (!resp.data.logradouro) {
            throw new Error('not found');
          }
          setLocation({
            zipcode: resp.data.cep,
            street: resp.data.logradouro,
            district: resp.data.bairro,
            city: resp.data.localidade,
            state: resp.data.uf,
            fullAddress: `${resp.data.logradouro}, ${resp.data.bairro} - ${resp.data.localidade}/${resp.data.uf}`,
          });
        },
        () => {
          setLocation(undefined);
          return 'Não foi possível encontrar o CEP informado.';
        },
      );
    } else {
      setLocation(undefined);
    }
  };

  return (
    <>
      <InputText type="tel" {...rest} onChange={handleChange} value={value} />

      {location?.fullAddress && (
        <AddressText
          color="text"
          fontSize="body2"
          fontWeight="normal"
          lineHeight="24px"
          margin="14px 0 0"
          show={value.length === 9}
        >
          {location?.fullAddress}
        </AddressText>
      )}
    </>
  );
};

export default InputZipCode;
