import { Button } from 'components/atoms/button';
import InputCellNumber from 'components/atoms/input-cell-number';
import { useAlert } from 'context/alert-context';
import { useAsync } from 'context/async-context';
import { useModalStepsContext } from 'context/modal-steps-context';
import { useUserContext } from 'context/user-context';
import { IUser } from 'models/user';
import { useState } from 'react';
import { meService } from 'services/me-service';
import { Typography } from 'styles/typography';

const ContactPhone = () => {
  const [inputValue, setInputValue] = useState('');
  const { getData, nextStep } = useModalStepsContext();
  const { showToast, showErrorAlert } = useAlert();
  const { handledAsync } = useAsync();
  const { patchUser, user } = useUserContext();

  const savedData = getData<IUser>();

  const btnClicked = async () => {
    const userPhone = user?.cellphone.replace(/\D/g, '').slice(2);
    if (userPhone === inputValue.replace(/\D/g, '')) {
      showErrorAlert({
        message:
          'O número do contato de emergência não pode ser o mesmo que o do seu cadastro.',
      });
    } else {
      handledAsync(async () => {
        const resp = await meService.patchBasicInfo({
          emergency_contact: savedData.emergency_contact,
          emergency_cellphone: inputValue,
        });
        patchUser(resp);
        showToast({
          text: 'Contato salvo',
        });
        nextStep();
      });
    }
  };

  const initialPhone = savedData?.emergency_cellphone
    ?.replace(/\D/g, '')
    ?.slice(2);

  return (
    <>
      <Typography
        as="h3"
        color="title"
        fontSize="heading3"
        fontWeight="bold"
        lineHeight="48px"
        display="block"
        letterSpacing="-0.01em"
        margin="0 0 51px"
      >
        Qual o celular do contato?
      </Typography>
      <InputCellNumber
        placeholder="Número"
        onChange={setInputValue}
        initialData={initialPhone}
      />
      <Button disabled={!inputValue} onClick={btnClicked} disableChevron>
        Finalizar cadastro
      </Button>
    </>
  );
};

export default ContactPhone;
