import styled, { css } from 'styled-components';

const sizes = {
  sm: {
    avatar: 35,
    bullet: 12,
  },
  lg: {
    avatar: 55,
    bullet: 18,
  },
};
interface AvatarStyledProps {
  size?: 'sm' | 'lg';
}

export const StatusBullet = styled.span<AvatarStyledProps>`
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 50%;

  &.online {
    background: green;
  }

  &.offline {
    background: black;
  }
`;

export const AvatarContainer = styled.div<AvatarStyledProps>`
  position: relative;

  ${({ size = 'sm' }) => css`
    width: ${sizes[size].avatar}px;
    height: ${sizes[size].avatar}px;

    ${StatusBullet} {
      width: ${sizes[size].bullet}px;
      height: ${sizes[size].bullet}px;
    }
  `}
`;
