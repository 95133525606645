import Image from 'components/atoms/image';
import { AvatarContainer, StatusBullet } from './styled';

interface Props {
  image?: string;
  online?: boolean;
  size?: 'sm' | 'lg';
  hideStatus?: boolean;
  onClick?: () => void;
}
const Avatar: React.FC<Props> = ({
  image,
  online,
  size,
  hideStatus,
  onClick = () => null,
}) => {
  return (
    <AvatarContainer onClick={onClick} size={size}>
      <Image src={image} circle width="100%" height="100%" />
      {!hideStatus && (
        <StatusBullet className={online ? 'online' : 'offline'} />
      )}
    </AvatarContainer>
  );
};

export default Avatar;
