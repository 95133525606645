import styled from 'styled-components';
import { colors } from 'styles/colors';

export const TextareaStyled = styled.textarea`
  font-family: 'Acid Grotesk';
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: ${colors.text};
  border: none;
  width: 100%;
  height: 192px;
  resize: none;
  background: ${colors.gray};
  padding: 16px 34px 16px 16px;

  ::-webkit-input-placeholder {
    color: ${colors.subText};
  }
  ::-moz-placeholder {
    color: ${colors.subText};
  }
  :-ms-input-placeholder {
    color: ${colors.subText};
  }
  :-moz-placeholder {
    color: ${colors.subText};
  }
`;
