import { SkillItemContainer } from 'components/atoms/skill-item/styled';
import styled from 'styled-components';
import { responsiveSize } from 'styles/sizes';
import { Typography } from 'styles/typography';

export const WorkerDetailContainer = styled.div`
  ${Typography} {
    svg {
      margin-right: 8px;
    }
  }
`;

export const SkillsStyled = styled.div`
  margin-bottom: 38px;
  ${SkillItemContainer} {
    display: inline-block;
    margin: 0 8px 8px 0;
  }
`;

export const VideoContainer = styled.div`
  margin: 0 -30px;
`;

export const AboutServiceItem = styled.div`
  min-width: 80vw;
`;

export const IconTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${responsiveSize(1)};
`;
