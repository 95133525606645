/* eslint-disable @typescript-eslint/no-explicit-any */
import TokenPage from 'components/templates/token-page';
import { useAlert } from 'context/alert-context';
import { useAsync } from 'context/async-context';
import { useModalStepsContext } from 'context/modal-steps-context';
import { useUserContext } from 'context/user-context';
import { meService } from 'services/me-service';
import { IChangePhonedModel } from './types';

const Token = () => {
  const { nextStep, getData } = useModalStepsContext();
  const { handledAsync } = useAsync();
  const { showToast } = useAlert();
  const { loadUserData } = useUserContext();

  const { cellphone } = getData<IChangePhonedModel>();

  const resendClicked = async () => {
    await meService.requestPhoneChange(cellphone);
  };

  const handleBtnClicked = (code: string) => {
    handledAsync(async () => {
      await meService.validateCodeAndChangePhone(cellphone, code);
      await loadUserData();
      showToast({
        text: 'Telefone alterado',
      });
      nextStep();
    });
  };

  return (
    <TokenPage
      buttonText="Finalizar"
      disableButtonChevron
      onConfirmClicked={handleBtnClicked}
      onResendClicked={resendClicked}
    />
  );
};

export default Token;
