import styled from 'styled-components';

export const PaddingContainer = styled.div`
  padding: 0 30px;
`;

export const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;

  .react-transform-wrapper {
    flex: 2;
  }

  img {
    width: 100%;
  }

  ${PaddingContainer} {
    flex: 1;
  }
`;
