import defaultProfilePhoto from 'assets/img/default-profile-photo.png';
import Image from 'components/atoms/image';
import { IComment } from 'models/comment';
import { Typography } from 'styles/typography';
import { CommentItem, FlexItem, RightCol, ServiceInfoStyled } from './styled';

interface ICommentProps {
  item: IComment;
  isWorker?: boolean;
}

const Comment: React.FC<ICommentProps> = ({ item, isWorker }) => {
  return (
    <CommentItem>
      {isWorker && (
        <ServiceInfoStyled>
          <Typography
            as="h4"
            fontSize="body1"
            display="block"
            margin="0 0 2px 0"
          >
            {item.service}
          </Typography>
          <Typography
            letterSpacing="0.02em"
            fontSize="menu"
            lineHeight="12px"
            fontWeight="bold"
          >
            {item.rated}
          </Typography>
        </ServiceInfoStyled>
      )}

      <FlexItem>
        <Image
          src={item.image || defaultProfilePhoto}
          circle
          width={40}
          height={40}
        />
        <RightCol>
          <Typography fontWeight="bold" display="block">
            {item.name}
          </Typography>
          {isWorker ? (
            <>
              <Typography display="block">Prestador</Typography>
            </>
          ) : (
            <>
              <Typography fontWeight="bold" display="block">
                {item.rated}
              </Typography>
              <Typography display="block">{item.service}</Typography>
            </>
          )}
        </RightCol>
      </FlexItem>
      {isWorker ? (
        <Typography align="justify" as="p" display="block">
          {item.text}
        </Typography>
      ) : (
        <Typography align="justify" as="p" display="block">
          “{item.text}”
        </Typography>
      )}
    </CommentItem>
  );
};

export default Comment;
