/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-duplicates */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-console */
import { format } from 'date-fns';
import { LoginProviderEnum } from 'enums/login-provider';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/messaging';
import { v4 as uuid } from 'uuid';

export interface NotificationPayload {
  data: {
    avatar: string;
    type: 'chat';
    route?: string;
  };
  notification: {
    title: string;
    body: string;
  };
}

class FirebaseService {
  init() {
    const {
      REACT_APP_FIREBASE_API_KEY,
      REACT_APP_FIREBASE_PROJECT_ID,
      REACT_APP_FIREBASE_SENDER_ID,
      REACT_APP_FIREBASE_APP_ID,
      REACT_APP_FIREBASE_MEASUREMENT_ID,
    } = process.env;

    // Firebase já inicializado
    if (firebase.apps.length) {
      return;
    }

    firebase.initializeApp({
      apiKey: REACT_APP_FIREBASE_API_KEY,
      projectId: REACT_APP_FIREBASE_PROJECT_ID,
      messagingSenderId: REACT_APP_FIREBASE_SENDER_ID,
      appId: REACT_APP_FIREBASE_APP_ID,
      measurementId: REACT_APP_FIREBASE_MEASUREMENT_ID,
      authDomain: `${REACT_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`,
      storageBucket: `${REACT_APP_FIREBASE_PROJECT_ID}.appspot.com`,
    });
  }

  isMessagingSupported = () => {
    return firebase.messaging.isSupported();
  };

  onMessageListener = (callback: (payload: NotificationPayload) => void) => {
    const messaging = firebase.messaging();
    messaging.onMessage(callback);
  };

  getToken = async () => {
    const messaging = firebase.messaging();
    const token = await messaging.getToken().catch(() => null);
    if (token) localStorage.setItem('@to_parado/push_token', token);
    return token;
  };

  private async getFirebaseUser() {
    await this.init();
    return new Promise<firebase.User | null>((resolve) => {
      firebase.auth().onAuthStateChanged(async (firebaseUser) => {
        resolve(firebaseUser);
      });
    });
  }

  async createUser(
    email: string,
    password: string,
  ): Promise<firebase.User | null | undefined> {
    const { currentUser } = firebase.auth();
    const { providerId } = currentUser?.providerData[0] || {};
    let userCredential: firebase.auth.UserCredential | undefined;
    try {
      if (
        providerId &&
        [LoginProviderEnum.FACEBOOK, LoginProviderEnum.GOOGLE].includes(
          providerId as LoginProviderEnum,
        )
      ) {
        const credential = firebase.auth.EmailAuthProvider.credential(
          email,
          password,
        );
        userCredential = await currentUser?.linkWithCredential(credential);
      } else {
        userCredential = await firebase
          .auth()
          .createUserWithEmailAndPassword(email, password);
      }
    } catch (err: any) {
      console.error('FIREBASE', err);
      switch (err?.code) {
        case 'auth/email-already-in-use':
        case 'auth/provider-already-linked':
          throw new Error('Já existe um cadastro com este número de telefone.');
        case 'auth/requires-recent-login': {
          await this.socialLogin(providerId as LoginProviderEnum);
          return this.createUser(email, password);
        }
        default:
          throw err;
      }
    }
    return userCredential?.user;
  }

  async login(cellphone, password) {
    const email = `${cellphone}@${process.env.REACT_APP_FAKE_EMAIL_DOMAIN}`;
    const resp = await firebase
      .auth()
      .signInWithEmailAndPassword(email, password);
    return resp.user;
  }

  async socialLogin(type: LoginProviderEnum) {
    const provider =
      type === LoginProviderEnum.GOOGLE
        ? new firebase.auth.GoogleAuthProvider()
        : new firebase.auth.FacebookAuthProvider();
    const resp = await firebase.auth().signInWithPopup(provider);
    if (!resp) {
      throw new Error('Ocorreu um erro ao carregar os dados do usuário.');
    }
    return resp;
  }

  async getUserToken(forceRefresh = false) {
    return (await this.getFirebaseUser())?.getIdToken(forceRefresh);
  }

  getUserProvider() {
    return firebase
      .auth()
      .currentUser?.providerData.map((eachProvider) => eachProvider?.providerId)
      .filter((eachProvider) => !!eachProvider) as string[];
  }

  async deleteCurrentUser() {
    return firebase.auth().currentUser?.delete();
  }

  async logout() {
    return firebase.auth().signOut();
  }

  async changePassword(oldPassword: string, newPassword: string) {
    try {
      const firebaseUser = await this.getFirebaseUser();
      if (firebaseUser) {
        await firebaseUser.reauthenticateWithCredential(
          firebase.auth.EmailAuthProvider.credential(
            `${firebaseUser.email}`,
            oldPassword,
          ),
        );
      }
      return firebaseUser?.updatePassword(newPassword);
    } catch (err: any) {
      console.error('FIREBASE', err);
      switch (err?.code) {
        case 'auth/wrong-password':
          throw new Error(
            'A senha digitada está incorreta. Verifique e tente novamente.',
          );
        default:
          throw err;
      }
    }
  }

  async uploadFile(file: File) {
    const storageRef = firebase.storage().ref();
    const extension = file.name.split('.').pop();
    const filename = `${uuid()}.${extension}`;
    const directory = format(new Date(), 'yyyy/MM');
    const fileRef = storageRef.child(`${directory}/${filename}`);
    return fileRef.put(file).then((resp) => resp.ref.getDownloadURL());
  }
}

export const firebaseService = new FirebaseService();
