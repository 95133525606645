import { useAsync } from 'context/async-context';
import { StepsContextProvider } from 'context/steps-context';
import { IServicePatch } from 'models/service';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { serviceService } from 'services/service-service';
import { HowToPay } from './how-to-pay';
import { ReviewCreatedService } from './review-created-service';
import { ServiceDescription } from './service-description';
import { ServicePhotos } from './service-photos';
import { WhichService } from './which-service';
import { ZipCode } from './zip-code';

const EditService: React.FC<unknown> = () => {
  const [previousData, setPreviousData] = useState<IServicePatch>();

  const { handledAsync } = useAsync();
  const history = useHistory();
  const { state } = useLocation<any>();
  const { uid } = state || {};

  const baseUrl = '/editar-servico';
  const allPages = [
    { url: `${baseUrl}/qual-servico`, component: WhichService },
    { url: `${baseUrl}/cep`, component: ZipCode },
    { url: `${baseUrl}/como-pagar`, component: HowToPay },
    { url: `${baseUrl}/descricao-do-servico`, component: ServiceDescription },
    { url: `${baseUrl}/fotos-do-servico`, component: ServicePhotos },
    { url: `${baseUrl}/revisar-servico`, component: ReviewCreatedService },
  ];

  useEffect(() => {
    if (uid) {
      handledAsync(
        async () => {
          const resp = await serviceService.getService(uid);
          setPreviousData({
            ...resp,
            category: resp.category?.id,
            sub_category: resp.sub_category?.id,
          });
        },
        (err) => {
          history.goBack();
          return 'Ocorreu um erro ao carregar os dados. Tente novamente.';
        },
      );
    }
  }, [uid]);

  const patchService = async (
    data: Partial<IServicePatch>,
    oldData?: IServicePatch,
  ) => {
    if (oldData?.uid) {
      await serviceService.patchService(oldData?.uid, data);
    }
  };

  if (!previousData) {
    return null;
  }

  return (
    <StepsContextProvider
      routes={allPages}
      headerTitle="Editar serviço"
      successRoute="/t/perfil"
      handlePatchData={patchService}
      initialData={previousData}
    />
  );
};

export default EditService;
