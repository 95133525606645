/* eslint-disable @typescript-eslint/no-explicit-any */
import { IIdLabelOption } from 'models/id-label-option';
import React, { useEffect } from 'react';
import { Typography } from 'styles/typography';

import { SelectStyled } from './styled';

interface ISelectProps {
  label: string;
  emptyText: string;
  optionList: IIdLabelOption[];
  initialData?: any;
  onChange: (value) => void;
}

const Select: React.FC<ISelectProps> = ({
  label,
  optionList,
  emptyText,
  initialData,
  onChange,
}) => {
  useEffect(() => {
    if (initialData) {
      changeSelect(initialData);
    }
  }, [initialData]);

  const changeSelect = (value) => {
    const isValid = value !== emptyText;
    onChange(isValid ? value : '');
  };

  const options = optionList.map((item) => ({
    value: `${item.id}`,
    label: item.label,
  }));

  return (
    <>
      <Typography display="block">{label}</Typography>
      <SelectStyled
        options={options}
        placeholder={emptyText}
        value={`${initialData}`}
        onChange={(e) => changeSelect(e?.value)}
        arrowClosed={<span className="Dropdown-arrow closed" />}
        arrowOpen={<span className="Dropdown-arrow open" />}
      />
    </>
  );
};

export default Select;
