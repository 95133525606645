import Image from 'components/atoms/image';
import { useModalPage } from 'context/modal-page-context';
import ImagePreview from 'modals/image-preview';
import { useRef } from 'react';
import Slider from 'react-slick';
import { Spacing } from 'styles/spacing';
import { Typography } from 'styles/typography';
import { SliderContainer, SliderItem, TextContent } from './styled';

export interface ISliderItemModel {
  image?: string;
  title?: string;
  text?: string;
  description?: string;
}
interface ISimpleSliderProps {
  items: ISliderItemModel[];
  disableLightbox?: boolean;
  infinite?: boolean;
  height?: number;
  onItemClicked?: () => void;
}

const SimpleSlider: React.FC<ISimpleSliderProps> = ({
  items,
  disableLightbox = false,
  onItemClicked = () => null,
  infinite = true,
  height,
}) => {
  const { openModal } = useModalPage();
  const mousePosition = useRef<any>();

  const settings = {
    infinite,
    arrows: false,
    dots: true,
    autoPlay: true,
    autoplaySpeed: 100,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handleOnMouseDown = (e) => {
    e.preventDefault();
    mousePosition.current = { clientX: e.clientX, clientY: e.clientY };
  };

  const onImgClicked = (e, item: ISliderItemModel) => {
    e.stopPropagation();
    if (
      e.clientX !== mousePosition.current?.clientX ||
      e.clientY !== mousePosition.current?.clientY
    ) {
      e.preventDefault();
      return;
    }

    if (!disableLightbox) {
      openModal({
        component: ImagePreview,
        props: {
          image: item.image,
          caption: item.description,
        },
      });
    } else if (onItemClicked) {
      onItemClicked();
    }
  };

  return (
    <SliderContainer>
      <Slider {...settings}>
        {items.map((item) => (
          <SliderItem
            key={item.title || item.image}
            onClick={(e) => onImgClicked(e, item)}
            onMouseDown={handleOnMouseDown}
          >
            <Image
              src={item.image}
              alt={item.description || item.title}
              height={height}
            />
            {item.title && (
              <>
                <Spacing size={3} />
                <Typography
                  as="h3"
                  fontSize="heading3"
                  fontWeight="bold"
                  lineHeight="150%"
                  display="block"
                >
                  {item.title}
                </Typography>
              </>
            )}
            {item.text && (
              <TextContent>
                <Spacing size={2} />
                <Typography
                  as="span"
                  fontSize="body2"
                  fontWeight="normal"
                  lineHeight="150%"
                  display="block"
                >
                  {item.text}
                </Typography>
              </TextContent>
            )}
          </SliderItem>
        ))}
      </Slider>
    </SliderContainer>
  );
};

export default SimpleSlider;
