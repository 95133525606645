import styled from 'styled-components';

export const CommentItem = styled.div``;

export const ServiceInfoStyled = styled.div`
  margin-bottom: 18px;
`;

export const FlexItem = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

export const RightCol = styled.div`
  margin-left: 8px;
`;
