import styled from 'styled-components';
import { colors } from 'styles/colors';

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
  background-color: ${colors.white};
  border-radius: 5px;
  overflow: hidden;
`;

export const BoxContent = styled.div`
  flex: 1;
  padding: 30px;
`;

export const BoxButton = styled.button`
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.primary};
  font-size: 20px;
  color: ${colors.white};
  font-weight: bold;
`;
