import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { FlexContainer } from 'styles/flex-container';
import { Spacing } from 'styles/spacing';

interface Props {
  listSize?: number;
}
const ServiceListSkeleton: React.FC<Props> = ({ listSize = 2 }) => {
  return (
    <>
      {Array(listSize)
        .fill(1)
        .map((item, index) => (
          <div key={index}>
            <Skeleton width="100%" height={240} />
            <Spacing size={1} />
            <Skeleton width="85%" height={20} />
            <FlexContainer padding="0">
              <Skeleton width="50%" height={20} />
              <Spacing size={1} />
              <Skeleton width="50%" height={20} />
            </FlexContainer>
            <Spacing size={5} />
          </div>
        ))}
    </>
  );
};

export default ServiceListSkeleton;
