import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from 'styles/colors';

export const ItemContainer = styled.div``;

export const LinkStyled = styled(Link)`
  text-decoration: none;
`;

export const StrongStyled = styled.strong`
  color: ${colors.primary};
`;
