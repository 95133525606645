import { Button } from 'components/atoms/button';
import { useModalStepsContext } from 'context/modal-steps-context';
import React from 'react';
import { Spacing } from 'styles/spacing';
import { Typography } from 'styles/typography';

const StarsSuccess = () => {
  const { nextStep } = useModalStepsContext();
  return (
    <>
      <Typography
        fontSize="heading3"
        fontWeight="bold"
        lineHeight="150%"
        letterSpacing="-0.01em"
      >
        Obrigado!
        <br />
        Agora, conte um pouco mais sobre como foi este serviço.
      </Typography>
      <Spacing size={5} />
      <Typography fontSize="body2">
        Assim você ajuda a melhorar o App.
      </Typography>
      <Button onClick={nextStep}>Dar minha opinião</Button>
    </>
  );
};

export default StarsSuccess;
