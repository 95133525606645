import ServiceList from 'components/organisms/service-list';
import { useUserContext } from 'context/user-context';
import { IServiceStatusEnum } from 'enums/service-status';
import { IService } from 'models/service';
import { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { serviceService } from 'services/service-service';
import { Spacing } from 'styles/spacing';
import { LinkTypography } from 'styles/typography';
import { SectionTitle } from './styled';

const MyServices = () => {
  const [serviceList, setServiceList] = useState<IService[]>([]);
  const [finishedServices, setFinishedServices] = useState<IService[]>([]);
  const [loading, setLoading] = useState(true);
  const { client } = useUserContext();

  useEffect(() => {
    if (client?.client_uid) {
      loadServices();
    }
  }, [client?.client_uid]);

  const loadServices = () => {
    serviceService
      .getMyServices([IServiceStatusEnum.PUBLISHED, IServiceStatusEnum.DONE])
      .then((resp) => {
        setServiceList(
          resp.filter((s) => s.status === IServiceStatusEnum.PUBLISHED),
        );
        setFinishedServices(
          resp.filter((s) => s.status === IServiceStatusEnum.DONE),
        );
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <SectionTitle fontSize="body1" fontWeight="normal" lineHeight="120%">
        Meus serviços
      </SectionTitle>
      <Link to="/criar-servico">
        <LinkTypography as="span" color="primary">
          Criar um novo serviço
        </LinkTypography>
      </Link>
      <Spacing size={4} />
      <ServiceList
        listItems={serviceList}
        loading={loading}
        canEdit
        loadMore={loadServices}
      />
      {finishedServices.length > 0 && (
        <>
          <SectionTitle fontSize="body1" fontWeight="normal" lineHeight="120%">
            Serviços finalizados
          </SectionTitle>
          <ServiceList listItems={finishedServices} loading={loading} />
        </>
      )}
    </>
  );
};

export default MyServices;
