import React, { useRef, useEffect } from 'react';
import { colors } from 'styles/colors';
import ActionSheet, { ActionSheetRef } from 'actionsheet-react';
import { Typography } from 'styles/typography';

export interface IActionSheetItem {
  label: string;
  isTitle?: boolean;
  onClick?: () => void;
  color?: keyof typeof colors;
}

interface Props {
  items: IActionSheetItem[];
  contrast?: boolean;
  open: boolean;
  onClose?: () => void;
}
const CustomActionSheet: React.FC<Props> = ({ items, open, onClose }) => {
  const ref = useRef<ActionSheetRef>();

  useEffect(() => {
    if (open && ref.current) ref.current.open();
  }, [open]);

  const handleClose = () => {
    if (ref.current) ref.current.close();
    if (onClose) onClose();
  };

  return (
    <ActionSheet
      sheetStyle={{ borderRadius: '0', padding: '20px 0' }}
      ref={ref}
      onClose={onClose}
    >
      {items.map((item, i) => (
        <Typography
          key={i}
          fontSize={item.isTitle ? 'heading3' : 'body1'}
          cursor={item.isTitle ? '' : 'pointer'}
          fontWeight="bold"
          display="block"
          padding="20px 40px"
          color={item.color || 'text'}
          onClick={() => {
            if (item.onClick) {
              item.onClick();
            }
            handleClose();
          }}
        >
          {item.label}
        </Typography>
      ))}
    </ActionSheet>
  );
};

export default CustomActionSheet;
