import { ModalStepsContextProvider } from 'context/modal-steps-context';
import { IModalSteps } from 'context/modal-steps-context/types';
import { IModalDefaultProps } from 'modals/types';
import React from 'react';
import { Modal, ModalTransition } from 'react-simple-hook-modal';
import PhoneNumber from './phone-number';
import NewPassword from './new-password';
import Token from './token';

const ForgotPassword: React.FC<IModalDefaultProps> = ({
  isModalOpen,
  onClose,
}) => {
  const steps: IModalSteps[] = [
    { component: <PhoneNumber /> },
    { component: <Token /> },
    { component: <NewPassword /> },
  ];

  return (
    <Modal
      id="forgot-password-modal"
      modalClassName="no-padding"
      isOpen={isModalOpen}
      transition={ModalTransition.SCALE}
    >
      <ModalStepsContextProvider
        headerTitle="Esqueci minha senha"
        closeModal={onClose}
        steps={steps}
      />
    </Modal>
  );
};

export default ForgotPassword;
