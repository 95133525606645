/* eslint-disable no-param-reassign */
import axios from 'axios';
import Talk from 'talkjs';
import { firebaseService } from './firebase-service';

class ChatService {
  private talkSession: Talk.Session | undefined;

  async getInstance() {
    const userToken = await firebaseService.getUserToken();

    return axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}/chat`,

      headers: {
        Authorization: userToken
          ? `Bearer ${userToken}`
          : process.env.REACT_APP_API_TOKEN,
      },
    });
  }

  async getChatData(userUid) {
    return (await this.getInstance()).get(`${userUid}`).then((res) => res.data);
  }

  async getTalkjsInstance() {
    return axios.create({
      baseURL: 'https://api.talkjs.com',
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_TALKJS_SECRETKEY}`,
      },
    });
  }

  async createUser({
    id,
    name,
    email,
    photoUrl,
    role = 'default',
  }: {
    id: string;
    name: string;
    email?: string;
    photoUrl?: string;
    role?: string;
  }) {
    return Talk.ready.then(
      () =>
        new Talk.User({
          id,
          name,
          email,
          photoUrl,
          role,
        }),
    );
  }

  async getTalkSession(me: Talk.User) {
    return Talk.ready.then(
      () =>
        new Talk.Session({
          appId: process.env.REACT_APP_TALKJS_APPID || '',
          me,
        }),
    );
  }

  setTalkSession(session: Talk.Session | undefined) {
    this.talkSession = session;
  }

  async getOrCreateConversation(other: Talk.User) {
    return Talk.ready.then(async () => {
      if (this.talkSession) {
        const conversation = this.talkSession.getOrCreateConversation(
          Talk.oneOnOneId(this.talkSession.me.id, other.id),
        );
        conversation.setParticipant(this.talkSession.me);
        conversation.setParticipant(other);
        return conversation;
      }
      return undefined;
    });
  }

  async createChatbox(conversation) {
    return Talk.ready.then(() =>
      this.talkSession?.createChatbox(conversation, {
        showChatHeader: false,
      }),
    );
  }

  async createInbox() {
    return Talk.ready.then(() =>
      this.talkSession?.createInbox({
        showFeedHeader: false,
        showChatHeader: false,
        selected: null,
      }),
    );
  }

  async favoriteConversation(conversation, value: boolean) {
    return (await this.getInstance()).put(
      `${conversation.id}/favorite/${value}`,
      value,
    );
  }
}

export const chatService = new ChatService();
