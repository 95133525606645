import { ICategory } from 'models/skill';
import React from 'react';
import { Typography } from 'styles/typography';
import { colors } from 'styles/colors';
import { IconContainer, Indicator, ItemStyled } from './styled';
import { iconsMap } from './data';

interface Props {
  category: ICategory;
  onClick: (item: ICategory) => void;
  indicator?: number;
  isActive?: boolean;
}

const CategoryItem: React.FC<Props> = ({
  category,
  onClick,
  indicator = 0,
  isActive = false,
}) => {
  const Icon = iconsMap[category.icon];

  return (
    <ItemStyled
      className={isActive ? 'active' : ''}
      onClick={() => onClick(category)}
    >
      {Icon && (
        <IconContainer>
          <Icon color={colors.blue} width="35px" height="35px" />
          {!isActive && indicator > 0 && <Indicator>{indicator}</Indicator>}
        </IconContainer>
      )}
      <Typography align="center">{category.name}</Typography>
    </ItemStyled>
  );
};

export default CategoryItem;
