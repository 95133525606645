import styled from 'styled-components';
import { colors } from 'styles/colors';
import { responsiveSize } from 'styles/sizes';
import { fontSizes } from 'styles/typography';

export const InputContainer = styled.div`
  position: relative;
`;

export const InputStyled = styled.input`
  width: 100%;
  border: 2px solid ${colors.text};
  padding: ${responsiveSize(2.5)} ${responsiveSize(3)};
  color: ${colors.text};
  font-size: ${fontSizes.body1};
  line-height: 120%;
  font-family: 'Acid Grotesk';
`;
