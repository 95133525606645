import styled from 'styled-components';
import { responsiveSize } from 'styles/sizes';

export const NotificationGroup = styled.section``;

export const NotificationGroupHeader = styled.div`
  padding-top: ${responsiveSize(6)};
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;
