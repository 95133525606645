import React, { useEffect, useRef } from 'react';

type ScreenDimensions = {
  width: number;
  height: number;
};
const FixVhUnit = () => {
  const screenRef = useRef<ScreenDimensions>();

  /**
   * Fix Vh Unit on mobile pages. Based on: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
   */
  const calculateVh = () => {
    if (
      window.innerWidth !== screenRef.current?.width &&
      window.innerHeight !== screenRef.current?.height
    ) {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      document.documentElement.style.setProperty(
        '--pageHeight',
        `${window.innerHeight}px`,
      );
    }
    screenRef.current = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  };

  useEffect(() => {
    window.addEventListener('resize', calculateVh);
    calculateVh();
    return () => window.removeEventListener('resize', calculateVh);
  }, []);

  return null;
};

export default FixVhUnit;
