import { useEffect } from 'react';
import Routes from 'routes';
import {
  firebaseService,
  NotificationPayload,
} from 'services/firebase-service';
import RootContext from 'context';
import FixVhUnit from 'helpers/fix-vh-unit';
import { GlobalStyle } from 'styles';
import { ToastContainer } from 'react-toastify';
import TagManager from 'react-gtm-module';
import 'styles/fonts.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import 'react-simple-hook-modal/dist/styles.css';
import { BrowserRouter } from 'react-router-dom';
import DesktopContainer from 'components/organisms/desktop-container/desktop-container';
import CheckUpdates from 'components/organisms/check-updates/check-updates';

import { useNotification } from 'components/molecules/in-app-notification';

function App() {
  const { showNotification } = useNotification();

  const isIframeMode = () => {
    const isDesktop = !/iPhone|iPad|iPod|Android|IEMobile/i.test(
      navigator.userAgent,
    );
    const isInIframe = !(window === window.parent);

    if (isDesktop && !isInIframe) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    firebaseService.init();
    const isSupported = firebaseService.isMessagingSupported();

    if (isSupported) {
      firebaseService.getToken();
      firebaseService.onMessageListener(triggerNotification);
    }

    if (!isIframeMode()) {
      TagManager.initialize({ gtmId: `${process.env.REACT_APP_GTM_ID}` });
    }
  }, []);

  const triggerNotification = ({ notification, data }: NotificationPayload) => {
    showNotification({
      title: notification.title,
      message: notification.body,
      avatar: data.avatar,
      type: 'push',
      onClick: () => {
        if (data?.route) {
          window.location.href = data.route;
        }
      },
    });
  };

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <FixVhUnit />
      <GlobalStyle />
      <DesktopContainer>
        <RootContext>
          <Routes />
          <CheckUpdates />
          <ToastContainer
            pauseOnFocusLoss={false}
            closeOnClick={false}
            position="top-center"
            icon={false}
            autoClose={5000}
            limit={1}
          />
        </RootContext>
      </DesktopContainer>
    </BrowserRouter>
  );
}

export default App;
