import React from 'react';
import {
  RadioContainer,
  Radio,
  RadioLabel,
  CustomRadio,
  Bullet,
} from './styled';

interface IRadioButtonProps {
  label?: string;
  id?: string;
  checked?: boolean;
  onClick: (id) => void;
}

const RadioButton: React.FC<IRadioButtonProps> = ({
  label,
  id,
  checked,
  onClick,
}) => {
  return (
    <RadioContainer>
      <Radio
        id={id}
        name="radio-list"
        checked={checked}
        onChange={() => onClick(id)}
      />
      <RadioLabel as="label" htmlFor={id} fontSize="body1">
        <CustomRadio>
          <Bullet />
        </CustomRadio>
        {label}
      </RadioLabel>
    </RadioContainer>
  );
};

export default RadioButton;
