import Image from 'components/atoms/image';
import { InputStyled } from 'components/atoms/input-text/styled';
import styled from 'styled-components';
import { colors } from 'styles/colors';
import { responsiveSize } from 'styles/sizes';

export const SliderContainer = styled.div`
  position: relative;
  width: 100vw;
  left: -30px;

  ${InputStyled} {
    margin-top: ${responsiveSize(5)};
    transition: all 0.3s ease-in-out;
  }

  .slick-slide:not(.slick-current) {
    ${InputStyled} {
      visibility: hidden;
      opacity: 0;
    }
  }
`;

export const ItemContainer = styled.div`
  position: relative;
`;

export const SlideImage = styled(Image)`
  width: 100%;
  max-width: 250px;
  height: 185px;
  object-fit: cover;
  margin: auto;
`;

export const CloseIconContainer = styled.button`
  position: absolute;
  top: 8px;
  right: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: ${colors.white};
`;
