import { SkillItemContainer } from './styled';

interface ISkillItemProps {
  id: number;
  label: string;
  disabled?: boolean;
  onClick?: (value: number) => void;
  isSelected?: boolean;
}

const SkillItem: React.FC<ISkillItemProps> = ({
  id,
  label,
  disabled,
  onClick,
  isSelected,
}) => {
  return (
    <SkillItemContainer
      active={isSelected}
      onClick={!disabled && onClick ? () => onClick(id) : undefined}
    >
      {label}
    </SkillItemContainer>
  );
};

export default SkillItem;
