import styled from 'styled-components';
import { CommentItem } from 'components/molecules/comment/styled';

export const CommentListContainer = styled.div`
  ${CommentItem} {
    & + ${CommentItem} {
      margin-top: 54px;
    }
  }
`;
