import {
  Container,
  Label,
  CheckboxContainer,
  HiddenCheckbox,
  StyledCheckbox,
} from './styled';

interface CheckboxCardProps {
  isChecked: boolean;
  title: string;
  description: string;
  onChange: (value: boolean) => void;
}

const CheckboxCard = ({
  description,
  isChecked,
  onChange,
  title,
}: CheckboxCardProps) => {
  return (
    <Container>
      <Label>
        <CheckboxContainer>
          <HiddenCheckbox
            id={`${title}-checkbox`}
            checked={isChecked}
            onChange={() => {
              onChange(!isChecked);
            }}
          />
          <StyledCheckbox isChecked={isChecked}>
            <div />
          </StyledCheckbox>
        </CheckboxContainer>
        <h3>{title}</h3>
        <span>{description}</span>
      </Label>
    </Container>
  );
};

export default CheckboxCard;
