import { Button } from 'components/atoms/button';
import CheckList from 'components/organisms/check-list';
import RadioList, { IRadioItem } from 'components/organisms/radio-list';
import { useModalStepsContext } from 'context/modal-steps-context';
import { ProfileEnum } from 'enums/profile';
import { IIdLabelOption } from 'models/id-label-option';
import { IRating } from 'models/rating';
import React, { useState } from 'react';
import { Spacing } from 'styles/spacing';
import { Typography } from 'styles/typography';

interface Props {
  currentUserProfile: ProfileEnum;
}
const Feedback: React.FC<Props> = ({ currentUserProfile }) => {
  const [answer, setAnswer] = useState<string[]>([]);
  const { nextStep, getData, patchData } = useModalStepsContext();

  const data = getData<IRating>();
  const goodRating = (data.stars || 0) >= 4;

  const btnClicked = () => {
    patchData<IRating>({ feedback: answer });
    nextStep();
  };

  let options: string[] = [];
  if (currentUserProfile === ProfileEnum.CLIENT) {
    if (goodRating) {
      options = [
        'Prestador pontual no horário de chegada e saída',
        'Foi preciso no orçamento inicial',
        'Ótima qualidade no serviço',
        'Atendimento educado e prestativo',
        'Outro',
      ];
    } else {
      options = [
        'Melhorar a pontualidade de chegada e saída',
        'Respeitar o orçamento acordado',
        'Melhorar a qualidade do serviço',
        'Melhorar o atendimento',
        'Outro',
      ];
    }
  } else if (goodRating) {
    options = [
      'Cliente estava disponível no horário agendado',
      'Os materias necessários estavam disponiveis conforme combinado',
      'Respeitou o que foi acordado pelo app',
      'Pagamento feito conforme combinado',
      'Outro',
    ];
  } else {
    options = [
      'Estar disponível no horário combinado',
      'Disponibilizar os materiais necessários conforme combinado',
      'Cumprir o que foi acordado anteriomente',
      'Pagar o que foi combinado no prazo estipulado',
      'Outro',
    ];
  }

  const onCheckBoxClicked = (resp: string[]) => {
    setAnswer(resp);
  };

  return (
    <>
      <Typography
        fontSize="heading3"
        fontWeight="bold"
        lineHeight="150%"
        letterSpacing="-0.01em"
      >
        {!goodRating
          ? `No que o ${
              currentUserProfile === ProfileEnum.CLIENT
                ? 'prestador'
                : 'cliente'
            } precisa melhorar?`
          : 'Conta pra gente o que você gostou.'}
      </Typography>
      <Spacing size={3} />
      <CheckList
        checkboxItems={options.map((item) => ({
          id: item,
          label: item,
        }))}
        onChange={onCheckBoxClicked}
      />
      <Button disabled={!answer} onClick={btnClicked}>
        Continuar
      </Button>
    </>
  );
};

export default Feedback;
