import { useLoader } from 'context/loader-context';
import { useUserContext } from 'context/user-context';
import { ProfileEnum } from 'enums/profile';
import ErrorAuthRequired from 'pages/error-auth-required';
import React, { useEffect, useState } from 'react';
import { Route, RouteProps, useLocation } from 'react-router-dom';

interface Props extends RouteProps {
  profile?: ProfileEnum;
}
const PrivateRoute: React.FC<Props> = ({
  profile,
  component: Component,
  children,
  ...rest
}) => {
  const [isLogged, setIsLogged] = useState(false);
  const [error, setError] = useState(false);
  const { loadUserData, openAuthModal } = useUserContext();
  const { pathname } = useLocation();
  const { setLoading } = useLoader();

  useEffect(() => {
    handleSignUp();
  }, []);

  const onAuthenticated = async (
    successRoute?: string,
    initialProfile?: ProfileEnum,
  ) => {
    try {
      setLoading(true);
      const tmpUser = await loadUserData();
      if (initialProfile && !tmpUser.profiles.includes(initialProfile)) {
        throw new Error();
      }
      setIsLogged(true);
    } catch (err) {
      openAuthModal(successRoute, initialProfile);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSignUp = () => {
    onAuthenticated(pathname, profile);
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        isLogged ? (
          <>{Component ? <Component {...props} /> : children}</>
        ) : (
          error && <ErrorAuthRequired loginAction={handleSignUp} />
        )
      }
    />
  );
};

export default PrivateRoute;
