import Header from 'components/molecules/header';
import PersonCard from 'components/molecules/person-card';
import SimpleSlider from 'components/molecules/simple-slider';
import RouteDirections from 'components/organisms/route-directions';
import Skeleton from 'react-loading-skeleton';
import { useAsync } from 'context/async-context';
import { useUserContext } from 'context/user-context';
import { PaymentTypeEnum } from 'enums/payment-type';
import PersonListSkeleton from 'components/skeletons/person-list';
import { ProfileEnum } from 'enums/profile';
import { IProgressStatusEnum } from 'enums/progress-status';
import { IServiceStatusEnum } from 'enums/service-status';
import { capitalizeFirstChar } from 'helpers';
import { IService } from 'models/service';
import { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { meService } from 'services/me-service';
import { serviceOfferService } from 'services/service-offer-service';
import { serviceService } from 'services/service-service';
import { FlexContainer } from 'styles/flex-container';
import { Spacing } from 'styles/spacing';
import { Typography } from 'styles/typography';
import Image from 'components/atoms/image';
import PlaceholderImg from 'assets/img/servico-placeholder.png';
import ActionButton from './action-button';
import EmergencyButton from './emergency-button';
import { FullSliderStyled, LinkStyled, ServiceDetailContainer } from './styled';

export const ServiceDetail = () => {
  const [service, setService] = useState<IService>();
  const [otherUid, setOtherUid] = useState<string>();
  const [status, setStatus] = useState<IProgressStatusEnum>(
    IProgressStatusEnum.NONE,
  );
  const { handledAsync } = useAsync();
  const { worker, setWorker, openAuthModal } = useUserContext();

  const { pathname } = useLocation();
  const { serviceUid } = useParams<any>();

  const getStatus = async (tmpService: IService) => {
    if (tmpService?.is_client || worker) {
      const {
        accepted,
        in_negociation,
      } = await serviceOfferService.getInProgress(
        tmpService?.is_client ? ProfileEnum.CLIENT : ProfileEnum.WORKER,
      );
      // Get only service offers connected to this service
      const thisAccepted = accepted.filter((e) => e.service_uid === serviceUid);
      const thisInNegotiation = in_negociation.filter(
        (e) => e.service_uid === serviceUid,
      );
      if (thisAccepted.length) {
        if (tmpService?.is_worker) setOtherUid(thisAccepted[0].client_uid);
        else if (tmpService?.is_client) setOtherUid(thisAccepted[0].worker_uid);
      }

      if (thisAccepted.length) setStatus(IProgressStatusEnum.IN_PROGRESS);
      else if (thisInNegotiation.length)
        setStatus(IProgressStatusEnum.ESTIMATE_SENT);
    }
  };

  const loadService = async () => {
    const tmpService = await serviceService.getService(serviceUid);
    setService(tmpService);
    getStatus(tmpService).catch(() => null);
  };

  useEffect(() => {
    handledAsync(
      async () => {
        await loadService();
      },
      undefined,
      { showLoading: false },
    );
  }, []);

  const favoriteClicked = (isFavorite) => {
    if (worker) {
      handledAsync(async () => {
        let favoriteValues;
        if (isFavorite) {
          favoriteValues = [...(worker?.favorite_services || []), serviceUid];
        } else {
          favoriteValues = worker?.favorite_services?.filter(
            (item) => item !== serviceUid,
          );
        }
        const newWorker = await meService.patchByProfile(ProfileEnum.WORKER, {
          favorite_services: favoriteValues,
        });
        setWorker(newWorker);
      });
    } else {
      openAuthModal(pathname, ProfileEnum.WORKER);
    }
  };

  const serviceClient = service?.client;
  const serviceWorker = service?.worker;
  const favorite = worker?.favorite_services?.includes(serviceUid);

  const inProgress =
    (service?.is_client || service?.is_worker) &&
    service.status === IServiceStatusEnum.IN_PROGRESS;

  return (
    <FlexContainer>
      <ServiceDetailContainer>
        <Header
          title="Serviço"
          onFavoriteClicked={favoriteClicked}
          favoriteIcon={!service?.is_client}
          isFavorite={favorite}
          smallMargin
        />

        {service ? (
          <FullSliderStyled>
            {service.pictures?.length ? (
              <SimpleSlider
                height={300}
                infinite={false}
                items={
                  service?.pictures?.map((pic) => ({
                    image: pic.path,
                    description: pic.description,
                  })) || []
                }
              />
            ) : (
              <Image src={PlaceholderImg} height={240} />
            )}
          </FullSliderStyled>
        ) : (
          <Skeleton height={240} />
        )}

        {inProgress && <EmergencyButton />}

        {service ? (
          <Typography fontSize="body1" display="block" margin="10px 0 16px">
            {capitalizeFirstChar(service?.sub_category.name)} ·{' '}
            {service?.location.district} / {service.location.city}
          </Typography>
        ) : (
          <>
            <Skeleton style={{ margin: '20px 0' }} height="25px" />
            <Skeleton style={{ marginBottom: '30px', marginTop: '0px' }} />
            <PersonListSkeleton listSize={1} />
            <Skeleton width={180} />
          </>
        )}
        <Typography
          as="a"
          target="_blank"
          rel="noreferrer noopener"
          textDecoration="underline"
          margin="0 0 24px"
          href={`https://www.google.com/maps/search/?api=1&query=${service?.location.fullAddress}`}
        >
          {service?.location.fullAddress}
        </Typography>
        {serviceClient && (
          <>
            <LinkStyled to={`/perfil-cliente/${serviceClient.uid}`}>
              <PersonCard
                image={serviceClient.picture_path}
                online={serviceClient.online}
                name={serviceClient.name}
                assessment={
                  serviceClient.score
                    ? `Nota ${serviceClient.score} em ${serviceClient.services_created} serviços`
                    : 'Não possui avaliações'
                }
              />
            </LinkStyled>
            {!service?.is_client && (
              <Link to={`/conversa/${serviceClient?.user_uid}`}>
                <Typography
                  as="button"
                  textDecoration="underline"
                  margin="10px 0 0 0"
                >
                  Conversar com o cliente
                </Typography>
              </Link>
            )}
          </>
        )}
        {serviceWorker && (
          <>
            <LinkStyled to={`/perfil-cliente/${serviceWorker.uid}`}>
              <PersonCard
                image={serviceWorker.picture_path}
                online={serviceWorker.online}
                name={serviceWorker.name}
                assessment={
                  serviceWorker.score
                    ? `Nota ${serviceWorker.score} em ${serviceWorker.services_finished} serviços`
                    : 'Não possui avaliações'
                }
              />
            </LinkStyled>
            <Link to={`/conversa/${serviceWorker?.user_uid}`}>
              <Typography
                as="button"
                textDecoration="underline"
                margin="10px 0 0 0"
              >
                Conversar com o prestador
              </Typography>
            </Link>
          </>
        )}
        <Spacing size={3} />

        {service ? (
          <>
            <Typography
              as="h4"
              fontSize="body1"
              margin="10px 0 10px"
              display="block"
            >
              Sobre o serviço
            </Typography>
            <Typography as="p" fontSize="body2" margin="0 0 24px">
              {service?.description}
            </Typography>
          </>
        ) : (
          <>
            <Skeleton width="50%" />
            <Skeleton count={2} />
            <Spacing size={1} />
          </>
        )}

        <br />
        {!service?.is_client &&
          service?.payment_type === PaymentTypeEnum.BUDGET && (
            <>
              <Typography as="h4" fontSize="body1" margin="0 0 10px">
                Orçamento do serviço
              </Typography>
              <Typography as="p" fontSize="body2" margin="0 0 62px">
                O cliente gostaria de receber um orçamento para este serviço.
                <br />
                <br />
                Diga qual o valor e tempo que você vai precisar para realizar
                este serviço. Você pode alterar o seu orçamento no primeiro dia
                de serviço conversando diretamente com o cliente.
              </Typography>
            </>
          )}
        {!service?.is_client &&
          (service ? (
            <RouteDirections
              serviceUid={service.uid}
              serviceAddress={service.location.fullAddress || ''}
            />
          ) : (
            <Skeleton height={140} />
          ))}

        {inProgress && <EmergencyButton />}

        <Spacing size={4} />

        <ActionButton
          service={service}
          status={status}
          inProgress={inProgress}
          otherUid={otherUid}
          reloadAction={loadService}
        />
      </ServiceDetailContainer>
    </FlexContainer>
  );
};
