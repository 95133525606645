import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';

const baseFontSize = 16;
export const fontSizes = {
  heading1: `${52 / baseFontSize}rem`,
  heading2: `${44 / baseFontSize}rem`,
  heading3: `${32 / baseFontSize}rem`,
  body1: `${20 / baseFontSize}rem`,
  body2: `${16 / baseFontSize}rem`,
  menu: `${10 / baseFontSize}rem`,
};

type FontWeight = '400' | 'bold' | 'normal';

export interface ITypographProps {
  color?: keyof typeof colors;
  fontWeight?: FontWeight;
  margin?: string;
  padding?: string;
  display?: string;
  lineHeight?: string;
  fontSize?: keyof typeof fontSizes;
  align?: string;
  textTransform?: string;
  textDecoration?: string;
  letterSpacing?: string;
  truncate?: boolean;
  cursor?: string;
}
export const Typography = styled.span<ITypographProps>`
  ${({
    color = 'text',
    fontSize = 'body2',
    align = 'left',
    lineHeight = '150%',
    display = 'inline-flex',
    fontWeight = '400',
    textTransform = 'none',
    textDecoration = 'none',
    letterSpacing = 'normal',
    margin = '0',
    padding = '0',
  }) => css`
    font-family: 'Acid Grotesk';
    font-size: ${fontSizes[fontSize]};
    color: ${colors[color]};
    margin: ${margin};
    padding: ${padding};
    text-align: ${align};
    line-height: ${lineHeight};
    font-weight: ${fontWeight};
    display: ${display};
    text-transform: ${textTransform};
    letter-spacing: ${letterSpacing};
    text-decoration: ${textDecoration};
  `};

  ${({ truncate }) =>
    truncate &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `}

  ${({ cursor }) =>
    cursor &&
    css`
      cursor: ${cursor};
    `}
`;

export const LinkTypography = styled(Typography).attrs(
  (props: ITypographProps) => ({
    textDecoration: 'underline',
    as: 'a',
    ...props,
  }),
)``;
