import styled from 'styled-components';
import { colors } from 'styles/colors';
import { Typography } from 'styles/typography';

export const ItemStyled = styled.div`
  width: 50%;
  height: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${colors.gray};
  border-right: 1px solid ${colors.gray};
  gap: 10px;
  cursor: pointer;

  &:nth-child(even) {
    border-right: 0;
  }

  &:hover,
  &.active {
    ${Typography} {
      color: ${colors.primary};
    }

    svg {
      fill: ${colors.primary};
      color: ${colors.primary};
    }
  }
`;

export const IconContainer = styled.div`
  position: relative;
`;

const circleSize = '22px';
export const Indicator = styled.span`
  position: absolute;
  top: -25%;
  right: -25%;
  background-color: ${colors.primary};
  color: ${colors.white};
  border-radius: 50%;
  text-align: center;
  width: ${circleSize};
  height: ${circleSize};
  line-height: ${circleSize};
`;
