export const colors = {
  primary: '#F92A30',
  blue: '#03274D',
  blueLink: '#5D75F5',
  gray: '#F2F2F2',
  text: '#1D1D1F',
  title: '#424242',
  subText: '#677483',
  white: '#FFFFFF',
};
