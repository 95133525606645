import { Button } from 'components/atoms/button';
import InputText from 'components/atoms/input-text';
import { useModalStepsContext } from 'context/modal-steps-context';
import { IRating } from 'models/rating';
import React, { useState } from 'react';
import { useAsync } from 'context/async-context';
import { ProfileEnum } from 'enums/profile';
import { Spacing } from 'styles/spacing';
import { Typography } from 'styles/typography';
import { serviceService } from 'services/service-service';

interface IComplementProps {
  serviceUid?: string;
  otherUid: string;
  currentUserProfile: ProfileEnum;
}

const Complement: React.FC<IComplementProps> = ({
  serviceUid,
  otherUid,
  currentUserProfile,
}) => {
  const [answer, setAnswer] = useState('');
  const { nextStep, getData } = useModalStepsContext();
  const { handledAsync } = useAsync();
  const data = getData<IRating>();
  const goodRating = (data.stars || 0) >= 4;

  const btnClicked = () => {
    handledAsync(async () => {
      data.complement = answer;
      await serviceService.evaluate(
        data,
        otherUid,
        currentUserProfile,
        serviceUid,
      );
      nextStep();
    });
  };

  return (
    <>
      <Typography
        fontSize="heading3"
        fontWeight="bold"
        lineHeight="150%"
        letterSpacing="-0.01em"
      >
        {!goodRating
          ? `No que o ${
              currentUserProfile === ProfileEnum.CLIENT
                ? 'prestador'
                : 'cliente'
            } precisa melhorar?`
          : 'Conta pra gente o que você gostou.'}
      </Typography>
      <Spacing size={3} />
      <InputText placeholder="Digite sua avaliação" onChange={setAnswer} />
      <Button onClick={btnClicked}>Enviar opinião</Button>
    </>
  );
};

export default Complement;
