import axios, { AxiosInstance } from 'axios';
import { ISkillsResponse } from 'models/skill';

class SkillsService {
  private instance: AxiosInstance;

  constructor() {
    this.instance = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        Authorization: process.env.REACT_APP_API_TOKEN,
      },
    });
  }

  async getAll(): Promise<ISkillsResponse> {
    return this.instance
      .get<ISkillsResponse>('/domains/skills')
      .then((resp) => resp.data);
  }
}

export const skillsService = new SkillsService();
