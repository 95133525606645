/* eslint-disable @typescript-eslint/no-explicit-any */
import TokenPage from 'components/templates/token-page';
import { useAsync } from 'context/async-context';
import { useStepsContext } from 'context/steps-context';
import { ILead } from 'models/lead';
import { leadService } from 'services/lead-service';

const RegisterSecurityValidation = () => {
  const { nextStep, getData, patchData, profile } = useStepsContext();
  const { handledAsync } = useAsync();
  const lead = getData<ILead>();

  const resendClicked = async () => {
    await leadService.createLead(
      lead.lead_name,
      lead.cellphone,
      profile!,
      true,
      true,
    );
  };

  const handleBtnClicked = (code: string) => {
    handledAsync(
      async () => {
        const resp = await leadService.validateLead(
          lead.cellphone,
          parseInt(code, 10),
        );
        patchData<ILead>(resp);
        nextStep();
      },
      (err) => {
        const { status } = err.response;
        return status === 409
          ? 'Já existe um cadastro com este número de telefone.'
          : 'Ocorreu um erro ao validar o código informado. Verifique e tente novamente.';
      },
    );
  };

  return (
    <TokenPage
      buttonText="Continuar"
      onConfirmClicked={handleBtnClicked}
      onResendClicked={resendClicked}
    />
  );
};

export default RegisterSecurityValidation;
