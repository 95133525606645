import styled from 'styled-components';

export const FullWidthContainer = styled.div`
  width: 100vw;
  margin: 0 -30px;
`;

export const PlaceholderUpload = styled.div`
  background-color: #677483;
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
