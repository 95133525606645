import { useUserContext } from 'context/user-context';
import React, { useEffect, useState } from 'react';
import { notificationService } from 'services/notification-service';
import { IconBell } from 'styles/icons';
import { Indicator, LinkStyled } from './styled';

const NotificationButton = () => {
  const [hasNew, setHasNew] = useState(false);
  const { user } = useUserContext();

  useEffect(() => {
    if (user) {
      notificationService.getUnread().then((resp) => setHasNew(resp > 0));
    }
  }, [user]);

  return (
    <LinkStyled to="/notificacoes">
      <IconBell />
      {hasNew && <Indicator />}
    </LinkStyled>
  );
};

export default NotificationButton;
