/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from 'components/atoms/button';
import InputDate from 'components/atoms/input-date';
import InputDocumentNumber from 'components/atoms/input-document-number';
import InputEmail from 'components/atoms/input-email';
import InputName from 'components/atoms/input-name';
import InputZipCode from 'components/atoms/input-zip-code';
import Select from 'components/atoms/select';
import Header from 'components/molecules/header';
import { useAlert } from 'context/alert-context';
import { useAsync } from 'context/async-context';
import { useUserContext } from 'context/user-context';
import { genderOptionList } from 'enums/gender';
import { ProfileEnum } from 'enums/profile';
import { formatDateToServer } from 'helpers';
import { IModalDefaultProps } from 'modals/types';
import { ILocation } from 'models/location';
import { IUser } from 'models/user';
import React, { useState } from 'react';
import { Modal, ModalTransition } from 'react-simple-hook-modal';
import { meService } from 'services/me-service';
import { FlexContainer } from 'styles/flex-container';
import { Typography } from 'styles/typography';
import { allFields } from './config';
import { EditProfileRouteEnum } from './types';

interface Props extends IModalDefaultProps {
  field: EditProfileRouteEnum;
  initialData?: any;
}
const ProfileEdit: React.FC<Props> = ({
  isModalOpen,
  onClose,
  field,
  initialData,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [location, setLocation] = useState<ILocation>();

  const { handledAsync } = useAsync();
  const { showToast } = useAlert();
  const { patchUser } = useUserContext();

  const fieldData = allFields[field] || {};
  const { heading, placeholder, fieldKey, buttonText } = fieldData;

  const handleBtnClicked = () => {
    handledAsync(async () => {
      let value: any;

      switch (field) {
        case EditProfileRouteEnum.ADDRESS:
          value = location;
          break;
        case EditProfileRouteEnum.BIRTH_DATE:
          value = formatDateToServer(inputValue);
          break;
        default:
          value = inputValue;
      }

      const data = {
        [fieldKey]: value,
      };
      const resp = await meService.patchBasicInfo(data);
      patchUser(resp);
      showToast({
        text: 'Perfil atualizado',
      });
      onClose();
    });
  };

  const renderField = () => {
    switch (field) {
      case EditProfileRouteEnum.NAME: {
        return (
          <InputName
            placeholder={placeholder}
            onChange={setInputValue}
            initialData={initialData}
          />
        );
      }
      case EditProfileRouteEnum.EMAIL: {
        return (
          <InputEmail
            placeholder={placeholder}
            onChange={setInputValue}
            initialData={initialData}
          />
        );
      }
      case EditProfileRouteEnum.BIRTH_DATE: {
        return (
          <InputDate
            placeholder={placeholder}
            onChange={setInputValue}
            initialData={initialData}
            minAge={18}
          />
        );
      }
      case EditProfileRouteEnum.DOCUMENT_NUMBER: {
        return (
          <InputDocumentNumber
            placeholder={placeholder}
            onChange={setInputValue}
            initialData={initialData}
          />
        );
      }
      case EditProfileRouteEnum.ADDRESS: {
        return (
          <InputZipCode
            placeholder={placeholder}
            onChange={setLocation}
            initialData={initialData}
          />
        );
      }
      case EditProfileRouteEnum.GENDER: {
        return (
          <Select
            label={placeholder || ''}
            emptyText={placeholder || ''}
            initialData={initialData || ''}
            onChange={setInputValue}
            optionList={genderOptionList}
          />
        );
      }
      default:
        return null;
    }
  };

  return (
    <Modal
      id="edit-profile-modal"
      modalClassName="no-padding"
      isOpen={isModalOpen}
      transition={ModalTransition.SCALE}
    >
      <FlexContainer fullPageHeight>
        <Header title="" isModal onCloseModal={onClose} />
        <Typography
          as="h3"
          color="title"
          fontSize="heading3"
          fontWeight="bold"
          display="block"
          letterSpacing="-0.01em"
          margin="0 0 51px"
        >
          {heading}
        </Typography>

        {renderField()}
        <Button
          disabled={
            !inputValue && (field !== EditProfileRouteEnum.ADDRESS || !location)
          }
          onClick={handleBtnClicked}
          disableChevron
        >
          {buttonText}
        </Button>
      </FlexContainer>
    </Modal>
  );
};

export default ProfileEdit;
