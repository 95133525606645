import { useAsync } from 'context/async-context';
import { StepsContextProvider } from 'context/steps-context';
import { PaymentTypeEnum } from 'enums/payment-type';
import { ProfileEnum } from 'enums/profile';
import { IService } from 'models/service';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { serviceService } from 'services/service-service';
import { Activities } from './activities';
import { HowToReceive } from './how-to-receive';
import { Tips } from './tips';

const Estimate = () => {
  const [service, setService] = useState<IService>();
  const { serviceUid } = useParams<any>();
  const { handledAsync } = useAsync();
  const history = useHistory();

  const baseUrl = `/orcamento/${serviceUid}`;
  const allPages = [
    { url: `${baseUrl}/como-receber`, component: HowToReceive },
    { url: `${baseUrl}/atividades`, component: Activities },
    { url: `${baseUrl}/dicas`, component: Tips },
  ];

  useEffect(() => {
    if (serviceUid) {
      handledAsync(
        async () => {
          const resp = await serviceService.getService(serviceUid);
          setService(resp);
        },
        (err) => {
          history.goBack();
          return 'Ocorreu um erro ao carregar os dados. Tente novamente.';
        },
      );
    }
  }, [serviceUid]);

  if (!service) {
    return null;
  }

  const isGivenValue = service.payment_type !== PaymentTypeEnum.BUDGET;

  if (isGivenValue) {
    allPages.shift();
  }

  return (
    <StepsContextProvider
      routes={allPages}
      headerTitle={isGivenValue ? 'Trabalhar' : 'Orçamento'}
      successRoute="/t/servicos"
      initialData={{ service_uid: serviceUid }}
      profile={ProfileEnum.WORKER}
    />
  );
};

export default Estimate;
