import styled from 'styled-components';
import { colors } from 'styles/colors';

export const ButtonSecondaryStyled = styled.button`
  display: block;
  width: 100%;
  padding: 18px 0;
  font-family: 'Acid Grotesk';
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${colors.text};
  border: 2px solid ${colors.text};

  &.bt-blue {
    background-color: ${colors.blue};
    color: ${colors.white};
    border: none;
  }

  &.bt-red {
    background-color: ${colors.primary};
    color: ${colors.white};
    border: none;
  }
`;
