import styled from 'styled-components';
import { responsiveSize } from 'styles/sizes';

export const LeftCol = styled.div`
  padding-right: ${responsiveSize(1.5)};
`;

export const RightCol = styled.div`
  flex: 1;
  overflow: hidden;
`;
