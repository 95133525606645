import styled from 'styled-components';
import { colors } from 'styles/colors';
import { responsiveSize } from 'styles/sizes';

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  display: none;
`;

export const CustomCheckbox = styled.span`
  border: 2px solid ${colors.text};
  min-width: ${responsiveSize(3)};
  min-height: ${responsiveSize(3)};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${responsiveSize(1)};
  transition: all 0.2s;
  transition-delay: 0.2s;
`;

export const Bullet = styled.span`
  width: 0;
  height: 0;
  background: ${colors.white};
  transition-delay: 0.3s;
  transition: all 0.2s;
  border: 0;
`;

export const CheckboxLabel = styled.label`
  font-family: 'Acid Grotesk';
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: ${colors.text};
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;

export const CheckboxContainer = styled.div`
  ${Checkbox}:checked + ${CheckboxLabel} ${CustomCheckbox} {
    background: ${colors.text};
    transition: all 0.2s;
  }

  ${Checkbox}:checked + ${CheckboxLabel} ${Bullet} {
    transition: all 0.2s;
    width: 8px;
    height: 8px;
  }
`;
