import { useModalPage } from 'context/modal-page-context';
import { useUserContext } from 'context/user-context';
import ActivateEmergencyModal from 'modals/activate-emergency';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { colors } from 'styles/colors';
import { FlexContainer } from 'styles/flex-container';
import { IconExclamation } from 'styles/icons';
import { Spacing } from 'styles/spacing';
import { Typography } from 'styles/typography';

const EmergencyButton = () => {
  const { openModal } = useModalPage();
  const { user } = useUserContext();
  const history = useHistory();

  return (
    <>
      <Spacing size={4} />
      <FlexContainer
        direction="row"
        alignItems="center"
        justifyContent="center"
        padding="0"
        margin="auto"
        width="auto"
        as="button"
        onClick={() => {
          if (!user?.emergency_cellphone) {
            history.push('/contato-emergencia');
            return;
          }
          openModal({
            component: ActivateEmergencyModal,
          });
        }}
      >
        <IconExclamation fill={colors.primary} />
        <Spacing size={1} direction="vertical" />
        <Typography fontSize="body2" color="primary" textDecoration="underline">
          Emergência
        </Typography>
      </FlexContainer>
      <Spacing size={4} />
    </>
  );
};

export default EmergencyButton;
