import ButtonSecondary from 'components/atoms/button-secondary';
import { LoginProviderEnum } from 'enums/login-provider';
import { responsiveSize } from 'styles/sizes';
import { Typography } from 'styles/typography';
import { LoginStyled, Row, BottomButton } from './styled';

interface Props {
  heading: string;
  mainActionText: string;
  socialLoginClicked: (provider: LoginProviderEnum) => void;
  mainButtonClicked: () => void;
  mainButtonTestId?: string;
  secondaryButtonText: string;
  secondaryButtonClicked: () => void;
}
const ChooseProviderPage: React.FC<Props> = ({
  heading,
  mainActionText,
  secondaryButtonText,
  socialLoginClicked,
  mainButtonClicked,
  mainButtonTestId,
  secondaryButtonClicked,
}) => {
  return (
    <>
      <LoginStyled>
        <Typography
          as="h3"
          color="title"
          fontSize="heading3"
          fontWeight="bold"
          display="block"
          letterSpacing="-0.01em"
          margin={`${responsiveSize(2.5)} 0 0`}
        >
          {heading}
        </Typography>
        <Row>
          <Typography
            as="span"
            align="center"
            display="block"
            margin={`${responsiveSize(2.5)} 0 ${responsiveSize(2)}`}
          >
            Use suas redes sociais
          </Typography>
          <ButtonSecondary
            variant="blue"
            onClick={() => socialLoginClicked(LoginProviderEnum.FACEBOOK)}
          >
            Facebook
          </ButtonSecondary>
          <ButtonSecondary
            variant="red"
            onClick={() => socialLoginClicked(LoginProviderEnum.GOOGLE)}
          >
            Google
          </ButtonSecondary>
        </Row>
        <Row>
          <Typography
            as="span"
            align="center"
            display="block"
            margin={`${responsiveSize(2.5)} 0 ${responsiveSize(2)}`}
          >
            {mainActionText}
          </Typography>
          <ButtonSecondary
            testId={mainButtonTestId}
            onClick={mainButtonClicked}
          >
            Celular e senha
          </ButtonSecondary>
        </Row>

        <BottomButton onClick={secondaryButtonClicked}>
          {secondaryButtonText}
        </BottomButton>
      </LoginStyled>
    </>
  );
};

export default ChooseProviderPage;
