import { Button } from 'components/atoms/button';
import CheckboxCard from 'components/molecules/checkbox-card';
import { ProfileEnum } from 'enums/profile';
import { StorageKeyEnum } from 'enums/storage-key';
import { IModalDefaultProps } from 'modals/types';
import { ILead } from 'models/lead';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal, ModalTransition } from 'react-simple-hook-modal';
import { FlexContainer } from 'styles/flex-container';
import { responsiveSize } from 'styles/sizes';
import { Typography } from 'styles/typography';
import * as S from './styles';

interface SocialLoginProfileProps extends IModalDefaultProps {
  lead: ILead;
}

const SocialLoginProfile = ({
  lead,
  isModalOpen,
  onClose,
}: SocialLoginProfileProps) => {
  const [userRole, serUserRole] = useState<null | ProfileEnum>(null);
  const history = useHistory();

  const handleChangeCheckbox = (type: ProfileEnum) => {
    serUserRole(
      type === ProfileEnum.CLIENT ? ProfileEnum.CLIENT : ProfileEnum.WORKER,
    );
  };

  const handleProfileChoosed = async () => {
    localStorage.setItem(
      StorageKeyEnum.LEAD,
      JSON.stringify({
        ...lead,
        profile: userRole,
      }),
    );
    history.push(
      `/cadastro-${
        userRole === ProfileEnum.CLIENT ? 'cliente' : 'prestador'
      }/nome`,
    );
    onClose();
  };

  return (
    <Modal
      id="update-profile-role"
      modalClassName="no-padding"
      isOpen={isModalOpen}
      transition={ModalTransition.BOTTOM_UP}
    >
      <FlexContainer darkMode fullPageHeight>
        <S.Container>
          <Typography
            as="h3"
            color="white"
            fontSize="heading3"
            fontWeight="bold"
            letterSpacing="-0.01em"
            margin={`${responsiveSize(4)} 0`}
            display="block"
          >
            Escolha o seu perfil
          </Typography>
          <Typography
            fontSize="body1"
            fontWeight="bold"
            color="white"
            display="block"
          >
            O que você você é?
          </Typography>

          <S.CheckboxWrapper>
            <CheckboxCard
              isChecked={!!(userRole && userRole === ProfileEnum.CLIENT)}
              description="Quero contratar um prestador"
              title="Cliente"
              onChange={() => handleChangeCheckbox(ProfileEnum.CLIENT)}
            />

            <CheckboxCard
              isChecked={!!(userRole && userRole === ProfileEnum.WORKER)}
              description="Quero trabalhar"
              title="Prestador"
              onChange={() => handleChangeCheckbox(ProfileEnum.WORKER)}
            />
          </S.CheckboxWrapper>

          <Button onClick={handleProfileChoosed} disabled={!userRole}>
            Avançar
          </Button>
        </S.Container>
      </FlexContainer>
    </Modal>
  );
};

export default SocialLoginProfile;
