/* eslint-disable no-unused-expressions */
import Image from 'components/atoms/image';
import SkillItem from 'components/atoms/skill-item';
import Header from 'components/molecules/header';
import WorkerProfile from 'components/molecules/worker-profile';
import CommentList from 'components/organisms/comment-list';
import { useAsync } from 'context/async-context';
import { useEffect, useState } from 'react';
import { workersService } from 'services/workers-service';
import { meService } from 'services/me-service';
import { useAlert } from 'context/alert-context';
import { IWorkerListItem } from 'models/user';
import { Link, useLocation, useParams } from 'react-router-dom';
import { SimpleCarousel } from 'styles/elements';
import { FlexContainer } from 'styles/flex-container';
import { IconLocation, IconStar, IconVerificated } from 'styles/icons';
import { Spacing } from 'styles/spacing';
import { chatService } from 'services/chat-service';
import { LinkTypography, Typography } from 'styles/typography';
// eslint-disable-next-line import/no-duplicates
import { format, parseISO } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { ptBR } from 'date-fns/locale';
import VideoPlayer from 'components/molecules/video-player';
import { useUserContext } from 'context/user-context';
import { ProfileEnum } from 'enums/profile';
import Skeleton from 'react-loading-skeleton';
import {
  AboutServiceItem,
  IconTextContainer,
  SkillsStyled,
  VideoContainer,
  WorkerDetailContainer,
} from './styled';

const WorkerDetail = () => {
  const [evaluationList, setEvaluationList] = useState<any[]>([]);
  const [workerProfile, setWorkerProfile] = useState<IWorkerListItem>();

  const { pathname } = useLocation();
  const { uid } = useParams<any>();
  const { showAlert } = useAlert();
  const { handledAsync } = useAsync();
  const {
    client,
    setClient,
    user,
    worker,
    openAuthModal,
    talkJSSession,
  } = useUserContext();

  const favorite = client?.favorite_workers?.includes(uid);

  useEffect(() => {
    handledAsync(async () => {
      let workerInfo = await workersService.getWorkerByUid(uid);

      workerInfo = {
        skills: workerInfo?.skills_per_service?.map(
          (item) => `${item.skill} (${item.quantity})`,
        ),
        ...workerInfo,
      };

      workerInfo = {
        assessment: workerInfo.score
          ? `Nota ${workerInfo.score}`
          : 'Sem avaliações',
        ...workerInfo,
      };

      setWorkerProfile(workerInfo);

      let evaluations = await workersService.getWorkerEvaluationsByUid(uid);
      evaluations = evaluations.map((item) => ({
        id: item.uid,
        image: item.picture_path,
        name: item.name,
        rated: `Deu nota ${item.score} em ${format(
          parseISO(item.created_at),
          "MMM'. de' yyyy",
          { locale: ptBR },
        )}`,
        service: item.category ? `Serviço de ${item.category}` : '',
        text: item.description,
      }));
      setEvaluationList(evaluations);
    });
  }, []);

  const favoriteClicked = (isFavorite) => {
    if (user && client && workerProfile) {
      handledAsync(async () => {
        let favoriteValues;
        chatService.setTalkSession(talkJSSession);

        const workerUser = await chatService.createUser({
          id: workerProfile.user_uid,
          name: workerProfile.name,
        });

        const conversation = await chatService.getOrCreateConversation(
          workerUser,
        );
        if (isFavorite) {
          favoriteValues = [...(client?.favorite_workers || []), uid];
        } else {
          favoriteValues = client?.favorite_workers.filter(
            (item) => item !== uid,
          );
        }
        const newClient = await meService.patchByProfile(ProfileEnum.CLIENT, {
          favorite_workers: favoriteValues,
        });
        await chatService.favoriteConversation(conversation, isFavorite);
        setClient(newClient);
      });
    } else {
      openAuthModal(pathname, ProfileEnum.CLIENT);
    }
  };

  return (
    <FlexContainer>
      <WorkerDetailContainer>
        <Header
          title="Prestador"
          onFavoriteClicked={favoriteClicked}
          smallMargin
          favoriteIcon={uid !== worker?.worker_uid}
          isFavorite={favorite}
        />
        <WorkerProfile worker={workerProfile} />
        <IconTextContainer>
          <IconStar />
          <FlexContainer padding="0" as="div">
            {workerProfile ? (
              <>
                <Typography>{workerProfile?.assessment}</Typography>
                <Typography>
                  {workerProfile.qt_evaluation &&
                    (workerProfile.qt_evaluation === '1'
                      ? `Avaliado por ${workerProfile.qt_evaluation} usuário`
                      : `Avaliado por ${workerProfile.qt_evaluation} usuários`)}
                </Typography>
              </>
            ) : (
              <>
                <Skeleton width="200px" />
                <Skeleton width="200px" />
              </>
            )}
          </FlexContainer>
        </IconTextContainer>

        <Spacing size={2} />

        <IconTextContainer
          as="button"
          onClick={() => {
            showAlert({
              title: 'E-mail e telefone verificados',
              text:
                'A validação de certas informações dos usuários é a forma de manter o Tô Parado seguro para todos.<br /><br /> Dessa forma, sabemos que os usuários estão comprometidos e informando dados válidos.',
            });
          }}
        >
          <IconVerificated />
          <Typography>
            {workerProfile ? (
              'E-mail e telefone verificados'
            ) : (
              <Skeleton width="225px" />
            )}
          </Typography>
        </IconTextContainer>

        <Spacing size={3} />

        {workerProfile ? (
          worker?.worker_uid !== uid && (
            <Link to={`/conversa/${workerProfile?.user_uid}`}>
              {worker?.uid !== uid && (
                <LinkTypography as="span" color="primary">
                  Conversar com o prestador
                </LinkTypography>
              )}
            </Link>
          )
        ) : (
          <Skeleton width="60%" />
        )}

        {workerProfile && (
          <>
            <SkillsStyled>
              <Typography
                as="h4"
                fontWeight="bold"
                fontSize="body1"
                margin="38px 0 36px"
                display="block"
              >
                Habilidades
              </Typography>
              {workerProfile?.skills?.map((skill, index) => (
                <SkillItem key={index} id={index} label={skill} disabled />
              ))}
            </SkillsStyled>
            <Typography
              as="h4"
              fontWeight="bold"
              fontSize="body1"
              margin="0 0 24px"
              display="block"
            >
              Sobre
            </Typography>
            <Typography margin="0 0 31px 0">
              {workerProfile?.location?.district &&
                workerProfile?.location?.city && (
                  <>
                    <IconLocation /> Mora em {workerProfile?.location?.district}
                    , {workerProfile?.location?.city}
                  </>
                )}
            </Typography>
            <VideoContainer>
              {workerProfile?.video_bio_path && (
                <VideoPlayer src={workerProfile?.video_bio_path} />
              )}
            </VideoContainer>
            <Typography as="p" display="block" margin="14px 0 48px 0">
              {workerProfile?.bio}
            </Typography>
            {workerProfile?.story_pictures && (
              <SimpleCarousel>
                {workerProfile?.story_pictures.map((service, index) => (
                  <AboutServiceItem key={index}>
                    <Image src={service.path} height={240} />
                    <Typography
                      as="p"
                      align="center"
                      display="block"
                      margin="18px 0 0 0"
                    >
                      {service.description}
                    </Typography>
                  </AboutServiceItem>
                ))}
              </SimpleCarousel>
            )}
            <Spacing size={4} />
            <Typography
              as="h4"
              fontWeight="bold"
              fontSize="body1"
              margin="0 0 26px"
              display="block"
            >
              Avaliação ({evaluationList?.length})
            </Typography>
          </>
        )}
        <CommentList items={evaluationList} />
      </WorkerDetailContainer>
    </FlexContainer>
  );
};

export default WorkerDetail;
