/* eslint-disable @typescript-eslint/no-explicit-any */
import AppliedFilter from 'components/atoms/applied-filter';
import NotificationButton from 'components/atoms/notification-button';
import ServiceList from 'components/organisms/service-list';
import { useAlert } from 'context/alert-context';
import { useUserContext } from 'context/user-context';
import { useAsync } from 'context/async-context';
import { IServiceStatusEnum } from 'enums/service-status';
import { getPaymentTypeDesc, paymentTypeOptions } from 'enums/payment-type';
import FiltersModal from 'modals/filters-modal';
import SearchModal from 'modals/search-modal';
import { IService } from 'models/service';
import { ISkill } from 'models/skill';
import { useEffect, useState } from 'react';
import { useModal } from 'react-simple-hook-modal';
import { serviceService } from 'services/service-service';
import { FlexContainer } from 'styles/flex-container';
import { IconFilter, IconSearch } from 'styles/icons';
import { Typography } from 'styles/typography';
import { useHistory } from 'react-router-dom';
import MyServices from './my-services';
import {
  ButtonFilter,
  FilterContainer,
  HeaderStyled,
  ServicesContainer,
} from './styled';

const sortOptions = [
  {
    label: 'Recentes',
    id: 'created_at:DESC',
  },
  {
    label: 'Maior valor',
    id: 'payment_value:DESC',
  },
  {
    label: 'Menor valor',
    id: 'payment_value:ASC',
  },
  {
    label: 'Próximos',
    id: 'distance:ASC',
  },
];

const ViewServices = () => {
  const history = useHistory();
  const { showToast } = useAlert();
  const { handledAsync } = useAsync();
  const [showSearch, setShowSearch] = useState(false);
  const [serviceList, setServiceList] = useState<IService[]>([]);
  const [myServicesList, setMyServicesList] = useState<IService[]>([]);

  const [subcategories, setSubcategories] = useState<ISkill[]>([]);
  const [filters, setFilters] = useState([]);
  const [sort, setSort] = useState('created_at:DESC');

  const [page, setPage] = useState(0);

  const { isModalOpen, openModal, closeModal } = useModal();

  const [loading, setLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [hasError, setHasError] = useState(false);

  const { getGeolocation, isClient } = useUserContext();

  const handleCreateNewService = () => {
    history.push('/criar-servico');
  };

  const loadMyServices = async () => {
    try {
      const response = await serviceService.getMyServices([
        IServiceStatusEnum.PUBLISHED,
        IServiceStatusEnum.DONE,
      ]);

      setMyServicesList(response);
    } catch (err) {
      console.log(err);
      setHasError(true);
    }
  };

  const loadMore = async () => {
    try {
      setHasError(false);
      setLoading(true);
      const limit = 15;
      const offset = page * limit;

      const geoLocation = await getGeolocation();

      const nextPage = await serviceService.getServices(
        offset,
        limit,
        filters,
        subcategories.map((item) => item.skill_id),
        sort,
        geoLocation,
      );

      setServiceList([...serviceList, ...nextPage]);
      setPage(page + 1);
      if (nextPage.length !== limit) setHasNextPage(false);
    } catch (err) {
      console.log(err);
      setHasError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isClient) {
      loadMyServices();
    }
    loadMore();
  }, []);

  useEffect(() => {
    setPage(0);
    setServiceList([]);
    setHasNextPage(true);
  }, [filters, sort, subcategories]);

  const filterClicked = () => {
    openModal();
  };

  const toggleSearch = () => {
    setShowSearch(!showSearch);
  };

  const onRemoveFilter = (label: string) => {
    const filterId = filters.find((item) => getPaymentTypeDesc(item) === label);
    if (filterId) {
      setFilters(filters.filter((item) => item !== filterId));
    } else {
      const newSubs = subcategories.filter((item) => item.skill !== label);
      setSubcategories(newSubs);
    }
  };

  const labelFilters: any = [
    ...filters.map((item) => getPaymentTypeDesc(item)),
    ...subcategories.map((item) => item.skill),
  ].filter(Boolean);

  const handleEdit = (uid: string) => {
    history.push(`/editar-servico`, {
      uid,
    });
  };

  const handleDelete = async (uid: string) => {
    handledAsync(async () => {
      await serviceService.deleteService(uid);
      loadMyServices();

      showToast({
        text: 'Desfazer essa ação?',
        rightAction: 'undo',
        onUndoClicked: () =>
          handledAsync(async () => {
            await serviceService.undoDeleteService(uid);
            loadMyServices();
          }),
      });
    });
  };

  return (
    <FlexContainer>
      <ServicesContainer>
        <HeaderStyled>
          <Typography as="span" fontSize="body2" fontWeight="bold">
            Serviços num raio de 30km
          </Typography>
          <NotificationButton />
        </HeaderStyled>

        {isClient && (
          <MyServices
            handleNewService={handleCreateNewService}
            data={myServicesList}
            loading={loading}
            onRemove={handleDelete}
            onEdit={handleEdit}
          />
        )}

        <FilterContainer>
          <Typography
            as="button"
            fontSize="heading3"
            fontWeight="bold"
            letterSpacing="-0.01em"
            onClick={toggleSearch}
          >
            Serviços
            <br /> publicados
          </Typography>
          <ButtonFilter>
            <IconSearch onClick={toggleSearch} />
            <IconFilter onClick={filterClicked} />
          </ButtonFilter>
        </FilterContainer>

        {labelFilters.length > 0 && (
          <FlexContainer direction="row" gap="8px" padding="0 0 24px 0">
            {labelFilters.map((item) => (
              <AppliedFilter
                key={item}
                label={item}
                onRemove={onRemoveFilter}
              />
            ))}
          </FlexContainer>
        )}

        {hasError ? (
          <Typography align="center">
            Ocorreu um erro ao buscar os serviços. Tente novamente.
          </Typography>
        ) : (
          <>
            <ServiceList
              listItems={serviceList}
              loadMore={loadMore}
              loading={loading}
              hasNextPage={hasNextPage}
            />
            {!loading && !serviceList.length && (
              <Typography align="center">
                {labelFilters.length > 0
                  ? 'Não encontramos resultados para os filtros aplicados.'
                  : 'Não encontramos serviços publicados na sua região.'}
              </Typography>
            )}
          </>
        )}

        <SearchModal
          defaultTitle="Que serviço você procura?"
          onClose={toggleSearch}
          onSelect={(items) => {
            setSubcategories(items);
            toggleSearch();
          }}
          isModalOpen={showSearch}
          initialSubcategories={subcategories}
        />
        <FiltersModal
          filtersTabName="Pagamento"
          onClose={closeModal}
          isModalOpen={isModalOpen}
          filtersOptions={paymentTypeOptions}
          sortOptions={sortOptions}
          filters={filters}
          sort={sort}
          onChange={({ filters: appliedFilters, sort: appliedSort }) => {
            setFilters(appliedFilters);
            setSort(appliedSort);
          }}
        />
      </ServicesContainer>
    </FlexContainer>
  );
};

export default ViewServices;
