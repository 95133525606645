/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef } from 'react';
import { colors } from 'styles/colors';
import { FlexContainer } from 'styles/flex-container';
import { IconClose } from 'styles/icons';
import { LinkTypography, Typography } from 'styles/typography';
import { ToastStyled } from './styled';

const DELAY_SECONDS = 3;

export interface IToastOptions {
  text: string;
  rightAction?: 'undo' | 'close';
  autoHide?: boolean;
  onUndoClicked?: () => void;
  onDismiss?: () => void;
}
interface Props extends IToastOptions {
  isOpen: boolean;
}
const Toast: React.FC<Props> = ({
  text,
  rightAction = 'close',
  autoHide = true,
  onUndoClicked = () => null,
  isOpen,
  onDismiss = () => null,
}) => {
  const timeout = useRef<any>(null);

  useEffect(() => {
    if (isOpen) {
      if (autoHide) {
        timeout.current = setTimeout(() => {
          onDismiss();
        }, DELAY_SECONDS * 1000);
      }
    } else if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }
  }, [isOpen, autoHide]);

  const handleUndoClicked = () => {
    onUndoClicked();
    onDismiss();
  };

  return (
    <ToastStyled
      style={{
        opacity: isOpen ? 1 : 0,
        visibility: isOpen ? 'visible' : 'hidden',
      }}
    >
      <FlexContainer
        darkMode
        direction="row"
        justifyContent="space-between"
        padding="0 30px 0 18px"
      >
        <Typography fontSize="body1" lineHeight="120%" fontWeight="normal">
          {text}
        </Typography>
        {rightAction === 'undo' && (
          <LinkTypography as="button" onClick={handleUndoClicked}>
            Desfazer
          </LinkTypography>
        )}
        {rightAction === 'close' && (
          <IconClose fill={colors.white} onClick={onDismiss} />
        )}
      </FlexContainer>
    </ToastStyled>
  );
};

export default Toast;
