import styled from 'styled-components';

interface SliderItemProps {
  isAccordionOpen: boolean;
}

export const Container = styled.div`
  position: relative;
  width: 100%;

  ul {
    &.slick-dots {
      bottom: 3px;
    }

    li {
      width: 4px;
      button {
        width: 4px;
        height: 4px;
        background: #1d1d1f;
        border-radius: 4px;
        padding: 0;
        &:before {
          content: '';
        }
      }

      &.slick-active {
        width: 24px;
        transition: all 0.3s;
        button {
          transition: all 0.3s;
          width: 24px;
        }
      }
    }
  }
`;

export const SliderItem = styled.div<SliderItemProps>`
  padding-bottom: ${({ isAccordionOpen }) => (isAccordionOpen ? 32 : 48)}px;

  a {
    text-decoration: none;
  }
`;

export const ServiceInformation = styled.div`
  display: flex;
  margin-top: 8px;
  align-items: center;
  width: 100%;
`;

export const LineSeparator = styled.div`
  width: 2px;
  height: 2px;
  background-color: #1d1d1f;
  margin: 0 5px;
`;
