export const SIZE_UNIT = 8 / 16;

export const breakpoints = {
  iphone5: '320px',
  mobile: '420px',
  smallHeight: '560px',
  tablet: '600px',
  tabletXl: '800px',
  desktop: '990px',
};

export const responsiveSize = (size: number) => {
  return `${SIZE_UNIT * size}rem`;
};

export const mediaQuery = (
  size: keyof typeof breakpoints,
  minOrMax: 'min' | 'max' = 'min',
  dimension = 'width',
) => {
  return `@media screen and (${minOrMax}-${dimension}: ${breakpoints[size]})`;
};
