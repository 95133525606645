import axios, { AxiosInstance } from 'axios';
import { ProfileEnum } from 'enums/profile';
import { ILead } from 'models/lead';
import { IUserCreatedResponse } from 'models/user';
import { firebaseService } from './firebase-service';

class LeadService {
  private instance: AxiosInstance;

  constructor() {
    this.instance = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        Authorization: process.env.REACT_APP_API_TOKEN,
      },
    });
  }

  async createLead(
    lead_name: string,
    cellphone: string,
    profile: ProfileEnum,
    term: boolean,
    policy: boolean,
  ) {
    return this.instance
      .post('/leads', { lead_name, cellphone, profile, term, policy })
      .then((resp) => resp.data);
  }

  async validateLead(cellphone: string, code: number) {
    return this.instance
      .put<Partial<ILead>>('/leads', { cellphone, code })
      .then((resp) => resp.data);
  }

  async patchLead(leadId: string, body: Partial<ILead>) {
    return this.instance
      .patch<Partial<ILead>>(`/leads/${leadId}`, body)
      .then((resp) => resp.data);
  }

  async registrateUser(leadId: string) {
    const userToken = await firebaseService.getUserToken();
    return this.instance
      .post<IUserCreatedResponse>(
        `/registrations/${leadId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        },
      )
      .then((resp) => resp.data)
      .catch((err) => {
        const { status } = err.response;
        if (status === 409) {
          throw new Error('Já existe um cadastro com este número de telefone.');
        }
        throw new Error(
          'Ocorreu um erro ao realizar o cadastro. Tente novamente.',
        );
      });
  }
}

export const leadService = new LeadService();
