import styled from 'styled-components';
import { responsiveSize } from 'styles/sizes';

export const HeaderStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: ${responsiveSize(2)};
  padding-bottom: ${responsiveSize(4)};
`;

export const ButtonBell = styled.button``;

export const FilterContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: ${responsiveSize(4)};
`;

export const ButtonFilter = styled.button`
  margin-bottom: ${responsiveSize(1)};
  gap: ${responsiveSize(2)};
  display: flex;
`;
