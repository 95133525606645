import { Button } from 'components/atoms/button/index';
import { Typography } from 'styles/typography';
import { useStepsContext } from 'context/steps-context';
import { Spacing } from 'styles/spacing';
import Banner from 'assets/img/criacao-servico.png';
import Image from 'components/atoms/image';

export const Intro = () => {
  const { nextStep } = useStepsContext();

  return (
    <>
      <Typography
        as="h3"
        color="white"
        fontSize="heading3"
        fontWeight="bold"
        lineHeight="48px"
        display="block"
        letterSpacing="-0.01em"
        margin="0 0 25px"
      >
        Você tem um serviço pra ser feito? Publique aqui.
      </Typography>
      <Image src={Banner} />
      <Typography
        as="p"
        color="white"
        fontSize="body2"
        fontWeight="normal"
        lineHeight="24px"
        display="block"
        margin="32px 0 0 0"
      >
        É através do seu serviço que os prestadores vão entender o que tem que
        ser feito e irão escrever pra você no chat para fechar negócios.
        <br />
        <br />
        Você também pode convidar um prestador para realizar o seu serviço.
      </Typography>
      <Spacing size={6} />
      <Button onClick={nextStep}>Vamos lá!</Button>
    </>
  );
};
