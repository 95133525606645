/* eslint-disable no-param-reassign */
import axios from 'axios';
import { firebaseService } from './firebase-service';

class EmergencyService {
  async getInstance() {
    const userToken = await firebaseService.getUserToken();

    return axios.create({
      baseURL: process.env.REACT_APP_API_URL,

      headers: {
        Authorization: userToken
          ? `Bearer ${userToken}`
          : process.env.REACT_APP_API_TOKEN,
      },
    });
  }

  async actionEmergency(mode: 'activated' | 'deactivated') {
    return (await this.getInstance()).put(`/me/emergency-mode/${mode}`);
  }
}

export const emergencyService = new EmergencyService();
