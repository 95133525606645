import { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
import { colors } from './colors';
import { mediaQuery } from './sizes';

export const GlobalStyle = createGlobalStyle`

*, body{
  margin: 0;
  padding: 0;
}

* {
  list-style: none;
  vertical-align: baseline;
  outline: 0!important;
  box-sizing: border-box;
}

body, html {
  height: 100%;
  font-size: 16px;

  ${mediaQuery('iphone5', 'max')}{
    font-size: 12px;
  }
  ${mediaQuery('smallHeight', 'max', 'height')}{
    font-size: 12px;
  }
}

body {
  background-color: ${colors.white};
  color: ${colors.text};  
  font-family: 'Acid Grotesk', Arial, Helvetica, sans-serif;
}

input, button{
  font-family: 'Acid Grotesk', Arial, Helvetica, sans-serif;
}

button {
  background: transparent;

  &:hover {
    cursor: pointer;
  }
}

a {
  &:hover {
    cursor: pointer;
  } 
}

#root {
  display: flex;
  min-height: 100%;
  width: 100%;
  position: relative;
}

::-webkit-input-placeholder {
  color: ${colors.subText};
}
::-moz-placeholder {
  color: ${colors.subText};
}
:-ms-input-placeholder {
  color: ${colors.subText};
}
:-moz-placeholder {
  color: ${colors.subText};
}

.rsm-bg-gray-700 {
  background: #000000;
  opacity: 0.7 !important;
}

#react-simple-modal-container {
  .rsm-flex {
    display: block;
  }

  .rsm-m-4 {
    margin: 0;
  }

  .rsm-rounded-md {
    border-radius: 0;
  }

  .rsm-shadow-lg {
    box-shadow: none;
  }
  
  .rsm-max-h-full {
    min-height: auto !important;
  }
  
  .rsm-p-8 {
    padding: 40px 30px 58px 30px;
  }

  .no-padding{
    .rsm-p-8{
      padding: 0;
    }
  }

  .absolute-bottom{
    position:absolute;
    bottom: 0;
  }
}

.shake {
  animation: shake 0.1s;
  animation-iteration-count: 3;
}

.shake-element-block {
  animation: shake-element-block 0.1s;
  animation-iteration-count: 3;
}

@keyframes shake {
  0% {margin-left: 0;}
  25% {margin-left: 5px;}
  50% {margin-left: 0;}
  75% {margin-left: -5px;}
  100% {margin-left: 0;}
}

@keyframes shake-element-block {
  0% {margin-left: 0;}
  25% {margin-left: 12px;}
  50% {margin-left: 0;}
  75% {margin-left: -12px;}
  100% {margin-left: 0;}
}

@keyframes beat {
  0%,
  50%,
  100% {
    transform: scale(1);
  }
  20%,
  80% {
    transform: scale(1.4);
  }
}

input[type=file] { 
  display: none;
}
input[type=number]::-webkit-inner-spin-button { 
  -webkit-appearance: none;
}
input[type=number] { 
  -moz-appearance: textfield;
  appearance: textfield;
}

/* SCROLLBAR (NÃO SUPORTADO NO FIREFOX E IE/EDGE) */
::-webkit-scrollbar {
    width: 4px;
    height: 3px;
}
/* Track */
::-webkit-scrollbar-track {
    background: ${colors.gray};
}
/* Handle */
::-webkit-scrollbar-thumb {
    background: ${colors.subText};
}

/* SCROLLBAR FIREFOX */
:root{
    scrollbar-face-color: ${colors.gray};
    scrollbar-track-color: ${colors.subText};
    scrollbar-color: ${colors.subText} ${colors.gray};
    scrollbar-width: thin;

    --toastify-color-success: #F2F2F2;
}

.Toastify__toast-container {
  width: calc(100% - 40px);
  left: 20px;
  top: 20px;

  .Toastify__toast--success {
    background-color: #F2F2F2;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
  }
}

.Toastify__progress-bar {
  top: 0;
  background-color: ${colors.primary};
}
`;
