import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';
import { fontSizes } from 'styles/typography';
import { Link } from 'react-router-dom';

interface ContainerProps {
  hasManyWorkers?: boolean;
  contentHeight: number;
  isOpen?: boolean;
}

interface AccordionContentProps {
  isOpen?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  background-color: #f2f2f2;
  width: calc(100% + 60px);
  padding: ${({ hasManyWorkers }) =>
    hasManyWorkers ? '24px 30px' : '24px 30px 0'};
  margin-left: -30px;
  overflow: hidden;

  transition: all 0.2s ease-in;

  ${({ isOpen, contentHeight }) => css`
    height: ${isOpen ? contentHeight + 270 : 290}px;
  `}
`;

export const ShowMoreButton = styled.button`
  color: ${colors.primary};
  font-size: ${fontSizes.body1};
`;

export const AccordionContent = styled.div<AccordionContentProps>`
  ${({ isOpen }) =>
    !isOpen &&
    css`
      height: 0;
      overflow: hidden;
    `}
`;

export const PersonItem = styled(Link)`
  display: block;
  margin-bottom: 24px;
  text-decoration: none;
`;
