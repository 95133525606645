import styled from 'styled-components';
import { colors } from 'styles/colors';
import ChevronBlack from 'assets/img/chevron-black.svg';
import ReactDropdown from 'react-dropdown';

export const SelectStyled = styled(ReactDropdown)`
  margin-top: 12px;
  font-family: 'Acid Grotesk';
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: ${colors.text};
  width: 100%;
  max-width: 300px;
  border: 2px solid rgba(29, 29, 31, 0.2);

  .Dropdown-control {
    padding: 10px;
    min-height: 44px;
    position: relative;

    .Dropdown-arrow-wrapper {
      position: absolute;
      right: 16px;
      top: 40%;
      width: 20px;
      height: 10px;

      .Dropdown-arrow {
        background-image: url(${ChevronBlack});
        background-repeat: no-repeat;
        display: block;
        width: 100%;
        height: 100%;

        &.open {
          transform: rotate(180deg);
        }
      }
    }
  }

  .Dropdown-option {
    padding: 16px;

    &.is-selected {
      background-color: ${colors.gray};
    }
  }
`;
export const OptionStyled = styled.option``;
