import styled from 'styled-components';
import { ButtonSecondaryStyled } from 'components/atoms/button-secondary/styled';
import { HeaderContainer } from 'components/molecules/header/styled';
import { colors } from 'styles/colors';

export const LoginStyled = styled.div`
  padding-bottom: 64px;
  @media (min-height: 530px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
  }

  ${HeaderContainer} {
    margin-bottom: 0;
  }

  ${ButtonSecondaryStyled} {
    margin-top: 0;
    & + ${ButtonSecondaryStyled} {
      margin-top: 14px;
    }
  }
`;

export const Row = styled.div``;

export const BottomButton = styled.button`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.gray};
  color: ${colors.primary};
  font-size: 20px;
`;
