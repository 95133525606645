import Avatar from 'components/molecules/avatar';
import Header from 'components/molecules/header';
import { useModalPage } from 'context/modal-page-context';
import { useUserContext } from 'context/user-context';
import { useAsync } from 'context/async-context';
import { WorkerStoryTypeEnum } from 'enums/worker-story-type';
import StoryModal from 'modals/story-modal';
import { IInspirationalStory, IWorker } from 'models/user';
import React, { useEffect, useState } from 'react';
import { workersService } from 'services/workers-service';
import { SimpleCarousel } from 'styles/elements';
import { FlexContainer } from 'styles/flex-container';
import { responsiveSize } from 'styles/sizes';
import { Spacing } from 'styles/spacing';
import { LinkTypography, Typography } from 'styles/typography';
import CustomActionSheet from 'components/molecules/custom-action-sheet';
import { meService } from 'services/me-service';
import { ProfileEnum } from 'enums/profile';
import { StoryPhotosModal } from './story-photos';
import StoryTextModal from './story-text';
import { StoryVideoModal } from './story-video';
import { InspiratingStory, InspiratingStoryWrapper } from './styled';

const YourStory = () => {
  const { openModal } = useModalPage();
  const { worker, setWorker } = useUserContext();
  const [stories, setStories] = useState<IInspirationalStory[]>([]);

  const { handledAsync } = useAsync();

  const textsData = {
    [WorkerStoryTypeEnum.VIDEO]: {
      title: 'Vídeo',
      emptyText:
        'Grave um vídeo de você mesmo de até um minuto contando sua história.',
      editText: 'Você adicionou 1 vídeo!',
      removeButtonText: 'Remover vídeo',
      buttonText: 'Subir vídeo',
      modalComponent: StoryVideoModal,
      isEdit: !!worker?.video_bio_path,
    },
    [WorkerStoryTypeEnum.PHOTOS]: {
      title: 'Fotos',
      emptyText:
        'Imagens podem contar boas histórias. Suba fotos importantes pra você.',
      editText: 'Você já adicionou fotos!',
      removeButtonText: 'Remover fotos',
      buttonText: 'Subir fotos',
      modalComponent: StoryPhotosModal,
      isEdit: !!worker?.story_pictures?.length,
    },
    [WorkerStoryTypeEnum.TEXT]: {
      title: 'Texto',
      emptyText: 'Escreva sobre você',
      editText: 'Você já adicionou texto!',
      removeButtonText: 'Remover texto',
      buttonText: 'Escrever',
      modalComponent: StoryTextModal,
      isEdit: !!worker?.bio,
    },
  };

  useEffect(() => {
    if (
      !worker?.bio &&
      !worker?.video_bio_path &&
      !worker?.story_pictures?.length
    ) {
      openModal({
        component: StoryModal,
      });
    }

    workersService.getInspirationalStories().then(setStories);
  }, []);

  const RenderSection = ({ type }) => {
    const data = textsData[type] || {};
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);

    const toggleConfirmOpen = () => {
      setIsConfirmOpen(!isConfirmOpen);
    };

    const deleteData = () => {
      handledAsync(async () => {
        let payload: Partial<IWorker>;
        if (type === WorkerStoryTypeEnum.VIDEO) {
          payload = {
            video_bio_path: '',
          };
        } else if (type === WorkerStoryTypeEnum.PHOTOS) {
          payload = {
            story_pictures: [],
          };
        } else {
          payload = {
            bio: '',
          };
        }
        await meService.patchByProfile(ProfileEnum.WORKER, payload);
        setWorker({ ...worker, ...payload } as IWorker);
      });
    };

    return (
      <>
        <FlexContainer
          padding={`${responsiveSize(4)} 0 0 0`}
          direction="row"
          flex1={false}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography fontSize="menu" lineHeight="120%" display="block">
            {data.title}
          </Typography>
          <FlexContainer
            padding="0"
            width="auto"
            direction="column"
            alignItems="flex-end"
          >
            <LinkTypography
              as="button"
              color="primary"
              onClick={() => {
                openModal({
                  component: data.modalComponent || StoryModal,
                });
              }}
            >
              {data.isEdit ? 'Editar' : data.buttonText}
            </LinkTypography>

            <LinkTypography
              as="button"
              color="primary"
              onClick={() => {
                setIsConfirmOpen(true);
              }}
            >
              <CustomActionSheet
                items={[
                  {
                    label: 'Deseja realmente remover?',
                    isTitle: true,
                  },
                  {
                    label: 'Não, voltar',
                  },
                  {
                    label: `Sim, remover`,
                    color: 'primary',
                    onClick: deleteData,
                  },
                ]}
                open={isConfirmOpen}
                onClose={toggleConfirmOpen}
              />
              {data.isEdit ? data.removeButtonText : ''}
            </LinkTypography>
          </FlexContainer>
        </FlexContainer>

        <Typography
          as="p"
          display="block"
          style={{ marginTop: responsiveSize(1), width: '65%' }}
        >
          {data.isEdit ? data.editText : data.emptyText}
        </Typography>
      </>
    );
  };

  return (
    <FlexContainer flex1>
      <Header title="Sua história" />
      <Typography as="h3" fontSize="body1" lineHeight="120%">
        Conte sua história por vídeo, texto ou imagens.
      </Typography>
      <Spacing size={3} />
      <RenderSection type={WorkerStoryTypeEnum.VIDEO} />
      <RenderSection type={WorkerStoryTypeEnum.PHOTOS} />
      <RenderSection type={WorkerStoryTypeEnum.TEXT} />
      <Spacing size={8} />

      {stories.length > 0 && (
        <>
          <Typography fontSize="menu" lineHeight="120%" display="block">
            Inspire-se
          </Typography>
          <Typography
            as="p"
            display="block"
            style={{ marginTop: responsiveSize(1) }}
          >
            Veja as histórias de alguns prestadores
          </Typography>
          <Spacing size={4} />
          <SimpleCarousel firstItemMargin>
            {stories.map((story) => (
              <InspiratingStoryWrapper
                key={story.uid}
                to={`/perfil-prestador/${story.uid}`}
              >
                <InspiratingStory>
                  <Avatar image={story.picture_path} size="lg" hideStatus />
                  <Typography
                    fontSize="body1"
                    fontWeight="bold"
                    lineHeight="120%"
                  >
                    {story.name}
                  </Typography>
                  <Typography>
                    Prestador desde {story.created_at?.split('-')[0]}
                  </Typography>
                </InspiratingStory>
              </InspiratingStoryWrapper>
            ))}
          </SimpleCarousel>
        </>
      )}
    </FlexContainer>
  );
};

export default YourStory;
