import { Button } from 'components/atoms/button';
import InputCellNumber from 'components/atoms/input-cell-number';
import { useAsync } from 'context/async-context';
import { useModalStepsContext } from 'context/modal-steps-context';
import { useState } from 'react';
import { forgotPasswordService } from 'services/forgot-password-service';
import { Typography } from 'styles/typography';

const PhoneNumber = () => {
  const [inputValue, setInputValue] = useState('');
  const { replaceData, nextStep } = useModalStepsContext();
  const { handledAsync } = useAsync();

  const btnClicked = async () => {
    handledAsync(async () => {
      const cellphone = inputValue.replace(/\D/g, '');
      await forgotPasswordService.sendCode(cellphone);
      replaceData({ cellphone });
      nextStep();
    });
  };

  return (
    <>
      <Typography
        as="h3"
        color="title"
        fontSize="heading3"
        fontWeight="bold"
        lineHeight="48px"
        display="block"
        letterSpacing="-0.01em"
        margin="0 0 51px"
      >
        Digite o número de seu celular
      </Typography>
      <InputCellNumber
        placeholder="Digite seu celular"
        onChange={setInputValue}
      />
      <Button disabled={!inputValue} onClick={btnClicked}>
        Continuar
      </Button>
    </>
  );
};

export default PhoneNumber;
