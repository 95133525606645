import styled from 'styled-components';
import { responsiveSize } from 'styles/sizes';
import { LinkTypography } from 'styles/typography';

export const InfoContainer = styled.div`
  padding-bottom: ${responsiveSize(3)};
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding-bottom: ${responsiveSize(1)};
`;

export const HelperIconWrapper = styled.div`
  margin-right: auto;
  margin-left: ${responsiveSize(1)};
`;

export const ActionLink = styled(LinkTypography).attrs((props) => ({
  color: 'primary',
  ...props,
}))``;
