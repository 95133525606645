import { EditProfileRouteEnum, IEditFieldInfoCollection } from './types';

export const allFields: IEditFieldInfoCollection = {
  [EditProfileRouteEnum.NAME]: {
    heading: 'Qual é seu nome?',
    fieldKey: 'name',
    placeholder: 'Digite seu nome completo',
    buttonText: 'Salvar nome',
  },
  [EditProfileRouteEnum.EMAIL]: {
    heading: 'Qual é seu e-mail?',
    fieldKey: 'email',
    placeholder: 'Digite seu e-mail',
    buttonText: 'Salvar e-mail',
  },
  [EditProfileRouteEnum.BIRTH_DATE]: {
    heading: 'Quando você nasceu?',
    fieldKey: 'birthday',
    placeholder: 'DD/MM/YYYY',
    buttonText: 'Salvar data de nascimento',
  },
  [EditProfileRouteEnum.DOCUMENT_NUMBER]: {
    heading: 'Qual o seu CPF ou CNPJ?',
    fieldKey: 'document_number',
    buttonText: 'Salvar documento',
  },
  [EditProfileRouteEnum.ADDRESS]: {
    heading: 'Qual o seu CEP?',
    fieldKey: 'location',
    placeholder: '00000-000',
    buttonText: 'Salvar endereço',
  },
  [EditProfileRouteEnum.GENDER]: {
    heading: 'Qual é o seu gênero?',
    fieldKey: 'gender',
    placeholder: 'Selecione seu gênero',
    buttonText: 'Salvar gênero',
  },
};
