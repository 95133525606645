import Comment from 'components/molecules/comment';
import { IComment } from 'models/comment';
import { CommentListContainer } from './styled';

interface ICommentList {
  isWorker?: boolean;
  items: IComment[];
}

const CommentList: React.FC<ICommentList> = ({ items, isWorker }) => {
  return (
    <CommentListContainer>
      {items.map((comment) => (
        <Comment key={comment.id} isWorker={isWorker} item={comment} />
      ))}
    </CommentListContainer>
  );
};

export default CommentList;
