import styled from 'styled-components';
import { colors } from 'styles/colors';

export const InputContainer = styled.div`
  position: relative;
`;

export const InputStyled = styled.input`
  width: 100%;
  border: 2px solid ${colors.text};
  padding: 20px 50px 17px 24px;
  color: ${colors.text};
  font-size: 20px;
  line-height: 24px;
  font-family: 'Acid Grotesk';
`;

export const ButtonPassword = styled.button`
  position: absolute;
  right: 20px;
  top: 23px;
  display: flex;
  align-items: center;
`;

export const IconEyeStyle = styled.img`
  width: 24px;
  height: 24px;
`;
