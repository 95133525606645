import NavTabs from 'components/molecules/nav-tabs';
import TitleHeader from 'components/molecules/title-header';
import { ProfileEnum } from 'enums/profile';
import { serviceOfferService } from 'services/service-offer-service';
import { IServiceOffer } from 'models/service-offer';
import React, { useState, useEffect, useCallback } from 'react';
import { FlexContainer } from 'styles/flex-container';
import { Spacing } from 'styles/spacing';
import { Typography } from 'styles/typography';
import { useAsync } from 'context/async-context';
import { useUserContext } from 'context/user-context';
import NoProfileTab from 'components/molecules/no-profile-tab';
import { responsiveSize } from 'styles/sizes';
import Skeleton from 'react-loading-skeleton';
import CardInProgress from './card-in-progress';

interface IServicesInProgress {
  accepted: IServiceOffer[];
  in_negociation: IServiceOffer[];
}

const InProgress = () => {
  const [activeTab, setActiveTab] = useState('' as ProfileEnum);
  const [services, setServices] = useState<IServicesInProgress>();
  const [isLoading, setIsLoading] = useState(false);

  const { handledAsync } = useAsync();
  const { user, loadUserData } = useUserContext();

  const userHasTabProfile = user?.profiles
    ?.map((item) => item.toString())
    .includes(activeTab);

  useEffect(() => {
    if (user) {
      setActiveTab(user.profiles[0]);
    }
  }, []);

  const getServicesData = useCallback(() => {
    if (userHasTabProfile) {
      handledAsync(
        async () => {
          setIsLoading(true);
          const tmpServices = await serviceOfferService
            .getInProgress(activeTab)
            .catch(() => undefined);
          setServices(tmpServices);
          setIsLoading(false);
        },
        undefined,
        { showLoading: false },
      );
    }
  }, [activeTab, userHasTabProfile]);

  useEffect(() => {
    getServicesData();
  }, [getServicesData]);

  return (
    <FlexContainer>
      <TitleHeader title="Em andamento" />
      <NavTabs
        tabs={[
          { value: ProfileEnum.CLIENT, label: 'Cliente' },
          { value: ProfileEnum.WORKER, label: 'Prestador' },
        ]}
        activeTab={activeTab}
        onTabChange={setActiveTab}
      />

      {!userHasTabProfile && (
        <NoProfileTab tab={activeTab} onProfileCreated={loadUserData} />
      )}

      {userHasTabProfile && (
        <>
          {isLoading ? (
            <FlexContainer padding="0" gap={responsiveSize(1.5)}>
              <Spacing size={6} />
              <Skeleton width={150} height={25} />
              <Skeleton width={250} height={20} />
              <Spacing size={3} />
              <Skeleton width={150} height={25} />
              <Skeleton width={250} height={20} />
            </FlexContainer>
          ) : (
            <>
              <Spacing size={7} />
              <Typography fontSize="body1" lineHeight="120%">
                Serviços contratados
              </Typography>
              <Typography align="left" padding="8px 0 0 0">
                {services?.accepted.length === 0 &&
                  'Você não possui serviços contratados.'}
              </Typography>
              <Spacing size={3} />

              {services?.accepted.map((service) => (
                <CardInProgress
                  key={service.uid}
                  service_offer={service}
                  userProfile={activeTab}
                  reloadAction={getServicesData}
                />
              ))}

              <Spacing size={3} />
              <Typography fontSize="body1" lineHeight="120%">
                Serviços em negociação
              </Typography>
              <Typography align="left" padding="8px 0 0 0">
                {services?.in_negociation.length === 0 &&
                  'Você não possui serviços em negociação.'}
              </Typography>
              <Spacing size={3} />
              {services?.in_negociation.map((service) => (
                <CardInProgress
                  key={service.uid}
                  service_offer={service}
                  userProfile={activeTab}
                  reloadAction={getServicesData}
                />
              ))}
            </>
          )}
        </>
      )}
    </FlexContainer>
  );
};

export default InProgress;
