import ImageUploading from 'react-images-uploading';
import { firebaseService } from 'services/firebase-service';
import { useAsync } from 'context/async-context';
import { meService } from 'services/me-service';
import { useUserContext } from 'context/user-context';
import React, { useState } from 'react';
import CustomActionSheet, { IActionSheetItem } from '../custom-action-sheet';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  hasPhoto?: boolean;
}
const AvatarUpload: React.FC<Props> = ({ isOpen, handleClose, hasPhoto }) => {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const [images, setImages] = useState<any[]>([]);
  const { handledAsync } = useAsync();
  const { patchUser } = useUserContext();

  const toggleConfirmOpen = () => {
    setIsConfirmOpen(!isConfirmOpen);
  };

  const deleteImg = () => {
    handledAsync(async () => {
      const user = await meService.patchBasicInfo({
        picture_path: '',
      });
      patchUser(user);
    });
  };

  const onUploadDB = (imgList) => {
    setImages(imgList);
    if (imgList[0]?.file && imgList.length > 0) {
      handledAsync(async () => {
        const imageUrl = await firebaseService.uploadFile(imgList[0].file);
        const user = await meService.patchBasicInfo({
          picture_path: imageUrl,
        });
        patchUser(user);
      });
    }
  };

  return (
    <>
      <ImageUploading
        value={images}
        onChange={onUploadDB}
        dataURLKey="data_url"
      >
        {({ onImageUpload }) => {
          const options: IActionSheetItem[] = [
            {
              label: 'Alterar foto de perfil',
              onClick: onImageUpload,
            },
          ];
          if (hasPhoto) {
            options.push({
              label: `Excluir foto de perfil`,
              color: 'primary',
              onClick: toggleConfirmOpen,
            });
          }
          return (
            <CustomActionSheet
              items={options}
              open={isOpen}
              onClose={handleClose}
            />
          );
        }}
      </ImageUploading>

      <CustomActionSheet
        items={[
          {
            label: 'Deseja excluir a foto de perfil?',
            isTitle: true,
          },
          {
            label: 'Não, voltar',
          },
          {
            label: `Sim, excluir`,
            color: 'primary',
            onClick: deleteImg,
          },
        ]}
        open={isConfirmOpen}
        onClose={toggleConfirmOpen}
      />
    </>
  );
};

export default AvatarUpload;
