import SkillsPage from 'components/templates/skills-page';
import { useAlert } from 'context/alert-context';
import { useAsync } from 'context/async-context';
import { useStepsContext } from 'context/steps-context';
import { ILead } from 'models/lead';
import { IWorker } from 'models/user';
import { useRouteMatch } from 'react-router-dom';
import { meService } from 'services/me-service';

const RegisterSkills = () => {
  const { nextStep, getData, patchData } = useStepsContext();
  const { path } = useRouteMatch();
  const { handledAsync } = useAsync();
  const { showToast } = useAlert();

  const isCreateWorkerProfile = path.includes('/criar-perfil-prestador');
  const isCreateFirstWorkerProfile = path.includes('/cadastro-prestador');

  const btnClicked = (skillsIds?: number[]) => {
    if (isCreateWorkerProfile && skillsIds?.length) {
      handledAsync(async () => {
        const savedData = getData<IWorker>();
        await meService.createWorkerProfile({
          location: savedData.location,
          gender: savedData.gender,
          skills: skillsIds,
        });
        showToast({
          text: 'Perfil criado com sucesso!',
        });
        nextStep();
      });
    } else {
      handledAsync(async () => {
        await patchData<ILead>({ skills: skillsIds }, true);
        nextStep();
      });
    }
  };

  return (
    <SkillsPage
      onSuccess={btnClicked}
      patchOnServer={
        isCreateFirstWorkerProfile ? false : !isCreateWorkerProfile
      }
      buttonText={isCreateWorkerProfile ? 'Finalizar' : undefined}
      hideButtonChevron={isCreateWorkerProfile}
    />
  );
};

export default RegisterSkills;
