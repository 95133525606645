import { Button } from 'components/atoms/button';
import { useModalStepsContext } from 'context/modal-steps-context';
import React from 'react';
import { Spacing } from 'styles/spacing';
import { Typography } from 'styles/typography';
import BannerImg from 'assets/img/avaliacao.png';
import Image from 'components/atoms/image';

const Success = () => {
  const { closeModal } = useModalStepsContext();
  return (
    <>
      <Image src={BannerImg} />
      <Spacing size={4} />
      <Typography
        fontSize="heading3"
        fontWeight="bold"
        lineHeight="150%"
        letterSpacing="-0.01em"
      >
        Obrigado pela contribuição!
      </Typography>
      <Spacing size={4} />
      <Typography fontSize="body2" lineHeight="150%">
        Avaliando os nossos prestadores, você ajuda a melhorar a qualidade dos
        profissionais no App.
      </Typography>
      <Button onClick={closeModal} disableChevron>
        Finalizar
      </Button>
    </>
  );
};

export default Success;
