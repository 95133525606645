/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Button } from 'components/atoms/button';
import InputPassword from 'components/atoms/input-password';
import { useAlert } from 'context/alert-context';
import { useAsync } from 'context/async-context';
import { useModalStepsContext } from 'context/modal-steps-context';
import { useUserContext } from 'context/user-context';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { forgotPasswordService } from 'services/forgot-password-service';
import { Typography } from 'styles/typography';
import { IForgotPasswordModel } from './types';

const NewPassword = () => {
  const [inputValue, setInputValue] = useState('');
  const { nextStep, getData } = useModalStepsContext();
  const { login } = useUserContext();
  const { showToast } = useAlert();
  const { handledAsync } = useAsync();
  const history = useHistory();

  const { cellphone, code } = getData<IForgotPasswordModel>();

  const handleBtnClicked = () => {
    handledAsync(async () => {
      await forgotPasswordService.validateCodeAndChangePassword(
        cellphone,
        code,
        inputValue,
      );
      await login(cellphone, inputValue);

      showToast({
        text: 'Senha alterada',
      });
      nextStep();
      history.push('/t/servicos');
    });
  };

  return (
    <>
      <Typography
        as="h3"
        color="title"
        fontSize="heading3"
        fontWeight="bold"
        display="block"
        letterSpacing="-0.01em"
        margin="0 0 45px"
      >
        Digite sua nova senha
      </Typography>
      <InputPassword onChange={setInputValue} />
      <Button disabled={!inputValue} onClick={handleBtnClicked} disableChevron>
        Finalizar
      </Button>
    </>
  );
};

export default NewPassword;
