import React, { FC } from 'react';
import NotFound from 'assets/img/not-found.png';
import Toolbar from 'components/molecules/toolbar';
import Image from 'components/atoms/image';
import { FlexContainer, ScrollView } from 'styles/flex-container';
import { Spacing } from 'styles/spacing';
import { LinkTypography, Typography } from 'styles/typography';
import { TabContainer } from './styled';

interface Props {
  hideToolbar?: boolean;
}

const GenericNotFound: FC<Props> = ({ hideToolbar }) => {
  return (
    <>
      <FlexContainer padding="0">
        <Spacing size={6} />
        <TabContainer>
          <Image src={NotFound} />
        </TabContainer>

        <FlexContainer width="90%">
          <ScrollView>
            <Typography
              fontWeight="bold"
              fontSize="heading3"
              letterSpacing="-0.01em"
            >
              Não deu para abrir essa página
            </Typography>
            <Spacing size={3} />
            <Typography fontSize="body1" lineHeight="120%">
              Confira se sua internet está funcionando
            </Typography>
            <Spacing size={3} />
            <LinkTypography
              as="button"
              fontSize="body1"
              color="primary"
              onClick={() => {
                window.location.reload(true);
              }}
            >
              Tentar de novo
            </LinkTypography>
          </ScrollView>
        </FlexContainer>
        {!hideToolbar && <Toolbar />}
      </FlexContainer>
    </>
  );
};

export default GenericNotFound;
