import Image from 'components/atoms/image';
import styled from 'styled-components';

export const WorkerProfileContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 35px;
`;

export const ProfileIcon = styled(Image).attrs((props) => ({
  ...props,
  height: 128,
  skeletonProps: { width: 128, height: 128, circle: true },
}))`
  width: 128px;
  height: 128px;
  border-radius: 50%;
  margin-left: -50px;
  cursor: pointer;
`;

export const RightCol = styled.div`
  margin-left: 14px;
  flex: 1;
`;
