import React, { useState, useRef, useEffect } from 'react';

import { Typography } from 'styles/typography';
import { IWorkerListItem } from 'models/user';
import PersonSlider from 'components/molecules/person-slider';
import PersonCard from 'components/molecules/person-card';
import {
  Container,
  ShowMoreButton,
  AccordionContent,
  PersonItem,
} from './styled';

interface CompatibleWorkersListProps {
  data: IWorkerListItem[];
}

const CompatibleWorkersList: React.FC<CompatibleWorkersListProps> = ({
  data,
}) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [accordionContentHeight, setAccordionContentHeight] = useState(0);
  const [sliderIndex, setSliderIndex] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      contentRef.current?.clientHeight &&
      contentRef.current.clientHeight > 0
    ) {
      setAccordionContentHeight(contentRef.current?.clientHeight);
    }
  }, [isAccordionOpen]);

  if (!data.length) return <></>;

  const hasManyWorkers = data.length > 1;

  const toggleAccordion = () => {
    setIsAccordionOpen((currState) => !currState);
  };

  return (
    <Container
      hasManyWorkers={hasManyWorkers}
      ref={containerRef}
      contentHeight={accordionContentHeight}
      isOpen={isAccordionOpen}
    >
      <Typography as="p" fontSize="body1">
        Compatíveis com seu serviço:
      </Typography>

      <PersonSlider
        isAccordionOpen={isAccordionOpen}
        data={data}
        onChange={setSliderIndex}
      />

      <AccordionContent ref={contentRef} isOpen={isAccordionOpen}>
        {data
          .filter((_, index) => index !== sliderIndex)
          .map((eachPerson) => (
            <PersonItem
              key={eachPerson.uid}
              to={`/perfil-prestador/${eachPerson.uid}`}
            >
              <PersonCard
                image={eachPerson.picture_path}
                online={eachPerson.online}
                name={eachPerson.name}
                assessment={eachPerson.assessment}
                skills={eachPerson.skills}
              />
            </PersonItem>
          ))}
      </AccordionContent>

      {hasManyWorkers && (
        <ShowMoreButton onClick={toggleAccordion}>
          {isAccordionOpen ? 'Mostrar menos' : 'Mostrar mais'}
        </ShowMoreButton>
      )}
    </Container>
  );
};

export default CompatibleWorkersList;
