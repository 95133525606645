import ProblemaResolvidoImg from 'assets/img/seu-problema-resolvido.png';
import VoceDecideValorImg from 'assets/img/voce-decide-valor.png';
import SemTaxasImg from 'assets/img/sem-taxas.png';
import DinheiroTodoSeuImg from 'assets/img/dinheiro-todo-seu.png';
import TrabalheQuandoQuiserImg from 'assets/img/trabalhe-quando-quiser.png';
import { IconReturn } from 'styles/icons';
import { ProfileEnum } from 'enums/profile';
import { colors } from 'styles/colors';
import { Button } from 'components/atoms/button';
import { FlexContainer } from 'styles/flex-container';
import { responsiveSize } from 'styles/sizes';
import { Typography } from 'styles/typography';
import BenefitsList from 'components/molecules/benefits-list';

import { CreateAccountContainer, IconReturnContainer } from './styled';

const customerBenefits = [
  { text: 'O Tô Parado é 100% grátis.', image: SemTaxasImg },
  {
    text: 'Você decide quanto quer pagar por serviço.',
    image: VoceDecideValorImg,
  },
  {
    text: 'Tenha acesso à todos os prestadores cadastrados.',
    image: ProblemaResolvidoImg,
  },
];

const workerBenefits = [
  {
    text: 'Você fica com todo o dinheiro dos serviços.',
    image: DinheiroTodoSeuImg,
  },
  {
    text: 'Cobre o valor que quiser.',
    image: VoceDecideValorImg,
  },
  {
    text: 'Combine com os clientes e trabalhe quando quiser.',
    image: TrabalheQuandoQuiserImg,
  },
];

interface AccountBenefitsProps {
  onNext: () => void;
  onPrev: () => void;
  type: ProfileEnum;
}

const AccountBenefits = ({ onNext, onPrev, type }: AccountBenefitsProps) => {
  const isCustomer = type === ProfileEnum.CLIENT;

  return (
    <FlexContainer darkMode fullPageHeight>
      <CreateAccountContainer>
        <IconReturnContainer onClick={onPrev}>
          <IconReturn width={24} fill={colors.white} />
        </IconReturnContainer>

        <Typography
          as="h3"
          color="white"
          fontSize="heading3"
          fontWeight="bold"
          letterSpacing="-0.01em"
          margin={`${responsiveSize(4)} 0`}
          display="block"
        >
          {`Vantagens da conta como ${isCustomer ? 'cliente' : 'prestador'}`}
        </Typography>

        <BenefitsList data={isCustomer ? customerBenefits : workerBenefits} />

        <Button justifyContent="center" disableChevron onClick={onNext}>
          Criar conta
        </Button>
      </CreateAccountContainer>
    </FlexContainer>
  );
};

export default AccountBenefits;
