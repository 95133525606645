export const currencyFormatted = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

export const capitalizeText = (text = '') => {
  return text
    .split(' ')
    .map((sub) => sub.charAt(0).toUpperCase() + sub.toLowerCase().substring(1))
    .join(' ');
};

export const capitalizeFirstChar = (text = '') => {
  return (text || '').charAt(0).toUpperCase() + (text || '').slice(1);
};

export const getFirstName = (name = '') => {
  return (name || '').split(' ')[0];
};
/**
 *
 * @param dateStr a date string in format YYYY-MM-DD
 * @returns a date string in format DD/MM/YYYY
 */
export const formatDateToView = (dateStr = '') => {
  const [initYear, initMonth, initDay] =
    dateStr?.split('T')[0]?.split('-') || [];
  return dateStr ? `${initDay}/${initMonth}/${initYear}` : '';
};

/**
 *
 * @param dateStr a date string in format DD/MM/YYYY
 * @returns a date string in format YYYY-MM-DD
 */
export const formatDateToServer = (dateStr = '') => {
  const [day, month, year] = dateStr.split('/');
  return `${year}-${month}-${day}`;
};

export const normalizeText = (text: string, toLowerCase = true) => {
  const normalized = (text || '')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
  return toLowerCase ? normalized.toLowerCase() : normalized;
};
