import { useState } from 'react';
import { Typography } from 'styles/typography';
import InputCellNumber from 'components/atoms/input-cell-number';
import { Button } from 'components/atoms/button';
import { useStepsContext } from 'context/steps-context';
import { ILead } from 'models/lead';

const LoginPhone = () => {
  const [inputValue, setInputValue] = useState('');
  const { getData, replaceData, nextStep } = useStepsContext();

  const savedData = getData<ILead>();

  const btnClicked = async () => {
    const cellphone = inputValue.replace(/\D/g, '');
    replaceData<ILead>({ cellphone });
    nextStep();
  };

  return (
    <>
      <Typography
        as="h3"
        color="title"
        fontSize="heading3"
        fontWeight="bold"
        lineHeight="48px"
        display="block"
        letterSpacing="-0.01em"
        margin="0 0 51px"
      >
        Digite o número de seu celular
      </Typography>
      <InputCellNumber
        placeholder="Digite seu celular"
        onChange={setInputValue}
        initialData={savedData?.cellphone}
      />
      <Button disabled={!inputValue} onClick={btnClicked}>
        Continuar
      </Button>
    </>
  );
};

export default LoginPhone;
