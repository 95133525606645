/* eslint-disable @typescript-eslint/no-explicit-any */
import TokenPage from 'components/templates/token-page';
import { useModalStepsContext } from 'context/modal-steps-context';
import { forgotPasswordService } from 'services/forgot-password-service';
import { IForgotPasswordModel } from './types';

const Token = () => {
  const { nextStep, patchData, getData } = useModalStepsContext();
  const { cellphone } = getData<IForgotPasswordModel>();

  const resendClicked = async () => {
    await forgotPasswordService.sendCode(cellphone);
  };

  const handleBtnClicked = (code: string) => {
    patchData({ code });
    nextStep();
  };

  return (
    <TokenPage
      buttonText="Finalizar"
      disableButtonChevron
      onConfirmClicked={handleBtnClicked}
      onResendClicked={resendClicked}
    />
  );
};

export default Token;
