import React, { useState } from 'react';

import { mask as masker, unMask } from 'remask';
import InputText, { IInputTextProps } from '../input-text';
import { InputContainer, CurrencyStyled } from './styled';

const InputCurrency: React.FC<IInputTextProps> = ({ onChange, ...rest }) => {
  const [value, setValue] = useState('');

  const validateCurrency = (event) => {
    const unMaskedValue = unMask(event);
    return unMaskedValue > 99;
  };

  const validateInput = (event) => {
    const isValid = validateCurrency(event);
    setValue(event);
    onChange(isValid ? event : '');
  };

  const handleChange = (inputValue: string) => {
    const orginalValue = unMask(inputValue);
    const maskedValue = masker(orginalValue, [
      ',9',
      ',99',
      '9,99',
      '99,99',
      '999,99',
      '9.999,99',
      '99.999,99',
      '999.999,99',
      '9.999.999,99',
    ]);
    validateInput(maskedValue);
  };

  return (
    <InputContainer>
      <InputText
        type="tel"
        {...rest}
        onChange={handleChange}
        value={value}
        inputExtraProps={{
          className: value.length > 0 ? 'input-onchange' : '',
        }}
      />
      <CurrencyStyled className={value.length > 0 ? 'show' : ''}>
        R$
      </CurrencyStyled>
    </InputContainer>
  );
};

export default InputCurrency;
