import { useState } from 'react';
import ProblemaResolvidoImg from 'assets/img/seu-problema-resolvido.png';
import VoceDecideValorImg from 'assets/img/voce-decide-valor.png';
import SemTaxasImg from 'assets/img/sem-taxas.png';
import DinheiroTodoSeuImg from 'assets/img/dinheiro-todo-seu.png';
import TrabalheQuandoQuiserImg from 'assets/img/trabalhe-quando-quiser.png';
import { FlexContainer } from 'styles/flex-container';
import CheckboxCard from 'components/molecules/checkbox-card';
import { Typography } from 'styles/typography';
import { responsiveSize } from 'styles/sizes';
import { Button } from 'components/atoms/button';
import { Modal, ModalTransition } from 'react-simple-hook-modal';
import { IconReturn } from 'styles/icons';
import BenefitsList from 'components/molecules/benefits-list';
import { useUserContext } from 'context/user-context';
import { ProfileEnum } from 'enums/profile';
import { colors } from 'styles/colors';
import { meService } from 'services/me-service';
import { useHistory } from 'react-router-dom';
import * as S from './styles';

const customerBenefits = [
  { text: 'O Tô Parado é 100% grátis.', image: SemTaxasImg },
  {
    text: 'Você decide quanto quer pagar por serviço.',
    image: VoceDecideValorImg,
  },
  {
    text: 'Tenha acesso à todos os prestadores cadastrados.',
    image: ProblemaResolvidoImg,
  },
];

const workerBenefits = [
  {
    text: 'Você fica com todo o dinheiro dos serviços.',
    image: DinheiroTodoSeuImg,
  },
  {
    text: 'Cobre o valor que quiser.',
    image: VoceDecideValorImg,
  },
  {
    text: 'Combine com os clientes e trabalhe quando quiser.',
    image: TrabalheQuandoQuiserImg,
  },
];

interface UpdateProfileRoleProps {
  shown: boolean;
}

const UpdateProfileRole = ({ shown }: UpdateProfileRoleProps) => {
  const [currentStep, setCurrentStep] = useState(1);
  const { loadUserData, user } = useUserContext();
  const history = useHistory();

  const [userRole, serUserRole] = useState<null | ProfileEnum>(null);

  const isCustomer = userRole === ProfileEnum.CLIENT;

  const handleChangeCheckbox = (type: 'client' | 'worker') => {
    serUserRole(type === 'client' ? ProfileEnum.CLIENT : ProfileEnum.WORKER);
  };

  const handleUpdatePreferredRole = async () => {
    if (!userRole) return;

    if (!user?.profiles.includes(userRole)) {
      if (userRole === ProfileEnum.CLIENT) {
        await meService.createClientProfile();
      } else {
        history.push('/criar-perfil-prestador');
      }
    }

    await meService.updatePreferredRole(userRole);
    await loadUserData();
  };

  return (
    <Modal
      id="update-profile-role"
      modalClassName="no-padding"
      isOpen={shown}
      transition={ModalTransition.BOTTOM_UP}
    >
      <FlexContainer darkMode fullPageHeight>
        {currentStep === 1 ? (
          <S.Container>
            <Typography
              as="h3"
              color="white"
              fontSize="heading3"
              fontWeight="bold"
              letterSpacing="-0.01em"
              margin={`${responsiveSize(4)} 0`}
              display="block"
            >
              Atualize seu perfil
            </Typography>
            <Typography
              fontSize="body1"
              fontWeight="bold"
              color="white"
              display="block"
            >
              O que você você é?
            </Typography>

            <S.CheckboxWrapper>
              <CheckboxCard
                isChecked={!!(userRole && userRole === ProfileEnum.CLIENT)}
                description="Quero contratar um prestador"
                title="Cliente"
                onChange={() => handleChangeCheckbox('client')}
              />

              <CheckboxCard
                isChecked={!!(userRole && userRole === ProfileEnum.WORKER)}
                description="Quero trabalhar"
                title="Prestador"
                onChange={() => handleChangeCheckbox('worker')}
              />
            </S.CheckboxWrapper>

            <Button onClick={() => setCurrentStep(2)} disabled={!userRole}>
              Vamos lá
            </Button>
          </S.Container>
        ) : (
          <S.Container>
            <S.IconReturnContainer onClick={() => setCurrentStep(1)}>
              <IconReturn width={24} fill={colors.white} />
            </S.IconReturnContainer>

            <Typography
              as="h3"
              color="white"
              fontSize="heading3"
              fontWeight="bold"
              letterSpacing="-0.01em"
              margin={`${responsiveSize(4)} 0`}
              display="block"
            >
              {`Vantagens da conta como ${
                isCustomer ? 'cliente' : 'prestador'
              }`}
            </Typography>

            <BenefitsList
              data={isCustomer ? customerBenefits : workerBenefits}
            />

            <Button
              justifyContent="center"
              disableChevron
              onClick={handleUpdatePreferredRole}
            >
              Ok, entendi
            </Button>
          </S.Container>
        )}
      </FlexContainer>
    </Modal>
  );
};

export default UpdateProfileRole;
