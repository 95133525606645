import styled from 'styled-components';
import { colors } from 'styles/colors';
import { fontSizes } from 'styles/typography';

export const InputContainer = styled.div`
  position: relative;

  svg {
    position: absolute;
    left: 20px;
    top: 20px;
  }
`;

export const InputStyled = styled.input`
  width: 100%;
  border: 2px solid ${colors.text};
  padding: 20px 50px 17px 60px;
  color: ${colors.text};
  font-size: ${fontSizes.body1};
  line-height: 120%;
  font-family: 'Acid Grotesk';

  &::placeholder {
    color: ${colors.subText};
  }
`;
