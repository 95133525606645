/* eslint-disable no-param-reassign */
import axios from 'axios';
import { IInspirationalStory, IWorkerListItem } from 'models/user';
import { firebaseService } from './firebase-service';

class WorkersService {
  async getInstance() {
    const userToken = await firebaseService.getUserToken();

    return axios.create({
      baseURL: process.env.REACT_APP_API_URL,

      headers: {
        Authorization: userToken
          ? `Bearer ${userToken}`
          : process.env.REACT_APP_API_TOKEN,
      },
    });
  }

  async getWorkers(
    offset: number,
    limit: number,
    filters?: string[],
    order?: string,
    lat?: number,
    lng?: number,
  ) {
    const filterskillsQuery = filters?.length ? `&skills_id=${filters}` : '';
    const orderQuery = order ? `&order=${order}` : '';
    const limitQuery = limit ? `limit=${limit}` : 'limit=15';
    const offsetQuery = offset ? `&offset=${offset}` : '';
    const geoLocationQuery = lat || lng ? `&lat=${lat}&lng=${lng}` : '';

    const instance = await this.getInstance();
    const workers = await instance
      .get<IWorkerListItem[]>(
        `/workers?${limitQuery}${filterskillsQuery}${orderQuery}${offsetQuery}${geoLocationQuery}`,
      )
      .then((res) =>
        res.data.map((worker) => {
          return {
            ...worker,
            assessment: worker.score
              ? `Nota ${worker.score}`
              : 'Sem avaliações',
          };
        }),
      );

    return workers;
  }

  async getFavoriteWorkers() {
    const workers = await (await this.getInstance())
      .get<IWorkerListItem[]>(`/workers?&filter=favorites`)
      .then((res) =>
        res.data.map((worker) => {
          return {
            ...worker,
            assessment: worker.score
              ? `Nota ${worker.score}`
              : 'Sem avaliações',
          };
        }),
      );

    return workers;
  }

  async getWorkersSearch(term: string) {
    const termQuery = `term=${term}`;

    return (await this.getInstance())
      .get<IWorkerListItem[]>(`/workers/search?${termQuery}`)
      .then((res) => res.data);
  }

  async getRelatedWorkers() {
    const services = await (await this.getInstance())
      .get<IWorkerListItem[]>('/workers/related')
      .then((rest) => rest.data);
    return services;
  }

  async getWorkerByUid(uid: string) {
    return (await this.getInstance())
      .get<IWorkerListItem>(`/workers/${uid}`)
      .then((res) => res.data);
  }

  async getWorkerEvaluationsByUid(uid: string) {
    return (await this.getInstance())
      .get<any[]>(`/workers/${uid}/evaluations`)
      .then((res) => res.data);
  }

  async getInspirationalStories() {
    return (await this.getInstance())
      .get<IInspirationalStory[]>(`/workers/inspirational-stories`)
      .then((res) => res.data);
  }
}

export const workersService = new WorkersService();
