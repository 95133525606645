import PersonCard from 'components/molecules/person-card';
import CompatibleWorkersList from 'components/organisms/compatible-workers-list';
import PersonListSkeleton from 'components/skeletons/person-list';
import { IWorkerListItem } from 'models/user';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { Typography } from 'styles/typography';
import { ListItem } from './styled';

interface IWorkersListProps {
  workers: IWorkerListItem[];
  compatibleWorkers: IWorkerListItem[];
  canEdit?: boolean;
  loadMore?: () => void;
  loading?: boolean;
  hasNextPage?: boolean;
}

const WorkersList: React.FC<IWorkersListProps> = ({
  workers,
  compatibleWorkers,
  loadMore = () => null,
  loading = false,
  hasNextPage = false,
}) => {
  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: loadMore,
    disabled: false,
    rootMargin: '0px 0px 0px 0px',
  });

  return (
    <>
      <CompatibleWorkersList data={compatibleWorkers} />

      {!loading && !!workers.length && !!compatibleWorkers.length && (
        <Typography fontSize="body1" margin="40px 0 24px">
          Demais prestadores
        </Typography>
      )}

      {loading ? (
        <PersonListSkeleton textLines={2} />
      ) : (
        workers.map((person) => (
          <ListItem key={person.uid} to={`/perfil-prestador/${person.uid}`}>
            <PersonCard
              image={person.picture_path}
              online={person.online}
              name={person.name}
              assessment={person.assessment}
              skills={person.skills}
              city={person.location?.city}
            />
          </ListItem>
        ))
      )}

      {hasNextPage && loading === false && (
        <div ref={sentryRef}>
          <PersonListSkeleton textLines={2} />
        </div>
      )}
    </>
  );
};

export default WorkersList;
