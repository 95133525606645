import { ModalStepsContextProvider } from 'context/modal-steps-context';
import { IModalSteps } from 'context/modal-steps-context/types';
import { IModalDefaultProps } from 'modals/types';
import React from 'react';
import { Modal, ModalTransition } from 'react-simple-hook-modal';
import NewNumber from './new-number';
import Token from './token';

const ChangePhone: React.FC<IModalDefaultProps> = ({
  isModalOpen,
  onClose,
}) => {
  const steps: IModalSteps[] = [
    { component: <NewNumber /> },
    { component: <Token /> },
  ];

  return (
    <Modal
      id="change-phone-modal"
      modalClassName="no-padding"
      isOpen={isModalOpen}
      transition={ModalTransition.SCALE}
    >
      <ModalStepsContextProvider
        headerTitle="Alteração de telefone"
        closeModal={onClose}
        steps={steps}
      />
    </Modal>
  );
};

export default ChangePhone;
