import styled from 'styled-components';
import { colors } from 'styles/colors';

interface StyledCheckboxProps {
  isChecked?: boolean;
  isDisabled?: boolean;
}

export const Container = styled.div`
  display: flex;
  justify-content: center;
  background-color: #fff;
  padding: 26px 0;
  max-width: 148px;
  height: 148px;
  flex: 1;
  border-radius: 8px;
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: fit-content;
  align-items: center;
  max-width: 80px;

  h3 {
    font-size: 20px;
    font-weight: 700;
    color: ${colors.primary};
    margin-bottom: 10px;
  }

  span {
    color: #1d1d1f;
    font-size: 10px;
    font-weight: 600;
    text-align: center;
  }
`;

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 15px;
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const StyledCheckbox = styled.div<StyledCheckboxProps>`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  border: 3px solid
    ${({ isChecked }) => (isChecked ? colors.primary : colors.text)};
  cursor: pointer;
  border-radius: 50%;

  div {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: ${colors.primary};
    opacity: ${({ isChecked }) => (isChecked ? 1 : 0)};
    transition: all 0.2s ease-in;
  }
`;
