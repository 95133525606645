import styled, { css } from 'styled-components';

interface Props {
  overlay?: boolean;
}
export const LoaderStyled = styled.div<Props>`
  text-align: center;

  ${({ overlay }) =>
    overlay &&
    css`
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 101;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.6);
    `}
`;
