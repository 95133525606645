import { StepsContextProvider } from 'context/steps-context';
import { useUserContext } from 'context/user-context';
import { ProfileEnum } from 'enums/profile';
import Gender from 'pages/register/register-gender';
import RegisterSkills from 'pages/register/register-skills';
import RegisterZipCode from 'pages/register/register-zip-code';

const CreateWorkerProfile = () => {
  const { user, redirectRoute, setRedirectRoute } = useUserContext();
  const baseRoute = `/criar-perfil-prestador`;

  const allPages = [
    { url: `${baseRoute}/cep`, component: RegisterZipCode },
    { url: `${baseRoute}/genero`, component: Gender },
    {
      url: `${baseRoute}/habilidades`,
      component: RegisterSkills,
    },
  ];

  if (!user) {
    return null;
  }

  return (
    <StepsContextProvider
      routes={allPages}
      headerTitle="Cadastro prestador"
      successRoute={redirectRoute || `/t/perfil`}
      onFinish={() => setRedirectRoute('')}
      profile={ProfileEnum.WORKER}
      initialData={user}
    />
  );
};

export default CreateWorkerProfile;
