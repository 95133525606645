import styled from 'styled-components';
import { colors } from 'styles/colors';
import { Typography } from 'styles/typography';

export const CustomRadio = styled.span`
  border: 2px solid ${colors.text};
  min-width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  transition: all 0.2s;
  transition-delay: 0.2s;
`;

export const Bullet = styled.span`
  width: 0;
  height: 0;
  background: ${colors.white};
  transition-delay: 0.3s;
  transition: all 0.2s;
  border: 0;
`;

export const RadioLabel = styled(Typography)`
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;

export const RadioContainer = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

export const Radio = styled.input.attrs({ type: 'radio' })`
  display: none;
  &:checked + ${RadioLabel} ${CustomRadio} {
    background: ${colors.text};
    transition: all 0.2s;
  }

  &:checked + ${RadioLabel} ${Bullet} {
    transition: all 0.2s;
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
`;
