import Image1 from 'assets/img/onboarding-1.png';
import Image2 from 'assets/img/onboarding-2.png';
import Image3 from 'assets/img/onboarding-3.png';
import { Button } from 'components/atoms/button';
import SimpleSlider from 'components/molecules/simple-slider';
import { useAlert } from 'context/alert-context';
import { useLoader } from 'context/loader-context';
import { useModalPage } from 'context/modal-page-context';
import { useUserContext } from 'context/user-context';
import CreateAccount from 'modals/create-account';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FlexContainer } from 'styles/flex-container';
import { Spacing } from 'styles/spacing';
import { LinkTypography } from 'styles/typography';
import { CenterContent, AlertContainer } from './styled';

const sliderItems = [
  {
    image: Image1,
    title: 'O App que conecta',
    text: 'A gente ajuda prestadores de serviços e clientes a fechar negócio.',
  },
  {
    image: Image2,
    title: 'Uma renda extra',
    text: 'Seu trabalho é divulgado pra milhares de clientes.',
  },
  {
    image: Image3,
    title: 'Uma mão pra te ajudar',
    text: 'Encontre o prestador pro que você precisar.',
  },
];

const alertContent = () => (
  <AlertContainer>
    <h3>Usamos seus dados para melhorar sua experiência no site.</h3>
    <p>
      Ao usar o Tô Parado você aceita nossos termos de uso de cookies para
      criarmos conteúdos relevantes para você.
    </p>
    <a
      href="https://toparado.com.br/politica-de-privacidade-2/"
      target="_blank"
      rel="noreferrer"
    >
      Saiba mais
    </a>
  </AlertContainer>
);

const Onboarding = () => {
  const { showAlertModal } = useAlert();
  const { openModal } = useModalPage();
  const { user } = useUserContext();
  const { isLoading } = useLoader();

  const showNewAccount = !isLoading && !user;

  useEffect(() => {
    if (localStorage.getItem('acceptCookies') !== 'true') {
      showAlertModal({
        content: alertContent(),
        buttonTitle: 'Ok, aceito!',
        onButtonClicked: () => localStorage.setItem('acceptCookies', 'true'),
      });
    }
  }, []);

  return (
    <FlexContainer darkMode>
      <Spacing size={3} />
      <CenterContent>
        <SimpleSlider disableLightbox items={sliderItems} infinite={false} />
      </CenterContent>
      <FlexContainer
        padding="0"
        direction="row"
        justifyContent="space-between"
        flex1={false}
      >
        {!showNewAccount ? (
          <>
            <Link to="/t/servicos">
              <LinkTypography as="span" fontSize="body1" lineHeight="120%">
                Ver serviços
              </LinkTypography>
            </Link>
            <Link to="/t/prestadores">
              <LinkTypography as="span" fontSize="body1" lineHeight="120%">
                Ver prestadores
              </LinkTypography>
            </Link>
          </>
        ) : (
          <Link to="/t/servicos">
            <LinkTypography as="span" fontSize="body1" lineHeight="120%">
              Entrar como visitante
            </LinkTypography>
          </Link>
        )}
      </FlexContainer>
      <Spacing size={3} />
      {showNewAccount && (
        <Button
          disableChevron
          onClick={() => openModal({ component: CreateAccount })}
          bottomFixed={false}
        >
          Acessar
        </Button>
      )}
    </FlexContainer>
  );
};

export default Onboarding;
