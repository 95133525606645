import InputSearch from 'components/atoms/input-search';
import Header from 'components/molecules/header';
import { useAsync } from 'context/async-context';
import { normalizeText } from 'helpers';
import { IFaq } from 'models/faq';
import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';

import { useHistory } from 'react-router-dom';
import { faqService } from 'services/faq-service';
import { FlexContainer } from 'styles/flex-container';
import { IconChevronRight } from 'styles/icons';
import { Typography } from 'styles/typography';

import { QuestionWrapper, QuestionList } from './styled';

const Support = () => {
  const [allFaq, setAllFaq] = useState<IFaq[]>([]);
  const [filteredList, setFilteredList] = useState<IFaq[]>([]);
  const [inputValue, setInputValue] = useState('');

  const history = useHistory();
  const { handledAsync } = useAsync();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    handledAsync(
      async () => {
        const data = await faqService.getAll();
        setFilteredList(data);
        setAllFaq(data);
      },
      undefined,
      { showLoading: false },
    );
  };

  const handleSearchChange = (text: string) => {
    setInputValue(text);
    const search = normalizeText(text);

    if (search) {
      const list = allFaq.filter(
        (item) =>
          normalizeText(item.answer).includes(search) ||
          normalizeText(item.question).includes(search),
      );
      setFilteredList(list);
    } else {
      setFilteredList(allFaq);
    }
  };

  const isLoading = !allFaq?.length;

  return (
    <FlexContainer>
      <Header title="Suporte" />
      {isLoading ? (
        <Skeleton width="100%" height={60} />
      ) : (
        <InputSearch
          placeholder="Qual é a sua dúvida?"
          onChange={handleSearchChange}
          value={inputValue}
        />
      )}

      <QuestionList>
        {allFaq.length > 0 && !filteredList.length && (
          <Typography as="li" display="block" color="primary" fontSize="body2">
            Nenhum resultado encontrado.
          </Typography>
        )}
        {filteredList.map((item) => (
          <FlexContainer
            key={item.id}
            as="li"
            direction="row"
            alignItems="center"
            padding="0 0 30px 0"
            onClick={() => history.push(`/suporte/${item.id}`)}
          >
            <QuestionWrapper>
              <Typography display="block" as="p" padding="0 8px 0 0">
                {isLoading ? <Skeleton width="75%" /> : item.question}
              </Typography>
            </QuestionWrapper>
            <IconChevronRight />
          </FlexContainer>
        ))}
      </QuestionList>
    </FlexContainer>
  );
};

export default Support;
