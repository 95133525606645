/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { IFaq } from 'models/faq';
import { firebaseService } from './firebase-service';

class FaqService {
  private async getInstance() {
    const userToken = await firebaseService.getUserToken();
    return axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}/domains/faq`,
      headers: {
        Authorization: userToken
          ? `Bearer ${userToken}`
          : process.env.REACT_APP_API_TOKEN,
      },
    });
  }

  async getAll() {
    return (await this.getInstance())
      .get<IFaq[]>('/')
      .then((resp) => resp.data);
  }

  async getById(id: number) {
    return (await this.getInstance())
      .get<IFaq>(`/${id}`)
      .then((resp) => resp.data);
  }

  async saveLikeOrDislike(id: number, action: 'like' | 'dislike') {
    return (await this.getInstance())
      .put(`/${id}/${action}`)
      .then((resp) => resp.data);
  }
}

export const faqService = new FaqService();
