/* eslint-disable @typescript-eslint/no-explicit-any */
import AppliedFilter from 'components/atoms/applied-filter';
import NotificationButton from 'components/atoms/notification-button';
import WorkersList from 'components/organisms/workers-list';
import { useUserContext } from 'context/user-context';
import FiltersModal from 'modals/filters-modal';
import SearchModal from 'modals/search-modal';
import { ISkill } from 'models/skill';
import { IWorkerListItem } from 'models/user';
import { useEffect, useState } from 'react';
import { useModal } from 'react-simple-hook-modal';
import { workersService } from 'services/workers-service';
import { FlexContainer } from 'styles/flex-container';
import { IconFilter, IconSearch } from 'styles/icons';
import { Typography } from 'styles/typography';
import { ButtonFilter, FilterContainer, HeaderStyled } from './styled';

const sortOptions = [
  {
    label: 'Prestadores online',
    id: 'last_active:DESC',
  },
  {
    label: 'Favoritos',
    id: 'fav:',
  },
  {
    label: 'Nota mais alta',
    id: 'score:ASC',
  },
  {
    label: 'Mais serviços realizados',
    id: 'services_finished:DESC',
  },
];

const storageKey = '@to_parado/workers-filters';

const Workers = () => {
  const [showSearch, setShowSearch] = useState(false);
  const [workers, setWorkers] = useState<IWorkerListItem[]>([]);
  const [relatedWorkers, setRelatedWorkers] = useState<IWorkerListItem[]>([]);
  const [subcategories, setSubcategories] = useState<ISkill[]>([]);

  const [order, setOrder] = useState();

  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [hasError, setHasError] = useState(false);

  const { isModalOpen, openModal, closeModal } = useModal();
  const { getGeolocation, isClient } = useUserContext();

  const loadMore = async () => {
    try {
      setHasError(false);
      setLoading(true);
      const limit = 15;
      const offset = page * limit;

      const geoLocation = await getGeolocation();

      if (isClient) {
        workersService.getRelatedWorkers().then(setRelatedWorkers);
      }

      const nextPage = await workersService.getWorkers(
        offset,
        limit,
        subcategories.map((item) => `${item.skill_id}`),
        order,
        geoLocation?.lat,
        geoLocation?.lng,
      );

      setWorkers([...workers, ...nextPage]);
      setPage(page + 1);
      if (nextPage.length !== limit) setHasNextPage(false);
    } catch (err) {
      setHasError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setPage(0);
    setWorkers([]);
    setHasNextPage(true);
  }, [subcategories, order]);

  useEffect(() => {
    const initialSubs = JSON.parse(localStorage.getItem(storageKey) || '[]');
    setSubcategories(initialSubs);
  }, []);

  const filterClicked = () => {
    openModal();
  };

  const toggleSearch = () => {
    setShowSearch(!showSearch);
  };

  const persistFiltersInStorage = (items: ISkill[]) => {
    localStorage.setItem(storageKey, JSON.stringify(items));
  };

  const onRemoveFilter = (label: string) => {
    const newSubs = subcategories.filter((item) => item.skill !== label);
    setSubcategories(newSubs);
    persistFiltersInStorage(newSubs);
  };

  const labelFilters: any = [...subcategories.map((item) => item.skill)].filter(
    Boolean,
  );

  return (
    <FlexContainer>
      <HeaderStyled>
        <Typography as="span" fontSize="body2" fontWeight="bold">
          Prestadores num raio de 30km
        </Typography>
        <NotificationButton />
      </HeaderStyled>
      <FilterContainer>
        <Typography
          as="button"
          fontSize="heading3"
          fontWeight="bold"
          letterSpacing="-0.01em"
          onClick={toggleSearch}
        >
          Prestadores
        </Typography>
        <ButtonFilter>
          <IconSearch onClick={toggleSearch} />
          <IconFilter onClick={filterClicked} />
        </ButtonFilter>
      </FilterContainer>

      {labelFilters.length > 0 && (
        <FlexContainer direction="row" gap="8px" padding="0 0 24px 0">
          {labelFilters.map((item) => (
            <AppliedFilter key={item} label={item} onRemove={onRemoveFilter} />
          ))}
        </FlexContainer>
      )}

      {hasError ? (
        <Typography align="center">
          Ocorreu um erro ao buscar os prestadores. Tente novamente.
        </Typography>
      ) : (
        <>
          <WorkersList
            workers={workers.filter(
              (eachWorker) =>
                relatedWorkers.findIndex(
                  (eachCompatibleWorker) =>
                    eachCompatibleWorker.uid === eachWorker.uid,
                ) < 0,
            )}
            compatibleWorkers={relatedWorkers}
            loadMore={loadMore}
            loading={loading}
            hasNextPage={hasNextPage}
          />

          {!loading && !hasNextPage && !workers.length && (
            <Typography align="center">
              {labelFilters.length > 0 || order === 'fav:'
                ? 'Não encontramos resultados para os filtros aplicados.'
                : 'Não encontramos prestadores cadastrados na sua região.'}
            </Typography>
          )}
        </>
      )}

      <SearchModal
        defaultTitle="Que tipo de prestador você procura?"
        onClose={toggleSearch}
        onSelect={(items) => {
          setSubcategories(items);
          persistFiltersInStorage(items);
          toggleSearch();
        }}
        isModalOpen={showSearch}
        initialSubcategories={subcategories}
      />

      <FiltersModal
        onClose={closeModal}
        isModalOpen={isModalOpen}
        filtersOptions={[]}
        filters={[]}
        sortOptions={sortOptions}
        sort={order}
        onChange={({ sort: appliedSort }) => {
          setOrder(appliedSort);
        }}
      />
    </FlexContainer>
  );
};

export default Workers;
