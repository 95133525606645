import InputCurrency from 'components/atoms/input-currency';
import RadioButton from 'components/atoms/radio-button';
import { useAlert } from 'context/alert-context';
import React, { Fragment, useState } from 'react';
import { IconInterrogation } from 'styles/icons';
import { Typography } from 'styles/typography';
import { RadioItem, RadiolistContainer } from './styled';

export interface IRadioItem {
  id: string;
  label?: string;
  placeholder?: string;
  info?: string;
  tooltipText?: string;
  showInput?: boolean;
}
interface IRadioListProps {
  radioItems: IRadioItem[];
  onInputChange?: (value: string) => void;
  onChange: (id: string) => void;
  value?: string;
  inputInitialData?: string;
}

const RadioList: React.FC<IRadioListProps> = ({
  onInputChange = () => null,
  onChange,
  radioItems,
  value = '',
  inputInitialData = '',
}) => {
  const [selected, setSelected] = useState(value);
  const { showAlert } = useAlert();

  const tooltipClicked = (item: IRadioItem) => {
    showAlert({
      title: item.label,
      text: item.tooltipText || '',
    });
  };

  return (
    <RadiolistContainer>
      {radioItems.map((item) => (
        <RadioItem key={item.id}>
          <RadioButton
            key={item.id}
            label={item.label}
            id={item.id}
            checked={selected === item.id}
            onClick={() => {
              setSelected(item.id);
              onChange(item.id);
            }}
          />
          {selected === item.id && item.showInput && (
            <>
              <InputCurrency
                onChange={onInputChange}
                placeholder={item.placeholder}
                initialData={inputInitialData}
              />
              {item.info && (
                <Typography margin="7px 0 0">{item.info}</Typography>
              )}
            </>
          )}

          {item.tooltipText && (
            <Typography
              as="button"
              className="button-interrogation"
              onClick={() => {
                tooltipClicked(item);
              }}
            >
              <IconInterrogation />
            </Typography>
          )}
        </RadioItem>
      ))}
    </RadiolistContainer>
  );
};

export default RadioList;
