import React from 'react';
import InputText, { IInputTextProps } from '../input-text';

const InputName: React.FC<IInputTextProps> = ({ onChange, ...rest }) => {
  const handleChange = (value) => {
    const pattern = /^[a-zA-ZÀ-ÖØ-öø-ÿ ]+$/;
    const isValid = pattern.test(value);
    onChange(isValid ? value : '');
  };

  return <InputText onChange={handleChange} {...rest} />;
};

export default InputName;
