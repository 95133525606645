import { Button } from 'components/atoms/button/index';
import RadioList, { IRadioItem } from 'components/organisms/radio-list';
import { useStepsContext } from 'context/steps-context';
import { PaymentTypeEnum } from 'enums/payment-type';
import { IServiceOffer } from 'models/service-offer';
import { useState } from 'react';
import { Typography } from 'styles/typography';

const RadioListItems: IRadioItem[] = [
  {
    label: 'Diária',
    id: PaymentTypeEnum.DAILY,
    placeholder: 'Digite o valor para um dia',
    tooltipText:
      'Avise o cliente quantas diárias você vai precisar para concluir o serviço.',
    showInput: true,
  },
  {
    label: 'Meia diária',
    id: PaymentTypeEnum.HALF_DAILY,
    placeholder: 'Digite o valor de meia diária',
    showInput: true,
  },
  {
    label: 'Valor hora',
    id: PaymentTypeEnum.HOUR_VALUE,
    placeholder: 'Digite o valor hora',
    showInput: true,
  },
  {
    label: 'Valor total',
    id: PaymentTypeEnum.TOTAL_VALUE,
    placeholder: 'Digite o valor total',
    showInput: true,
  },
];

export const HowToReceive = () => {
  const [selected, setSelected] = useState<PaymentTypeEnum>();
  const [inputValue, setInputValue] = useState('');
  const { nextStep, patchData } = useStepsContext();

  const btnClicked = () => {
    const newValue = inputValue.replace(/\./g, '').replace(',', '.');

    patchData<IServiceOffer>({
      payment_type: selected,
      payment_value: parseFloat(newValue),
    });
    nextStep();
  };

  const radioClicked = (id) => {
    setSelected(id);
    setInputValue('');
  };

  return (
    <>
      <Typography
        as="h3"
        color="title"
        fontSize="heading3"
        fontWeight="bold"
        lineHeight="48px"
        display="block"
        letterSpacing="-0.01em"
        margin="0 0 41px"
      >
        Como gostaria de receber?
      </Typography>
      <RadioList
        onChange={radioClicked}
        onInputChange={setInputValue}
        radioItems={RadioListItems}
      />
      <Button disabled={!selected || !inputValue} onClick={btnClicked}>
        Avançar
      </Button>
    </>
  );
};
