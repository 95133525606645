import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';

export const ToolbarContainer = styled.div`
  border-top: 1px solid ${colors.gray};
  width: 100%;
  background: ${colors.white};
  padding: 10px 30px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

interface ITabProps {
  active?: 1 | 0;
}
export const TabLinkStyled = styled(Link)<ITabProps>`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  align-items: center;
  position: relative;

  ${({ active }) =>
    active
      ? css`
          svg path {
            stroke: ${colors.primary};
            fill: ${colors.primary};
          }
        `
      : css`
          svg path {
            fill: 'none';
            stroke: ${colors.text};
          }
        `}
`;

export const LabelStyled = styled.label`
  font-family: 'Acid Grotesk';
  font-size: 10px;
  line-height: 12px;
  color: ${colors.text};
  letter-spacing: 0.02em;
  display: block;
`;

export const NotificationBadge = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: ${colors.primary};
  top: 0;
  right: -3px;
`;
