/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { IServiceOffer, IServiceOfferInsert } from 'models/service-offer';
import { ProfileEnum } from 'enums/profile';
import { firebaseService } from './firebase-service';

class ServiceOfferService {
  async getInstance() {
    const userToken = await firebaseService.getUserToken();
    return axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}/service-offers`,
      headers: {
        Authorization: userToken
          ? `Bearer ${userToken}`
          : process.env.REACT_APP_API_TOKEN,
      },
    });
  }

  async createServiceOffer(data: IServiceOfferInsert) {
    return (await this.getInstance()).post('/', data).then((resp) => resp.data);
  }

  async getInProgress(profile: ProfileEnum) {
    const serviceOffers = await (await this.getInstance())
      .get<{ in_negociation: IServiceOffer[]; accepted: IServiceOffer[] }>(
        `/in-progress/${profile}`,
      )
      .then((res) => res.data);
    return serviceOffers;
  }

  async accept(uid) {
    const data = await (await this.getInstance())
      .put(`/${uid}/accept`)
      .then((res) => res.data);
    return data;
  }

  async decline(uid) {
    const data = await (await this.getInstance())
      .put(`/${uid}/decline`)
      .then((res) => res.data);
    return data;
  }

  async undoDecline(uid) {
    const data = await (await this.getInstance())
      .put(`/${uid}/decline/undo`)
      .then((res) => res.data);
    return data;
  }

  async cancelProposal(uid) {
    const data = await (await this.getInstance())
      .put(`/${uid}/cancel`)
      .then((res) => res.data);
    return data;
  }

  async undoCancel(uid) {
    const data = await (await this.getInstance())
      .put(`/${uid}/cancel/undo`)
      .then((res) => res.data);
    return data;
  }
}

export const serviceOfferService = new ServiceOfferService();
