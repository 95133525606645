import React from 'react';
import { NavStyled, NavLink } from './styled';

export interface INavTabItem {
  value: string;
  label: string;
}
interface Props {
  tabs: INavTabItem[];
  activeTab: string;
  onTabChange: (value) => void;
  noMarginTop?: boolean;
  contrast?: boolean;
}
const NavTabs: React.FC<Props> = ({
  tabs,
  activeTab,
  onTabChange,
  noMarginTop,
  contrast,
}) => {
  return (
    <NavStyled
      style={{ marginTop: noMarginTop ? 0 : undefined }}
      contrast={contrast}
    >
      {tabs.map((tab) => (
        <NavLink
          type="button"
          key={tab.value}
          onClick={() => onTabChange(tab.value)}
          active={activeTab === tab.value}
          contrast={contrast}
        >
          {tab.label}
        </NavLink>
      ))}
    </NavStyled>
  );
};

export default NavTabs;
