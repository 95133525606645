import styled from 'styled-components';
import { responsiveSize } from 'styles/sizes';
import { Typography } from 'styles/typography';

export const ClientDetailContainer = styled.div`
  ${Typography} {
    svg {
      margin-right: 8px;
    }
  }
`;

export const IconTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${responsiveSize(1)};
`;

export const PersonInfo = styled.div`
  padding-bottom: ${responsiveSize(4)};
  display: flex;
`;

export const PersonInfoRightArea = styled.div`
  padding-left: ${responsiveSize(1)};
  flex: 1;
`;

export const ServiceCard = styled.div`
  min-width: 80vw;
`;
