/* eslint-disable @typescript-eslint/no-explicit-any */
import Header from 'components/molecules/header';
import NavTabs from 'components/molecules/nav-tabs';
import { NotificationTypeEnum } from 'enums/notification-type';
import { ProfileEnum } from 'enums/profile';
import { colors } from 'styles/colors';
import { FlexContainer } from 'styles/flex-container';
import { IconChat, IconTip, IconTool } from 'styles/icons';
import { responsiveSize } from 'styles/sizes';
import { Typography } from 'styles/typography';
import React, { useState, useEffect, useRef } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { SectionLink, SectionTitle } from 'pages/profile/styled';
import { useAsync } from 'context/async-context';
import { INotification } from 'models/notification';
import { useUserContext } from 'context/user-context';
import { notificationService } from 'services/notification-service';
import { Link } from 'react-router-dom';
import NoProfileTab from 'components/molecules/no-profile-tab';
import { NotificationGroup, NotificationGroupHeader } from './styled';

const Notifications = () => {
  const [activeTab, setActiveTab] = useState(ProfileEnum.CLIENT);
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [hasNextPage, setHasNextPage] = useState(true);
  const { user, loadUserData } = useUserContext();
  const loading = useRef(false);
  const page = useRef(0);
  const limit = 15;

  const userHasTabProfile = user?.profiles
    ?.map((item) => item.toString())
    .includes(activeTab);

  const loadNotifications = async () => {
    loading.current = true;

    if (userHasTabProfile) {
      const res = await notificationService.getAll(
        activeTab,
        page.current * limit,
        limit,
      );
      setNotifications([...notifications, ...res]);
      if (res.length < limit) setHasNextPage(false);
    } else {
      setNotifications([]);
    }
    page.current += 1;
    loading.current = false;
  };

  const [sentryRef] = useInfiniteScroll({
    loading: loading.current,
    hasNextPage,
    onLoadMore: loadNotifications,
    disabled: false,
    rootMargin: '0px 0px 0px 0px',
  });

  const getSectionIconAndText = (type: string): any => {
    switch (type) {
      case NotificationTypeEnum.TIPS: {
        return {
          Icon: IconTip,
          title: 'Dica!',
        };
      }
      case NotificationTypeEnum.SERVICES: {
        return {
          Icon: IconTool,
          title: 'Serviços',
        };
      }
      case NotificationTypeEnum.CHAT: {
        return {
          Icon: IconChat,
          title: 'Conversas',
        };
      }
      default:
        return {};
    }
  };

  useEffect(() => {
    if (user) setActiveTab(user.profiles[0]);
  }, [user]);

  useEffect(() => {
    if (user) {
      setNotifications([]);
      page.current = 0;
      if (userHasTabProfile) setHasNextPage(true);
    }
  }, [activeTab]);

  return (
    <FlexContainer>
      <Header title="Notificações" />
      <NavTabs
        tabs={[
          { value: ProfileEnum.CLIENT, label: 'Cliente' },
          { value: ProfileEnum.WORKER, label: 'Prestador' },
        ]}
        activeTab={activeTab}
        onTabChange={setActiveTab}
        noMarginTop
      />
      {notifications.map((notification, index) => {
        const { Icon, title } = getSectionIconAndText(notification.type);

        return (
          <NotificationGroup key={notification.created_at}>
            {notifications[index - 1]?.type !== notification.type && (
              <NotificationGroupHeader>
                <Icon stroke={colors.primary} fill={colors.primary} />
                <Typography
                  fontSize="menu"
                  lineHeight="120%"
                  letterSpacing="0.02em"
                >
                  {title}
                </Typography>
              </NotificationGroupHeader>
            )}
            <Link style={{ textDecoration: 'none' }} to={notification.data.url}>
              <Typography
                display="block"
                margin={`${responsiveSize(3)} 0 0 0`}
                dangerouslySetInnerHTML={{
                  __html: notification.data.message,
                }}
              />
            </Link>
          </NotificationGroup>
        );
      })}
      {hasNextPage && <div ref={sentryRef} />}
      {!userHasTabProfile && (
        <NoProfileTab tab={activeTab} onProfileCreated={loadUserData} />
      )}
      {!hasNextPage && userHasTabProfile && !notifications.length && (
        <NotificationGroupHeader>
          Não encontramos nenhuma notificação para este perfil.
        </NotificationGroupHeader>
      )}
    </FlexContainer>
  );
};

export default Notifications;
