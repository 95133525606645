import React, { useState, useEffect } from 'react';
import Checkbox from 'components/atoms/checkbox';
import { IIdLabelOption } from 'models/id-label-option';
import { ChecklistContainer, ItemContainer } from './styled';

interface ICheckListProps {
  checkboxItems: IIdLabelOption[];
  onChange: (items: string[]) => void;
  checked?: string[];
}

const CheckList: React.FC<ICheckListProps> = ({
  checkboxItems,
  onChange,
  checked = [],
}) => {
  const [selected, setSelected] = useState(checked);

  useEffect(() => {
    onChange(selected);
  }, [selected]);

  const isItemSelected = (id: string) => {
    return selected?.includes(id);
  };

  const onItemClicked = (value: string) => {
    if (isItemSelected(value)) {
      setSelected(selected?.filter((item) => item !== value));
    } else {
      setSelected([...selected, value]);
    }
  };

  return (
    <ChecklistContainer>
      {checkboxItems.map((item) => (
        <ItemContainer key={item.id}>
          <Checkbox
            onChange={() => onItemClicked(item.id)}
            checked={isItemSelected(item.id)}
            label={item.label}
            id={item.id}
          />
        </ItemContainer>
      ))}
    </ChecklistContainer>
  );
};

export default CheckList;
