export enum EditProfileRouteEnum {
  NAME = 'nome',
  BIRTH_DATE = 'data-nascimento',
  DOCUMENT_NUMBER = 'cpf-cnpj',
  ADDRESS = 'endereco',
  GENDER = 'genero',
  EMAIL = 'email',
}

export interface IEditFieldInfo {
  fieldKey: string;
  heading: string;
  placeholder?: string;
  buttonText: string;
}

export interface IEditFieldInfoCollection {
  [key: string]: IEditFieldInfo;
}
