import Header from 'components/molecules/header';
import SkillsPage from 'components/templates/skills-page';
import { useAlert } from 'context/alert-context';
import { useUserContext } from 'context/user-context';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { FlexContainer } from 'styles/flex-container';

const EditSkills = () => {
  const { showToast } = useAlert();
  const history = useHistory();
  const { worker } = useUserContext();

  const onSuccess = () => {
    showToast({
      text: 'Perfil atualizado',
      onDismiss: () => history.goBack(),
    });
  };
  return (
    <FlexContainer>
      <Header title="Habilidades" />
      <SkillsPage
        onSuccess={onSuccess}
        hideButtonChevron
        previousSkills={worker?.skills?.map((skill) => skill.skill_id)}
      />
    </FlexContainer>
  );
};

export default EditSkills;
