import { Button } from 'components/atoms/button/index';
import CategorySelector from 'components/organisms/category-selector';
import { useAsync } from 'context/async-context';
import { ProfileEnum } from 'enums/profile';
import { ICategory, ISkill } from 'models/skill';
import { useState } from 'react';
import { meService } from 'services/me-service';
import { Typography } from 'styles/typography';

interface Props {
  onSuccess: (skillsIds?: number[]) => void;
  hideButtonChevron?: boolean;
  previousSkills?: number[];
  patchOnServer?: boolean;
  buttonText?: string;
}
const SkillsPage: React.FC<Props> = ({
  onSuccess,
  hideButtonChevron,
  previousSkills,
  patchOnServer = true,
  buttonText = 'Adicionar habilidades',
}) => {
  const [category, setCategory] = useState<ICategory>();
  const [subcategories, setSubcategories] = useState<ISkill[]>([]);
  const { handledAsync } = useAsync();

  const onFinishClicked = () => {
    const skillsIds = subcategories.map((skill) => skill.skill_id);
    if (patchOnServer) {
      handledAsync(async () => {
        await meService.patchByProfile(ProfileEnum.WORKER, {
          skills: skillsIds,
        });

        onSuccess(skillsIds);
      });
    } else {
      onSuccess(skillsIds);
    }
  };

  return (
    <>
      <Typography
        as="h3"
        color="title"
        fontSize="heading3"
        fontWeight="bold"
        lineHeight="48px"
        display="block"
        letterSpacing="-0.01em"
        margin="0 0 41px"
      >
        {category
          ? `Escolha suas habilidades em ${category.name.toLowerCase()}`
          : 'Selecione no que você topa trabalhar:'}
      </Typography>
      <CategorySelector
        onCategorySelected={setCategory}
        onSubcategorySelected={setSubcategories}
        showSearch={!category}
        initialSubcategories={previousSkills}
        multiple
      />
      <Button
        disabled={!subcategories.length}
        onClick={onFinishClicked}
        disableChevron={hideButtonChevron}
      >
        {buttonText}
      </Button>
    </>
  );
};

export default SkillsPage;
