import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';
import { responsiveSize } from 'styles/sizes';
import { fontSizes } from 'styles/typography';

interface NavLinkProps {
  active?: boolean;
  contrast?: boolean;
}

export const NavStyled = styled.nav<NavLinkProps>`
  margin-top: ${responsiveSize(4)};
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 100vw;
    height: 4px;
    background: ${({ contrast }) => (contrast ? colors.subText : colors.gray)};
    bottom: 0;
  }
`;

export const NavLink = styled.button<NavLinkProps>`
  border-bottom: none;
  color: ${colors.subText};
  z-index: 9;
  font-family: 'Acid Grotesk';
  font-size: ${fontSizes.body1};
  line-height: 120%;
  padding: 0 7px 19px 7px;

  ${({ active, contrast }) =>
    active &&
    css`
      color: ${contrast ? colors.white : colors.text};
      border-bottom: 4px solid ${contrast ? colors.white : colors.text};
    `}
`;
