import styled from 'styled-components';
import { colors } from 'styles/colors';

export const ToastStyled = styled.div`
  position: fixed;
  z-index: 100;
  bottom: 70px;
  left: 0;
  background-color: ${colors.text};
  min-height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
`;
