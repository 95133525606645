import React, { useState } from 'react';
import Skeleton, { SkeletonProps } from 'react-loading-skeleton';
import { ImgStyled, PictureStyled } from './styled';

interface Props {
  src?: string;
  alt?: string;
  width?: number | string;
  height?: number | string;
  className?: string;
  circle?: boolean;
  skeletonProps?: SkeletonProps;
  onClick?: () => void;
}
const Image: React.FC<Props> = ({
  src = '',
  alt = '',
  width,
  height,
  skeletonProps,
  className,
  circle,
  onClick = () => null,
  children,
}) => {
  const [loaded, setLoaded] = useState(false);
  return (
    <PictureStyled
      style={{
        width: width || undefined,
        height: height || undefined,
        display: height ? 'block' : undefined,
      }}
    >
      {!loaded && (
        <Skeleton
          className={className}
          width={width || '100%'}
          height={height || 240}
          circle={circle}
          {...skeletonProps}
        />
      )}
      {src && (
        <ImgStyled
          src={src}
          alt={alt}
          className={`${loaded ? 'loaded' : ''} ${className || ''}`}
          onLoad={() => setLoaded(true)}
          onError={() => setLoaded(true)}
          style={{ borderRadius: circle ? '50%' : undefined }}
          onClick={onClick}
        />
      )}
      {children}
    </PictureStyled>
  );
};

export default Image;
