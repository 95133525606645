import { Button } from 'components/atoms/button';
import { useModalStepsContext } from 'context/modal-steps-context';
import React, { Fragment, useState } from 'react';
import { FlexContainer } from 'styles/flex-container';
import { Spacing } from 'styles/spacing';
import { Typography } from 'styles/typography';
import { IconStar } from 'styles/icons';
import { colors } from 'styles/colors';
import { IRating } from 'models/rating';
import { useUserContext } from 'context/user-context';

const MAX_STARS = 5;

const RatingStars = () => {
  const [rating, setRating] = useState(0);
  const { nextStep, replaceData } = useModalStepsContext();
  const { user } = useUserContext();

  const btnClicked = () => {
    replaceData<IRating>({ stars: rating });
    nextStep();
  };

  return (
    <>
      <Typography
        fontSize="heading3"
        fontWeight="bold"
        lineHeight="150%"
        letterSpacing="-0.01em"
      >
        {user?.name}, o que você achou desse serviço?
      </Typography>
      <Spacing size={5} />
      <Typography fontSize="body2">Toque na estrela para avaliar.</Typography>
      <Spacing size={9} />
      <FlexContainer padding="0" direction="row">
        {Array(MAX_STARS)
          .fill(0)
          .map((_, index) => (
            <Fragment key={index}>
              <IconStar
                onClick={() => setRating(index + 1)}
                fill={rating >= index + 1 ? colors.text : undefined}
                strokeWidth={1}
                width={30}
                height={28}
              />
              {index + 1 < MAX_STARS && (
                <Spacing size={2} direction="vertical" />
              )}
            </Fragment>
          ))}
      </FlexContainer>
      <Button disabled={!rating} onClick={btnClicked}>
        Continuar
      </Button>
    </>
  );
};

export default RatingStars;
