import React, { useState } from 'react';
import IconEye from 'assets/img/eye.svg';
import IconEyeOff from 'assets/img/eye-off.svg';
import {
  InputContainer,
  InputStyled,
  ButtonPassword,
  IconEyeStyle,
} from './styled';

interface IInputPasswordProps {
  onChange: (value: string) => void;
  placeholder?: string;
  minLength?: number;
  maxLength?: number;
}

const InputPassword: React.FC<IInputPasswordProps> = ({
  onChange,
  placeholder,
  minLength = 6,
  maxLength = 12,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const onInputChange = (event) => {
    const value = event.target.value.slice(0, maxLength);
    const isValid = (value || '').length >= minLength;
    setInputValue(value);
    onChange(isValid ? value : '');
  };

  const buttonClicked = () => {
    setShowPassword(!showPassword);
  };

  return (
    <InputContainer>
      <InputStyled
        type={showPassword ? 'text' : 'password'}
        placeholder={placeholder}
        onChange={onInputChange}
        value={inputValue}
      />
      <ButtonPassword onClick={buttonClicked} type="button">
        {showPassword ? (
          <IconEyeStyle src={IconEyeOff} alt="ícone de esconder senha" />
        ) : (
          <IconEyeStyle src={IconEye} alt="ícone de revelar senha" />
        )}
      </ButtonPassword>
    </InputContainer>
  );
};

export default InputPassword;
