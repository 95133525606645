/* eslint-disable @typescript-eslint/no-explicit-any */
import Header from 'components/molecules/header';
import React, { createContext, useState } from 'react';
import { FlexContainer } from 'styles/flex-container';
import { IModalSteps } from './types';

type DefaultType = Record<string, unknown>;

interface ContextProps {
  getData: <T = DefaultType>() => T;
  patchData: <T = DefaultType>(data: Partial<T>) => Promise<void>;
  replaceData: <T = DefaultType>(data: Partial<T>) => void;
  nextStep: () => void;
  prevStep: () => void;
  closeModal: () => void;
}

const ModalStepsContext = createContext({} as ContextProps);
export const useModalStepsContext = () => React.useContext(ModalStepsContext);

interface ProviderProps {
  steps: IModalSteps[];
  headerTitle: string;
  closeModal: () => void;
  initialData?: any;
}
export const ModalStepsContextProvider: React.FC<ProviderProps> = ({
  steps,
  headerTitle,
  closeModal,
  initialData,
  children,
}) => {
  const [savedData, setSavedData] = useState<DefaultType>(initialData || {});
  const [activeIndex, setActiveIndex] = useState(0);

  const getData = <T,>() => {
    return savedData as T;
  };

  const replaceData = <T,>(data: Partial<T>) => {
    setSavedData(data as DefaultType);
  };

  const patchData = async <T,>(data: Partial<T>) => {
    const newData = { ...savedData, ...data };
    replaceData(newData);
  };

  const nextStep = () => {
    const next = activeIndex + 1;
    if (next < steps.length) {
      setActiveIndex(next);
    } else {
      closeModal();
    }
  };

  const prevStep = () => {
    if (activeIndex > 0) {
      setActiveIndex(activeIndex - 1);
    }
  };

  const activeStep = steps[activeIndex];

  return (
    <ModalStepsContext.Provider
      value={{
        getData,
        patchData,
        replaceData,
        nextStep,
        prevStep,
        closeModal,
      }}
    >
      <FlexContainer
        as="section"
        darkMode={activeStep?.contrastHeader}
        fullPageHeight
      >
        {!activeStep?.hideHeader && (
          <Header
            title={headerTitle}
            progress={((activeIndex + 1) / steps.length) * 100}
            contrast={activeStep?.contrastHeader}
            isModal
            onBack={activeIndex > 0 ? prevStep : undefined}
            onCloseModal={closeModal}
          />
        )}
        {activeStep?.component}
        {children}
      </FlexContainer>
    </ModalStepsContext.Provider>
  );
};
