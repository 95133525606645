import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from 'styles/colors';

export const ServiceDetailContainer = styled.div`
  .header {
    display: none;
  }
`;

export const FullSliderStyled = styled.div`
  position: relative;
  left: -30px;
  width: 100vw;
  overflow: hidden;
`;

export const BackButton = styled.button`
  transform: rotate(180deg);
  position: absolute;
  top: 13px;
  left: 13px;
  z-index: 3;
  width: 40px;
  height: 40px;
  background: ${colors.white};
`;

export const LinkStyled = styled(Link)`
  text-decoration: none;
  display: block;
`;
