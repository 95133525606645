import { IIdLabelOption } from 'models/id-label-option';

export enum GenderEnum {
  MALE = 'male',
  FEMALE = 'female',
  OTHER = 'other',
}

export const genderOptionList: IIdLabelOption[] = [
  {
    label: 'Feminino',
    id: GenderEnum.FEMALE,
  },
  {
    label: 'Masculino',
    id: GenderEnum.MALE,
  },
  {
    label: 'Outro',
    id: GenderEnum.OTHER,
  },
];

export const getGenderDesc = (value) => {
  return genderOptionList.find((item) => item.id === value)?.label || value;
};
