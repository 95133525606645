import ActionSheetMenu from 'components/molecules/action-sheet-menu';
import SimpleSlider from 'components/molecules/simple-slider';
import { useAsync } from 'context/async-context';
import { useAlert } from 'context/alert-context';
import { getPaymentTypeDesc } from 'enums/payment-type';
import { capitalizeFirstChar, currencyFormatted } from 'helpers';
import { IService } from 'models/service';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FlexContainer } from 'styles/flex-container';
import { Typography } from 'styles/typography';
import { serviceService } from 'services/service-service';
import Image from 'components/atoms/image';
import PlaceholderImg from 'assets/img/servico-placeholder.png';
import CustomActionSheet from '../custom-action-sheet';
import { ItemContainer, LinkStyled, StrongStyled } from './styled';

interface Props {
  item: IService;
  canEdit?: boolean;
  onRemove?: () => void;
  noCarousel?: boolean;
}

const ServiceItem: React.FC<Props> = ({
  item,
  canEdit,
  onRemove = () => null,
  noCarousel = false,
}) => {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const history = useHistory();
  const { handledAsync } = useAsync();
  const { showToast } = useAlert();

  const serviceLink = `/servico/${item.uid}`;

  const toggleConfirmOpen = () => {
    setIsConfirmOpen(!isConfirmOpen);
  };

  const deleteService = () => {
    handledAsync(async () => {
      await serviceService.deleteService(item.uid);
      onRemove();

      showToast({
        text: 'Desfazer essa ação?',
        rightAction: 'undo',
        onUndoClicked: () =>
          handledAsync(async () => {
            await serviceService.undoDeleteService(item.uid);
            onRemove();
          }),
      });
    });
  };

  return (
    <ItemContainer key={item.uid}>
      {!noCarousel && item?.pictures?.length ? (
        <SimpleSlider
          disableLightbox
          infinite={false}
          height={240}
          key={item.uid}
          items={item.pictures.map((pic) => {
            return { image: pic.path, title: '', text: '' };
          })}
          onItemClicked={() => history.push(serviceLink)}
        />
      ) : (
        <Image
          src={item?.pictures?.length ? item?.pictures[0].path : PlaceholderImg}
          height={240}
        />
      )}

      <LinkStyled to={serviceLink}>
        <Typography fontSize="body1" display="block" margin="7px 0 8px 0">
          {capitalizeFirstChar(item.sub_category.name)} ·{' '}
          {item.location?.district} / {item.location.city} <br />{' '}
          {item.description}
        </Typography>
      </LinkStyled>

      <FlexContainer padding="0" direction="row" justifyContent="space-between">
        <LinkStyled to={serviceLink}>
          <Typography fontSize="body1" display="block">
            {!item.payment_value ? (
              <StrongStyled>
                {getPaymentTypeDesc(item.payment_type)}
              </StrongStyled>
            ) : (
              <>
                <StrongStyled>
                  {currencyFormatted.format(item.payment_value)}
                </StrongStyled>
                {' / '}
                {getPaymentTypeDesc(item.payment_type)}
              </>
            )}
          </Typography>
        </LinkStyled>
        {canEdit && (
          <>
            <ActionSheetMenu
              items={[
                {
                  label: 'Editar serviço',
                  onClick: () =>
                    history.push(`/editar-servico`, {
                      uid: item.uid,
                    }),
                },
                {
                  label: `Excluir serviço`,
                  color: 'primary',
                  onClick: toggleConfirmOpen,
                },
              ]}
            />
            <CustomActionSheet
              items={[
                {
                  label: 'Deseja excluir esse serviço?',
                  isTitle: true,
                },
                {
                  label: 'Não, voltar',
                },
                {
                  label: `Sim, excluir`,
                  color: 'primary',
                  onClick: deleteService,
                },
              ]}
              open={isConfirmOpen}
              onClose={toggleConfirmOpen}
            />
          </>
        )}
      </FlexContainer>
    </ItemContainer>
  );
};

export default ServiceItem;
