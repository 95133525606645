import React from 'react';

interface Props {
  size: string;
  color: string;
}
const SVGAnimation: React.FC<Props> = ({ size, color }) => {
  return (
    /* generated by https://loading.io/ */
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{
        margin: 'auto',
        background: 'none',
        display: 'block',
        shapeRendering: 'auto',
      }}
      width={size}
      height={size}
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <g transform="rotate(0 50 50)">
        <rect x="48" y="20" rx="2" ry="4" width="4" height="20" fill={color}>
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.9615384615384615s"
            begin="-0.8413461538461539s"
            repeatCount="indefinite"
          />
        </rect>
      </g>
      <g transform="rotate(45 50 50)">
        <rect x="48" y="20" rx="2" ry="4" width="4" height="20" fill={color}>
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.9615384615384615s"
            begin="-0.7211538461538461s"
            repeatCount="indefinite"
          />
        </rect>
      </g>
      <g transform="rotate(90 50 50)">
        <rect x="48" y="20" rx="2" ry="4" width="4" height="20" fill={color}>
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.9615384615384615s"
            begin="-0.6009615384615384s"
            repeatCount="indefinite"
          />
        </rect>
      </g>
      <g transform="rotate(135 50 50)">
        <rect x="48" y="20" rx="2" ry="4" width="4" height="20" fill={color}>
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.9615384615384615s"
            begin="-0.4807692307692307s"
            repeatCount="indefinite"
          />
        </rect>
      </g>
      <g transform="rotate(180 50 50)">
        <rect x="48" y="20" rx="2" ry="4" width="4" height="20" fill={color}>
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.9615384615384615s"
            begin="-0.3605769230769231s"
            repeatCount="indefinite"
          />
        </rect>
      </g>
      <g transform="rotate(225 50 50)">
        <rect x="48" y="20" rx="2" ry="4" width="4" height="20" fill={color}>
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.9615384615384615s"
            begin="-0.24038461538461536s"
            repeatCount="indefinite"
          />
        </rect>
      </g>
      <g transform="rotate(270 50 50)">
        <rect x="48" y="20" rx="2" ry="4" width="4" height="20" fill={color}>
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.9615384615384615s"
            begin="-0.12019230769230768s"
            repeatCount="indefinite"
          />
        </rect>
      </g>
      <g transform="rotate(315 50 50)">
        <rect x="48" y="20" rx="2" ry="4" width="4" height="20" fill={color}>
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="0.9615384615384615s"
            begin="0s"
            repeatCount="indefinite"
          />
        </rect>
      </g>
    </svg>
  );
};

export default SVGAnimation;
