import { Button } from 'components/atoms/button';
import CategorySelector from 'components/organisms/category-selector';
import { useAsync } from 'context/async-context';
import { useStepsContext } from 'context/steps-context';
import { IServicePatch } from 'models/service';
import { ICategory, ISkill } from 'models/skill';
import { useState } from 'react';
import { serviceService } from 'services/service-service';
import { Typography } from 'styles/typography';

export const WhichService = () => {
  const [category, setCategory] = useState<ICategory>();
  const [subcategories, setSubcategories] = useState<ISkill[]>([]);
  const { nextStep, getData, patchData } = useStepsContext();
  const { handledAsync } = useAsync();

  const previousData = getData<IServicePatch>();

  const btnClicked = async () => {
    handledAsync(async () => {
      const payload = {
        category: category?.id || subcategories[0]?.categories[0],
        sub_category: subcategories[0]?.skill_id,
      } as IServicePatch;

      if (previousData?.uid) {
        await patchData<IServicePatch>(payload, true);
      } else {
        const resp = await serviceService.createService(payload);
        await patchData<IServicePatch>({ ...resp, ...payload }, false);
      }
      nextStep();
    });
  };

  return (
    <>
      <Typography
        as="h3"
        color="title"
        fontSize="heading3"
        fontWeight="bold"
        lineHeight="48px"
        display="block"
        letterSpacing="-0.01em"
        margin="0 0 24px"
      >
        {category
          ? `Selecione o serviço específico em ${category.name.toLowerCase()}`
          : 'Que tipo de serviço você precisa de atendimento?'}
      </Typography>
      <CategorySelector
        onCategorySelected={setCategory}
        onSubcategorySelected={setSubcategories}
        showSearch={!category}
        resetOnBackToCategories
        initialSubcategories={
          previousData.sub_category ? [previousData.sub_category] : []
        }
      />
      <Button disabled={!subcategories.length} onClick={btnClicked}>
        Continuar
      </Button>
    </>
  );
};
