import { Button } from 'components/atoms/button/index';
import { useAsync } from 'context/async-context';
import { useStepsContext } from 'context/steps-context';
import { IServiceOffer } from 'models/service-offer';
import { serviceOfferService } from 'services/service-offer-service';
import { Typography } from 'styles/typography';

export const Tips = () => {
  const { handledAsync } = useAsync();
  const { nextStep, getData } = useStepsContext();

  const btnClicked = () => {
    handledAsync(async () => {
      const data = getData<IServiceOffer>();
      await serviceOfferService.createServiceOffer(data);
      nextStep();
    });
  };

  return (
    <>
      <Typography
        as="h3"
        color="title"
        fontSize="heading3"
        fontWeight="bold"
        lineHeight="48px"
        display="block"
        letterSpacing="-0.01em"
        margin="0 0 41px"
      >
        Dicas!
      </Typography>
      <Typography as="p" fontSize="body1" margin="0 0 10px">
        Ferramentas
      </Typography>
      <Typography as="p" margin="0 0 54px">
        Se seu serviço precisa de ferramenta, lembre-se de levá-las.
      </Typography>
      <Typography as="p" fontSize="body1" margin="0 0 10px">
        Materiais
      </Typography>
      <Typography as="p" margin="0 0 60px">
        Fale com o cliente sobre a lista de materiais necessários.
      </Typography>
      <Typography as="p" fontSize="body1" margin="0 0 10px">
        Combine tudo com o cliente
      </Typography>
      <Typography as="p" margin="0 0 60px">
        Não esqueça de combinar com o cliente todos os detalhes do serviço antes
        de iniciar o serviço.
      </Typography>
      <Button onClick={btnClicked} disableChevron>
        Finalizar
      </Button>
    </>
  );
};
