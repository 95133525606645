import React, { useEffect, useState } from 'react';

import { TextareaStyled } from './styled';

interface ITextareaProps {
  initialData?: string;
  onChange: (value: string) => void;
  placeholder?: string;
  value?: string;
  maxLength?: number;
}

const Textarea: React.FC<ITextareaProps> = ({
  placeholder,
  onChange,
  value,
  initialData,
  maxLength,
}) => {
  useEffect(() => {
    handleChange(initialData || '');
  }, [initialData]);
  const [newInput, setNewInput] = useState('');

  const validateTextarea = (inputValue) => {
    return inputValue.length > 0;
  };

  const handleChange = (inputValue) => {
    const newValue = inputValue.slice(0, maxLength);

    const isValid = validateTextarea(inputValue);
    setNewInput(newValue);
    onChange(isValid ? newValue : '');
  };

  return (
    <>
      <TextareaStyled
        placeholder={placeholder}
        defaultValue={value !== undefined ? undefined : initialData}
        onChange={(e) => handleChange(e.target.value)}
        value={value ?? newInput}
      />
    </>
  );
};

export default Textarea;
