import Avatar from 'components/molecules/avatar';
import ServiceItem from 'components/molecules/service-item';
import Header from 'components/molecules/header';
import CommentList from 'components/organisms/comment-list';
import { useAsync } from 'context/async-context';
import { useEffect, useState } from 'react';
import { clientsService } from 'services/clients-service';
import { serviceService } from 'services/service-service';
import { IService } from 'models/service';
import { meService } from 'services/me-service';
import { useAlert } from 'context/alert-context';
import { IClient } from 'models/user';
import { Link, useLocation, useParams } from 'react-router-dom';
import { SimpleCarousel } from 'styles/elements';
import { FlexContainer } from 'styles/flex-container';
import { IconStar, IconVerificated } from 'styles/icons';
import { Spacing } from 'styles/spacing';
import { chatService } from 'services/chat-service';
import { LinkTypography, Typography } from 'styles/typography';
// eslint-disable-next-line import/no-duplicates
import { format, parseISO } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { ptBR } from 'date-fns/locale';
import { useUserContext } from 'context/user-context';
import { ProfileEnum } from 'enums/profile';
import Skeleton from 'react-loading-skeleton';
import ImagePreview from 'modals/image-preview';
import { useModalPage } from 'context/modal-page-context';
import {
  ClientDetailContainer,
  IconTextContainer,
  PersonInfo,
  PersonInfoRightArea,
  ServiceCard,
} from './styled';

const ClientDetail = () => {
  const [evaluationList, setEvaluationList] = useState<any[]>([]);
  const [clientProfile, setClientProfile] = useState<IClient>();
  const [serviceList, setServiceList] = useState<IService[]>([]);

  const { openModal } = useModalPage();
  const { pathname } = useLocation();
  const { uid } = useParams<any>();
  const { showAlert } = useAlert();
  const { handledAsync } = useAsync();
  const {
    client,
    user,
    worker,
    setWorker,
    openAuthModal,
    talkJSSession,
  } = useUserContext();

  const favorite = worker?.favorite_clients?.includes(uid);

  const onPhotoClicked = () => {
    openModal({
      component: ImagePreview,
      props: {
        image: clientProfile?.picture_path,
        caption: clientProfile?.name,
      },
    });
  };

  const favoriteClicked = (isFavorite) => {
    if (user && worker && clientProfile) {
      handledAsync(async () => {
        let favoriteValues;
        await chatService.setTalkSession(talkJSSession);

        const clientUser = await chatService.createUser({
          id: clientProfile.user_uid || '',
          name: clientProfile.name,
        });

        const conversation = await chatService.getOrCreateConversation(
          clientUser,
        );
        if (isFavorite) {
          favoriteValues = [...(worker?.favorite_clients || []), uid];
        } else {
          favoriteValues = worker?.favorite_clients?.filter(
            (item) => item !== uid,
          );
        }
        const newWorker = await meService.patchByProfile(ProfileEnum.WORKER, {
          favorite_clients: favoriteValues,
        });
        await chatService.favoriteConversation(conversation, isFavorite);
        setWorker(newWorker);
      });
    } else {
      openAuthModal(pathname, ProfileEnum.WORKER);
    }
  };

  useEffect(() => {
    handledAsync(async () => {
      let clientInfo = await clientsService.getClientByUid(uid);

      clientInfo = {
        assessment: clientInfo.score
          ? `Nota ${clientInfo.score}`
          : 'Sem avaliações',
        ...clientInfo,
      };
      setClientProfile(clientInfo);

      let evaluations = await clientsService.getClientEvaluationsByUid(uid);
      evaluations = evaluations.map((item) => ({
        id: item.uid,
        image: item.picture_path,
        name: item.name,
        rated: `Deu nota ${item.score} em ${format(
          parseISO(item.created_at),
          "MMM'. de' yyyy",
          { locale: ptBR },
        )}`,
        service: `Serviço de ${item.category}`,
        text: item.description,
      }));

      const services = await serviceService.getUserServices(uid);

      setServiceList(services);
      setEvaluationList(evaluations);
    });
  }, []);

  return (
    <FlexContainer>
      <ClientDetailContainer>
        <Header
          title="Cliente"
          smallMargin
          onFavoriteClicked={favoriteClicked}
          favoriteIcon={uid !== client?.client_uid}
          isFavorite={favorite}
        />

        <PersonInfo>
          <Avatar
            size="lg"
            image={clientProfile?.picture_path}
            online={clientProfile?.online}
            onClick={onPhotoClicked}
          />
          <PersonInfoRightArea>
            <Typography display="block" fontSize="body1" lineHeight="120%">
              {clientProfile?.name || <Skeleton width="50%" />}
            </Typography>
            <Spacing size={1} />
            <Typography display="block" fontSize="body2">
              {clientProfile ? 'Cliente' : <Skeleton width="30%" />}
            </Typography>
          </PersonInfoRightArea>
        </PersonInfo>
        <IconTextContainer>
          <IconStar />
          <FlexContainer padding="0" as="div">
            {clientProfile ? (
              <>
                <Typography>{clientProfile?.assessment}</Typography>
                <Typography>
                  {clientProfile.qt_evaluation &&
                    (clientProfile.qt_evaluation === '1'
                      ? `Avaliado por ${clientProfile.qt_evaluation} usuário`
                      : `Avaliado por ${clientProfile.qt_evaluation} usuários`)}
                </Typography>
              </>
            ) : (
              <>
                <Skeleton width="200px" />
                <Skeleton width="200px" />
              </>
            )}
          </FlexContainer>
        </IconTextContainer>

        <Spacing size={2} />

        <IconTextContainer
          as="button"
          onClick={() => {
            showAlert({
              title: 'E-mail e telefone verificados',
              text:
                'A validação de certas informações dos usuários é a forma de manter o Tô Parado seguro para todos.<br /><br /> Dessa forma, sabemos que os usuários estão comprometidos e informando dados válidos.',
            });
          }}
        >
          <IconVerificated />
          <Typography>
            {clientProfile ? (
              'E-mail e telefone verificados'
            ) : (
              <Skeleton width="225px" />
            )}
          </Typography>
        </IconTextContainer>

        <Spacing size={3} />

        {clientProfile ? (
          client?.client_uid !== uid && (
            <Link to={`/conversa/${clientProfile?.user_uid}`}>
              <LinkTypography as="span" color="primary">
                Conversar com o cliente
              </LinkTypography>
            </Link>
          )
        ) : (
          <Skeleton width="60%" />
        )}
        <Spacing size={4} />

        {clientProfile?.location?.district && (
          <>
            <Typography
              as="h4"
              fontSize="body2"
              margin="0 0 13px 0"
              display="block"
            >
              Sobre
            </Typography>
            <Typography fontSize="body1" margin="0 0 66px 0">
              Mora em {clientProfile?.location?.district},{' '}
              {clientProfile?.location?.city}
            </Typography>
          </>
        )}

        {serviceList.length > 0 && (
          <>
            <Typography
              as="h4"
              fontSize="body2"
              margin="0 0 30px 0"
              display="block"
            >
              Serviços em aberto
            </Typography>
            <SimpleCarousel firstItemMargin>
              {serviceList.map((service) => (
                <ServiceCard key={service.uid}>
                  <ServiceItem item={service} noCarousel />
                </ServiceCard>
              ))}
            </SimpleCarousel>
          </>
        )}
        {clientProfile && (
          <>
            <Typography
              as="h4"
              fontSize="body2"
              margin="68px 0 28px 0"
              display="block"
            >
              Nota e Histórico de serviços ({evaluationList?.length})
            </Typography>
            <CommentList isWorker items={evaluationList} />
          </>
        )}
      </ClientDetailContainer>
    </FlexContainer>
  );
};

export default ClientDetail;
