/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { INotification } from 'models/notification';
import { firebaseService } from './firebase-service';

class NotificationService {
  private async getInstance() {
    const userToken = await firebaseService.getUserToken();
    return axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}/notifications`,
      headers: {
        Authorization: userToken
          ? `Bearer ${userToken}`
          : process.env.REACT_APP_API_TOKEN,
      },
    });
  }

  async getAll(profile, offset = 0, limit = 5) {
    return (await this.getInstance())
      .get<INotification[]>(`/${profile}?offset=${offset}&limit=${limit}`)
      .then((resp) => resp.data);
  }

  async getUnread() {
    return (await this.getInstance())
      .get<number>(`/unread`)
      .then((resp) => resp.data);
  }
}

export const notificationService = new NotificationService();
