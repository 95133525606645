import { Button } from 'components/atoms/button/index';
import RadioList, { IRadioItem } from 'components/organisms/radio-list';
import { useAsync } from 'context/async-context';
import { useStepsContext } from 'context/steps-context';
import { PaymentTypeEnum } from 'enums/payment-type';
import { IServicePatch } from 'models/service';
import { useState } from 'react';
import { responsiveSize } from 'styles/sizes';
import { Spacing } from 'styles/spacing';
import { Typography } from 'styles/typography';

const RadioListItems: IRadioItem[] = [
  {
    label: 'Diária',
    id: PaymentTypeEnum.DAILY,
    placeholder: 'Digite o valor para um dia',
    showInput: true,
  },
  {
    label: 'Meia diária',
    id: PaymentTypeEnum.HALF_DAILY,
    placeholder: 'Digite o valor de meia diária',
    showInput: true,
  },
  {
    label: 'Valor hora',
    id: PaymentTypeEnum.HOUR_VALUE,
    placeholder: 'Digite o valor hora',
    showInput: true,
  },
  {
    label: 'Valor total',
    id: PaymentTypeEnum.TOTAL_VALUE,
    placeholder: 'Digite o valor total',
    showInput: true,
  },
  {
    label: 'Orçamento',
    id: PaymentTypeEnum.BUDGET,
    tooltipText:
      'Receba do prestador uma estimativa de valor e atividades para o seu serviço. Opção ideal para quem não tem certeza de quanto pagar.',
  },
];

export const HowToPay = () => {
  const { nextStep, getData, patchData } = useStepsContext();
  const previousData = getData<IServicePatch>();

  const [selected, setSelected] = useState(previousData.payment_type || '');
  const [inputValue, setInputValue] = useState(
    `${
      previousData.payment_value
        ? parseFloat(`${previousData.payment_value}`).toFixed(2)
        : ''
    }`,
  );
  const { handledAsync } = useAsync();

  const btnClicked = async () => {
    handledAsync(async () => {
      const newValue = inputValue.replace(/\./g, '').replace(',', '.');
      await patchData<IServicePatch>(
        {
          payment_type: selected,
          payment_value: inputValue ? parseFloat(newValue) : null,
        },
        true,
      );
      nextStep();
    });
  };

  const radioClicked = (id: string) => {
    setSelected(id);
    setInputValue('');
  };

  return (
    <>
      <Typography
        as="h3"
        color="title"
        fontSize="heading3"
        fontWeight="bold"
        display="block"
        letterSpacing="-0.01em"
        margin={`0 0 ${responsiveSize(3)}`}
      >
        Como gostaria de pagar?
      </Typography>
      <RadioList
        onChange={radioClicked}
        value={selected}
        radioItems={RadioListItems}
        onInputChange={setInputValue}
        inputInitialData={inputValue}
      />
      <Spacing size={3} />
      <Button
        disabled={
          !selected || (selected !== PaymentTypeEnum.BUDGET && !inputValue)
        }
        bottomFixed={false}
        onClick={btnClicked}
      >
        Continuar
      </Button>
    </>
  );
};
