import styled from 'styled-components';
import { responsiveSize } from 'styles/sizes';

export const SliderContainer = styled.div`
  position: relative;

  img {
    width: 100%;
  }

  ul {
    &.slick-dots {
      bottom: 3px;
    }

    li {
      width: 4px;
      button {
        width: 4px;
        height: 4px;
        background: #fff;
        border-radius: 4px;
        padding: 0;
        &:before {
          content: '';
        }
      }

      &.slick-active {
        width: 24px;
        transition: all 0.3s;
        button {
          transition: all 0.3s;
          width: 24px;
        }
      }
    }
  }
`;

export const SliderItem = styled.div``;

export const TextContent = styled.div`
  height: ${responsiveSize(14)};
`;
