import { Button } from 'components/atoms/button';
import { useAsync } from 'context/async-context';
import { useUserContext } from 'context/user-context';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { FlexContainer } from 'styles/flex-container';
import { Spacing } from 'styles/spacing';
import { Typography } from 'styles/typography';
import { emergencyService } from 'services/emergency-service';
import { ContactAvatar, RightCol } from './styled';

const EmergencyActivated = () => {
  const history = useHistory();
  const { user } = useUserContext();
  const { handledAsync } = useAsync();

  const handleBtnClicked = () => {
    handledAsync(async () => {
      await emergencyService.actionEmergency('deactivated');
      history.push('/t/servicos');
    });
  };

  return (
    <FlexContainer darkMode>
      <Spacing size={14} />
      <Typography
        as="h3"
        fontSize="heading3"
        fontWeight="bold"
        letterSpacing="-0.01em"
        display="block"
      >
        Emergência ativada
      </Typography>
      <Spacing size={5} />
      <FlexContainer direction="row" padding="0" gap="15px" flex1={false}>
        <ContactAvatar />
        <RightCol>
          <Typography
            fontSize="body1"
            lineHeight="120%"
            display="block"
            margin="0 0 8px 0"
          >
            {user?.emergency_contact}
          </Typography>
          <Typography display="block">
            {user?.emergency_cellphone}
            <br />A gente tá ligando pro seu contato de emergência
          </Typography>
        </RightCol>
      </FlexContainer>

      <Button disableChevron onClick={handleBtnClicked}>
        Encerrar emergência
      </Button>
    </FlexContainer>
  );
};

export default EmergencyActivated;
