import styled from 'styled-components';
import { colors } from 'styles/colors';

export const CurrencyStyled = styled.span`
  color: ${colors.text};
  font-size: 20px;
  line-height: 24px;
  font-family: 'Acid Grotesk';
  position: absolute;
  left: 25px;
  top: 21px;
  display: none;

  &.show {
    display: block;
  }
`;

export const InputContainer = styled.div`
  position: relative;

    input {
      width: 100%;
      border: 2px solid ${colors.text};
      padding: 19px 24px 17px 22px;
      color: ${colors.text};
      font-size: 20px;
      line-height: 24px;
      font-family: 'Acid Grotesk';
    }
  }

  .input-onchange {
    padding-left: 50px;
  }

  label {
    font-family: 'Acid Grotesk';
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    position: absolute;
    left: 17px;
    top: -12px;
    background: #fff;
    padding: 0 8px;
  }
`;
