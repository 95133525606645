import { setupMaster } from 'cluster';
import { Button } from 'components/atoms/button';
import Textarea from 'components/atoms/textarea';
import Header from 'components/molecules/header';
import { useAlert } from 'context/alert-context';
import { useAsync } from 'context/async-context';
import { useUserContext } from 'context/user-context';
import { ProfileEnum } from 'enums/profile';
import { IModalDefaultProps } from 'modals/types';
import { IWorker } from 'models/user';
import React, { useState } from 'react';
import { Modal, ModalTransition } from 'react-simple-hook-modal';
import { meService } from 'services/me-service';
import { workersService } from 'services/workers-service';
import { FlexContainer } from 'styles/flex-container';
import { Typography } from 'styles/typography';

type Props = IModalDefaultProps;
const StoryTextModal: React.FC<Props> = ({ isModalOpen, onClose }) => {
  const [inputValue, setInputValue] = useState('');

  const { handledAsync } = useAsync();
  const { showToast } = useAlert();
  const { worker, setWorker } = useUserContext();

  const handleBtnClicked = () => {
    handledAsync(async () => {
      const payload = {
        bio: inputValue,
      } as IWorker;
      await meService.patchByProfile(ProfileEnum.WORKER, payload);
      setWorker({ ...worker, ...payload });
      showToast({
        text: 'História atualizada',
      });
      onClose();
    });
  };

  return (
    <Modal
      id="story-text-modal"
      modalClassName="no-padding"
      isOpen={isModalOpen}
      transition={ModalTransition.SCALE}
    >
      <FlexContainer fullPageHeight>
        <Header title="" isModal onCloseModal={onClose} />
        <Typography
          as="h3"
          color="title"
          fontSize="heading3"
          fontWeight="bold"
          display="block"
          letterSpacing="-0.01em"
          margin="0 0 51px"
        >
          Escreva sobre você
        </Typography>
        <Textarea
          placeholder="Escreva sobre você"
          onChange={setInputValue}
          initialData={worker?.bio}
          maxLength={500}
        />
        <Button
          disabled={!inputValue}
          onClick={handleBtnClicked}
          disableChevron
        >
          Salvar
        </Button>
      </FlexContainer>
    </Modal>
  );
};

export default StoryTextModal;
