import { Button } from 'components/atoms/button';
import { useAsync } from 'context/async-context';
import { useModalPage } from 'context/modal-page-context';
import { useUserContext } from 'context/user-context';
import { PaymentTypeEnum, getPaymentTypeDesc } from 'enums/payment-type';
import { ProfileEnum } from 'enums/profile';
import { IProgressStatusEnum } from 'enums/progress-status';
import { IServiceStatusEnum } from 'enums/service-status';
import { currencyFormatted } from 'helpers';
import ServiceRating from 'modals/service-rating';
import { IService } from 'models/service';
import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { serviceService } from 'services/service-service';
import { FlexContainer } from 'styles/flex-container';
import { IconClock } from 'styles/icons';
import { responsiveSize } from 'styles/sizes';
import { Spacing } from 'styles/spacing';
import { Typography } from 'styles/typography';

interface Props {
  service?: IService;
  status?: IProgressStatusEnum;
  inProgress?: boolean;
  otherUid?: string;
  reloadAction: () => void;
}
const ActionButton: React.FC<Props> = ({
  service,
  status,
  inProgress,
  otherUid,
  reloadAction,
}) => {
  const { handledAsync } = useAsync();
  const { openModal } = useModalPage();
  const { worker, openAuthModal } = useUserContext();

  const history = useHistory();
  const { pathname } = useLocation();
  const { serviceUid } = useParams<any>();

  const closeService = (done: boolean) => {
    if (otherUid) {
      handledAsync(async () => {
        await serviceService.terminateService(serviceUid, done);
        openModal({
          component: ServiceRating,
          onClose: async () => {
            await reloadAction();
          },
          props: {
            serviceUid,
            otherUid,
            role: service?.is_client ? ProfileEnum.CLIENT : ProfileEnum.WORKER,
          },
        });
      });
    }
  };

  const btnOfferClicked = () => {
    if (!worker) {
      openAuthModal(pathname, ProfileEnum.WORKER);
      return;
    }
    history.push(`/orcamento/${serviceUid}`);
  };

  if (inProgress) {
    return (
      <Button backgroundColor="blue" disableChevron>
        <FlexContainer
          direction="row"
          alignItems="center"
          padding="0"
          justifyContent="space-between"
        >
          O serviço deu certo?
          <Typography
            color="white"
            textDecoration="underline"
            margin={`0 0 0 ${responsiveSize(2)}`}
            onClick={() => closeService(true)}
          >
            Sim
          </Typography>
          <Typography
            color="white"
            textDecoration="underline"
            onClick={() => closeService(false)}
          >
            Não
          </Typography>
        </FlexContainer>
      </Button>
    );
  }

  if (!service?.is_client) {
    if (status === IProgressStatusEnum.ESTIMATE_SENT) {
      return (
        <Button
          backgroundColor="blue"
          onClick={() => null}
          disableChevron
          justifyContent="none"
        >
          <IconClock />
          <Spacing size={4} direction="vertical" />
          <Typography fontSize="body2" color="white" lineHeight="150%">
            {service?.payment_type === PaymentTypeEnum.BUDGET
              ? 'Orçamento enviado!'
              : 'Você já se candidatou.'}
            <br />
            Aguarde o retorno do cliente
          </Typography>
        </Button>
      );
    }

    if (
      service?.status === IServiceStatusEnum.PUBLISHED &&
      status === IProgressStatusEnum.NONE
    ) {
      return (
        <Button onClick={btnOfferClicked}>
          {service.payment_type === PaymentTypeEnum.BUDGET ? (
            'Fazer orçamento'
          ) : (
            <FlexContainer
              direction="row"
              alignItems="center"
              padding={`0 ${responsiveSize(2)} 0 0`}
              justifyContent="space-between"
            >
              Trabalhar
              <Typography color="white" fontWeight="bold" align="center">
                {currencyFormatted.format(service.payment_value)} /{' '}
                {getPaymentTypeDesc(service.payment_type)}
              </Typography>
            </FlexContainer>
          )}
        </Button>
      );
    }
  }

  return null;
};

export default ActionButton;
