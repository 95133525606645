import ChooseProviderPage from 'components/templates/choose-provider-page';
import { useAsync } from 'context/async-context';
import { useStepsContext } from 'context/steps-context';
import { useUserContext } from 'context/user-context';
import { LoginProviderEnum } from 'enums/login-provider';
import { capitalizeText } from 'helpers';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { firebaseService } from 'services/firebase-service';
import { ProfileEnum } from 'enums/profile';
import { useModalPage } from 'context/modal-page-context';
import SocialLoginProfile from 'modals/social-login-profile';
import { meService } from 'services/me-service';

const LoginChooseProvider = () => {
  const history = useHistory();
  const { nextStep, goToSuccess } = useStepsContext();
  const { handledAsync } = useAsync();
  const { openModal } = useModalPage();
  const { user } = useUserContext();

  const socialLogin = async (type: LoginProviderEnum) => {
    const capitalizedType = capitalizeText(
      Object.keys(LoginProviderEnum)[
        Object.values(LoginProviderEnum).indexOf(type)
      ],
    );

    handledAsync(
      async () => {
        const userCredential = await firebaseService.socialLogin(type);
        if (userCredential) {
          const userData = await meService.getUserInfo().catch((_) => null);
          if (!userData) {
            openModal({
              component: SocialLoginProfile,
              props: {
                lead: {
                  lead_name: userCredential.user?.displayName || '',
                  email: userCredential.user?.email || '',
                },
              },
            });
          } else {
            goToSuccess();
          }
        }
      },
      () =>
        `Ocorreu um erro ao fazer login usando sua conta do ${capitalizedType}.`,
    );
  };

  useEffect(() => {
    if (user?.profiles?.includes(ProfileEnum.WORKER)) {
      history.push('/t/servicos');
    } else if (user?.profiles?.includes(ProfileEnum.CLIENT)) {
      history.push('/t/prestadores');
    }
  }, []);

  return (
    <>
      <ChooseProviderPage
        heading="Entre na sua conta do Tô Parado"
        socialLoginClicked={socialLogin}
        mainActionText="Ou entre com os seus dados"
        mainButtonClicked={nextStep}
        mainButtonTestId="login-phone-password"
        secondaryButtonText="Quero criar uma conta"
        secondaryButtonClicked={() => history.push('/')}
      />
    </>
  );
};

export default LoginChooseProvider;
