import { useAsync } from 'context/async-context';
import { StepsContextProvider } from 'context/steps-context';
import { StorageKeyEnum } from 'enums/storage-key';
import { IServicePatch } from 'models/service';
import React from 'react';
import { serviceService } from 'services/service-service';
import { HowToPay } from './how-to-pay';
import { Intro } from './intro';
import { ReviewCreatedService } from './review-created-service';
import { ServiceDescription } from './service-description';
import { ServicePhotos } from './service-photos';
import { WhichService } from './which-service';
import { ZipCode } from './zip-code';

const CreateService: React.FC<unknown> = () => {
  const { handledAsync } = useAsync();

  const baseUrl = '/criar-servico';
  const allPages = [
    { url: `${baseUrl}/intro`, component: Intro, contrastHeader: true },
    { url: `${baseUrl}/qual-servico`, component: WhichService },
    { url: `${baseUrl}/cep`, component: ZipCode },
    { url: `${baseUrl}/como-pagar`, component: HowToPay },
    { url: `${baseUrl}/descricao-do-servico`, component: ServiceDescription },
    { url: `${baseUrl}/fotos-do-servico`, component: ServicePhotos },
    { url: `${baseUrl}/revisar-servico`, component: ReviewCreatedService },
  ];

  const patchService = async (
    data: Partial<IServicePatch>,
    previousData?: IServicePatch,
  ) => {
    if (previousData?.uid) {
      await serviceService.patchService(previousData?.uid, data);
    }
  };

  return (
    <StepsContextProvider
      routes={allPages}
      headerTitle="Publicar um serviço"
      successRoute="/t/perfil"
      handlePatchData={patchService}
      storageKey={StorageKeyEnum.SERVICE_DRAFT}
    />
  );
};

export default CreateService;
