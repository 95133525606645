/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import Chats from 'pages/chats';
import InProgress from 'pages/in-progress';
import Profile from 'pages/profile';
import Workers from 'pages/workers';
import ViewServices from 'pages/view-services';
import { useUserContext } from 'context/user-context';
import {
  IconTool,
  IconShirt,
  IconCalendar,
  IconChat,
  IconProfile,
} from 'styles/icons';
import {
  LabelStyled,
  TabLinkStyled,
  ToolbarContainer,
  NotificationBadge,
} from './styled';

export interface IToolbarItem {
  route: string;
  icon?: any;
  label: string;
  authenticated?: boolean;
  component: any;
}

const Toolbar: FC = () => {
  const { pathname } = useLocation();
  const { missingProfileData } = useUserContext();
  const pages: IToolbarItem[] = [
    {
      route: '/t/servicos',
      icon: <IconTool />,
      label: 'Serviços',
      component: ViewServices,
    },
    {
      route: '/t/prestadores',
      icon: <IconShirt />,
      label: 'Prestadores',
      component: Workers,
    },
    {
      route: '/t/trabalhos',
      icon: <IconCalendar />,
      label: 'Trabalhos',
      component: InProgress,
      authenticated: true,
    },
    {
      route: '/t/conversas',
      icon: <IconChat />,
      label: 'Conversas',
      component: Chats,
      authenticated: true,
    },
    {
      route: '/t/perfil',
      icon: <IconProfile />,
      label: 'Perfil',
      component: Profile,
      authenticated: true,
    },
  ];
  return (
    <ToolbarContainer>
      {pages.map((tab) => (
        <TabLinkStyled
          to={tab.route}
          key={tab.label}
          active={tab.route === pathname ? 1 : 0}
        >
          {tab.label === 'Perfil' && !!missingProfileData.length && (
            <NotificationBadge />
          )}
          {tab.icon}
          <LabelStyled>{tab.label}</LabelStyled>
        </TabLinkStyled>
      ))}
    </ToolbarContainer>
  );
};

export default Toolbar;
