import { IIdLabelOption } from 'models/id-label-option';

export enum PaymentTypeEnum {
  DAILY = 'daily',
  HALF_DAILY = 'half-daily',
  HOUR_VALUE = 'hour-value',
  TOTAL_VALUE = 'total-value',
  BUDGET = 'budget',
}

export const paymentTypeOptions: IIdLabelOption[] = [
  {
    label: 'Diária',
    id: PaymentTypeEnum.DAILY,
  },
  {
    label: 'Meia diária',
    id: PaymentTypeEnum.HALF_DAILY,
  },
  {
    label: 'Valor hora',
    id: PaymentTypeEnum.HOUR_VALUE,
  },
  {
    label: 'Valor total',
    id: PaymentTypeEnum.TOTAL_VALUE,
  },
  {
    label: 'Orçamento',
    id: PaymentTypeEnum.BUDGET,
  },
];

export const getPaymentTypeDesc = (value) => {
  return paymentTypeOptions.find((item) => item.id === value)?.label || value;
};
