import styled from 'styled-components';
import { colors } from 'styles/colors';
import { breakpoints, mediaQuery, responsiveSize } from 'styles/sizes';
import { Typography } from 'styles/typography';

export const IframeContainer = styled.section`
  width: 360px;
  height: 80%;
  min-height: ${breakpoints.smallHeight};
  max-height: 700px;
  z-index: 10;
  box-sizing: content-box;
  background: #fff9f9;
  border: 10px solid #fff9f9;
  border-top-width: 45px;
  border-bottom-width: 32px;
  border-radius: 28px;
  position: relative;

  img {
    position: absolute;
    transform: translate(-50%, -200%);
    transform-style: preserve-3d;
    left: 50%;
  }

  iframe {
    border-radius: 15px;
    border: 1px solid ${colors.text};
  }
`;

export const TextArea = styled.div`
  margin-top: -10%;
`;

export const LogoImage = styled.img`
  width: 300px;
  margin-bottom: ${responsiveSize(5)};
`;

export const BigLogo = styled.img`
  position: absolute;
  left: 43%;
  height: 90%;
  max-height: 805px;
  opacity: 0.5;

  ${mediaQuery('desktop', 'max')} {
    display: none;
  }
`;

export const BannerGoogleLink = styled.a`
  text-decoration: underline;
  margin-top: ${responsiveSize(5)};
  display: block;
`;

export const DesktopStyled = styled.section`
  background-color: ${colors.text};
  display: flex;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  ${mediaQuery('tabletXl', 'max')} {
    flex-direction: column;

    ${TextArea} {
      margin-top: 0;
      text-align: center;
    }

    ${Typography} {
      display: none;
    }

    ${BannerGoogleLink}, ${LogoImage} {
      margin: 0;
    }
  }
`;
