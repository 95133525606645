import styled from 'styled-components';

export const Container = styled.div``;

export const EachRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
`;

export const Icon = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 16px;
  min-width: 48px;
`;
