import { ButtonSecondaryStyled } from './styled';

interface IButtonSecondaryProps {
  variant?: 'blue' | 'red';
  onClick?: () => void;
  testId?: string;
}

const ButtonSecondary: React.FC<IButtonSecondaryProps> = ({
  variant,
  children,
  onClick,
  testId,
}) => {
  return (
    <ButtonSecondaryStyled
      id={testId}
      onClick={onClick}
      className={`bt-${variant}`}
    >
      {children}
    </ButtonSecondaryStyled>
  );
};

export default ButtonSecondary;
