/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Button } from 'components/atoms/button';
import InputPassword from 'components/atoms/input-password';
import { useAsync } from 'context/async-context';
import { useModalPage } from 'context/modal-page-context';
import { useStepsContext } from 'context/steps-context';
import { useUserContext } from 'context/user-context';
import { ILead } from 'models/lead';
import { useState } from 'react';
import { Typography } from 'styles/typography';
import ForgotPassword from '../forgot-password';

const LoginPassword = () => {
  const [inputValue, setInputValue] = useState('');
  const { nextStep, getData } = useStepsContext();
  const { handledAsync } = useAsync();
  const { login, registerDeviceToken } = useUserContext();
  const { openModal } = useModalPage();

  const handleBtnClicked = () => {
    handledAsync(
      async () => {
        const data = getData<ILead>();
        await login(data.cellphone, inputValue);
        registerDeviceToken();
        nextStep();
      },
      () => 'Celular e/ou senha inválido(s). Tente novamente.',
    );
  };

  const handleForgotPassword = () => {
    openModal({ component: ForgotPassword });
  };

  return (
    <>
      <Typography
        as="h3"
        color="title"
        fontSize="heading3"
        fontWeight="bold"
        lineHeight="48px"
        display="block"
        letterSpacing="-0.01em"
        margin="0 0 45px"
      >
        Digite sua senha
      </Typography>
      <InputPassword placeholder="Senha" onChange={setInputValue} />
      <Typography
        as="button"
        textDecoration="underline"
        display="block"
        margin="20px 0 0 0"
        onClick={handleForgotPassword}
      >
        Esqueci minha senha
      </Typography>

      <Button disabled={!inputValue} onClick={handleBtnClicked} disableChevron>
        Entrar no App
      </Button>
    </>
  );
};

export default LoginPassword;
