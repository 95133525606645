import { useRef } from 'react';
import Slider, { Settings } from 'react-slick';
import { Link } from 'react-router-dom';

import PersonCard from 'components/molecules/person-card';
import { Typography } from 'styles/typography';
import { IWorkerListItem } from 'models/user';

import {
  Container,
  SliderItem,
  LineSeparator,
  ServiceInformation,
} from './styled';

interface IWorkerListItemWithCategory extends IWorkerListItem {
  categoryName?: string;
}

interface PersonSliderProps {
  data: IWorkerListItemWithCategory[];
  isAccordionOpen: boolean;
  onChange: (index: number) => void;
}

const PersonSlider = ({
  data,
  isAccordionOpen,
  onChange,
}: PersonSliderProps) => {
  const settings: Settings = {
    infinite: false,
    arrows: false,
    dots: !isAccordionOpen,
    autoplay: false,
    autoplaySpeed: 100,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: !isAccordionOpen,
  };

  const state = useRef({ x: 0 });

  const handleMouseDown = (e) => {
    state.current.x = e.screenX;
  };

  const handleClick = (e) => {
    const delta = Math.abs(e.screenX - state.current.x);

    if (delta > 10) {
      e.preventDefault();
    }
  };

  return (
    <Container>
      <Slider
        {...settings}
        afterChange={(currentSlide) => onChange(currentSlide)}
      >
        {data.map((eachWorker) => (
          <SliderItem isAccordionOpen={isAccordionOpen} key={eachWorker.uid}>
            <Link
              to={`/perfil-prestador/${eachWorker.uid}`}
              onMouseDown={handleMouseDown}
              onClick={handleClick}
            >
              <ServiceInformation>
                <Typography as="span" fontSize="body2">
                  {eachWorker?.skills && eachWorker.skills[0]}
                </Typography>

                <LineSeparator />

                <Typography as="span" fontSize="body2">
                  {eachWorker.location?.city}
                </Typography>
              </ServiceInformation>

              <Typography
                as="p"
                fontSize="body2"
                fontWeight="bold"
                margin="0 0 12px 0"
              >
                Orçamento
              </Typography>

              <PersonCard
                image={eachWorker.picture_path}
                online={eachWorker.online}
                name={eachWorker.name}
                assessment={eachWorker.assessment}
                skills={eachWorker.skills}
              />
            </Link>
          </SliderItem>
        ))}
      </Slider>
    </Container>
  );
};

export default PersonSlider;
