import React from 'react';
import { Modal, ModalTransition } from 'react-simple-hook-modal';
import { ITypographProps, Typography } from 'styles/typography';
import { IconClose } from 'styles/icons';
import { IModalDefaultProps } from 'modals/types';
import { Button } from 'components/atoms/button';
import { Spacing } from 'styles/spacing';

export interface ISimpleModalOptions {
  title?: string;
  text?: string;
  htmlId?: string;
  buttonText?: string;
  onButtonClicked?: () => void;
  textMaxHeight?: string;
  transition?: ModalTransition;
  titleProps?: ITypographProps;
  textProps?: ITypographProps;
  showCloseButton?: boolean;
  modalClassName?: string;
}

interface Props extends IModalDefaultProps, ISimpleModalOptions {}

const SimpleModal: React.FC<Props> = ({
  htmlId = 'alert-modal',
  isModalOpen,
  onClose,
  title,
  text,
  textMaxHeight,
  buttonText,
  onButtonClicked = () => null,
  transition = ModalTransition.TOP_DOWN,
  titleProps,
  textProps,
  showCloseButton = true,
  modalClassName = undefined,
}) => {
  return (
    <Modal
      id={htmlId}
      modalClassName={modalClassName}
      isOpen={isModalOpen}
      transition={transition}
    >
      {showCloseButton && (
        <div style={{ textAlign: 'right' }}>
          <IconClose width={24} onClick={onClose} />
        </div>
      )}
      <Typography
        as="h3"
        color="title"
        fontSize="heading3"
        fontWeight="bold"
        display="block"
        letterSpacing="-0.01em"
        margin="0 0 19px"
        {...titleProps}
      >
        {title}
      </Typography>
      <Typography
        as="p"
        fontSize="body2"
        fontWeight="normal"
        display="block"
        {...textProps}
        style={
          textMaxHeight
            ? { maxHeight: textMaxHeight, overflow: 'auto' }
            : undefined
        }
        dangerouslySetInnerHTML={{ __html: text || '' }}
      />
      {buttonText && (
        <>
          <Spacing size={3} />
          <Button
            justifyContent="center"
            disableChevron
            onClick={() => {
              onButtonClicked();
              onClose();
            }}
          >
            {buttonText}
          </Button>
        </>
      )}
    </Modal>
  );
};

export default SimpleModal;
