import { StepsContextProvider } from 'context/steps-context';
import { useUserContext } from 'context/user-context';
import { ProfileEnum } from 'enums/profile';
import React from 'react';
import LoginChooseProvider from './login-choose-provider';
import LoginPassword from './login-password';
import LoginPhone from './login-phone';

const Login = () => {
  const { redirectRoute, setRedirectRoute, user } = useUserContext();

  const allPages = [
    { url: `/login/provedor`, component: LoginChooseProvider },
    { url: `/login/celular`, component: LoginPhone },
    { url: `/login/senha`, component: LoginPassword },
  ];

  const defaultRoute = user?.profiles?.includes(ProfileEnum.CLIENT)
    ? '/t/prestadores'
    : '/t/servicos';

  return (
    <StepsContextProvider
      routes={allPages}
      headerTitle="Entre com seus dados"
      successRoute={redirectRoute || defaultRoute}
      onFinish={() => setRedirectRoute('')}
    />
  );
};

export default Login;
